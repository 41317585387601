import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  facebookAuth: {
    id: '',
    userID: '',
    accessToken: '',
    name: '',
    expiresIn: 0,
    picture: {
      data: {
        height: 0,
        width: 0,
        url: '',
      },
    },
  },
  selectedFacebookPage: {},
  channelData: {},
  isLogged: false,
};

export default function facebookMessenger(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SET_FB_AUTH_STATE:
        draft.facebookAuth = { ...action.payload };
        break;

      case types.SET_FB_SELECTED_PAGE:
        draft.selectedFacebookPage = { ...action.payload };
        break;

      case types.SET_FB_CHANNEL_DATA:
        draft.channelData = { ...action.payload };
        break;

      case types.SET_FB_IS_LOGGED:
        draft.isLogged = action.payload;
        break;

      default:
    }
  });
}
