const Types = {
  LIST_TAGS: '@tags/LIST_TAGS',
  LIST_TAGS_SUCCESS: '@tags/LIST_TAGS_SUCCESS',

  CREATE_TAG: '@tags/CREATE_TAG',
  CREATE_TAG_SUCCESS: '@tags/CREATE_TAG_SUCCESS',

  EDIT_TAG: '@tags/EDIT_TAG',
  EDIT_TAG_SUCCESS: '@tags/EDIT_TAG_SUCCESS',

  REMOVE_TAG: '@tags/REMOVE_TAG',
  REMOVE_TAG_SUCCESS: '@tags/REMOVE_TAG_SUCCESS',
};

export default Types;
