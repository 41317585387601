import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { Container } from './styles';

const Tooltip = ({
  position,
  theme,
  render,
  children,
  show,
  className,
  width,
  ...rest
}) => {
  return (
    <>
      {!show ? (
        children
      ) : (
        <Container
          width={width}
          className={classNames(theme, position, className)}
          {...rest}
        >
          {render && <div className="tooltip-content">{render()}</div>}

          <div className="tooltip-target">{children}</div>
        </Container>
      )}
    </>
  );
};

Tooltip.propTypes = {
  render: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  children: PropTypes.element.isRequired,
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  theme: PropTypes.oneOf(['light', 'dark']),
  show: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string,
};

Tooltip.defaultProps = {
  position: 'bottom',
  theme: 'dark',
  show: true,
  className: '',
  width: '100px',
};

export default Tooltip;
