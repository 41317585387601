import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  reCaptchaValue: '',
  resetRef: false,
  disabled: true,
};

export default function reCaptcha(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SET_RECAPTCHA: {
        draft.reCaptchaValue = action.payload.reCaptchaValue;
        break;
      }
      case types.DISABLE_RECAPTCHA: {
        draft.disabled = action.payload.disabled;
        break;
      }
      case types.RESET_RECAPTCHA: {
        draft.resetRef = action.payload.resetRef;
        break;
      }
      default:
    }
  });
}
