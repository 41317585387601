export const formatSelectOptions = (data, useSameField = false) => {
  const dataSelect = [];
  data.forEach((element) => {
    if (useSameField) {
      dataSelect.push({ label: element, value: element });
    } else {
      dataSelect.push({ label: element.name, value: element.name });
    }
  });

  return dataSelect;
};
