import types from './types';

export const createIvr = (payload, callback) => ({
  type: types.CREATE_IVR,
  payload,
  callback,
});

export const deleteIvrRequest = ({ id }, callback) => ({
  type: types.DELETE_IVR_REQUEST,
  payload: { id, callback },
});
export const deleteIvrSuccess = ({ id }) => ({
  type: types.DELETE_IVR_SUCCESS,
  payload: { id },
});

export const createConfigIvrOmni = ({ payload }, callback) => ({
  type: types.CREATE_CONFIG_IVR_OMNI,
  payload: { payload, callback },
});
export const createConfigIvrOmniSuccess = (action) => ({
  type: types.CREATE_CONFIG_IVR_OMNI_SUCCESS,
  payload: action.payload,
});

export const createConfigIvrOmniInApiDS = ({ payload }, callback) => ({
  type: types.CREATE_CONFIG_IVR_OMNI_IN_API_DS,
  payload: { payload, callback },
});

export const fetchAllConfigIvrOmni = (action) => ({
  type: types.FETCH_ALL_CONFIG_IVR_OMNI,
  payload: action.payload,
});

export const fetchAllConfigIvrOmniSuccess = (action) => ({
  type: types.FETCH_ALL_CONFIG_IVR_OMNI_SUCCESS,
  payload: action.payload,
});

export const fetchConfigIvrOmniById = (id) => ({
  type: types.FETCH_CONFIG_IVR_OMNI_BY_ID,
  payload: { id },
});
export const fetchConfigIvrOmniByIdSuccess = (payload) => ({
  type: types.FETCH_CONFIG_IVR_OMNI_BY_ID_SUCCESS,
  payload,
});
export const fetchConfigIvrOmniByIdFailure = () => ({
  type: types.FETCH_CONFIG_IVR_OMNI_BY_ID_FAILURE,
});

export const updateConfigIvrOmni = ({ payload }, callback) => ({
  type: types.UPDATE_CONFIG_IVR_OMNI,
  payload: { payload, callback },
});

export const updateConfigIvrOmniSuccess = (action) => ({
  type: types.UPDATE_CONFIG_IVR_OMNI_SUCCESS,
  payload: action.payload,
});

export const removeConfigIvrOmni = (action) => ({
  type: types.REMOVE_CONFIG_IVR_OMNI,
  payload: action.payload,
});

export const removeConfigIvrOmniSuccess = (action) => ({
  type: types.REMOVE_CONFIG_IVR_OMNI_SUCCESS,
  payload: action.payload,
});

export const publishIvrRequest = (payload) => ({
  type: types.PUBLISH_IVR_REQUEST,
  payload,
});
export const publishIvrSuccess = (payload) => ({
  type: types.PUBLISH_IVR_SUCCESS,
  payload,
});
export const publishIvrFailure = () => ({
  type: types.PUBLISH_IVR_FAILURE,
});

export const fetchIvrsRequest = (payload) => ({
  type: types.FETCH_IVRS_REQUEST,
  payload,
});
export const fetchIvrsSuccess = (payload) => ({
  type: types.FETCH_IVRS_SUCCESS,
  payload,
});
export const fetchIvrsFailure = () => ({
  type: types.FETCH_IVRS_FAILURE,
});

export const ivrUploadAudiosRequest = (params, callback) => ({
  type: types.IVR_UPLOAD_AUDIOS_REQUEST,
  payload: { ...params, callback },
});
export const ivrUploadAudiosSuccess = ({ audios }) => ({
  type: types.IVR_UPLOAD_AUDIOS_SUCCESS,
  payload: { audios },
});
export const ivrUploadAudiosFailure = () => ({
  type: types.IVR_UPLOAD_AUDIOS_FAILURE,
});

export const fetchIvrAudios = ({ params }) => ({
  type: types.FETCH_IVR_AUDIOS,
  payload: { params },
});

export const fetchIvrAudiosSuccess = ({ audios }) => ({
  type: types.FETCH_IVR_AUDIOS_SUCCESS,
  payload: { audios },
});

export const deleteIvrAudios = ({ audioId }, callback) => ({
  type: types.DELETE_IVR_AUDIOS_REQUEST,
  payload: { audioId, callback },
});

export const deleteIvrAudiosSuccess = ({ audioId }) => ({
  type: types.DELETE_IVR_AUDIOS_SUCCESS,
  payload: { audioId },
});

export const updateIvrAudios = (payload, callback) => ({
  type: types.UPDATE_IVR_AUDIOS,
  payload: { ...payload, callback },
});
export const updateIvrAudiosSuccess = (payload) => ({
  type: types.UPDATE_IVR_AUDIOS_SUCCESS,
  payload,
});

export const cloneIvrRequest = (payload, callback) => ({
  type: types.CLONE_IVR_REQUEST,
  payload: { payload, callback },
});
export const cloneIvrSuccess = (data) => ({
  type: types.CLONE_IVR_SUCCESS,
  payload: data,
});

export const fetchIvrServicesByOrganization = (payload) => ({
  type: types.FETCH_IVR_SERVICES_BY_ORGANIZATION,
  payload,
});
export const fetchIvrServicesByOrganizationSuccess = (payload) => ({
  type: types.FETCH_IVR_SERVICES_BY_ORGANIZATION_SUCCESS,
  payload,
});
export const fetchIvrServicesByOrganizationFailure = (payload) => ({
  type: types.FETCH_IVR_SERVICES_BY_ORGANIZATION_FAILURE,
  payload,
});

export const fetchIvrDacDsQueues = ({ botId }) => ({
  type: types.FETCH_IVR_DAC_DS_QUEUES,
  payload: { botId },
});
export const fetchIvrDacDsQueuesSuccess = (data) => ({
  type: types.FETCH_IVR_DAC_DS_QUEUES_SUCCESS,
  payload: data,
});
export const fetchIvrDacDsQueuesFailure = () => ({
  type: types.FETCH_IVR_DAC_DS_QUEUES_FAILURE,
});

export const searchIvrAttendGroups = () => ({
  type: types.SEARCH_IVR_ATTEND_GROUPS,
});
export const searchIvrAttendGroupsSuccess = (data) => ({
  type: types.SEARCH_IVR_ATTEND_GROUPS_SUCCESS,
  payload: data,
});
export const searchIvrAttendGroupsFailure = () => ({
  type: types.SEARCH_IVR_ATTEND_GROUPS_FAILURE,
});

export const fetchAllWhatsappNumberchips = ({ botId }) => ({
  type: types.FETCH_ALL_WHATSAPP_NUMBERCHIPS,
  payload: { botId },
});
export const fetchAllWhatsappNumberchipsSuccess = (data) => ({
  type: types.FETCH_ALL_WHATSAPP_NUMBERCHIPS_SUCCESS,
  payload: data,
});
export const fetchAllWhatsappNumberchipsFailure = () => ({
  type: types.FETCH_ALL_WHATSAPP_NUMBERCHIPS_FAILURE,
});

export const fetchAllWhatsappTemplates = ({ botId, numberchip }) => ({
  type: types.FETCH_ALL_WHATSAPP_TEMPLATES,
  payload: { botId, numberchip },
});
export const fetchAllWhatsappTemplatesSuccess = (data) => ({
  type: types.FETCH_ALL_WHATSAPP_TEMPLATES_SUCCESS,
  payload: data,
});
export const fetchAllWhatsappTemplatesFailure = () => ({
  type: types.FETCH_ALL_WHATSAPP_TEMPLATES_FAILURE,
});

export const fetchNamaDatasets = ({ botId }) => ({
  type: types.FETCH_NAMA_DATASETS,
  payload: { botId },
});
export const fetchNamaDatasetsSuccess = (data) => ({
  type: types.FETCH_NAMA_DATASETS_SUCCESS,
  payload: data,
});
export const fetchNamaDatasetsFailure = () => ({
  type: types.FETCH_NAMA_DATASETS_FAILURE,
});

export const fetchTopdeskOperatorGroup = ({ botId }) => ({
  type: types.FETCH_TOPDESK_OPERATOR_GROUP,
  payload: { botId },
});
export const fetchTopdeskOperatorGroupSuccess = (data) => ({
  type: types.FETCH_TOPDESK_OPERATOR_GROUP_SUCCESS,
  payload: data,
});
export const fetchTopdeskOperatorGroupFailure = () => ({
  type: types.FETCH_TOPDESK_OPERATOR_GROUP_FAILURE,
});

export const generateTtsRequest = ({
  id,
  text,
  voice,
  botId,
  expire = true,
  audioName,
  audioSave,
  callback,
}) => ({
  type: types.GENERATE_TTS_REQUEST,
  payload: { id, text, voice, botId, expire, audioName, audioSave, callback },
});

export const generateTtsSaveSuccess = (payload) => ({
  type: types.GENERATE_TTS_SAVE_SUCCESS,
  payload,
});

export const generateTtsSuccess = (payload) => ({
  type: types.GENERATE_TTS_SUCCESS,
  payload,
});

export const generateTtsFailure = () => ({
  type: types.GENERATE_TTS_FAILURE,
});

export const setAudioTmpTts = (payload) => ({
  type: types.SET_AUDIO_TMP_TTS,
  payload,
});

export const fetchTtsVoices = () => ({
  type: types.FETCH_TTS_VOICES,
});
export const fetchTtsVoicesSuccess = (payload) => ({
  type: types.FETCH_TTS_VOICES_SUCCESS,
  payload,
});
export const fetchTtsVoicesFailure = () => ({
  type: types.FETCH_TTS_VOICES_FAILURE,
});

export const searchIvrAudios = ({ botId, queryParams }) => ({
  type: types.SEARCH_IVR_AUDIOS,
  payload: { botId, queryParams },
});
export const searchIvrAudiosSuccess = (payload) => ({
  type: types.SEARCH_IVR_AUDIOS_SUCCESS,
  payload,
});
export const searchIvrAudiosFailure = (payload) => ({
  type: types.SEARCH_IVR_AUDIOS_FAILURE,
  payload,
});
export const resetSearchIvrAudios = () => ({
  type: types.RESET_SEARCH_IVR_AUDIOS,
});

export const fetchIvrItemsLogKeys = ({ botId }) => ({
  type: types.FETCH_IVR_ITEMS_LOG_KEYS,
  payload: { botId },
});
export const fetchIvrItemsLogKeysSuccess = (data) => ({
  type: types.FETCH_IVR_ITEMS_LOG_KEYS_SUCCESS,
  payload: data,
});
export const fetchIvrItemsLogKeysFailure = () => ({
  type: types.FETCH_IVR_ITEMS_LOG_KEYS_FAILURE,
});

export const searchIvrAccountsByOrg = () => ({
  type: types.SEARCH_IVR_ACCOUNTS_BY_ORG,
});
export const searchIvrAccountsByOrgSuccess = (payload) => ({
  type: types.SEARCH_IVR_ACCOUNTS_BY_ORG_SUCCESS,
  payload,
});
export const searchIvrAccountsByOrgFailure = () => ({
  type: types.SEARCH_IVR_ACCOUNTS_BY_ORG_FAILURE,
});
