import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  ivrPlans: { data: [], error: false, loading: true },
  planHistory: { data: [], error: false, loading: true },
  isLoadingSelectIvrPlanId: null,
  planConsumptionByMonth: { data: null, error: false, loading: true },
  planConsumptionByDay: { data: null, error: false, loading: true },
};

export default function ivrPlans(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_IVR_PLANS: {
        draft.ivrPlans = { error: false, loading: true };
        break;
      }
      case types.FETCH_IVR_PLANS_SUCCESS: {
        const data = action.payload;
        draft.ivrPlans = { error: false, loading: false, data };
        break;
      }
      case types.FETCH_IVR_PLANS_FAILURE: {
        draft.ivrPlans = { error: true, loading: false };
        break;
      }

      case types.SEARCH_IVR_PLAN_HISTORY: {
        draft.planHistory.error = false;
        draft.planHistory.loading = true;
        break;
      }
      case types.SEARCH_IVR_PLAN_HISTORY_SUCCESS: {
        const data = action.payload;
        draft.planHistory = { error: false, loading: false, data };
        break;
      }
      case types.SEARCH_IVR_PLAN_HISTORY_FAILURE: {
        draft.planHistory = { error: true, loading: false };
        break;
      }

      case types.SELECT_IVR_PLAN:
        draft.isLoadingSelectIvrPlanId = action.payload?.id;
        break;
      case types.SELECT_IVR_PLAN_SUCCESS:
        draft.isLoadingSelectIvrPlanId = null;
        break;
      case types.SELECT_IVR_PLAN_FAILURE:
        draft.isLoadingSelectIvrPlanId = null;
        break;

      case types.FETCH_IVR_PLAN_CONSUMPTION_BY_MONTH: {
        draft.planConsumptionByMonth = { error: false, loading: true };
        break;
      }
      case types.FETCH_IVR_PLAN_CONSUMPTION_BY_MONTH_SUCCESS: {
        const data = action.payload;
        draft.planConsumptionByMonth = { error: false, loading: false, data };
        break;
      }
      case types.FETCH_IVR_PLAN_CONSUMPTION_BY_MONTH_FAILURE: {
        draft.planConsumptionByMonth = {
          error: true,
          loading: false,
          data: null,
        };
        break;
      }

      case types.FETCH_IVR_PLAN_CONSUMPTION_BY_DAY: {
        draft.planConsumptionByDay.error = false;
        draft.planConsumptionByDay.loading = true;
        break;
      }
      case types.FETCH_IVR_PLAN_CONSUMPTION_BY_DAY_SUCCESS: {
        const data = action.payload;
        draft.planConsumptionByDay = { error: false, loading: false, data };
        break;
      }
      case types.FETCH_IVR_PLAN_CONSUMPTION_BY_DAY_FAILURE: {
        draft.planConsumptionByDay = {
          error: true,
          loading: false,
          data: null,
        };
        break;
      }

      default:
        return state;
    }
  });
}
