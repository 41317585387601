import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;

  .tooltip-content {
    border-radius: 5px;
    font-size: 13px;
    display: none;
    padding: 6px 10px;
    position: absolute;
    transition: opacity 0.5s;
    z-index: 6;
    width: ${({ width }) => width};

    &::after {
      border-style: solid;
      border-width: 6px;
      content: '';
      position: absolute;
    }
  }

  &:hover {
    .tooltip-content {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }

  &.dark {
    .tooltip-content {
      background-color: var(--neutral-3);
      color: #fff;
      box-shadow: 0px 5px 6px #00000040;

      &::after {
        border-color: var(--neutral-3) transparent transparent transparent;
      }
    }
  }

  &.light {
    .tooltip-content {
      background: #fff;
      border-radius: 5px;
      box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);

      &::after {
        border-color: #fff transparent transparent transparent;
      }

      &::before {
        content: '';
        border-style: solid;
        border-width: 9px;
        position: absolute;
        border-color: #d8d8d8 transparent transparent transparent;
      }
    }
  }

  &.top {
    .tooltip-content {
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
    }

    &.dark .tooltip-content {
      &::after {
        top: calc(100%);
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.light .tooltip-content {
      &::before,
      &::after {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &.bottom {
    .tooltip-content {
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
    }

    &.dark .tooltip-content {
      &::after {
        bottom: calc(100%);
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
    }

    &.light .tooltip-content {
      &::before,
      &::after {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
    }
  }

  &.right {
    .tooltip-content {
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    }

    &.dark .tooltip-content {
      &::after {
        right: 100%;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
      }
    }

    &.light .tooltip-content {
      &::before,
      &::after {
        right: 100%;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  &.left {
    .tooltip-content {
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    }

    &.dark .tooltip-content {
      &::after {
        left: 100%;
        top: 50%;
        transform: translateY(-50%) rotate(270deg);
      }
    }

    &.light .tooltip-content {
      &::before,
      &::after {
        left: 100%;
        top: 50%;
        transform: translateY(-50%) rotate(270deg);
      }
    }
  }

  &.show {
    .tooltip-content {
      display: inline-block;
    }
  }
`;
