import types from './types';

export const fetchAllScheduleGroups = (payload) => ({
  type: types.FETCH_ALL_SCHEDULE_GROUPS,
  payload,
});
export const fetchAllScheduleGroupsSuccess = (payload) => ({
  type: types.FETCH_ALL_SCHEDULE_GROUPS_SUCCESS,
  payload,
});
export const fetchAllScheduleGroupsFailure = () => ({
  type: types.FETCH_ALL_SCHEDULE_GROUPS_FAILURE,
});

export const fetchScheduleGroup = (payload) => ({
  type: types.FETCH_SCHEDULE_GROUP,
  payload,
});

export const fetchScheduleGroupSuccess = ({ payload }) => ({
  type: types.FETCH_SCHEDULE_GROUP_SUCCESS,
  payload,
});

export const createScheduleGroup = (group, callback) => ({
  type: types.CREATE_SCHEDULE_GROUP,
  payload: { group, callback },
});

export const createScheduleGroupSuccess = ({ payload }) => ({
  type: types.CREATE_SCHEDULE_GROUP_SUCCESS,
  payload,
});

export const updateScheduleGroup = (group, callback) => ({
  type: types.UPDATE_SCHEDULE_GROUP,
  payload: { group, callback },
});
export const updateScheduleGroupSuccess = (group) => ({
  type: types.UPDATE_SCHEDULE_GROUP_SUCCESS,
  payload: group,
});

export const fetchAllScheduleRules = (payload) => ({
  type: types.FETCH_ALL_SCHEDULES_RULES,
  payload,
});
export const fetchAllScheduleRulesSuccess = ({ payload }) => ({
  type: types.FETCH_ALL_SCHEDULES_RULES_SUCCESS,
  payload,
});

export const fetchScheduleRule = (payload) => ({
  type: types.FETCH_SCHEDULE_RULE,
  payload,
});

export const fetchScheduleRuleSuccess = (payload) => ({
  type: types.FETCH_SCHEDULE_RULE_SUCCESS,
  payload,
});

export const searchScheduleRules = (queryParams) => ({
  type: types.SEARCH_SCHEDULE_RULES,
  payload: { queryParams },
});
export const searchScheduleRulesSuccess = (payload) => ({
  type: types.SEARCH_SCHEDULE_RULES_SUCCESS,
  payload,
});
export const searchSchedulesRuleFailure = (payload) => ({
  type: types.SEARCH_SCHEDULE_RULES_FAILURE,
  payload,
});

export const createScheduleRule = (formData, callback) => ({
  type: types.CREATE_SCHEDULE_RULE,
  payload: { formData, callback },
});

export const createScheduleRuleSuccess = ({ payload }) => ({
  type: types.CREATE_SCHEDULE_RULE_SUCCESS,
  payload,
});

export const updateScheduleRule = (ruleId, formData, callback) => ({
  type: types.UPDATE_SCHEDULE_RULE,
  payload: { ruleId, formData, callback },
});

export const updateScheduleRuleSuccess = (action) => ({
  type: types.UPDATE_SCHEDULE_RULE_SUCCESS,
  payload: action.payload,
});

export const deleteScheduleRule = ({ ruleId }, callback) => ({
  type: types.DELETE_SCHEDULE_RULE,
  payload: { ruleId, callback },
});
export const deleteScheduleRuleSuccess = (ruleId) => ({
  type: types.DELETE_SCHEDULE_RULE_SUCCESS,
  payload: { ruleId },
});
export const deleteScheduleRuleFailure = () => ({
  type: types.DELETE_SCHEDULE_RULE_FAILURE,
});

export const associateRulesToGroup = ({ groupId, ruleIds }, callback) => ({
  type: types.ASSOCIATE_RULES_TO_GROUP,
  payload: { groupId, ruleIds, callback },
});
export const associateRulesToGroupSuccess = (payload) => ({
  type: types.ASSOCIATE_RULES_TO_GROUP_SUCCESS,
  payload,
});
export const associateRulesToGroupFailure = () => ({
  type: types.ASSOCIATE_RULES_TO_GROUP_FAILURE,
});

export const searchIvrHolidays = (filters) => ({
  type: types.SEARCH_IVR_HOLIDAYS,
  payload: { filters },
});
export const searchIvrHolidaysSuccess = (data) => ({
  type: types.SEARCH_IVR_HOLIDAYS_SUCCESS,
  payload: data,
});
export const searchIvrHolidaysFailure = () => ({
  type: types.SEARCH_IVR_HOLIDAYS_FAILURE,
});

export const fetchIvrHolidaysAvailableCountries = () => ({
  type: types.FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES,
});
export const fetchIvrHolidaysAvailableCountriesSuccess = (data) => ({
  type: types.FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES_SUCCESS,
  payload: data,
});
export const fetchIvrHolidaysAvailableCountriesFailure = () => ({
  type: types.FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES_FAILURE,
});

export const fetchIvrHolidaysAvailableYears = () => ({
  type: types.FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS,
});
export const fetchIvrHolidaysAvailableYearsSuccess = (data) => ({
  type: types.FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS_SUCCESS,
  payload: data,
});
export const fetchIvrHolidaysAvailableYearsFailure = () => ({
  type: types.FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS_FAILURE,
});

export const importScheduleRuleFromHolidays = (holidayIds, callback) => ({
  type: types.IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS,
  payload: { holidayIds, callback },
});
export const importScheduleRuleFromHolidaysSuccess = (data) => ({
  type: types.IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS_SUCCESS,
  payload: data,
});
export const importScheduleRuleFromHolidaysFailure = () => ({
  type: types.IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS_FAILURE,
});
