import types from './types';

export const rdStationLogin = () => ({
  type: types.RD_STATION_LOGIN,
  payload: null,
});

export const generateAccessToken = (payload) => ({
  type: types.GENERATE_ACCESS_TOKEN_REQUEST,
  payload,
});

export const generateAccessTokenSuccess = (payload) => ({
  type: types.GENERATE_ACCESS_TOKEN_SUCCESS,
  payload,
});

export const deleteAccessToken = (payload) => ({
  type: types.REMOVE_ACCESS_TOKEN_REQUEST,
  payload,
});

export const getFields = (params) => ({
  type: types.GET_FIELDS_REQUEST,
  payload: { ...params },
});

export const getFieldsSuccess = (customFields) => ({
  type: types.GET_FIELDS_SUCCESS,
  payload: customFields,
});

export const resetAuthData = () => ({
  type: types.RESET_AUTH_DATA,
  payload: null,
});
