import produce from 'immer';
import types from './types';

export const INITIAL_STATE = {
  isValidEmail: null,
  isLoading: false,
  token: null,
  loggedUser: null,
};

const INITIAL_STATE_TFA = {
  active: false,
  dataURL: '',
  message: '',
  error: '',
};

export const auth = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CHECK_EMAIL_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case types.CHECK_EMAIL_SUCCESS: {
        draft.isLoading = false;
        draft.isValidEmail = action.payload.isValid;
        break;
      }
      case types.CHECK_EMAIL_FAILURE: {
        draft.isLoading = false;
        draft.isValidEmail = action.payload.isValid;
        break;
      }
      case types.RESET_CHECK_EMAIL: {
        draft.isValidEmail = null;
        break;
      }

      case types.SIGNIN_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case types.UPDATE_SELECT_ORGANIZATION_SUCCESS:
      case types.UPDATE_SELECTED_COMPANY_ORGANIZATION_SUCCESS:
      case types.TFA_VERIFY_SUCCESS:
      case types.SIGNIN_SUCCESS:
        {
          const isRootUser = action.payload.loggedUser?.claim?.find(
            ({ name }) => name === 'Root'
          );
          draft.isLoading = false;
          draft.token = action.payload.token;
          draft.loggedUser = {
            ...action.payload.loggedUser,
            isRootUser: Boolean(isRootUser),
          };
        }
        break;

      case types.UPDATE_LOGGED_USER_SELECTED_COMPANY:
        draft.loggedUser = {
          ...draft.loggedUser,
          selectedCompany: {
            ...action.payload,
            claim: draft.loggedUser.selectedCompany.claim,
          },
        };
        break;

      case types.SIGNIN_FAILURE:
        draft.isLoading = false;
        break;

      case types.LAST_NOTICE: {
        draft.loggedUser = {
          ...draft.loggedUser,
          lastNotice: action.payload.lastNotice,
        };
        break;
      }

      case types.SIGNUP_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case types.SIGNUP_SUCCESS: {
        draft.isLoading = false;
        break;
      }
      case types.SIGNUP_FAILURE: {
        draft.isLoading = false;
        break;
      }

      case types.CONFIRM_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case types.CONFIRM_SUCCESS: {
        draft.isLoading = false;
        break;
      }
      case types.CONFIRM_FAILURE: {
        draft.isLoading = false;
        break;
      }

      case types.RECOVER_EMAIL_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case types.RECOVER_EMAIL_SUCCESS: {
        draft.isLoading = false;
        break;
      }
      case types.RECOVER_EMAIL_FAILURE: {
        draft.isLoading = false;
        break;
      }

      case types.RECOVER_PASSWORD_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case types.RECOVER_PASSWORD_SUCCESS: {
        draft.isLoading = false;
        break;
      }
      case types.RECOVER_PASSWORD_FAILURE: {
        draft.isLoading = false;
        break;
      }

      case types.RESET_PASSWORD_REQUEST: {
        draft.isLoading = true;
        break;
      }
      case types.RESET_PASSWORD_SUCCESS: {
        draft.isLoading = false;
        break;
      }
      case types.RESET_PASSWORD_FAILURE: {
        draft.isLoading = false;
        break;
      }

      case types.SIGNOUT: {
        draft.token = null;
        draft.loggedUser = null;
        break;
      }

      case types.FETCH_LOGGEDUSER_SUCCESS:
      case types.UPDATE_USER_PROFILE_SUCCESS: {
        draft.loggedUser = {
          ...action.payload.loggedUser,
        };
        break;
      }

      case types.TFA_ACTIVE_SUCCESS: {
        draft.loggedUser = {
          ...action.payload.user,
        };
        break;
      }

      case types.TFA_DISABLE_SUCCESS: {
        draft.loggedUser = {
          ...state.loggedUser,
          tfa: { active: false },
        };
        break;
      }

      default:
    }
  });
};

export const tfaStatus = (state = INITIAL_STATE_TFA, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.TFA_SETUP_SUCCESS: {
        draft.dataURL = action.payload.dataURL;
        break;
      }

      default:
    }
  });
};

const authFuncs = { auth, tfaStatus };

export default authFuncs;
