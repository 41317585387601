import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  telephoneOperators: { data: null, error: false, loading: false },
  loadingTelephoneOperatorCreation: false,
  loadingTelephoneOperatorIdDeletion: null,
  loadingTelephoneOperatorIdUpdate: null,
};

export default function ivrTelephoneOperators(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_IVR_TELEPHONE_OPERATORS: {
        draft.telephoneOperators.error = false;
        draft.telephoneOperators.loading = true;
        break;
      }
      case types.FETCH_IVR_TELEPHONE_OPERATORS_SUCCESS: {
        draft.telephoneOperators.error = false;
        draft.telephoneOperators.loading = false;
        draft.telephoneOperators.data = action.payload;
        break;
      }
      case types.FETCH_IVR_TELEPHONE_OPERATORS_FAILURE: {
        draft.telephoneOperators.data = null;
        draft.telephoneOperators.error = true;
        draft.telephoneOperators.loading = false;
        break;
      }

      case types.CREATE_IVR_TELEPHONE_OPERATOR: {
        draft.loadingTelephoneOperatorCreation = true;
        break;
      }
      case types.CREATE_IVR_TELEPHONE_OPERATOR_SUCCESS: {
        draft.loadingTelephoneOperatorCreation = false;
        draft.telephoneOperators.data = [
          action.payload,
          ...(state.telephoneOperators.data || []),
        ];
        break;
      }
      case types.CREATE_IVR_TELEPHONE_OPERATOR_FAILURE: {
        draft.loadingTelephoneOperatorCreation = false;
        break;
      }

      case types.UPDATE_IVR_TELEPHONE_OPERATOR: {
        draft.loadingTelephoneOperatorIdUpdate = action.payload?.data?._id;
        break;
      }
      case types.UPDATE_IVR_TELEPHONE_OPERATOR_SUCCESS: {
        draft.loadingTelephoneOperatorIdUpdate = null;
        const updatedItem = action.payload;
        const newData = state.telephoneOperators?.data?.map?.((item) =>
          item._id === updatedItem?._id ? updatedItem : item
        );
        draft.telephoneOperators.data = newData;
        break;
      }
      case types.UPDATE_IVR_TELEPHONE_OPERATOR_FAILURE: {
        draft.loadingTelephoneOperatorIdUpdate = null;
        break;
      }

      case types.DELETE_IVR_TELEPHONE_OPERATOR: {
        draft.loadingTelephoneOperatorIdDeletion = action.payload?._id;
        break;
      }
      case types.DELETE_IVR_TELEPHONE_OPERATOR_SUCCESS: {
        draft.loadingTelephoneOperatorIdDeletion = null;
        const newData = state.telephoneOperators?.data?.filter?.(
          (item) => item._id !== action.payload?._id
        );
        draft.telephoneOperators.data = newData || [];
        break;
      }
      case types.DELETE_IVR_TELEPHONE_OPERATOR_FAILURE: {
        draft.loadingTelephoneOperatorIdDeletion = null;
        break;
      }

      default:
        return state;
    }
  });
}
