import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';

import { ModalButtonClose } from './styles';

const ModalButtonCloseComponent = ({ children, ...rest }) => {
  return <ModalButtonClose {...rest}>{children}</ModalButtonClose>;
};

ModalButtonCloseComponent.propTypes = {
  children: PropTypes.node,
};

ModalButtonCloseComponent.defaultProps = {
  children: <MdClose color="#5A5D68" size={16} />,
};

export default ModalButtonCloseComponent;
