import { setupCache } from 'axios-cache-adapter';
import { indexedDBStore } from './indexedDBStore';
import getURLCached from './getURLCached';
import generateCacheInvalidation from './generateCacheInvalidation';

export { removeStoragePrefixWithCache } from './removeStoragePrefixWithCache';
export { invalidateCache } from './generateCacheInvalidation';

const cache = setupCache({
  maxAge: 30 * 60 * 1000,
  store: indexedDBStore,
  exclude: {
    filter: (req) => getURLCached(req),
    query: false,
  },
  invalidate: (config, request) => generateCacheInvalidation(config, request),
});

export default cache;
