import types from './types';

export const fetchUsers = ({ search }) => ({
  type: types.FETCH_USERS,
  payload: { search },
});
export const fetchUsersSuccess = ({ users }) => ({
  type: types.FETCH_USERS_SUCCESS,
  payload: { users },
});

export const fetchUser = ({ id }) => ({
  type: types.FETCH_USER,
  payload: { id },
});

export const fetchUserSuccess = ({ user }) => ({
  type: types.FETCH_USER_SUCCESS,
  payload: { user },
});

export const createUser = ({ user }, callback) => ({
  type: types.CREATE_USER,
  payload: { user, callback },
});

export const createUserSuccess = ({ user }) => ({
  type: types.CREATE_USER_SUCCESS,
  payload: { user },
});

export const updateUser = ({ user }, callback) => ({
  type: types.UPDATE_USER,
  payload: { user, callback },
});

export const updateUserSuccess = ({ user }) => ({
  type: types.UPDATE_USER_SUCCESS,
  payload: { user },
});
