const Types = {
  VALIDATE_TOPDESK_SETTINGS_REQUEST:
    'topdesk/VALIDATE_TOPDESK_SETTINGS_REQUEST',
  VALIDATE_TOPDESK_SETTINGS_SUCCESS:
    'topdesk/VALIDATE_TOPDESK_SETTINGS_SUCCESS',

  GET_TOPDESK_CATEGORIES_REQUEST: 'topdesk/GET_TOPDESK_CATEGORIES_REQUEST',
  GET_TOPDESK_CATEGORIES_SUCCESS: 'topdesk/GET_TOPDESK_CATEGORIES_SUCCESS',

  GET_TOPDESK_SUB_CATEGORIES_REQUEST:
    'topdesk/GET_TOPDESK_SUB_CATEGORIES_REQUEST',
  GET_TOPDESK_SUB_CATEGORIES_SUCCESS:
    'topdesk/GET_TOPDESK_SUB_CATEGORIES_SUCCESS',

  GET_TOPDESK_OPERATORS_REQUEST: 'topdesk/GET_TOPDESK_OPERATORS_REQUEST',
  GET_TOPDESK_OPERATORS_SUCCESS: 'topdesk/GET_TOPDESK_OPERATORS_SUCCESS',

  GET_TOPDESK_CALL_TYPES_REQUEST: 'topdesk/GET_TOPDESK_CALL_TYPES_REQUEST',
  GET_TOPDESK_CALL_TYPES_SUCCESS: 'topdesk/GET_TOPDESK_CALL_TYPES_SUCCESS',

  GET_TOPDESK_ENTRY_TYPES_REQUEST: 'topdesk/GET_TOPDESK_ENTRY_TYPES_REQUEST',
  GET_TOPDESK_ENTRY_TYPES_SUCCESS: 'topdesk/GET_TOPDESK_ENTRY_TYPES_SUCCESS',

  GET_TOPDESK_STATUSES_REQUEST: 'topdesk/GET_TOPDESK_STATUSES_REQUEST',
  GET_TOPDESK_STATUSES_SUCCESS: 'topdesk/GET_TOPDESK_STATUSES_SUCCESS',
};

export default Types;
