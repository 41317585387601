import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  templateBot: {},
  currentTemplate: {},
  templates: [],
};

export default function filter(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_TEMPLATES_SUCCESS: {
        draft.templates = action.payload;
        break;
      }

      case types.CREATE_TEMPLATE_BOT_SUCCESS:
      case types.UPDATE_TEMPLATE_BOT_SUCCESS:
      case types.GET_TEMPLATE_BOT_SUCCESS: {
        draft.templateBot = action.payload;
        break;
      }

      case types.GET_TEMPLATE_ID_SUCCESS: {
        draft.currentTemplate = action.payload;
        break;
      }

      default:
        return state;
    }
  });
}
