import types from './types';

export const fetchItems = ({ botId, quickAccess }) => ({
  type: types.FETCH_ITEMS,
  payload: { botId, quickAccess },
});

export const fetchItemsSuccess = ({ items }) => ({
  type: types.FETCH_ITEMS_SUCCESS,
  payload: { items },
});

export const fetchAllItems = ({ botId, callback }) => ({
  type: types.FETCH_ALL_ITEMS,
  payload: { botId, callback },
});

export const fetchAllItemsSuccess = ({ allItems }) => ({
  type: types.FETCH_ALL_ITEMS_SUCCESS,
  payload: { allItems },
});

export const fetchItemLastVersion = ({ botId, originalId }) => ({
  type: types.FETCH_ITEM_LAST_VERSION,
  payload: { botId, originalId },
});

export const fetchItemSuccessLastVersion = ({ itemLastVersion }) => ({
  type: types.FETCH_ITEM_LAST_VERSION_SUCCESS,
  payload: { itemLastVersion },
});

export const fetchSubflowItems = ({ botId, subflowId }) => ({
  type: types.FETCH_SUBFLOW_ITEMS,
  payload: { botId, subflowId },
});

export const fetchSubflowItemsSuccess = ({ items, subflowPath }) => ({
  type: types.FETCH_SUBFLOW_ITEMS_SUCCESS,
  payload: { items, subflowPath },
});

export const createItem = (payload) => ({
  type: types.CREATE_ITEM,
  payload,
});
export const createItemSuccess = (action) => ({
  type: types.CREATE_ITEM_SUCCESS,
  payload: action.payload,
});

export const ivrCloneItemGroup = (item, callback) => ({
  type: types.IVR_CLONE_ITEM_GROUP,
  payload: { item, callback },
});
export const ivrCloneItemGroupSuccess = (action) => ({
  type: types.IVR_CLONE_ITEM_GROUP_SUCCESS,
  payload: action.payload,
});
export const ivrCloneItemGroupFailure = () => ({
  type: types.IVR_CLONE_ITEM_GROUP_FAILURE,
});

export const ivrCloneItems = (
  { botId, subflowFor, items, connectorIds },
  callback
) => ({
  type: types.IVR_CLONE_ITEMS,
  payload: { botId, subflowFor, items, connectorIds, callback },
});
export const ivrCloneItemsSuccess = (items) => ({
  type: types.IVR_CLONE_ITEMS_SUCCESS,
  payload: items,
});
export const ivrCloneItemsFailure = () => ({
  type: types.IVR_CLONE_ITEMS_FAILURE,
});

export const updateItem = (payload, callback) => ({
  type: types.UPDATE_ITEM,
  payload: { item: payload, callback },
});

export const updateItemSuccess = ({ index, item }) => ({
  type: types.UPDATE_ITEM_SUCCESS,
  payload: { index, item },
});

export const createItemExampleSuccess = ({ item }) => ({
  type: types.CREATE_ITEM_EXAMPLE_SUCCESS,
  payload: { item },
});

export const updateItems = (payload, callback) => ({
  type: types.UPDATE_ITEMS,
  payload: { items: payload, callback },
});

export const updateItemsSuccess = (payload) => ({
  type: types.UPDATE_ITEMS_SUCCESS,
  payload,
});

export const deleteItem = ({ botId, items }, callback) => ({
  type: types.DELETE_ITEM,
  payload: { botId, items, callback },
});
export const deleteItemSuccess = ({ itemIds }) => ({
  type: types.DELETE_ITEM_SUCCESS,
  payload: { itemIds },
});

export const uploadCardImage = ({ file, item, cardId }, callback) => ({
  type: types.UPLOAD_CARD_IMAGE,
  payload: { file, item, cardId, callback },
});

export const uploadItemFile = ({ file, item, botId }, callback) => ({
  type: types.UPLOAD_ITEM_FILE,
  payload: { file, item, botId, callback },
});

export const removeItemFile = ({ item, botId }, callback) => ({
  type: types.REMOVE_ITEM_FILE,
  payload: { item, botId, callback },
});

export const downloadItemFile = (action, callback) => ({
  type: types.DOWNLOAD_ITEM_FILE,
  payload: { ...action.payload, callback },
});

export const returnItem = (action, callback) => ({
  type: types.RETURN_ITEM,
  payload: { ...action, callback },
});

export const returnItemSuccess = (payload) => ({
  type: types.RETURN_ITEM_SUCCESS,
  payload,
});

export const createGptResponses = (payload) => ({
  type: types.CREATE_CHATGPT_RESPONSES,
  payload: { payload },
});

export const fetchItemsSummary = ({ botId }) => ({
  type: types.FETCH_ITEMS_SUMMARY,
  payload: { botId },
});
export const fetchItemsSummarySuccess = (data) => ({
  type: types.FETCH_ITEMS_SUMMARY_SUCCESS,
  payload: data,
});
export const fetchItemsSummaryFailure = () => ({
  type: types.FETCH_ITEMS_SUMMARY_FAILURE,
});

export const fetchResumedAllItems = ({ botId, callback }) => ({
  type: types.FETCH_RESUMED_ALL_ITEMS,
  payload: { botId, callback },
});

export const fetchResumedAllItemsSuccess = (payload) => ({
  type: types.FETCH_RESUMED_ALL_ITEMS_SUCCESS,
  payload,
});
