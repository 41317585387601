import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import {
  commonLoadingStart,
  commonLoadingFinish,
  commonChangingFinish,
} from '../common/actions';
import i18n from '../../../translations/i18n';

import { toast } from '../../../components/CustomToast';
import history from '../../../services/history';
import api from '../../../services/api';
import * as actions from './actions';
import * as actionsBot from '../bots/actions';
import types from './types';

export function* checkEmail({ payload }) {
  try {
    const { email, setErrorMessage } = payload;

    const response = yield call(api.get, '/v3/auth/check-email', {
      params: { email },
    });

    if (!response.data.valid) {
      yield put(
        actions.checkEmailFailure({
          isValid: false,
          errorMessage: response.data.message,
        })
      );

      if (setErrorMessage) {
        setErrorMessage(response.data.message);
      }
      return;
    }

    yield put(actions.checkEmailSuccess({ isValid: true, email }));
  } catch ({ response }) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.erro_comunication_server')
    );
    yield put(actions.checkEmailFailure({ isValid: false }));
  }
}

export function* signIn({ payload }) {
  const { email, password, tokenReCaptcha, callback } = payload;
  try {
    yield put(commonLoadingStart());
    const response = yield call(api.post, '/v3/auth/sign-in', {
      user: {
        email,
        password,
      },
      tokenReCaptcha,
    });

    const { token, user } = response.data;

    yield put(
      actions.signInSuccess({
        token,
        loggedUser: { ...user },
      })
    );

    const isRootUser = user?.claim?.find(({ name }) => name === 'Root');

    if (!isRootUser && !user.companies?.length) {
      history.push('/signup', {
        user,
      });
      toast.warn(i18n.t('error.user_without_company'));
      return;
    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    if (user.organizations?.length > 1 || isRootUser) {
      history.push('/select-company-organization');
      return;
    }

    if (
      user.tfa?.active &&
      user.tfa?.secret &&
      user.tfa?.dataURL &&
      user?.organizations.length < 2 &&
      !isRootUser
    ) {
      history.push(`/tfa`, { userId: user._id, email: user.email });
      return;
    }

    if (
      user?.tfa?.active &&
      user.tfa?.security_lock &&
      user?.organizations.length < 2
    ) {
      history.push('/tfa-activate');
      return;
    }

    if (
      !user?.tfa?.active &&
      user?.organizations.length < 2 &&
      user?.selectedCompany?.tfa &&
      !isRootUser
    ) {
      history.push('/tfa-activate');
      return;
    }

    if (user?.lastNotice) {
      history.push('/bots');
      return;
    }

    history.push('/bots');
  } catch ({ response }) {
    yield put(actions.signFailure());

    const captcha = response?.data?.message?.captcha;
    const userNotConfirmed = response?.data?.message?.userNotConfirmed;
    const passwordInvalid = response?.data?.message?.passwordInvalid;

    if (response?.status === 404) {
      toast.error(i18n.t('error.error'), i18n.t('error.user_not_exists'));
    } else if (captcha) {
      toast.error(i18n.t('error.error'), i18n.t('error.invalid_captcha'));
    } else if (userNotConfirmed) {
      callback(true);
      toast.error(i18n.t('error.error'), i18n.t('error.user_not_confirmed'));
    } else if (passwordInvalid) {
      toast.error(i18n.t('error.error'), i18n.t('error.user_password_error'));
    } else {
      toast.error(
        i18n.t('error.error'),
        response?.data?.message || i18n.t('error.erro_comunication_server')
      );
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateSelectedCompanyOrganization({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { companyId, organizationId, isRootUser } = payload;

    const data = {
      selectedCompany: companyId,
      selectedOrganization: organizationId,
      userIsRoot: isRootUser,
    };

    const responseSelect = yield call(
      api.put,
      `v3/users/select-company-organization`,
      data
    );

    const { user } = responseSelect.data;

    const responseToken = yield call(
      api.post,
      `v3/users/token/refresh/${user._id}`
    );

    const { token } = responseToken.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(
      actions.updateSelectedCompanyOrganizationSuccess({
        token,
        loggedUser: { ...user, isSelectCompany: true },
      })
    );

    const loggedUser = yield select((state) => state.auth.loggedUser);

    const shouldActivateTfa =
      loggedUser?.selectedCompany?.tfa && !loggedUser?.tfa?.active;

    const shouldActivateTfaOffCompany =
      loggedUser?.tfa?.security_lock && loggedUser?.tfa?.active;

    const shouldSendTfaCode =
      loggedUser?.tfa?.secret && loggedUser?.tfa?.active;

    const shouldNotActivateTfa =
      loggedUser?.selectedCompany?.tfa && loggedUser?.tfa?.active;

    if (shouldNotActivateTfa || shouldSendTfaCode) {
      history.push('/tfa');
      return;
    }

    if (shouldActivateTfa || shouldActivateTfaOffCompany) {
      history.push('/tfa-activate');
      return;
    }

    history.push('/bots');
  } catch ({ response }) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_update_select_sector')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateSelectOrganization(action) {
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.put,
      `v3/users/select-organization`,
      action.payload
    );
    const { user } = data;

    const responseToken = yield call(
      api.post,
      `v3/users/token/refresh/${user._id}`
    );

    const { token } = responseToken.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    const newSelectedOrganization = user.selectedOrganization;
    let loggedUser = yield select((state) => state.auth.loggedUser);
    loggedUser = {
      ...loggedUser,
      selectedOrganization: newSelectedOrganization,
    };

    yield put(
      actions.updateSelectedCompanyOrganizationSuccess({
        token,
        loggedUser,
      })
    );

    yield put(
      actions.updateSelectOrganizationSuccess({
        token,
        loggedUser,
      })
    );

    yield put(actionsBot.fetchBotsRequest());

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.change_sector_success')
    );
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_update_sector'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* lastNotice({ payload }) {
  try {
    const { updatedUser } = payload;

    yield call(api.put, `/v3/users/${updatedUser._id}`, {
      ...updatedUser,
    });
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error'));
  }
}

export function* signUp({ payload }) {
  const { user, tokenReCaptcha } = payload;
  try {
    yield put(commonLoadingStart());

    const recreatingUser = !user.password;

    yield call(api.post, '/v3/auth/sign-up', {
      user,
      tokenReCaptcha,
    });

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.account_create_success')
    );
    yield put(actions.signUpSuccess());

    if (recreatingUser) {
      history.push('/');
    } else {
      history.push(`signup/success?email=${user.email}`);
    }
  } catch ({ response }) {
    const { callback } = payload;

    if (response && response.status === 422) {
      const errorMessage =
        response.data?.errors?.errors?.err?.message ||
        i18n.t('error.error_create_account');

      toast.error(i18n.t('error.error'), errorMessage);
    } else if (response && response.status === 400) {
      callback?.({
        error: { email: i18n.t('error.error_email_already_exists') },
      });
    }

    yield put(actions.signUpFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* confirm({ payload }) {
  try {
    const { token, email } = payload;

    yield call(api.post, '/v3/auth/confirm', {
      token,
      email,
    });

    yield put(actions.confirmSuccess());
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.account_confirmed_success')
    );

    history.push(`/`);
  } catch ({ response }) {
    const errors = response.data?.errors;

    if (errors.user) {
      toast.error(
        i18n.t('error.error'),
        i18n.t('error.error_user_not_exists_or_confirmed')
      );
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_invalid_token'));
    }
    yield put(actions.confirmFailure());
  }
}

export function* recoverEmailRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { phone, tokenReCaptcha } = payload;

    yield call(api.post, '/v3/auth/recover-email', {
      phone,
      tokenReCaptcha,
    });

    yield put(actions.recoverEmailSuccess());
    history.push(`/recover-email/success`);
  } catch ({ response }) {
    toast.error(
      i18n.t('error.error'),
      response.data.error || i18n.t('error.error_recover_email')
    );
    yield put(actions.recoverEmailFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* recoverPassword({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { email, tokenReCaptcha } = payload;

    yield call(api.post, '/v3/auth/recover-password', {
      email,
      tokenReCaptcha,
    });

    yield put(actions.recoverEmailSuccess());

    history.push(`recover-password/success?email=${email}`);
  } catch ({ response }) {
    toast.error(
      i18n.t('error.error'),
      response.data?.errors?.email || i18n.t('error.error_recover_password')
    );
    yield put(actions.recoverEmailFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* resetPassword({ payload }) {
  try {
    const { password, passwordConfirmation, email, tokenReCaptcha } = payload;

    yield call(api.patch, '/v3/auth/reset-password', {
      password,
      passwordConfirmation,
      email,
      tokenReCaptcha,
    });

    yield put(actions.resetPasswordSuccess());
    toast.success(i18n.t('success.success'), i18n.t('success.change_password'));
    history.push('/');
  } catch ({ response }) {
    const errors = response.data?.errors;

    if (errors.captcha) {
      toast.error(i18n.t('error.error'), response.data?.errors?.captcha);
    } else if (errors.email) {
      toast.error(i18n.t('error.error'), response.data?.errors?.email);
    } else if (errors.password) {
      toast.error(i18n.t('error.error'), response.data?.errors?.password);
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_reset_password'));
    }
    yield put(actions.resetPasswordFailure());
  }
}

export function setTokenAndLanguage({ payload }) {
  if (!payload) return;

  const { token, loggedUser } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }

  if (loggedUser?.preferredLanguage) {
    i18n.changeLanguage(loggedUser.preferredLanguage);
  }
}

export function* signOut() {
  try {
    yield call(api.post, 'v3/auth/logout');
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.logout_error'));
  }
}

export function* tfaVerify({ payload }) {
  const { userId, tfaToken, callback } = payload;
  try {
    const { data } = yield call(api.post, 'v3/auth/tfa/verify', {
      userId,
      tfaToken,
    });

    const { token, user } = data;
    api.defaults.headers.Authorization = `Bearer ${token}`;

    const loggedUser = yield select((state) => state.auth.loggedUser);
    yield put(
      actions.tfaVerifySuccess({
        token,
        loggedUser: {
          ...loggedUser,
          claim: user.claim,
          selectedCompany: user.selectedCompany,
          tfa: {
            ...loggedUser.tfa,
            ...user.tfa,
          },
          isTfaLogged: true,
        },
      })
    );
    callback?.(true);

    // const isRootUser = user?.claim?.find(({ name }) => name === 'Root');

    // if (user.companies?.length > 1 || isRootUser) {
    //   history.push('/select-company-organization');
    //   return;
    // }

    history.push('/bots');
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.tfa_invalid_code'));
    callback?.(false);
  }
}

export function* tfaSetup() {
  try {
    const { data } = yield call(api.post, 'v3/auth/tfa/setup');
    yield put(actions.tfaSetupSuccess(data));
  } catch (e) {
    yield put(
      actions.tfaSetupSuccess({
        payload: {
          error: e.message,
        },
      })
    );
  }
}

export function* tfaActive({ payload }) {
  const { token, callback } = payload;
  try {
    const { data } = yield call(api.post, 'v3/auth/tfa/active', {
      token,
    });
    const { user } = data;

    const isRootUser = user?.claim?.find(({ name }) => name === 'Root');

    yield put(
      actions.updateUserProfileSuccess({
        loggedUser: {
          ...user,
          isRootUser,
          isTfaLogged: true,
        },
      })
    );

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.tfa_active_success')
    );
    callback?.(true);
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.tfa_active_failed'));
    callback?.(false);
  }
}

export function* tfaDisable() {
  try {
    yield call(api.post, 'v3/auth/tfa/disable');

    yield put(actions.tfaDisableSuccess());

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.tfa_disable_success')
    );
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.tfa_disable_failed'));
  }
}

export function* resendEmailConfirmation({ payload }) {
  try {
    const { email } = payload;
    yield call(api.get, `v3/auth/resend-email-confirmation?email=${email}`);

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.confirm_email_send_success')
    );
  } catch (error) {
    toast.error(i18n.t('error.error_send_confirm_email'));
  }
}

export function* notice({ payload }) {
  const { user } = payload;
  try {
    const { data } = yield call(api.put, `/v3/users/${user._id}`, {
      ...user,
      lastNotice: payload.notice,
    });
    yield put(actions.noticeSuccess(data?.lastNotice || true));
  } catch (error) {
    toast.error(i18n.t('error.error_notice'));
  }
}

export function* updateUserProfile({ payload }) {
  const { loggedUser, callback } = payload;

  try {
    yield put(commonLoadingStart());
    const response = yield call(
      api.put,
      `/v3/users/update-profile/${loggedUser._id}`,
      loggedUser
    );

    if (response && response?.data) {
      const oldLoggedUser = yield select((state) => state.auth.loggedUser);
      const isRootUser = response.data?.claim?.find(
        ({ name }) => name === 'Root'
      );

      yield put(
        actions.updateUserProfileSuccess({
          loggedUser: {
            ...oldLoggedUser,
            ...loggedUser,
            isRootUser,
          },
        })
      );

      i18n.changeLanguage(
        response.data?.preferredLanguage || loggedUser.preferredLanguage
      );

      toast.success(
        i18n.t('success.success'),
        i18n.t('success.user_update_success')
      );
      callback?.(true);
      yield put(commonChangingFinish());
    }
  } catch ({ response }) {
    toast.error(i18n.t('error.user_update_user'));
    callback?.(false);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateUserPassword({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { data } = payload;

    yield call(api.put, `/v3/users/update-password`, data);

    toast.success(i18n.t('success.success'), i18n.t('success.change_password'));
  } catch ({ response }) {
    if (response?.data?.message?.wrongOldPassword) {
      toast.error(
        i18n.t('error.error'),
        i18n.t('error.current_password_not_match')
      );
      return;
    }

    toast.error(i18n.t('error.change_password_error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchLoggeduser({ payload }) {
  try {
    const { id } = payload;

    yield put(commonLoadingStart());

    const response = yield call(api.get, `/v3/users/${id}`);

    yield put(actions.fetchLoggeduserSuccess({ loggedUser: response.data }));
  } catch ({ response }) {
    toast.error(i18n.t('error.user_update_user'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createPassword({ payload }) {
  try {
    const { token, password } = payload;
    yield call(api.post, `/v3/password/create/${token}`, {
      password,
    });
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_create_password')
    );
    history.push('/bots');
  } catch (error) {
    toast.error(i18n.t('error.error_create_password'));
  }
}

export default all([
  takeLatest(types.FETCH_LOGGEDUSER, fetchLoggeduser),
  takeLatest(types.CHECK_EMAIL_REQUEST, checkEmail),
  takeLatest(types.SIGNUP_REQUEST, signUp),
  takeLatest(types.CONFIRM_REQUEST, confirm),
  takeLatest(types.RECOVER_EMAIL_REQUEST, recoverEmailRequest),
  takeLatest(types.RECOVER_PASSWORD_REQUEST, recoverPassword),
  takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword),
  takeLatest(types.SIGNIN_REQUEST, signIn),
  takeLatest(
    types.UPDATE_SELECTED_COMPANY_ORGANIZATION_REQUEST,
    updateSelectedCompanyOrganization
  ),
  takeLatest(
    types.UPDATE_SELECT_ORGANIZATION_REQUEST,
    updateSelectOrganization
  ),

  takeLatest('persist/REHYDRATE', setTokenAndLanguage),
  takeLatest(types.SIGNOUT, signOut),
  takeLatest(types.TFA_VERIFY, tfaVerify),
  takeLatest(types.TFA_SETUP, tfaSetup),
  takeLatest(types.TFA_ACTIVE, tfaActive),
  takeLatest(types.TFA_DISABLE, tfaDisable),
  takeLatest(types.AUTH_NOTICE_REQUEST, notice),
  takeLatest(types.RESEND_EMAIL_CONFIRMATION_REQUEST, resendEmailConfirmation),
  takeLatest(types.UPDATE_USER_PROFILE_REQUEST, updateUserProfile),
  takeLatest(types.UPDATE_USER_PASSWORD_REQUEST, updateUserPassword),
  takeLatest(types.CREATE_PASSWORD, createPassword),
]);
