const Types = {
  FETCH_ALL_SCHEDULE_GROUPS: '@ivr/FETCH_ALL_SCHEDULE_GROUPS',
  FETCH_ALL_SCHEDULE_GROUPS_SUCCESS: '@ivr/FETCH_ALL_SCHEDULE_GROUPS_SUCCESS',
  FETCH_ALL_SCHEDULE_GROUPS_FAILURE: '@ivr/FETCH_ALL_SCHEDULE_GROUPS_FAILURE',

  FETCH_SCHEDULE_GROUP: '@ivr/FETCH_SCHEDULE_GROUP',
  FETCH_SCHEDULE_GROUP_SUCCESS: '@ivr/FETCH_SCHEDULE_GROUP_SUCCESS',

  CREATE_SCHEDULE_GROUP: '@ivr/CREATE_SCHEDULE_GROUP',
  CREATE_SCHEDULE_GROUP_SUCCESS: '@ivr/CREATE_SCHEDULE_GROUP_SUCCESS',

  UPDATE_SCHEDULE_GROUP: '@ivr/UPDATE_SCHEDULE_GROUP',
  UPDATE_SCHEDULE_GROUP_SUCCESS: '@ivr/UPDATE_SCHEDULE_GROUP_SUCCESS',

  FETCH_ALL_SCHEDULES_RULES: '@ivr/FETCH_ALL_SCHEDULES_RULES',
  FETCH_ALL_SCHEDULES_RULES_SUCCESS: '@ivr/FETCH_ALL_SCHEDULES_RULES_SUCCESS',

  SEARCH_SCHEDULE_RULES: '@ivr/SEARCH_SCHEDULE_RULES',
  SEARCH_SCHEDULE_RULES_SUCCESS: '@ivr/SEARCH_SCHEDULE_RULES_SUCCESS',
  SEARCH_SCHEDULE_RULES_FAILURE:
    '@ivr/SEARCH_SCHEDULE_RULES_FROM_GROUP_FAILURE',

  FETCH_SCHEDULE_RULE: '@ivr/FETCH_SCHEDULE_RULE',
  FETCH_SCHEDULE_RULE_SUCCESS: '@ivr/FETCH_SCHEDULE_RULE_SUCCESS',

  CREATE_SCHEDULE_RULE: '@ivr/CREATE_SCHEDULE_RULE',
  CREATE_SCHEDULE_RULE_SUCCESS: '@ivr/CREATE_SCHEDULE_RULE_SUCCESS',

  UPDATE_SCHEDULE_RULE: '@ivr/UPDATE_SCHEDULE_RULE',
  UPDATE_SCHEDULE_RULE_SUCCESS: '@ivr/UPDATE_SCHEDULE_RULE_SUCCESS',

  DELETE_SCHEDULE_RULE: '@ivr/DELETE_SCHEDULE_RULE',
  DELETE_SCHEDULE_RULE_SUCCESS: '@ivr/DELETE_SCHEDULE_RULE_SUCCESS',
  DELETE_SCHEDULE_RULE_FAILURE: '@ivr/DELETE_SCHEDULE_RULE_FAILURE',

  ASSOCIATE_RULES_TO_GROUP: '@ivr/ASSOCIATE_RULES_TO_GROUP',
  ASSOCIATE_RULES_TO_GROUP_SUCCESS: '@ivr/ASSOCIATE_RULES_TO_GROUP_SUCCESS',
  ASSOCIATE_RULES_TO_GROUP_FAILURE: '@ivr/ASSOCIATE_RULES_TO_GROUP_FAILURE',

  SEARCH_IVR_HOLIDAYS: '@ivrHolidays/SEARCH_IVR_HOLIDAYS',
  SEARCH_IVR_HOLIDAYS_SUCCESS: '@ivrHolidays/SEARCH_IVR_HOLIDAYS_SUCCESS',
  SEARCH_IVR_HOLIDAYS_FAILURE: '@ivrHolidays/SEARCH_IVR_HOLIDAYS_FAILURE',

  FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES:
    '@ivrHolidays/FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES',
  FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES_SUCCESS:
    '@ivrHolidays/FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES_SUCCESS',
  FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES_FAILURE:
    '@ivrHolidays/FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES_FAILURE',

  FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS:
    '@ivrHolidays/FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS',
  FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS_SUCCESS:
    '@ivrHolidays/FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS_SUCCESS',
  FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS_FAILURE:
    '@ivrHolidays/FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS_FAILURE',

  IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS:
    '@ivrHolidays/IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS',
  IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS_SUCCESS:
    '@ivrHolidays/IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS_SUCCESS',
  IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS_FAILURE:
    '@ivrHolidays/IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS_FAILURE',
};

export default Types;
