import types from './types';

export const listFilterInfo = (payload) => ({
  type: types.LIST_FILTER_INFO,
  payload,
});

export const listFilterInfoSuccess = (payload) => ({
  type: types.LIST_FILTER_INFO_SUCCESS,
  payload,
});

export const filterReportInfo = (payload) => ({
  type: types.FILTER_REPORT_INFO,
  payload,
});

export const filterReportInfoSuccess = (payload) => ({
  type: types.FILTER_REPORT_INFO_SUCCESS,
  payload,
});

export const filterErrorsLogInfo = (payload) => ({
  type: types.FILTER_ERRORS_LOG_INFO,
  payload,
});

export const filterErrorsLogInfoSuccess = (payload) => ({
  type: types.FILTER_ERRORS_LOG_INFO_SUCCESS,
  payload,
});

export const filterOrgInfo = (payload) => ({
  type: types.FILTER_ORGS_INFO,
  payload,
});

export const filterOrgInfoSuccess = (payload) => ({
  type: types.FILTER_ORGS_INFO_SUCCESS,
  payload,
});
