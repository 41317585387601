import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '~/services/api';
import { toast } from '~/components/CustomToast';
import i18n from '../../../translations/i18n';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';
import types from './types';

export function* fetchProjects({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/projects`, {
      params,
      timeout: 30000,
    });

    yield put(actions.fetchProjectsSuccess({ projects: data }));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_projects'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchGrammars({ payload }) {
  try {
    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/grammars`, {
      params,
      timeout: 30000,
    });

    yield put(actions.fetchGrammarsSuccess({ grammars: data }));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_grammars'));
  }
}

export function* fetchStatus({ payload }) {
  try {
    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/status`, {
      params,
      timeout: 30000,
    });

    yield put(actions.fetchStatusSuccess({ status: data }));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_status'));
  }
}

export function* fetchVoices({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/voices`, {
      params,
      timeout: 30000,
    });

    yield put(actions.fetchVoicesSuccess({ voices: data }));
  } catch (e) {
    yield put(actions.fetchVoicesFailiure());
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_voices'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchVoicebotInstances({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { organizationId } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/instance`, {
      params: { organizationId },
      timeout: 30000,
    });

    yield put(actions.fetchVoicebotInstancesSuccess({ instances: data }));
  } catch (e) {
    yield put(actions.fetchVoicebotInstancesFailiure());
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_instances'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createVoicebotInstance({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { instance, callback } = payload;

    const { data } = yield call(api.post, `/v1/voice-bots/instance`, instance);

    yield put(actions.createVoicebotInstanceSuccess(data));

    if (callback) {
      callback();
    }
  } catch (err) {
    const errorMessage = err.response?.data?.message;
    toast.error(
      i18n.t('error.error'),
      errorMessage || i18n.t('error.error_create_instances')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateVoicebotInstance({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { instance, callback } = payload;

    const { data } = yield call(
      api.put,
      `/v1/voice-bots/instance/${instance?._id}`,
      instance
    );

    yield put(actions.updateVoicebotInstanceSuccess(data));

    if (callback) {
      callback();
    }
  } catch (err) {
    const errorMessage = err.response?.data?.message;
    toast.error(
      i18n.t('error.error'),
      errorMessage || i18n.t('error.error_update_instances')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchVoicebotVariables({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { params } = payload;

    const { data } = yield call(api.get, `/v1/voice-bots/variables`, {
      params,
      timeout: 30000,
    });

    yield put(actions.fetchVoicebotVariablesSuccess({ variables: data }));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_variables'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.FETCH_PROJECTS_REQUEST, fetchProjects),
  takeLatest(types.FETCH_GRAMMARS_REQUEST, fetchGrammars),
  takeLatest(types.FETCH_STATUS_REQUEST, fetchStatus),
  takeLatest(types.FETCH_VOICES_REQUEST, fetchVoices),
  takeLatest(types.FETCH_VOICEBOT_INSTANCES_REQUEST, fetchVoicebotInstances),
  takeLatest(types.CREATE_VOICEBOT_INSTANCE_REQUEST, createVoicebotInstance),
  takeLatest(types.UPDATE_VOICEBOT_INSTANCE_REQUEST, updateVoicebotInstance),
  takeLatest(types.FETCH_VARIABLES_REQUEST, fetchVoicebotVariables),
]);
