export function cep(e) {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  e.currentTarget.value = value;
  return e;
}

export function currency(e) {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  e.currentTarget.value = value;
  return e;
}

export function cpf(e) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
    e.currentTarget.value = value;
  }
  return e;
}

export function cnpj(e) {
  e.currentTarget.maxLength = 18;
  let value = e.currentTarget.value;
  if (!value.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/)) {
    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
    e.currentTarget.value = value;
  }
  return e;
}

export function date(e) {
  e.currentTarget.maxLength = 10;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '$1/$2');
  value = value.replace(/(\d{2})(\d)/, '$1/$2');
  value = value.replace(/(\d{4})(\d)/, '$1/$2');
  e.currentTarget.value = value;
  return e;
}

export function time(e) {
  e.currentTarget.maxLength = 8;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '$1:$2');
  value = value.replace(/(\d{2})(\d)/, '$1:$2');
  value = value.replace(/(\d{2})(\d)/, '$1:$2');
  e.currentTarget.value = value;
  return e;
}

export function hour(e) {
  e.currentTarget.maxLength = 5;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '$1:$2');
  value = value.replace(/(\d{2})(\d)/, '$1:$2');
  value = value.replace(/(\d{2})(\d)/, '$1:$2');
  e.currentTarget.value = value;
  return e;
}

export function phone(e) {
  e.currentTarget.maxLength = 15;

  const match = e.currentTarget.value
    .replace(/\D/g, '')
    .match(/(\d{0,2})(\d{0,5})(\d{0,4})/);

  e.currentTarget.value = !match[2]
    ? match[1]
    : `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ''}`;

  return e;
}

export function phoneDDDi(e) {
  e.currentTarget.maxLength = 15;
  const match = e.currentTarget.value
    .replace(/\D/g, '')
    .match(/(\d{0,3})(\d{0,4})(\d{0,4})/);

  e.currentTarget.value = !match[2]
    ? match[1]
    : `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ''}`;
  return e;
}
