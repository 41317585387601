import types from './types';

export const getCompanyUsersCountRequest = (payload) => ({
  type: types.GET_COMPANY_USERS_COUNT_REQUEST,
  payload,
});

export const getCompanyUsersCountSuccess = (payload) => ({
  type: types.GET_COMPANY_USERS_COUNT_SUCCESS,
  payload,
});

export const getCompanySectorsCountRequest = (payload) => ({
  type: types.GET_COMPANY_SECTORS_COUNT_REQUEST,
  payload,
});

export const getCompanySectorsCountSuccess = (payload) => ({
  type: types.GET_COMPANY_SECTORS_COUNT_SUCCESS,
  payload,
});

export const getMyDashboardRequest = (payload) => ({
  type: types.GET_MY_DASHBOARD_REQUEST,
  payload,
});

export const getMyDashboardSuccess = (payload) => ({
  type: types.GET_MY_DASHBOARD_SUCCESS,
  payload,
});

export const createMyDashboardRequest = (payload) => ({
  type: types.CREATE_MY_DASHBOARD_REQUEST,
  payload,
});

export const createMyDashboardSuccess = (payload) => ({
  type: types.CREATE_MY_DASHBOARD_SUCCESS,
  payload,
});

export const updateMyDashboardRequest = (payload) => ({
  type: types.UPDATE_MY_DASHBOARD_REQUEST,
  payload,
});

export const updateMyDashboardSuccess = (payload) => ({
  type: types.UPDATE_MY_DASHBOARD_SUCCESS,
  payload,
});

export const getListErrorLog = (payload) => ({
  type: types.GET_LIST_ERROR_LOG,
  payload,
});

export const successListErrorLog = (payload) => ({
  type: types.SUCCESS_LIST_ERROR_LOG,
  payload,
});

export const getCompanyMessagesRequest = (payload) => ({
  type: types.GET_COMPANY_MESSAGES_REQUEST,
  payload,
});

export const getCompanyMessagesSuccess = (payload) => ({
  type: types.GET_COMPANY_MESSAGES_SUCCESS,
  payload,
});

export const getCompanySessionsRequest = (payload) => ({
  type: types.GET_COMPANY_SESSIONS_REQUEST,
  payload,
});

export const getCompanySessionsSuccess = (payload) => ({
  type: types.GET_COMPANY_SESSIONS_SUCCESS,
  payload,
});

export const getReportSessionGenerate = (payload) => ({
  type: types.GET_REPORT_SESSION_GENERATE,
  payload,
});

export const getReportSessionGenerateSuccess = (payload) => ({
  type: types.GET_REPORT_SESSION_GENERATE_SUCCESS,
  payload,
});

/* ================ Bot Reports ===================== */

export const getBotSessionTags = (payload) => ({
  type: types.GET_BOT_SESSION_TAGS,
  payload,
});

export const getBotSessionTagsSuccess = (payload) => ({
  type: types.GET_BOT_SESSION_TAGS_SUCCESS,
  payload,
});

export const getBotMessagesRequest = (payload) => ({
  type: types.GET_BOT_MESSAGES_REQUEST,
  payload,
});

export const getBotMessagesSuccess = (payload) => ({
  type: types.GET_BOT_MESSAGES_SUCCESS,
  payload,
});

export const getCompanyBotsCountRequest = (payload) => ({
  type: types.GET_COMPANY_BOTS_COUNT_REQUEST,
  payload,
});

export const getCompanyBotsCountSuccess = (payload) => ({
  type: types.GET_COMPANY_BOTS_COUNT_SUCCESS,
  payload,
});

export const getClientsReportsSuccess = (payload) => ({
  type: types.GET_CLIENTS_REPORTS_SUCCESS,
  payload,
});

export const getBotSessionRequest = (payload) => ({
  type: types.GET_BOT_SESSION_REPORT,
  payload,
});

export const getBotSessionSuccess = (payload) => ({
  type: types.GET_BOT_SESSION_REPORT_SUCCESS,
  payload,
});

export const generateBotCustomReport = (payload) => ({
  type: types.GENERATE_BOT_CUSTOM_REPORT,
  payload,
});

export const getBotResume = (payload) => ({
  type: types.GET_BOT_RESUME,
  payload,
});
export const getBotResumeSuccess = (payload) => ({
  type: types.GET_BOT_RESUME_SUCCESS,
  payload,
});

/* ================ AI Reports ===================== */

export const getNotUnderstoodIntentions = (payload) => ({
  type: types.GET_NOT_UNDERSTOOD_INTENTIONS,
  payload,
});

export const getNotUnderstoodIntentionsSuccess = (payload) => ({
  type: types.GET_NOT_UNDERSTOOD_INTENTIONS_SUCCESS,
  payload,
});

export const getIntentionsPerformance = (payload) => ({
  type: types.GET_INTENTIONS_PERFORMANCE,
  payload,
});

export const getIntentionsPerformanceSuccess = (payload) => ({
  type: types.GET_INTENTIONS_PERFORMANCE_SUCCESS,
  payload,
});

export const getUnderstoodIntentions = (payload) => ({
  type: types.GET_UNDERSTOOD_INTENTIONS,
  payload,
});

export const getUnderstoodIntentionsSuccess = (payload) => ({
  type: types.GET_UNDERSTOOD_INTENTIONS_SUCCESS,
  payload,
});

export const exportListErrorLog = (payload) => ({
  type: types.EXPORT_GET_LIST_ERROR_LOG,
  payload,
});

export const loadingDownloadListErrorLog = (payload) => ({
  type: types.LOAGING_LIST_ERROR_LOG,
  payload,
});

/* ================ Nps Reports ===================== */

export const getNpsReportRequest = (payload) => ({
  type: types.GET_NPS_REPORT_REQUEST,
  payload,
});

export const getNpsReportSuccess = (payload) => ({
  type: types.GET_NPS_REPORT_SUCCESS,
  payload,
});
