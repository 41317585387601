import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { ContainerSideBar, GlobalStyle, ContainerBgSideBar } from './style';
import { getCompanyPlanConsumption } from '../../store/modules/plans/actions';

import logoLigoBotsFull from '~/assets/ligo/ligo-bots.svg';
import logoLigo from '~/assets/ligo/avatar-ligo-white.svg';

import Profile from './components/Profile';
import Plan from './components/Plan';
import Typography from '../Typography';

import ItemThatOpenProvider from './context/ItemThatOpen';

const SideBar = ({ profiles, menuOptions, isOpened, onToggle }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isOpenedSide, setIsOpenedSide] = useState(isOpened);
  const [consumptionCount, setConsumptionCount] = React.useState(0);

  const { planConsumption } = useSelector(({ plans }) => plans);
  const plans = useSelector((state) => state.company.company);
  const { loggedUser } = useSelector(({ auth }) => auth);
  const { isRootUser } = loggedUser;
  const [selectedDate] = useState(new Date());

  const claimUser =
    loggedUser?.selectedCompany?.claim?.name?.toLowerCase?.() || '';

  const isNoPortrait = useMediaQuery({ query: '(min-width: 961px)' });
  const isPortrait = useMediaQuery({ query: '(max-width: 960px)' });

  const timezone =
    loggedUser && loggedUser?.selectedCompany
      ? loggedUser?.selectedCompany.timezone.fuso
      : undefined;

  const reportFilter = {
    companyId: plans._id,
    date: selectedDate,
    timezone,
  };

  useEffect(() => {
    if (plans._id) {
      dispatch(getCompanyPlanConsumption(reportFilter));
    }
  }, [plans]);

  useEffect(() => {
    if (plans?.plan?.name?.toLowerCase() === 'freemium') {
      setConsumptionCount(planConsumption?.webchatConsumption || 0);
    } else {
      setConsumptionCount(planConsumption?.whatsappConsumption || 0);
    }
  }, [planConsumption]);

  useEffect(() => {
    setIsOpenedSide(isOpened);
  }, [isOpened]);

  useEffect(() => {
    if (isPortrait) {
      setIsOpenedSide(false);
    }

    if (isNoPortrait) {
      if (!isOpened) {
        setIsOpenedSide(false);
      } else {
        setIsOpenedSide(true);
      }
    }
  }, [isPortrait, isNoPortrait]);

  const maxMessages = useMemo(() => {
    if (plans?.plan?.name?.toLowerCase() === 'freemium') {
      return plans?.plan?.amountWebchatConversation;
    }
    return plans?.plan?.amountWhatsappConversation;
  }, [plans.plan]);

  const getConsumption = () => {
    if (maxMessages === 0) {
      return 0;
    }
    return parseFloat(((consumptionCount * 100) / maxMessages).toFixed(2));
  };

  const handleToogle = () => {
    if (isOpenedSide !== isOpened) {
      setIsOpenedSide(!isOpenedSide);
    } else {
      onToggle();
    }
  };

  const handleMenuOptionClick = (option) => {
    option.title?.toLowerCase() === 'faq' ||
    option.title?.toLowerCase() === 'documentação' ||
    option.title?.toLowerCase() === 'documentation'
      ? window.open(option.route, '_blank')
      : history.push(option.route);
  };

  return (
    <>
      <GlobalStyle isOpen={isOpenedSide} />

      <ContainerBgSideBar
        isOpen={isOpenedSide}
        tabIndex="-1"
        onClick={handleToogle}
      />

      <ContainerSideBar isOpen={isOpenedSide}>
        <button
          onClick={handleToogle}
          type="button"
          className={`btn ${!isOpenedSide && 'close'}`}
        >
          {isOpenedSide ? <FaCaretLeft /> : <FaCaretRight />}
        </button>
        <div className="item main-sidebar">
          <div className="logo-details">
            <Link to="/bots" className="details-title">
              {isOpenedSide ? (
                <img src={logoLigoBotsFull} alt="Logo boteria" />
              ) : (
                <img className="rounded" src={logoLigo} alt="Logo boteria" />
              )}
            </Link>
          </div>

          <ul className="nav-list">
            <ItemThatOpenProvider>
              {profiles.map((profile, index) => (
                <li id={`menu-profile-${String(index)}`} key={String(index)}>
                  <Profile
                    profile={profile}
                    isOpenSidebar={isOpenedSide}
                    index={index}
                  />
                </li>
              ))}
            </ItemThatOpenProvider>

            <div className="separator" />

            <Plan
              consumption={getConsumption()}
              disabled={!(claimUser === 'admin' || isRootUser)}
            />

            {menuOptions.map((option, index) => (
              <li key={String(index)} hidden={option?.hidden}>
                {(option.separator && <div className="separator" />) || (
                  <>
                    <button
                      className={`btn-link ${
                        option.route ===
                          `/${location.pathname.split('/')[1]}` && 'emphasis'
                      }`}
                      type="button"
                      disabled={option.disabled}
                      onClick={() => handleMenuOptionClick(option)}
                    >
                      <i>{option.icon}</i>
                      {(option.title && (
                        <>
                          <div className="links_name">
                            <div className="title">{option.title}</div>
                            <div className="name">{option.name}</div>
                          </div>
                          {option.badge && (
                            <span className="badge">{option.badge}</span>
                          )}
                        </>
                      )) || <span className="links_name">{option.name}</span>}
                    </button>
                    {option.tooltip && (
                      <span className="tooltip">
                        {(option.title && (
                          <>
                            {option.title} <br /> <strong>{option.name}</strong>
                          </>
                        )) ||
                          option.name}
                      </span>
                    )}
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>

        <div>
          <div className="separator" />
          <div className="item">
            <a
              href="https://ligo.freshstatus.io/"
              target="_blank"
              rel="noreferrer"
            >
              <Typography variant="caption-default">Status page</Typography>
            </a>
            <a href="/privacy-policy" target="_blank" rel="noreferrer">
              <Typography variant="caption-default">
                {t('labels.policy_privacy')}
              </Typography>
            </a>
          </div>
        </div>
      </ContainerSideBar>
    </>
  );
};

SideBar.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object),
  menuOptions: PropTypes.arrayOf(PropTypes.object),
  isOpened: PropTypes.bool,
  onToggle: PropTypes.func,
};

SideBar.defaultProps = {
  profiles: [],
  menuOptions: [],
  isOpened: false,
  onToggle: () => {},
};

export default SideBar;
