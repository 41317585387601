import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import Proptypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as IconPlan } from '../../../../assets/icons/icon_plan.svg';

import * as S from './style';

const SideBarPlan = ({ consumption, disabled }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  let statusColor = '#38B878';
  if (Number(consumption) > 50 && Number(consumption) <= 80) {
    statusColor = '#F9D65B';
  } else if (Number(consumption) > 80) {
    statusColor = '#F9675B';
  }

  const option = {
    title: t('profile.plan.title'),
    icon: <IconPlan />,
    tooltip: true,
    route: '/preferences/plan',
  };

  return (
    <li>
      <button
        className={`btn-link ${
          option.route === location.pathname && 'emphasis'
        }`}
        disabled={disabled}
        type="button"
        onClick={() => history.push(option.route)}
      >
        <i>{option.icon}</i>
        <div className="links_name">
          <S.PlanInfo consumption={consumption} color={statusColor}>
            <div className="title">{option.title}</div>
            <div className="loading-bar" />
            <div className="loading-sub">
              {consumption}% {t('profile.plan.used')}
            </div>
          </S.PlanInfo>
        </div>
      </button>
      {option.tooltip && (
        <span className="tooltip">
          <>
            {option.title} <br />{' '}
            <strong style={{ fontSize: '0.8rem' }}>
              {consumption}% {t('profile.plan.used')}
            </strong>
          </>
        </span>
      )}
    </li>
  );
};

SideBarPlan.propTypes = {
  consumption: Proptypes.number.isRequired,
  disabled: Proptypes.bool.isRequired,
};

export default SideBarPlan;
