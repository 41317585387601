import { indexedDBStore } from './indexedDBStore';

export const removeStoragePrefixWithCache = async () => {
  try {
    if ('caches' in window) {
      await indexedDBStore.clear();

      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map((cache) => caches.delete(cache)));
      console.log('Caches do navegador limpos com sucesso');
    }

    if (window.gc) {
      window.gc();
      console.log('Coleta de lixo forçada');
    } else {
      console.warn('Coleta de lixo não disponível');
    }
  } catch (error) {
    console.error('Erro ao limpar o cache');
  }
};
