import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '~/components/Typography';

import logoLigo from '~/assets/ligo/logo-azul.png';
import iconAlert from '~/assets/icons/icon_alert_gray.svg';

import * as S from './styles';

const StaticError = () => {
  const { t } = useTranslation();
  return (
    <S.Container>
      <img src={iconAlert} alt="" className="icon" />
      <Typography variant="h5-normal">
        <strong>{t('error.error_unexpected')}</strong>
      </Typography>
      <Typography variant="h6-normal" className="descripton">
        {t('reports.history.expanded_no_access_description')}
      </Typography>
      <img src={logoLigo} alt="" className="img-logo" />
    </S.Container>
  );
};

export default StaticError;
