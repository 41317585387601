import types from './types';

export const setIsUserViewerRedator = (isUserViewerRedator) => ({
  type: types.SET_IS_USER_VIEWER_REDATOR,
  payload: isUserViewerRedator,
});

export const setIsViewerRedator = (isViewerRedator) => ({
  type: types.SET_IS_VIEWER_REDATOR,
  payload: isViewerRedator,
});

export const setIsViewer = (isViewer) => ({
  type: types.SET_IS_VIEWER,
  payload: isViewer,
});
