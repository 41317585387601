import { indexedDBStore } from './indexedDBStore';

export const invalidateCache = async (patterns) => {
  const store = indexedDBStore;
  const cacheKeys = await store.keys();

  await Promise.all(
    cacheKeys.map(async (key) => {
      if (patterns.some((pattern) => key.includes(pattern))) {
        await store.removeItem(key);
      }
    })
  );
};

const generateCacheInvalidation = async (config, request) => {
  if (request.clearCacheEntry && request.clearCacheEntry?.length !== 0) {
    await invalidateCache(request.clearCacheEntry);
  }
};

export default generateCacheInvalidation;
