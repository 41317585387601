import types from './types';

export const fetchIvrBedrockPromptTemplates = () => ({
  type: types.FETCH_IVR_BEDROCK_PROMPT_TEMPLATES,
});
export const fetchIvrBedrockPromptTemplatesSuccess = (templates) => ({
  type: types.FETCH_IVR_BEDROCK_PROMPT_TEMPLATES_SUCCESS,
  payload: templates,
});
export const fetchIvrBedrockPromptTemplatesFailure = (templates) => ({
  type: types.FETCH_IVR_BEDROCK_PROMPT_TEMPLATES_SUCCESS,
  payload: templates,
});

export const searchIvrBedrockPromptTemplates = ({ page, limit, search }) => ({
  type: types.SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES,
  payload: { page, limit, search },
});
export const searchIvrBedrockPromptTemplatesSuccess = (data) => ({
  type: types.SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES_SUCCESS,
  payload: data,
});
export const searchIvrBedrockPromptTemplatesFailure = () => ({
  type: types.SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES_FAILURE,
});

export const createIvrBedrockPromptTemplate = (data, callback) => ({
  type: types.CREATE_IVR_BEDROCK_PROMPT_TEMPLATE,
  payload: { data, callback },
});
export const createIvrBedrockPromptTemplateSuccess = (template) => ({
  type: types.CREATE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS,
  payload: template,
});
export const createIvrBedrockPromptTemplateFailure = () => ({
  type: types.CREATE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE,
});

export const updateIvrBedrockPromptTemplate = (data, callback) => ({
  type: types.UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE,
  payload: { data, callback },
});
export const updateIvrBedrockPromptTemplateSuccess = (template) => ({
  type: types.UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS,
  payload: template,
});
export const updateIvrBedrockPromptTemplateFailure = () => ({
  type: types.UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE,
});

export const deleteIvrBedrockPromptTemplate = (id, callback) => ({
  type: types.DELETE_IVR_BEDROCK_PROMPT_TEMPLATE,
  payload: { id, callback },
});
export const deleteIvrBedrockPromptTemplateSuccess = () => ({
  type: types.DELETE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS,
});
export const deleteIvrBedrockPromptTemplateFailure = () => ({
  type: types.DELETE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE,
});

export const createIvrBedrockPromptCompletions = (data, callback) => ({
  type: types.CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS,
  payload: { data, callback },
});
export const createIvrBedrockPromptCompletionsSuccess = () => ({
  type: types.CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS_SUCCESS,
});
export const createIvrBedrockPromptCompletionsFailure = () => ({
  type: types.CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS_FAILURE,
});

export const fetchIvrBedrockModels = () => ({
  type: types.FETCH_IVR_BEDROCK_MODELS,
});
export const fetchIvrBedrockModelsSuccess = (data) => ({
  type: types.FETCH_IVR_BEDROCK_MODELS_SUCCESS,
  payload: data,
});
export const fetchIvrBedrockModelsFailure = () => ({
  type: types.FETCH_IVR_BEDROCK_MODELS_FAILURE,
});
