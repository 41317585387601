import types from './types';

export const fetchCompany = (payload) => ({
  type: types.FETCH_COMPANY,
  payload,
});
export const fetchCompanySuccess = (payload) => ({
  type: types.FETCH_COMPANY_SUCCESS,
  payload,
});

export const fetchCompanies = (payload) => ({
  type: types.FETCH_COMPANIES,
  payload,
});
export const fetchCompaniesSuccess = (payload) => ({
  type: types.FETCH_COMPANIES_SUCCESS,
  payload,
});

export const updateCompany = (payload) => ({
  type: types.UPDATE_COMPANY,
  payload,
});
export const updateCompanySuccess = (payload) => ({
  type: types.UPDATE_COMPANY_SUCCESS,
  payload,
});

export const updateSelectedCompanySuccess = (payload) => ({
  type: types.UPDATE_SELECTED_COMPANY_SUCCESS,
  payload,
});

export const changePlanCompany = (payload) => ({
  type: types.CHANGE_PLAN_COMPANY,
  payload,
});

export const changePlanCompanySuccess = (payload) => ({
  type: types.CHANGE_PLAN_COMPANY_SUCCESS,
  payload,
});

export const createAdditionalMessage = (payload) => ({
  type: types.CREATE_ADDITIONAL_MESSAGE,
  payload,
});

export const createAdditionalMessageSuccess = (payload) => ({
  type: types.CREATE_ADDITIONAL_MESSAGE_SUCCESS,
  payload,
});

export const createNewPlan = (payload) => ({
  type: types.CREATE_NEW_PLAN,
  payload,
});

export const createNewPlanSuccess = (payload) => ({
  type: types.CREATE_NEW_PLAN_SUCCESS,
  payload,
});

export const fetchOrganizations = (payload) => ({
  type: types.FETCH_ORGANIZATIONS,
  payload,
});

export const fetchOrganizationsSuccess = (payload) => ({
  type: types.FETCH_ORGANIZATIONS_SUCCESS,
  payload,
});

export const fetchCompanyHistoryActions = (payload) => ({
  type: types.FETCH_COMPANY_HISTORY_ACTIONS,
  payload,
});

export const fetchCompanyHistoryActionsSuccess = (payload) => ({
  type: types.FETCH_COMPANY_HISTORY_ACTIONS_SUCCESS,
  payload,
});
