import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  profiles: [],
  pressets: [],
};

export default function leia(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_PROFILES: {
        draft.loading = true;
        break;
      }

      case types.FETCH_PROFILES_SUCCESS: {
        draft.profiles = action.payload.profiles;
        draft.loading = false;
        break;
      }

      case types.FETCH_PRESSETS: {
        draft.loading = true;
        break;
      }

      case types.FETCH_PRESSETS_SUCCESS: {
        draft.pressets = action.payload.pressets;
        draft.loading = false;
        break;
      }

      default:
        return state;
    }
  });
}
