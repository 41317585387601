import axios from 'axios';

import { toast } from '../components/CustomToast';
import i18n from '../translations/i18n';
// import cache from './cache';
import { apiInterceptorDuplicated } from './apiInterceptors';

const url = process.env.REACT_APP_API_URL || 'http://localhost:3333';

const api = axios.create({
  baseURL: `${url}/api` || 'http://localhost:3333',
  // adapter: cache.adapter,
});

const UNAUTHORIZED_STATUS = 401;
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === UNAUTHORIZED_STATUS) {
      localStorage.removeItem('persist:@boteria-frontend');
      toast.error(i18n.t('error.error'), i18n.t('error.error_token_expired'));
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

/**
 * Lógica para evitar chamadas duplicadas
 * Caso de uso: tenho dois componentes na tela que fazem a mesma requisição,
 * a implementação abaixo evita que a api seja chamada duas vezes.
 * fonte -> https://medium.com/@velja/cancelling-duplicate-requests-with-axios-076813d3a343
 */
const pendingRequests = new Map();

api.interceptors.request.use(
  (config) => {
    apiInterceptorDuplicated.request(config, pendingRequests);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    apiInterceptorDuplicated.response(response, pendingRequests);
    return response;
  },
  (error) => {
    if (error.config) {
      const requestIdentifier = `${error.config.url}_${error.config.method}`;
      pendingRequests.delete(requestIdentifier);
    }
    return Promise.reject(error);
  }
);

export default api;
