import { takeLatest, call, put, all, select, fork } from 'redux-saga/effects';

import api from '../../../services/api';
import * as actions from './actions';
import types from './types';

import {
  fetchItemsSuccess,
  fetchSubflowItemsSuccess,
  fetchResumedAllItemsSuccess,
  fetchAllItemsSuccess,
} from '../items/actions';
import {
  fetchConnectorsSuccess,
  fetchSubflowConnectorsSuccess,
} from '../connectors/actions';
import {
  fetchBehaviorPendingsSuccess,
  fetchBotsSuccess,
  fetchBotSuccess,
  fetchBuilderPendingsSuccess,
  updateBotRequest,
} from '../bots/actions';
import { fetchCompanySuccess } from '../company/actions';

function* fetchAllBots() {
  const response = yield call(api.get, '/v3/bots');

  yield put(fetchBotsSuccess({ bots: response.data }));
}

function* fetchItems({ botId }) {
  const { data } = yield call(api.get, `/v3/bots/${botId}/items`);
  yield put(fetchItemsSuccess({ items: data?.items || [] }));
  yield put(fetchAllItemsSuccess({ allItems: data?.items || [] }));
  yield put(fetchBotSuccess({ bot: data?.bot }));
}

function* fetchCompany({ companyId }) {
  const { data } = yield call(api.get, `v3/companies/${companyId}`);
  yield put(fetchCompanySuccess(data));
}

function* fetchSubflowItems({ botId, subflowId }) {
  const { data } = yield call(
    api.get,
    `/v3/bots/${botId}/items-subflow/${subflowId}`
  );
  yield put(
    fetchSubflowItemsSuccess({
      items: data?.items || [],
      subflowPath: data?.path || [],
    })
  );
  yield put(fetchBotSuccess({ bot: data?.bot }));
}

function* fetchResumedAllItems({ botId }) {
  const { data } = yield call(api.get, `/v3/bots/${botId}/resumed-all-items`);
  yield put(fetchResumedAllItemsSuccess(data));
}

export function* fetchBuilderNopublishCount({ botId, isFork, isUra }) {
  if (isUra) {
    const builderPendingReponse = yield call(
      api.get,
      `v3/bots/${botId}/builder-pending`
    );
    yield put(fetchBuilderPendingsSuccess(builderPendingReponse.data));
    return;
  }

  const urlBuilder = `v3/bots/${botId}/builder-pending`;
  const urlBehavior = `v3/bots/${botId}/behavior-pending`;
  if (isFork) return;
  const [dataBuilder, dataBehavior] = yield all([
    call(api.get, urlBuilder),
    call(api.get, urlBehavior),
  ]);

  yield put(fetchBuilderPendingsSuccess(dataBuilder.data));
  yield put(fetchBehaviorPendingsSuccess(dataBehavior.data));

  const countUpdatesNoPublish =
    dataBehavior.data.totalDocs + dataBuilder.data.totalDocs;
  const currentCountUpdatesNoPublish = yield select(
    ({ bots }) => bots.bot.countUpdatesNoPublish
  );

  if (currentCountUpdatesNoPublish !== countUpdatesNoPublish) {
    yield put(updateBotRequest(botId, { countUpdatesNoPublish }, false));
  }
}

function* fetchConnectors({ botId }) {
  const { data } = yield call(api.get, `/v3/bots/${botId}/connectors`);
  yield put(fetchConnectorsSuccess({ connectors: data?.connectors || [] }));
}

function* fetchSubflowConnectors({ botId, subflowId }) {
  const { data } = yield call(
    api.get,
    `/v3/bots/${botId}/connectors-subflow/${subflowId}`
  );
  yield put(
    fetchSubflowConnectorsSuccess({ connectors: data?.connectors || [] })
  );
}

function* fetchAll({ payload }) {
  const { botId, subflowId, isFork, companyId, isUra } = payload;

  if (subflowId) {
    yield all([
      fork(fetchAllBots),
      fork(fetchSubflowItems, { botId, subflowId }),
      fork(fetchSubflowConnectors, { botId, subflowId }),
      fork(fetchResumedAllItems, { botId }),
      fork(fetchBuilderNopublishCount, { botId, isFork, isUra }),
      fork(fetchCompany, { companyId }),
    ]);
  } else {
    yield all([
      fork(fetchAllBots),
      fork(fetchItems, { botId }),
      fork(fetchConnectors, { botId }),
      fork(fetchResumedAllItems, { botId }),
      fork(fetchBuilderNopublishCount, { botId, isFork, isUra }),
      fork(fetchCompany, { companyId }),
    ]);
  }
}

function* main(action) {
  try {
    yield call(fetchAll, action);
    yield put(actions.fetchDataSuccess());
  } catch (e) {
    yield put(actions.fetchDataFailure());
  }
}

export default all([takeLatest(types.FETCH_DATA_START, main)]);
