import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { FaExclamationTriangle } from 'react-icons/fa';

import Button from '~/components/Button';
import Modal, { ModalFooter } from '~/components/Modal';
import Typography from '~/components/Typography';

import * as S from './styles';

const ModalConfirmation = ({
  title,
  description,
  messageWarning,
  isOpen,
  setModaIsOpen,
  onClick,
  confirmOptionMessage,
  idCancelButton,
  idDeleteButton,
}) => {
  const { t } = useTranslation();

  const handleModalisOpen = () => {
    setModaIsOpen(false);
  };

  const handleClickConfirm = () => {
    onClick();
    setModaIsOpen(false);
  };

  return (
    <Modal
      maxWidth="413px"
      title={title}
      isOpen={isOpen}
      onClose={handleModalisOpen}
    >
      {description ? (
        <S.TextElement>
          <Typography variant="body-small" marginBottom="16px">
            {description}
          </Typography>
        </S.TextElement>
      ) : null}

      <S.ContainerBody>
        <div className="divIcon">
          <FaExclamationTriangle />
        </div>

        <div className="divText">
          <p>
            {t('bots.important')}
            <span> {messageWarning} </span>
          </p>
        </div>
      </S.ContainerBody>
      <ModalFooter>
        <Button
          variant="ghost"
          onClick={() => setModaIsOpen(!isOpen)}
          id={idCancelButton}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          color="danger"
          variant="fill"
          size="medium"
          type="button"
          onClick={handleClickConfirm}
          id={idDeleteButton}
        >
          {confirmOptionMessage || t('buttons.delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ModalConfirmation.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  messageWarning: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setModaIsOpen: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  confirmOptionMessage: PropTypes.string,
};

ModalConfirmation.defaultProps = {
  confirmOptionMessage: null,
  description: '',
};

export default ModalConfirmation;
