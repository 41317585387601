import { useHistory } from 'react-router-dom';

import history from '~/services/history';

function SetHistory() {
  history.component = useHistory();

  return null;
}

export default SetHistory;
