import { takeLatest, call, put, all } from 'redux-saga/effects';
import types from './types';
import api from '~/services/api';
import { toast } from '~/components/CustomToast';
import i18n from '../../../translations/i18n';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';

export function* getRegisters() {
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.get, '/v3/register-from');

    yield put(actions.getRegistersSuccess(data));
  } catch (error) {
    console.error(error);
    toast.error(i18n.t('error.error'), i18n.t('error.error_register_from'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([takeLatest(types.GET_REGISTERS_REQUEST, getRegisters)]);
