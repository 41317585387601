import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  configsIvrOmni: [],
  configIvrOmni: null,
  isLoadingConfigIvrOmni: false,
  audios: [],
  servicesOrganination: [],
  audioTmpTts: {},
  loadingAudioTts: false,
  loadingAudioTtsId: null,
  foundAudios: { data: [], metadata: null },
  isLoadingSearchAudios: false,
  ivrs: { data: null, error: false, loading: false },
  ttsVoices: { data: null, error: false, loading: false },
  isLoadingIvrPublish: false,
  isLoadingAudioUpload: false,
  dacDsQueues: { data: null, error: false, loading: false },
  attendGroups: { data: null, error: false, loading: false },
  whatsappNumberchips: { data: null, error: false, loading: false },
  whatsappTemplates: { data: null, error: false, loading: false },
  namaDatasets: { data: null, error: false, loading: false },
  topdeskOperatorGroups: { data: null, error: false, loading: false },
  itemsLogKeys: { data: null, error: false, loading: false },
  foundIvrAccounts: { data: null, error: false, loading: false },
};

const updateObject = (values, newValue) => {
  const updated = values.map((value) => {
    if (value._id === newValue._id) {
      return { ...newValue };
    }

    return value;
  });

  return updated;
};

export default function ivrState(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CREATE_CONFIG_IVR_OMNI_SUCCESS:
        draft.configsIvrOmni = [...state.configsIvrOmni, action.payload];
        break;
      case types.FETCH_ALL_CONFIG_IVR_OMNI_SUCCESS:
        draft.configsIvrOmni = action.payload;
        break;

      case types.FETCH_CONFIG_IVR_OMNI_BY_ID:
        draft.isLoadingConfigIvrOmni = true;
        break;
      case types.FETCH_CONFIG_IVR_OMNI_BY_ID_SUCCESS:
        draft.configIvrOmni = action.payload;
        draft.isLoadingConfigIvrOmni = false;
        break;
      case types.FETCH_CONFIG_IVR_OMNI_BY_ID_FAILURE:
        draft.configIvrOmni = null;
        draft.isLoadingConfigIvrOmni = false;
        break;

      case types.UPDATE_CONFIG_OMNI_IVR_SUCCESS:
        draft.configsIvrOmni = updateObject(
          state.configsIvrOmni,
          action.payload
        );
        break;
      case types.REMOVE_CONFIG_IVR_OMNI_SUCCESS:
        draft.configsIvrOmni = state.configsIvrOmni.filter(
          (config) => config._id !== action.payload._id
        );
        break;
      case types.FETCH_IVR_AUDIOS_SUCCESS:
        draft.audios = action.payload.audios;
        break;
      case types.IVR_UPLOAD_AUDIOS_REQUEST:
        draft.isLoadingAudioUpload = true;
        break;
      case types.IVR_UPLOAD_AUDIOS_SUCCESS:
        draft.audios = [...state.audios, ...action.payload.audios];
        draft.isLoadingAudioUpload = false;
        break;
      case types.IVR_UPLOAD_AUDIOS_FAILURE:
        draft.isLoadingAudioUpload = false;
        break;
      case types.DELETE_IVR_AUDIOS_SUCCESS:
        draft.audios = draft.audios.filter(
          (audio) => audio._id !== action.payload.audioId
        );
        break;

      case types.UPDATE_IVR_AUDIOS_SUCCESS:
        draft.audios = updateObject(state.audios, action.payload);
        draft.foundAudios.data = updateObject(
          state.foundAudios.data,
          action.payload
        );
        break;

      case types.FETCH_IVR_SERVICES_BY_ORGANIZATION_SUCCESS:
        draft.servicesOrganination = action.payload;
        break;

      case types.GENERATE_TTS_REQUEST: {
        draft.loadingAudioTts = true;
        draft.loadingAudioTtsId = action.payload.id;
        break;
      }
      case types.GENERATE_TTS_SUCCESS: {
        draft.loadingAudioTts = false;
        draft.loadingAudioTtsId = null;
        draft.audioTmpTts = action.payload;
        break;
      }
      case types.GENERATE_TTS_FAILURE: {
        draft.loadingAudioTts = false;
        break;
      }
      case types.GENERATE_TTS_SAVE_SUCCESS: {
        draft.audios = [...state.audios, action.payload];
        draft.loadingAudioTts = false;
        break;
      }
      case types.SET_AUDIO_TMP_TTS: {
        draft.audioTmpTts = action.payload;
        break;
      }

      case types.FETCH_TTS_VOICES: {
        draft.ttsVoices.loading = true;
        break;
      }
      case types.FETCH_TTS_VOICES_SUCCESS: {
        draft.ttsVoices.data = action.payload;
        draft.ttsVoices.loading = false;
        break;
      }
      case types.FETCH_TTS_VOICES_FAILURE: {
        draft.ttsVoices = { data: null, error: true, loading: false };
        break;
      }

      case types.SEARCH_IVR_AUDIOS: {
        draft.isLoadingSearchAudios = true;
        break;
      }
      case types.SEARCH_IVR_AUDIOS_SUCCESS: {
        draft.foundAudios = action.payload;
        draft.isLoadingSearchAudios = false;
        break;
      }
      case types.SEARCH_IVR_AUDIOS_FAILURE: {
        draft.foundAudios = { data: [], metadata: null };
        draft.isLoadingSearchAudios = false;
        break;
      }
      case types.RESET_SEARCH_IVR_AUDIOS: {
        draft.isLoadingSearchAudios = false;
        draft.foundAudios = {};
        break;
      }

      case types.FETCH_IVRS_REQUEST: {
        draft.ivrs = { ...state.ivrs, loading: true, error: false };
        break;
      }
      case types.FETCH_IVRS_SUCCESS: {
        const data = action.payload.data;
        draft.ivrs = { ...state.ivrs, data, loading: false, error: false };
        break;
      }
      case types.FETCH_IVRS_FAILURE: {
        draft.ivrs = { ...state.ivrs, loading: false, error: true };
        break;
      }

      case types.CLONE_IVR_SUCCESS: {
        draft.ivrs.data = [...(state.ivrs.data || []), action.payload];
        break;
      }

      case types.DELETE_IVR_SUCCESS: {
        const { id } = action.payload;
        const newIvrList = state.ivrs?.data?.filter?.((ivr) => ivr._id !== id);
        draft.ivrs = { ...state.ivrs, data: newIvrList || [] };
        break;
      }

      case types.PUBLISH_IVR_REQUEST: {
        draft.isLoadingIvrPublish = true;
        break;
      }
      case types.PUBLISH_IVR_SUCCESS: {
        draft.isLoadingIvrPublish = false;
        break;
      }
      case types.PUBLISH_IVR_FAILURE: {
        draft.isLoadingIvrPublish = false;
        break;
      }

      case types.FETCH_IVR_DAC_DS_QUEUES: {
        draft.dacDsQueues.loading = true;
        draft.dacDsQueues.data = null;
        break;
      }
      case types.FETCH_IVR_DAC_DS_QUEUES_SUCCESS: {
        draft.dacDsQueues.loading = false;
        draft.dacDsQueues.data = action.payload;
        break;
      }
      case types.FETCH_IVR_DAC_DS_QUEUES_FAILURE: {
        draft.dacDsQueues.loading = false;
        draft.dacDsQueues.error = true;
        break;
      }

      case types.SEARCH_IVR_ATTEND_GROUPS: {
        draft.attendGroups.loading = true;
        draft.attendGroups.data = null;
        break;
      }
      case types.SEARCH_IVR_ATTEND_GROUPS_SUCCESS: {
        draft.attendGroups.loading = false;
        draft.attendGroups.data = action.payload;
        break;
      }
      case types.SEARCH_IVR_ATTEND_GROUPS_FAILURE: {
        draft.attendGroups.loading = false;
        draft.attendGroups.error = true;
        break;
      }

      case types.FETCH_ALL_WHATSAPP_NUMBERCHIPS: {
        draft.whatsappNumberchips.loading = true;
        draft.whatsappNumberchips.data = null;
        break;
      }
      case types.FETCH_ALL_WHATSAPP_NUMBERCHIPS_SUCCESS: {
        draft.whatsappNumberchips.loading = false;
        draft.whatsappNumberchips.data = action.payload;
        break;
      }
      case types.FETCH_ALL_WHATSAPP_NUMBERCHIPS_FAILURE: {
        draft.whatsappNumberchips.loading = false;
        draft.whatsappNumberchips.error = true;
        break;
      }

      case types.FETCH_ALL_WHATSAPP_TEMPLATES: {
        draft.whatsappTemplates.loading = true;
        draft.whatsappTemplates.data = null;
        break;
      }
      case types.FETCH_ALL_WHATSAPP_TEMPLATES_SUCCESS: {
        draft.whatsappTemplates.loading = false;
        draft.whatsappTemplates.data = action.payload;
        break;
      }
      case types.FETCH_ALL_WHATSAPP_TEMPLATES_FAILURE: {
        draft.whatsappTemplates.loading = false;
        draft.whatsappTemplates.error = true;
        break;
      }

      case types.FETCH_NAMA_DATASETS: {
        draft.namaDatasets.loading = true;
        draft.namaDatasets.data = null;
        break;
      }
      case types.FETCH_NAMA_DATASETS_SUCCESS: {
        draft.namaDatasets.loading = false;
        draft.namaDatasets.data = action.payload;
        break;
      }
      case types.FETCH_NAMA_DATASETS_FAILURE: {
        draft.namaDatasets.loading = false;
        draft.namaDatasets.error = true;
        break;
      }

      case types.FETCH_TOPDESK_OPERATOR_GROUP: {
        draft.topdeskOperatorGroups.loading = true;
        draft.topdeskOperatorGroups.data = null;
        break;
      }
      case types.FETCH_TOPDESK_OPERATOR_GROUP_SUCCESS: {
        draft.topdeskOperatorGroups.loading = false;
        draft.topdeskOperatorGroups.data = action.payload;
        break;
      }
      case types.FETCH_TOPDESK_OPERATOR_GROUP_FAILURE: {
        draft.topdeskOperatorGroups.loading = false;
        draft.topdeskOperatorGroups.error = true;
        break;
      }

      case types.FETCH_IVR_ITEMS_LOG_KEYS: {
        draft.itemsLogKeys.loading = true;
        draft.itemsLogKeys.data = null;
        break;
      }
      case types.FETCH_IVR_ITEMS_LOG_KEYS_SUCCESS: {
        draft.itemsLogKeys.loading = false;
        draft.itemsLogKeys.data = action.payload;
        break;
      }
      case types.FETCH_IVR_ITEMS_LOG_KEYS_FAILURE: {
        draft.itemsLogKeys.loading = false;
        draft.itemsLogKeys.error = true;
        break;
      }

      case types.SEARCH_IVR_ACCOUNTS_BY_ORG: {
        draft.foundIvrAccounts.loading = true;
        draft.foundIvrAccounts.data = null;
        break;
      }
      case types.SEARCH_IVR_ACCOUNTS_BY_ORG_SUCCESS: {
        draft.foundIvrAccounts.loading = false;
        draft.foundIvrAccounts.data = action.payload;
        break;
      }
      case types.SEARCH_IVR_ACCOUNTS_BY_ORG_FAILURE: {
        draft.foundIvrAccounts.loading = false;
        draft.foundIvrAccounts.error = true;
        break;
      }
      default:
        return { ...state };
    }
  });
}
