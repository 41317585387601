import { takeLatest, call, put, all } from 'redux-saga/effects';

import i18n from '~/translations/i18n';
import api from '~/services/api';
import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import { toast } from '~/components/CustomToast';
import { isRequestCanceledError } from '~/utils/apiErrors';

import * as actions from './actions';
import types from './types';

export function* getTemplates() {
  yield put(commonLoadingStart());
  try {
    const url = `/v3/template-bot/list/all`;
    const { data } = yield call(api.get, url);

    yield put(actions.getTemplatesSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_templates'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createTemplateBot(action) {
  const { payload } = action;

  yield put(commonLoadingStart());

  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });

  try {
    const url = `/v3/template-bot`;

    const { data } = yield call(api.post, url, formData);

    yield put(actions.createTemplateBotsuccess({ ...data }));
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_create_template')
    );
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_create_template'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateTemplateBot(action) {
  const { payload } = action;

  yield put(commonLoadingStart());

  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });

  try {
    const url = `/v3/template-bot/${payload._id}`;

    const { data } = yield call(api.put, url, formData);

    yield put(actions.updateTemplateBotSuccess({ ...data }));
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_update_template')
    );
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_update_template'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateTemplateBotFlow(action) {
  const { payload } = action;

  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key]);
  });

  try {
    const url = `/v3/template-bot`;

    const { data } = yield call(api.put, url, formData);

    yield put(actions.updateTemplateBotFlowSuccess({ ...data }));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_update_flow_template')
    );
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_update_flow_template')
    );
  }
}

export function* getTemplateBot(action) {
  const { payload } = action;

  try {
    const url = `/v3/template-bot/${payload.botId}`;
    const response = yield call(api.get, url);

    yield put(actions.getTemplateBotSuccess({ ...response.data }));
  } catch (error) {
    if (!isRequestCanceledError(error)) {
      toast.error(i18n.t('error.error'), i18n.t('error.error_list_template'));
    }
  }
}

export function* getTemplateId(action) {
  const { payload } = action;

  try {
    const url = `/v3/template-bot/template/${payload.templateId}`;
    const { data } = yield call(api.get, url);

    yield put(actions.getTemplateIdSuccess({ ...data }));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_template'));
  }
}

export default all([
  takeLatest(types.GET_TEMPLATES_REQUEST, getTemplates),
  takeLatest(types.GET_TEMPLATE_BOT, getTemplateBot),
  takeLatest(types.UPDATE_TEMPLATE_BOT, updateTemplateBot),
  takeLatest(types.UPDATE_TEMPLATE_BOT_FLOW, updateTemplateBotFlow),
  takeLatest(types.CREATE_TEMPLATE_BOT, createTemplateBot),
  takeLatest(types.GET_TEMPLATE_ID_REQUEST, getTemplateId),
]);
