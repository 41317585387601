import React from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Container,
  LabelWrapper,
  CustomTextArea,
  InputWrapper,
  Helper,
  Error,
} from './styles';

const TextArea = React.forwardRef(
  (
    {
      id,
      label,
      description,
      helper,
      error,
      placeholder,
      className,
      size,
      color,
      disabled,
      startIcon,
      endIcon,
      marginBottom,
      rows,
      ...props
    },
    ref
  ) => {
    const selfId = id || uniqid(`input__`);

    return (
      <Container className={className} marginBottom={marginBottom}>
        {label && (
          <LabelWrapper htmlFor={selfId}>
            <span className="label">{label}</span>
            {description && <span className="description">{description}</span>}
          </LabelWrapper>
        )}

        <InputWrapper className={cc({ hasLabel: Boolean(label) })}>
          <CustomTextArea
            className={cc([
              'input',
              size,
              color,
              {
                error: Boolean(error),
                'has-start-icon': Boolean(startIcon),
                'has-end-icon': Boolean(endIcon),
              },
            ])}
            id={selfId}
            placeholder={placeholder}
            disabled={disabled}
            rows={rows}
            ref={ref}
            {...props}
          />
          {startIcon && (
            <span className={cc(['icon', 'start-icon'])}>{startIcon}</span>
          )}
          {endIcon && (
            <span className={cc(['icon', 'end-icon'])}>{endIcon}</span>
          )}
        </InputWrapper>

        {helper && <Helper className={cc(['helper', color])}>{helper}</Helper>}

        {error && <Error cclassName={cc(['error'])}>{error}</Error>}
      </Container>
    );
  }
);

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  helper: PropTypes.string,
  error: PropTypes.string,
  color: PropTypes.oneOf(['', 'danger', 'success']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  startIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  marginBottom: PropTypes.string,
  rows: PropTypes.string,
};

TextArea.defaultProps = {
  id: '',
  label: '',
  placeholder: '',
  className: '',
  description: '',
  helper: '',
  error: '',
  color: '',
  size: 'medium',
  startIcon: '',
  endIcon: '',
  disabled: false,
  rows: '4',
  marginBottom: '3px',
};

export default TextArea;
