import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  company: {},
  companies: [],
  organizations: [],
  getOrganizationsCompleted: false,
  historyActions: [],
};

export default function company(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_COMPANY_SUCCESS:
        draft.company = { ...action.payload };
        break;

      case types.FETCH_COMPANIES_SUCCESS:
        draft.companies = [...action.payload];
        break;

      case types.UPDATE_COMPANY_SUCCESS:
      case types.UPDATE_SELECTED_COMPANY_SUCCESS: {
        draft.company = action.payload;
        break;
      }

      case types.CHANGE_PLAN_COMPANY_SUCCESS: {
        draft.company = action.payload.company;
        break;
      }

      case types.CREATE_ADDITIONAL_MESSAGE_SUCCESS: {
        draft.company = action.payload.company;
        break;
      }

      case types.CREATE_NEW_PLAN_SUCCESS: {
        draft.company = action.payload.company;
        break;
      }

      case types.FETCH_ORGANIZATIONS_SUCCESS: {
        draft.organizations = action.payload;
        draft.getOrganizationsCompleted = true;
        break;
      }

      case types.FETCH_COMPANY_HISTORY_ACTIONS_SUCCESS: {
        draft.historyActions = action.payload;
        break;
      }

      default:
    }
  });
}
