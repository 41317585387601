const Types = {
  FETCH_IVR_BEDROCK_PROMPT_TEMPLATES:
    '@ivrBedrock/FETCH_IVR_BEDROCK_PROMPT_TEMPLATES',
  FETCH_IVR_BEDROCK_PROMPT_TEMPLATES_SUCCESS:
    '@ivrBedrock/FETCH_IVR_BEDROCK_PROMPT_TEMPLATES_SUCCESS',
  FETCH_IVR_BEDROCK_PROMPT_TEMPLATES_FAILURE:
    '@ivrBedrock/FETCH_IVR_BEDROCK_PROMPT_TEMPLATES_FAILURE',

  SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES:
    '@ivrBedrock/SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES',
  SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES_SUCCESS:
    '@ivrBedrock/SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES_SUCCESS',
  SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES_FAILURE:
    '@ivrBedrock/SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES_FAILURE',

  CREATE_IVR_BEDROCK_PROMPT_TEMPLATE:
    '@ivrBedrock/CREATE_IVR_BEDROCK_PROMPT_TEMPLATE',
  CREATE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS:
    '@ivrBedrock/CREATE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS',
  CREATE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE:
    '@ivrBedrock/CREATE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE',

  UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE:
    '@ivrBedrock/UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE',
  UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS:
    '@ivrBedrock/UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS',
  UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE:
    '@ivrBedrock/UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE',

  DELETE_IVR_BEDROCK_PROMPT_TEMPLATE:
    '@ivrBedrock/DELETE_IVR_BEDROCK_PROMPT_TEMPLATE',
  DELETE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS:
    '@ivrBedrock/DELETE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS',
  DELETE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE:
    '@ivrBedrock/DELETE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE',

  CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS:
    '@ivrBedrock/CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS',
  CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS_SUCCESS:
    '@ivrBedrock/CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS_SUCCESS',
  CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS_FAILURE:
    '@ivrBedrock/CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS_FAILURE',

  FETCH_IVR_BEDROCK_MODELS: '@ivrBedrock/FETCH_IVR_BEDROCK_MODELS',
  FETCH_IVR_BEDROCK_MODELS_SUCCESS:
    '@ivrBedrock/FETCH_IVR_BEDROCK_MODELS_SUCCESS',
  FETCH_IVR_BEDROCK_MODELS_FAILURE:
    '@ivrBedrock/FETCH_IVR_BEDROCK_MODELS_FAILURE',
};

export default Types;
