export const updateCarouselCardImageUrl = ({ oldItem, newItem, cardId }) => {
  const updatedCard = newItem.carousel.cards.find(
    (card) => card._id === cardId
  );
  const updatedCards = oldItem.carousel?.cards?.map((card) =>
    cardId === card._id ? { ...card, imageUrl: updatedCard.imageUrl } : card
  );
  return updatedCards;
};

export const getItemsOnGroup = (items, allItems, itemsOnGroup = []) => {
  items.forEach((itemId) => {
    const newGroups = allItems
      .filter((item) => item?.type === 'Group' && item?.subflowFor === itemId)
      .map((item) => item._id);
    if (newGroups && newGroups?.length > 0) {
      newGroups.forEach((item) => itemsOnGroup.push(item));
      getItemsOnGroup(newGroups, allItems, itemsOnGroup);
    }
  });
  return itemsOnGroup;
};
