import React from 'react';

import { LoadingBar } from './styles';

const LoadingBarComponent = () => (
  <LoadingBar>
    <div className="spinner__progress" />
  </LoadingBar>
);

export default LoadingBarComponent;
