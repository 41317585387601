import produce from 'immer';

import types from './types';

const initialState = {
  fields: [],
  authData: {},
};

export default function rdStationState(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_FIELDS_SUCCESS:
        draft.fields = [...action.payload];
        break;

      case types.GENERATE_ACCESS_TOKEN_SUCCESS:
        draft.authData = { ...action.payload };
        break;

      case types.RESET_AUTH_DATA:
        draft.authData = null;
        break;

      default:
    }
  });
}
