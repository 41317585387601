import React from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';

import * as S from './styles';

export const variantMapping = {
  'h1-heavy': 'h1',
  'h1-normal': 'h1',
  'h2-heavy': 'h2',
  'h2-normal': 'h2',
  'h3-heavy': 'h3',
  'h3-normal': 'h3',
  'h4-heavy': 'h4',
  'h4-normal': 'h4',
  'h5-heavy': 'h5',
  'h5-normal': 'h5',
  'h6-heavy': 'h6',
  'h6-normal': 'h6',
  'body-default': 'p',
  'body-default-strong': 'p',
  'body-large': 'p',
  'body-large-strong': 'p',
  'body-small': 'p',
  'body-small-strong': 'p',
  'button-caption': 'span',
  'button-caption-tight': 'span',
  'caption-default': 'span',
  'caption-small': 'span',
  'caption-small-tight': 'span',
  'overline-large': 'span',
  'overline-small': 'span',
};

const Typography = ({
  align,
  as: asProp,
  color,
  className,
  marginBottom,
  marginTop,
  children,
  noWrap,
  style,
  variant,
  ...props
}) => {
  const asComponent = asProp || variantMapping[variant] || 'span';

  const customStyle = {
    ...(align !== 'inherit' && {
      textAlign: align,
    }),
    ...(color && {
      color: `var(--${color})`,
    }),
    ...(noWrap && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block',
    }),
    ...style,
  };

  return (
    <S.Container
      as={asComponent}
      className={cc([variant, className])}
      style={customStyle}
      marginBottom={marginBottom}
      marginTop={marginTop}
      {...props}
    >
      {children}
    </S.Container>
  );
};

Typography.propTypes = {
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  color: PropTypes.string,
  className: PropTypes.string,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  children: PropTypes.node.isRequired,
  as: PropTypes.string,
  noWrap: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.any),
  variant: PropTypes.oneOf([
    'body-default',
    'body-default-strong',
    'body-large',
    'body-large-strong',
    'body-small',
    'body-small-strong',
    'button-caption',
    'button-caption-tight',
    'caption-default',
    'caption-small',
    'caption-small-tight',
    'h1-heavy',
    'h1-normal',
    'h2-heavy',
    'h2-normal',
    'h3-heavy',
    'h3-normal',
    'h4-heavy',
    'h4-normal',
    'h5-heavy',
    'h5-normal',
    'h6-heavy',
    'h6-normal',
    'overline-large',
    'overline-small',
  ]),
};

Typography.defaultProps = {
  align: 'inherit',
  color: '',
  className: '',
  marginBottom: '',
  marginTop: '',
  as: '',
  noWrap: false,
  style: {},
  variant: 'body-default',
};

export default Typography;
