import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  foundNumbers: { items: [] },
  foundNumbersForManagement: { data: null, error: false, loading: true },
  foundNumbersForBuy: { data: null, error: false, loading: true },
  isLoadingNumbers: false,
  isLoadingAssociateNumber: false,
  isLoadingIvrNumberMutation: false,
};

export default function ivrNumbersState(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_IVR_NUMBERS:
        draft.isLoadingNumbers = true;
        break;
      case types.FETCH_IVR_NUMBERS_SUCCESS:
        draft.foundNumbers = action.payload;
        draft.isLoadingNumbers = false;
        break;
      case types.FETCH_IVR_NUMBERS_FAILURE:
        draft.foundNumbers = { items: [] };
        draft.isLoadingNumbers = false;
        break;

      case types.SEARCH_IVR_NUMBERS_FOR_MANAGEMENT:
        draft.foundNumbersForManagement.loading = true;
        draft.foundNumbersForManagement.error = false;
        break;
      case types.SEARCH_IVR_NUMBERS_FOR_MANAGEMENT_SUCCESS:
        draft.foundNumbersForManagement.data = action.payload;
        draft.foundNumbersForManagement.loading = false;
        break;
      case types.SEARCH_IVR_NUMBERS_FOR_MANAGEMENT_FAILURE:
        draft.foundNumbersForManagement.loading = false;
        draft.foundNumbersForManagement.error = true;
        draft.foundNumbersForManagement.data = [];
        break;

      case types.SEARCH_IVR_NUMBERS_FOR_BUY:
        draft.foundNumbersForBuy.loading = true;
        draft.foundNumbersForBuy.error = false;
        break;
      case types.SEARCH_IVR_NUMBERS_FOR_BUY_SUCCESS:
        draft.foundNumbersForBuy.data = action.payload;
        draft.foundNumbersForBuy.loading = false;
        break;
      case types.SEARCH_IVR_NUMBERS_FOR_BUY_FAILURE:
        draft.foundNumbersForBuy.loading = false;
        draft.foundNumbersForBuy.error = true;
        draft.foundNumbersForBuy.data = [];
        break;

      case types.REMOVE_IVR_NUMBER_FROM_ORGANIZATION:
      case types.BUY_IVR_NUMBER:
      case types.CREATE_IVR_NUMBER:
      case types.UPDATE_IVR_NUMBER:
        draft.isLoadingIvrNumberMutation = true;
        break;
      case types.REMOVE_IVR_NUMBER_FROM_ORGANIZATION_SUCCESS:
      case types.BUY_IVR_NUMBER_SUCCESS:
      case types.CREATE_IVR_NUMBER_SUCCESS:
      case types.UPDATE_IVR_NUMBER_SUCCESS:
        draft.isLoadingIvrNumberMutation = false;
        break;
      case types.REMOVE_IVR_NUMBER_FROM_ORGANIZATION_FAILURE:
      case types.BUY_IVR_NUMBER_FAILURE:
      case types.CREATE_IVR_NUMBER_FAILURE:
      case types.UPDATE_IVR_NUMBER_FAILURE:
        draft.isLoadingIvrNumberMutation = false;
        break;

      case types.ADD_ASSOCIATE_NUMBERS_TO_IVR: {
        draft.isLoadingAssociateNumber = true;
        break;
      }
      case types.ADD_ASSOCIATE_NUMBERS_TO_IVR_SUCCESS: {
        draft.isLoadingAssociateNumber = false;
        break;
      }
      case types.ADD_ASSOCIATE_NUMBERS_TO_IVR_FAILURE: {
        draft.isLoadingAssociateNumber = false;
        break;
      }
      case types.REMOVE_ASSOCIATE_NUMBER_FROM_IVR: {
        draft.isLoadingAssociateNumber = true;
        break;
      }
      case types.REMOVE_ASSOCIATE_NUMBER_FROM_IVR_SUCCESS: {
        draft.isLoadingAssociateNumber = false;
        break;
      }
      case types.REMOVE_ASSOCIATE_NUMBER_FROM_IVR_FAILURE: {
        draft.isLoadingAssociateNumber = false;
        break;
      }

      default:
        return { ...state };
    }
  });
}
