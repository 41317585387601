import styled from 'styled-components';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Textfooter = styled.div`
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  /* or 16px */

  text-align: center;
  letter-spacing: 0.054em;
  text-transform: uppercase;
  font-feature-settings: 'ss01' on;
  color: var(--neutral-3);

  span {
    text-transform: none;
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 150%;

    text-align: center;
    letter-spacing: 0.0075em;
    font-feature-settings: 'ss01' on;

    color: #102693;
  }
`;
