import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  font-size: 10px;
  text-indent: -9999em;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: transparent;

  position: relative;
  animation: ${rotate} 800ms infinite linear;

  &:after {
    background: ${({ variant, background }) =>
      !background && (variant === 'ghost' || variant === 'outline')
        ? 'var(--neutral-0)'
        : 'var(--primary-800-light)'};
    background: ${({ background }) => background !== '' && background};
    width: 18px;
    height: 18px;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    inset: 0;
    transition-duration: 0.2s, 0.2s, 0.2s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  }

  &:before {
    width: 50%;
    height: 50%;
    background: ${({ variant }) =>
      variant === 'ghost' || variant === 'outline'
        ? 'var(--neutral-3)'
        : 'var(--absolute-white)'};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
`;

const buttonFillCss = css`
  &.fill {
    color: var(--absolute-white);
    border: 0px;

    &.primary {
      background: var(--primary-800-light);

      & ${Loader}:after {
        background: var(--primary-800-light);
      }

      &:hover,
      &:hover ${Loader}:after {
        background: var(--primary-700-light);
      }
      &.active {
        background: var(--primary-600-light);
      }
    }

    &.success {
      background: var(--semantic-green-800-light);

      & ${Loader}:after {
        background: var(--semantic-green-800-light);
      }

      &:hover,
      &:hover ${Loader}:after {
        background: var(--semantic-green-700-light);
      }
      &.active {
        background: var(--semantic-green-600-light);
      }
    }

    &.danger {
      background: var(--semantic-red-800-light);

      & ${Loader}:after {
        background: var(--semantic-red-800-light);
      }

      &:hover,
      &:hover ${Loader}:after {
        background: var(--semantic-red-700-light);
      }
      &.active {
        background: var(--semantic-red-600-light);
      }
    }

    &.warning {
      color: var(--semantic-orange-800-light);
      background: var(--semantic-orange-100-light);

      & .icon svg path {
        fill: var(--semantic-orange-800-light);
      }

      & ${Loader}:after {
        background: var(--semantic-orange-100-light);
      }

      &:hover,
      &:hover ${Loader}:after {
        background: var(--semantic-orange-300-light);
      }
      &.active {
        background: var(--semantic-orange-100-light);
      }
    }

    &:disabled {
      background: var(--neutral-0);
      color: var(--neutral-2);
      &:hover {
        background: var(--neutral-0);
      }
    }
  }
`;

const buttonGhostCss = css`
  &.ghost {
    border: 0px;

    &.primary {
      background: var(--neutral-0);
      color: var(--primary-800-light);

      & .icon svg path {
        fill: ${({ isToFillIcon }) =>
          isToFillIcon && 'var(--primary-800-light)'};
      }

      & ${Loader}:after {
        background: var(--neutral-0);
      }

      &.ghost:hover,
      &.ghost:hover ${Loader}:after {
        background: var(--neutral-1);
      }
      &.ghost.active {
        background: var(--primary-200-light);
      }
    }

    &.success {
      color: var(--semantic-green-800-light);
      background: var(--semantic-green-100-light);

      & .icon svg path {
        fill: ${({ isToFillIcon }) =>
          isToFillIcon && 'var(--semantic-green-800-light)'};
      }

      & ${Loader}:after {
        background: var(--semantic-green-100-light);
      }

      &:hover,
      &:hover ${Loader}:after {
        background: var(--semantic-green-200-light);
      }
      &.active {
        background: var(--semantic-green-100-light);
      }
    }

    &.danger {
      color: var(--semantic-red-800-light);
      background: var(--semantic-red-100-light);

      & .icon svg path {
        fill: ${({ isToFillIcon }) =>
          isToFillIcon && 'var(--semantic-red-800-light)'};
      }

      & ${Loader}:after {
        background: var(--semantic-red-100-light);
      }

      &:hover,
      &:hover ${Loader}:after {
        background: var(--semantic-red-200-light);
      }
      &.active {
        background: var(--semantic-red-100-light);
      }
    }

    &.warning {
      color: var(--semantic-orange-800-light);
      background: var(--semantic-orange-100-light);

      & .icon svg path {
        fill: ${({ isToFillIcon }) =>
          isToFillIcon && 'var(--semantic-orange-100-light)'};
      }

      & ${Loader}:after {
        background: var(--semantic-orange-100-light);
      }

      &:hover,
      &:hover ${Loader}:after {
        background: var(--semantic-orange-300-light);
      }
      &.active {
        background: var(--semantic-orange-100-light);
      }
    }

    &:disabled {
      background: var(--neutral-0);
      color: var(--neutral-2);

      .icon svg path {
        fill: currentColor;
      }

      &:hover {
        background: var(--neutral-0);
      }
    }
  }
`;

const buttonNeutralCss = css`
  &.neutral {
    border: 0px;

    &.primary {
      background: var(--absolute-white);
      color: var(--neutral-3);
      &.neutral:hover {
        background: var(--neutral-1);
      }
      &.neutral.active {
        background: var(--neutral-0);
      }
    }

    &.success {
      color: var(--semantic-green-800-light);
      background: var(--semantic-green-100-light);
      &:hover {
        background: var(--semantic-green-200-light);
      }
      &.active {
        background: var(--semantic-green-100-light);
      }
    }

    &.danger {
      color: var(--semantic-red-800-light);
      background: var(--semantic-red-100-light);
      &:hover {
        background: var(--semantic-red-200-light);
      }
      &.active {
        background: var(--semantic-red-100-light);
      }
    }

    &.warning {
      color: var(--semantic-orange-800-light);
      background: var(--semantic-orange-100-light);
      &:hover {
        background: var(--semantic-orange-300-light);
      }
      &.active {
        background: var(--semantic-orange-100-light);
      }
    }

    &:disabled {
      background: var(--neutral-0);
      color: var(--neutral-2);
      &:hover {
        background: var(--neutral-0);
      }
    }
  }
`;

const buttonOutlineCss = css`
  &.outline {
    background: var(transparent);

    &.primary {
      color: var(--primary-800-light);
      border: 1px solid var(--primary-800-light);

      &:hover {
        background: var(--primary-100-light);
        border: 1px solid;
      }
      &.active {
        background: var(--primary-100-light);
      }
    }

    &.success {
      border: 1px solid var(--semantic-green-800-light);
      background-color: none;
      color: var(--semantic-green-800-light);
      &:hover {
        background-color: var(--semantic-green-100-light);
      }
      &.active {
        background-color: var(--semantic-green-100-light);
      }
    }

    &.danger {
      border: 1px solid var(--semantic-red-800-light);
      background-color: none;
      color: var(--semantic-red-800-light);
      &:hover {
        background-color: var(--semantic-red-100-light);
      }
      &.active {
        background-color: var(--semantic-red-100-light);
      }
    }

    &.warning {
      border: 1px solid var(--semantic-orange-100-light);
      color: var(--semantic-orange-800-light);
      background-color: none;
      &:hover {
        color: var(--semantic-orange-100-light);
        background-color: var(--semantic-orange-300-light);
      }
      &.active {
        background-color: var(--semantic-orange-300-light);
      }
    }

    &:disabled {
      color: var(--neutral-2);
      background: none;
      border-color: var(--neutral-2);
      &:hover {
        color: var(--neutral-2);
        background: none;
        border-color: var(--neutral-2);
      }
    }
  }
`;

export const Container = styled.button`
  border-radius: var(--radius-small);
  font-size: 0.875rem;
  letter-spacing: -0.3px;
  line-height: 114%;
  font-weight: 500;

  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    border 0.2s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;

  .btnContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;

    svg {
      width: 18px;
    }
  }

  & span {
    display: grid;
    place-items: center;
  }

  & .icon {
    width: 20px;
    display: flex;
    align-items: center;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #6690ff;
  }

  &.small {
    height: 32px;
    padding: 8px 16px;
  }
  &.medium {
    height: 40px;
    padding: 12px 20px;
  }
  &.large {
    height: 64px;
    padding: 12px 24px;
  }
  &.full {
    width: 100%;
  }

  ${buttonFillCss}
  ${buttonGhostCss}
  ${buttonNeutralCss}
  ${buttonOutlineCss}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
      min-width: ${props.width};
    `}

  &:disabled {
    cursor: initial;
  }
`;
