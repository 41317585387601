import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  isLoading: false,
  isChanging: false,
  isOffline: true,
};

export default function common(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.LOADING_START: {
        draft.isLoading = true;
        break;
      }
      case types.LOADING_FINISH: {
        draft.isLoading = false;
        break;
      }
      case types.CHANGING_START: {
        draft.isChanging = true;
        break;
      }
      case types.CHANGING_FINISH: {
        draft.isChanging = false;
        break;
      }
      case types.NETWORK_ONLINE: {
        draft.isOffline = false;
        break;
      }
      case types.NETWORK_OFFLINE: {
        draft.isOffline = true;
        break;
      }

      default:
    }
  });
}
