import React, { useState, useCallback } from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Loader } from '../Button/styles';

import {
  cep,
  currency,
  cpf,
  cnpj,
  date,
  time,
  phone,
  hour,
  phoneDDDi,
} from './masks';

import {
  Container,
  LabelWrapper,
  CustomInput,
  InputWrapper,
  Helper,
  Error,
  Loading,
  RequiredIcon,
  LabelContainer,
} from './styles';

const Input = React.forwardRef(
  (
    {
      id,
      label,
      description,
      helper,
      error,
      placeholder,
      className,
      size,
      color,
      disabled,
      startIcon,
      endIcon,
      type,
      marginBottom,
      marginTop,
      mask,
      width,
      loading,
      autoComplete,
      variant,
      required,
      ...props
    },
    ref
  ) => {
    const selfId = id || uniqid(`input__`);

    const [isPassVisible, setisPassVisible] = useState(false);

    const handleKeyPress = useCallback(
      (e) => {
        if (mask === 'cep') {
          cep(e);
        }
        if (mask === 'currency') {
          currency(e);
        }
        if (mask === 'cpf') {
          cpf(e);
        }
        if (mask === 'cnpj') {
          cnpj(e);
        }
        if (mask === 'date') {
          date(e);
        }
        if (mask === 'time') {
          time(e);
        }
        if (mask === 'phone') {
          phone(e);
        }
        if (mask === 'hour') {
          hour(e);
        }
        if (mask === 'phoneDDDi') {
          phoneDDDi(e);
        }
      },
      [mask]
    );

    const handlePassView = () => {
      setisPassVisible(!isPassVisible);
    };

    return (
      <Container
        className={className}
        marginBottom={marginBottom}
        marginTop={marginTop}
        width={width}
      >
        {label && (
          <LabelWrapper htmlFor={selfId}>
            <LabelContainer>
              <span className="label">{label}</span>
              {required && <RequiredIcon>*</RequiredIcon>}
            </LabelContainer>
            {description && <span className="description">{description}</span>}
          </LabelWrapper>
        )}

        <InputWrapper className={cc({ hasLabel: Boolean(label) })}>
          <CustomInput
            className={cc([
              'input',
              size,
              color,
              variant,
              {
                error: Boolean(error),
                'has-start-icon': Boolean(startIcon),
                'has-end-icon': Boolean(endIcon) || !!loading,
              },
            ])}
            id={selfId}
            width={width}
            placeholder={placeholder}
            disabled={disabled}
            onChangeCapture={handleKeyPress}
            type={isPassVisible ? 'text' : type}
            autoComplete={autoComplete}
            ref={ref}
            min={0}
            required={required}
            {...props}
          />
          {startIcon && (
            <span className={cc(['icon', 'start-icon'])}>{startIcon}</span>
          )}
          {loading ? (
            <Loading>
              <Loader variant="ghost" />
            </Loading>
          ) : (
            endIcon && (
              <span className={cc(['icon', 'end-icon'])}>{endIcon}</span>
            )
          )}

          {type === 'password' && (
            <button
              type="button"
              className="pass-view"
              onClick={handlePassView}
            >
              {(isPassVisible && <FaEyeSlash />) || <FaEye />}
            </button>
          )}
        </InputWrapper>

        {helper && <Helper className={cc(['helper', color])}>{helper}</Helper>}

        {error && <Error cclassName={cc(['error'])}>{error}</Error>}
      </Container>
    );
  }
);

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  helper: PropTypes.node,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  color: PropTypes.oneOf(['', 'danger', 'success', 'builder']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  startIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  mask: PropTypes.oneOf([
    '',
    'cep',
    'currency',
    'cpf',
    'cnpj',
    'date',
    'time',
    'phone',
    'phoneDDDi',
    'hour',
  ]),
  width: PropTypes.string,
  loading: PropTypes.bool,
  autoComplete: PropTypes.string,
  variant: PropTypes.oneOf(['outline', 'filled']),
  required: PropTypes.bool,
};

Input.defaultProps = {
  id: '',
  label: '',
  placeholder: '',
  className: '',
  description: '',
  helper: '',
  error: '',
  color: '',
  size: 'medium',
  startIcon: '',
  endIcon: '',
  type: 'text',
  disabled: false,
  marginBottom: '0px',
  marginTop: '0px',
  mask: '',
  width: '100%',
  loading: false,
  autoComplete: 'off',
  variant: 'outline',
  required: false,
};

export default Input;
