import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  registers: [],
};

export default function registerFormState(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_REGISTERS_SUCCESS: {
        draft.registers = action.payload;
        break;
      }
      default:
        break;
    }
  });
}
