const Types = {
  LOADING_START: '@common/LOADING_START',
  LOADING_FINISH: '@common/LOADING_FINISH',
  CHANGING_START: '@common/CHANGING_START',
  CHANGING_FINISH: '@common/CHANGING_FINISH',
  NETWORK_ONLINE: '@common/NETWORK_ONLINE',
  NETWORK_OFFLINE: '@common/NETWORK_OFFLINE',
};

export default Types;
