import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  foundHolidays: { data: null, loading: false, error: false },
  availableCountries: { data: null, loading: false, error: false },
  availableYears: { data: null, loading: false, error: false },
  schedules: {
    rules: [],
    groups: [],
    rule: {},
    group: {},
  },
  foundScheduleRules: { data: null, error: false, loading: false },
  isLoadingUpdateAssociateRule: false,
  isLoadingDeleteScheduleRule: false,
  isLoadingImportScheduleRule: false,
};

export default function ivrSchedules(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_ALL_SCHEDULE_GROUPS_SUCCESS:
        draft.schedules.groups = action.payload;
        break;

      case types.FETCH_SCHEDULE_GROUP_SUCCESS:
        draft.schedules.group = action.payload;
        break;

      case types.CREATE_SCHEDULE_GROUP_SUCCESS:
        draft.schedules.groups = [...state.schedules.groups, action.payload];
        break;

      case types.FETCH_SCHEDULE_RULE_SUCCESS:
        draft.schedules.rule = action.payload;
        break;

      case types.FETCH_ALL_SCHEDULES_RULES_SUCCESS:
        draft.schedules.rules = action.payload;
        break;

      case types.SEARCH_SCHEDULE_RULES:
        draft.foundScheduleRules.loading = true;
        draft.foundScheduleRules.error = false;
        break;
      case types.SEARCH_SCHEDULE_RULES_SUCCESS:
        draft.foundScheduleRules.data = action.payload;
        draft.foundScheduleRules.loading = false;
        break;
      case types.SEARCH_SCHEDULE_RULES_FAILURE:
        draft.foundScheduleRules.loading = false;
        draft.foundScheduleRules.error = true;
        break;

      case types.ASSOCIATE_RULES_TO_GROUP:
        draft.isLoadingUpdateAssociateRule = true;
        break;
      case types.ASSOCIATE_RULES_TO_GROUP_SUCCESS:
        draft.isLoadingUpdateAssociateRule = false;
        break;
      case types.ASSOCIATE_RULES_TO_GROUP_FAILURE:
        draft.isLoadingUpdateAssociateRule = false;
        break;

      case types.DELETE_SCHEDULE_RULE:
        draft.isLoadingDeleteScheduleRule = true;
        break;
      case types.DELETE_SCHEDULE_RULE_SUCCESS:
        draft.isLoadingDeleteScheduleRule = false;
        break;
      case types.DELETE_SCHEDULE_RULE_FAILURE:
        draft.isLoadingDeleteScheduleRule = false;
        break;

      case types.SEARCH_IVR_HOLIDAYS:
        draft.foundHolidays.loading = true;
        draft.foundHolidays.error = false;
        break;

      case types.SEARCH_IVR_HOLIDAYS_SUCCESS:
        draft.foundHolidays.data = action.payload;
        draft.foundHolidays.loading = false;
        break;

      case types.SEARCH_IVR_HOLIDAYS_FAILURE:
        draft.foundHolidays = { data: null, loading: false, error: true };
        break;

      case types.FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES:
        draft.availableCountries.loading = true;
        draft.availableCountries.error = false;
        break;
      case types.FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES_SUCCESS:
        draft.availableCountries.data = action.payload;
        draft.availableCountries.loading = false;
        break;
      case types.FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES_FAILURE:
        draft.availableCountries = { data: null, loading: false, error: true };
        break;

      case types.FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS:
        draft.availableYears.loading = true;
        draft.availableYears.error = false;
        break;
      case types.FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS_SUCCESS:
        draft.availableYears.data = action.payload;
        draft.availableYears.loading = false;
        break;
      case types.FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS_FAILURE:
        draft.availableYears = { data: null, loading: false, error: true };
        break;

      case types.IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS:
        draft.isLoadingImportScheduleRule = true;
        break;
      case types.IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS_SUCCESS:
        draft.isLoadingImportScheduleRule = false;
        break;
      case types.IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS_FAILURE:
        draft.isLoadingImportScheduleRule = false;
        break;

      default:
        return state;
    }
  });
}
