import { takeLatest, put, all, call } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import * as actions from './actions';
import types from './types';

export function* fetchIvrChatGPTModels() {
  try {
    const { data } = yield call(api.get, '/v3/ivr/chat-gpt/models');

    yield put(actions.fetchIvrChatGPTModelsSuccess(data));
  } catch (e) {
    yield put(actions.fetchIvrChatGPTModelsFailure());
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_models'));
  }
}

export function* fetchIvrChatGPTPromptTemplates() {
  try {
    const { data } = yield call(api.get, `/v3/ivr/chat-gpt/prompts/templates`);

    yield put(actions.fetchIvrChatGPTPromptTemplatesSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_templates'));
    yield put(actions.fetchIvrChatGPTPromptTemplatesFailure());
  }
}

export function* searchIvrChatGPTPromptTemplates({ payload }) {
  try {
    const { page, limit, search } = payload;
    const { data } = yield call(
      api.get,
      `/v3/ivr/chat-gpt/prompts/templates/search`,
      {
        params: { page, limit, search },
      }
    );

    yield put(actions.searchIvrChatGPTPromptTemplatesSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_listing_templates')
    );
    yield put(actions.searchIvrChatGPTPromptTemplatesFailure());
  }
}

export function* createIvrChatGPTPromptTemplates({ payload }) {
  try {
    const { data, callback } = payload;

    const response = yield call(
      api.post,
      `/v3/ivr/chat-gpt/prompts/templates`,
      data
    );

    yield put(actions.createIvrChatGPTPromptTemplateSuccess(response.data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_creating_template')
    );
    yield put(actions.createIvrChatGPTPromptTemplateFailure());
  }
}

export function* updateIvrChatGPTPromptTemplates({ payload }) {
  try {
    const { data, callback } = payload;

    const response = yield call(
      api.put,
      `/v3/ivr/chat-gpt/prompts/templates`,
      data
    );

    yield put(actions.updateIvrChatGPTPromptTemplateSuccess(response.data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_updating_the_template')
    );
    yield put(actions.updateIvrChatGPTPromptTemplateFailure());
  }
}

export function* deleteIvrChatGPTPromptTemplates({ payload }) {
  try {
    const { id, callback } = payload;

    yield call(api.delete, `/v3/ivr/chat-gpt/prompts/templates/${id}`);

    yield put(actions.deleteIvrChatGPTPromptTemplateSuccess());

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_deleting_the_template')
    );
    yield put(actions.deleteIvrChatGPTPromptTemplateFailure());
  }
}

export function* createIvrChatGPTPromptCompletions({ payload }) {
  try {
    const { data, callback } = payload;

    const response = yield call(
      api.post,
      `/v3/ivr/chat-gpt/prompts/completions`,
      data
    );

    yield put(actions.createIvrChatGPTPromptCompletionsSuccess(response.data));

    callback?.(response.data);
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_creating_chatgpt_responses')
    );
    yield put(actions.createIvrChatGPTPromptCompletionsFailure());
  }
}

export default all([
  takeLatest(types.FETCH_IVR_CHAT_GPT_MODELS, fetchIvrChatGPTModels),
  takeLatest(
    types.FETCH_IVR_CHATGPT_PROMPT_TEMPLATES,
    fetchIvrChatGPTPromptTemplates
  ),
  takeLatest(
    types.SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES,
    searchIvrChatGPTPromptTemplates
  ),
  takeLatest(
    types.CREATE_IVR_CHATGPT_PROMPT_TEMPLATE,
    createIvrChatGPTPromptTemplates
  ),
  takeLatest(
    types.UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE,
    updateIvrChatGPTPromptTemplates
  ),
  takeLatest(
    types.DELETE_IVR_CHATGPT_PROMPT_TEMPLATE,
    deleteIvrChatGPTPromptTemplates
  ),
  takeLatest(
    types.CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS,
    createIvrChatGPTPromptCompletions
  ),
]);
