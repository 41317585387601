import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '~/assets/ligo/ligo-bots.svg';
import PoweredByFooter from '~/components/PoweredByFooter';
import SelectLanguage from '~/components/SelectLanguage';

import { Container, ContainerMenu } from './styles';

const AuthLayout = ({ children }) => {
  return (
    <Container>
      <Link to="/" className="logo">
        <img src={logo} alt="Logo" />
      </Link>

      <>{children}</>

      <ContainerMenu>
        <SelectLanguage />
      </ContainerMenu>

      <PoweredByFooter />
    </Container>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthLayout;
