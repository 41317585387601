import types from './types';

export const fetchConnectors = ({ botId }) => ({
  type: types.FETCH_CONNECTORS,
  payload: { botId },
});

export const fetchConnectorsSuccess = ({ connectors }) => ({
  type: types.FETCH_CONNECTORS_SUCCESS,
  payload: { connectors },
});

export const fetchSubflowConnectors = ({ botId, subflowId }) => ({
  type: types.FETCH_SUBFLOW_CONNECTORS,
  payload: { botId, subflowId },
});

export const fetchSubflowConnectorsSuccess = ({ connectors }) => ({
  type: types.FETCH_SUBFLOW_CONNECTORS_SUCCESS,
  payload: { connectors },
});

export const fetchAllConnectors = ({ botId }) => ({
  type: types.FETCH_ALL_CONNECTORS,
  payload: { botId },
});

export const fetchAllConnectorsSuccess = ({ allConnectors }) => ({
  type: types.FETCH_ALL_CONNECTORS_SUCCESS,
  payload: { allConnectors },
});

export const deleteConnectors = (payload) => ({
  type: types.DELETE_CONNECTORS,
  payload,
});

export const deleteConnectorsSuccess = (payload) => ({
  type: types.DELETE_CONNECTOR_SUCCESS,
  payload,
});

export const createConnector = (payload) => ({
  type: types.CREATE_CONNECTOR,
  payload,
});

export const createConnectorSuccess = (payload) => ({
  type: types.CREATE_CONNECTOR_SUCCESS,
  payload,
});

export const updateConnectors = (payload) => ({
  type: types.UPDATE_CONNECTORS,
  payload,
});
