import types from './types';

export const listIvrTags = ({ botId }) => ({
  type: types.LIST_TAGS,
  payload: { botId },
});
export const listIvrTagsSuccess = (payload) => ({
  type: types.LIST_TAGS_SUCCESS,
  payload,
});

export const createIvrTag = ({ botId, values }, callback) => ({
  type: types.CREATE_TAG,
  payload: { botId, values, callback },
});
export const createIvrTagSuccess = (payload) => ({
  type: types.CREATE_TAG_SUCCESS,
  payload,
});

export const editIvrTag = (payload) => ({
  type: types.EDIT_TAG,
  payload,
});
export const editIvrTagSuccess = (tag) => ({
  type: types.EDIT_TAG_SUCCESS,
  payload: { tag },
});

export const removeIvrTag = ({ id }) => ({
  type: types.REMOVE_TAG,
  payload: { id },
});
export const removeIvrTagSuccess = (payload) => ({
  type: types.REMOVE_TAG_SUCCESS,
  payload,
});
