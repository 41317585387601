import React from 'react';
import cc from 'classcat';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { useTranslation } from 'react-i18next';
import Tooltip from '~/components/Tooltip';
import { ReactComponent as IconTooltip } from '~/assets/icons/icon_tooltip.svg';

import { Container, Content, CustomSwitch, ErrorMessage } from './styles';

const Switch = React.forwardRef(
  (
    {
      id,
      label,
      labelDescription,
      labelPlacement,
      color,
      className,
      labelClassName,
      error,
      disabled,
      permissionUser,
      dataTestid,
      tooltipContent,
      ...props
    },
    ref
  ) => {
    const selfId = id || uniqid(`switch__`);

    const { t } = useTranslation();
    const { checkpoint, ...filteredProps } = props;

    return (
      <Container className={cc(['switch', className])}>
        <Content
          className={cc(['content', `label-placement--${labelPlacement}`])}
        >
          <CustomSwitch className="switch" htmlFor={selfId}>
            <input
              disabled={disabled}
              type="checkbox"
              id={selfId}
              {...filteredProps}
              ref={ref}
              data-testid={dataTestid}
            />
            <span
              className={cc(['slider', color, { disabled }])}
              title={
                checkpoint
                  ? t(
                      'bots.builder.components.menu_settings.block.escape.checkpoint_title'
                    )
                  : props?.title
              }
              style={
                permissionUser !== 'Viewer' && !checkpoint
                  ? { cursor: 'pointer' }
                  : { cursor: 'not-allowed', opacity: '0.4' }
              }
            />
          </CustomSwitch>

          {label && (
            <div>
              <label
                htmlFor={selfId}
                className={cc(['label', labelClassName])}
                style={{ marginRight: '10px' }}
              >
                {label}
              </label>
              {tooltipContent && (
                <Tooltip
                  position="top"
                  render={() => tooltipContent}
                  width="200px"
                  appendToBody
                  style={{ marginLeft: '5px' }}
                >
                  <IconTooltip />
                </Tooltip>
              )}
              <p className={cc(['labelDescription'])}>{labelDescription}</p>
            </div>
          )}
        </Content>

        {error && <ErrorMessage className="error">{error}</ErrorMessage>}
      </Container>
    );
  }
);

Switch.propTypes = {
  id: PropTypes.string,
  label: PropTypes.node,
  labelDescription: PropTypes.node,
  labelPlacement: PropTypes.oneOf(['start', 'end', 'top', 'bottom']),
  color: PropTypes.oneOf(['primary', 'success', 'danger']),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  checkpoint: PropTypes.bool,
  tooltipContent: PropTypes.node,
};

Switch.defaultProps = {
  id: '',
  label: '',
  labelDescription: '',
  labelPlacement: 'end',
  color: 'primary',
  className: '',
  labelClassName: '',
  error: '',
  disabled: false,
  checkpoint: false,
  tooltipContent: false,
};

export default Switch;
