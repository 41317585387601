import types from './types';

export const fetchIvrNumbers = (queryParams) => ({
  type: types.FETCH_IVR_NUMBERS,
  payload: { queryParams },
});
export const fetchIvrNumbersSuccess = (data) => ({
  type: types.FETCH_IVR_NUMBERS_SUCCESS,
  payload: data,
});
export const fetchIvrNumbersFailure = () => ({
  type: types.FETCH_IVR_NUMBERS_FAILURE,
});

export const searchIvrNumbersForManagement = (params) => ({
  type: types.SEARCH_IVR_NUMBERS_FOR_MANAGEMENT,
  payload: { params },
});
export const searchIvrNumbersForManagementSuccess = (data) => ({
  type: types.SEARCH_IVR_NUMBERS_FOR_MANAGEMENT_SUCCESS,
  payload: data,
});
export const searchIvrNumbersForManagementFailure = () => ({
  type: types.SEARCH_IVR_NUMBERS_FOR_MANAGEMENT_FAILURE,
});

export const searchIvrNumbersForBuy = (params) => ({
  type: types.SEARCH_IVR_NUMBERS_FOR_BUY,
  payload: { params },
});
export const searchIvrNumbersForBuySuccess = (data) => ({
  type: types.SEARCH_IVR_NUMBERS_FOR_BUY_SUCCESS,
  payload: data,
});
export const searchIvrNumbersForBuyFailure = () => ({
  type: types.SEARCH_IVR_NUMBERS_FOR_BUY_FAILURE,
});

export const buyIvrNumber = ({ id, accountId }, callback) => ({
  type: types.BUY_IVR_NUMBER,
  payload: { id, accountId, callback },
});
export const buyIvrNumberSuccess = () => ({
  type: types.BUY_IVR_NUMBER_SUCCESS,
});
export const buyIvrNumberFailure = () => ({
  type: types.BUY_IVR_NUMBER_FAILURE,
});

export const createIvrNumber = ({ data }, callback) => ({
  type: types.CREATE_IVR_NUMBER,
  payload: { data, callback },
});
export const createIvrNumberSuccess = () => ({
  type: types.CREATE_IVR_NUMBER_SUCCESS,
});
export const createIvrNumberFailure = () => ({
  type: types.CREATE_IVR_NUMBER_FAILURE,
});

export const updateIvrNumber = ({ data }, callback) => ({
  type: types.UPDATE_IVR_NUMBER,
  payload: { data, callback },
});
export const updateIvrNumberSuccess = () => ({
  type: types.UPDATE_IVR_NUMBER_SUCCESS,
});
export const updateIvrNumberFailure = () => ({
  type: types.UPDATE_IVR_NUMBER_FAILURE,
});

export const addAssociateNumbersToIvr = ({ ivrId, dids }, callback) => ({
  type: types.ADD_ASSOCIATE_NUMBERS_TO_IVR,
  payload: { ivrId, dids, callback },
});
export const addAssociateNumbersToIvrSuccess = () => ({
  type: types.ADD_ASSOCIATE_NUMBERS_TO_IVR_SUCCESS,
});
export const addAssociateNumbersToIvrFailure = () => ({
  type: types.ADD_ASSOCIATE_NUMBERS_TO_IVR_FAILURE,
});

export const removeAssociateNumberFromIvr = ({ ivrId, did }, callback) => ({
  type: types.REMOVE_ASSOCIATE_NUMBER_FROM_IVR,
  payload: { ivrId, did, callback },
});
export const removeAssociateNumberFromIvrSuccess = () => ({
  type: types.REMOVE_ASSOCIATE_NUMBER_FROM_IVR_SUCCESS,
});
export const removeAssociateNumberFromIvrFailure = () => ({
  type: types.REMOVE_ASSOCIATE_NUMBER_FROM_IVR_FAILURE,
});

export const removeIvrNumberFromOrganization = ({ id }, callback) => ({
  type: types.REMOVE_IVR_NUMBER_FROM_ORGANIZATION,
  payload: { id, callback },
});
export const removeIvrNumberFromOrganizationSuccess = () => ({
  type: types.REMOVE_IVR_NUMBER_FROM_ORGANIZATION_SUCCESS,
});
export const removeIvrNumberFromOrganizationFailure = () => ({
  type: types.REMOVE_IVR_NUMBER_FROM_ORGANIZATION_FAILURE,
});
