import { takeLatest, call, put, all } from 'redux-saga/effects';
import i18n from '../../../translations/i18n';

import api from '../../../services/api';
import { toast } from '../../../components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';
import types from './types';

export function* createOrganization({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { companyId, name, setModalIsOpen } = payload;

    const response = yield call(
      api.post,
      `/v3/organizations/create/${companyId}`,
      { name }
    );

    yield put(
      actions.createOrganizationSuccess({
        organization: response.data,
      })
    );
    setModalIsOpen(false);
  } catch (err) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_create_sector'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteOrganization({ payload }) {
  const { organizationId, setModalIsOpen } = payload;

  try {
    yield put(commonLoadingStart());

    yield call(api.delete, `/v3/organizations/delete/${organizationId}`);

    yield put(
      actions.deleteOrganizationSuccess({
        organizationId,
      })
    );
    setModalIsOpen(false);
  } catch (err) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_delete_sector'));
    setModalIsOpen(false);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* editOrganization({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { organizationId, name, setModalIsOpen } = payload;

    yield call(api.put, `/v3/organizations/update/${organizationId}`, { name });

    yield put(
      actions.editOrganizationSuccess({
        organizationId,
        name,
      })
    );

    setModalIsOpen(false);
  } catch (err) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_edit_sector'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchOrganizationsFromCompany({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { companyId } = payload;

    const response = yield call(api.get, `/v3/organizations/list/${companyId}`);

    yield put(
      actions.fetchOrganizationsFromCompanySuccess({
        organizations: response.data,
      })
    );
  } catch (err) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_sectors'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchOrganizationsPlusUsers({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { companyId } = payload;

    const response = yield call(
      api.get,
      `/v3/organizations/list/users/${companyId}`
    );

    yield put(
      actions.fetchOrganizationsPlusUsersSuccess({
        organizations: response.data,
      })
    );
  } catch (err) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_sectors_users')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(
    types.FETCH_ORGANIZATIONS_FROM_COMPANY,
    fetchOrganizationsFromCompany
  ),
  takeLatest(types.FETCH_ORGANIZATION_PLUS_USERS, fetchOrganizationsPlusUsers),
  takeLatest(types.CREATE_ORGANIZATION, createOrganization),
  takeLatest(types.DELETE_ORGANIZATION, deleteOrganization),
  takeLatest(types.EDIT_ORGANIZATION, editOrganization),
]);
