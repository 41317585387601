const Types = {
  FETCH_NPS_REQUEST: '@nps/FETCH_NPS_REQUEST',
  FETCH_NPS_SUCCESS: '@nps/FETCH_NPS_SUCCESS',
  UPDATE_NPS_REQUEST: '@nps/UPDATE_NPS_REQUEST',
  UPDATE_NPS_SUCCESS: '@nps/UPDATE_NPS_SUCCESS',
  DELETE_NPS_REQUEST: '@nps/DELETE_NPS_REQUEST',
  DELETE_NPS_SUCCESS: '@nps/DELETE_NPS_SUCCESS',
  CREATE_NPS_REQUEST: '@nps/CREATE_NPS_REQUEST',
  CREATE_NPS_SUCCESS: '@nps/CREATE_NPS_SUCCESS',
};

export default Types;
