import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .icon {
    position: absolute;
    color: var(--neutral-3);

    &.start-icon {
      left: 12px;
    }
    &.end-icon {
      right: 12px;
    }
  }

  & .pass-view {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 14px;
    font-size: 1rem;
    color: var(--neutral-3);
  }
`;

export const LabelWrapper = styled.label`
  margin-bottom: 8px;

  .label {
    display: block;
    color: var(--neutral-3);

    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 114%;
    letter-spacing: -0.02em;
  }

  .description {
    font-style: normal;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 120%;
    color: var(--neutral-3);
    display: block;
  }
`;

export const CustomTextArea = styled.textarea`
  font-family: 'Monospace', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  letter-spacing: -0.02em;
  resize: vertical;
  padding: 8px 12px;

  border-radius: var(--radius-small);
  border: 1px solid var(--neutral-2);
  color: var(--neutral-3) !important;
  width: 100%;

  background: var(--absolute-white);
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    border 0.2s ease-in-out;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #6690ff;
  }

  &:hover {
    border: 1px solid var(--primary-800-light);
  }
  &:active {
    border: 1px solid var(--primary-800-light);
  }
  &:disabled {
    border: 1px solid var(--neutral-1);
    background: var(--neutral-0);
    color: var(--neutral-1);
  }

  &.small {
    min-height: 32px;
  }

  &.medium {
    min-height: 40px;
  }

  &.large {
    min-height: 48px;
    font-size: 1rem;
  }

  &.success {
    background: var(--semantic-green-100-light);
    border: 1px solid var(--semantic-green-600-light);
    color: var(--semantic-green-600-light);
  }

  &.danger {
    background: var(--semantic-red-100-light);
    border: 1px solid var(--semantic-red-600-light);
    color: var(--semantic-red-600-light);
  }

  &.error {
    background: var(--semantic-red-100-light);
    border: 1px solid var(--semantic-red-600-light);
    color: var(--semantic-red-600-light);
  }

  &.has-start-icon {
    padding-left: 40px;
  }
  &.has-end-icon {
    padding-right: 40px;
  }
`;

export const Helper = styled.div`
  &.helper {
    font-style: normal;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 120%;
    color: var(--neutral-3);
    display: block;
    margin-top: 4px;

    &.success {
      color: var(--semantic-green-600-light);
    }

    &.danger {
      color: var(--semantic-red-600-light);
    }
  }
`;

export const Error = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 120%;
  display: block;
  margin-top: 4px;
  color: var(--semantic-red-600-light);
`;
