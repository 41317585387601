import { takeLatest, all, call, put } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import * as actions from './actions';
import types from './types';

export function* fetchIvrPlans({ payload }) {
  try {
    const { type } = payload;

    const { data } = yield call(api.get, `/v3/ivr/plans`, { params: { type } });

    yield put(actions.fetchIvrPlansSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_plans'));
    yield put(actions.fetchIvrPlansFailure());
  }
}

export function* searchIvrPlanHistory({ payload }) {
  try {
    const { params } = payload;

    const { data } = yield call(api.get, `/v3/ivr/plans/history/search`, {
      params,
    });

    yield put(actions.searchIvrPlanHistorySuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_history_plan'));
    yield put(actions.searchIvrPlanHistoryFailure());
  }
}

export function* selectIvrPlan({ payload }) {
  try {
    const { id, callback } = payload;

    yield call(api.put, `/v3/ivr/plans/${id}/select`);

    callback?.();
    yield put(actions.selectIvrPlanSuccess());
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('Houve um erro ao selecionar o plano')
    );
    yield put(actions.selectIvrPlanFailure());
  }
}

export function* fetchIvrPlanConsumptionMonth({ payload }) {
  try {
    const { referenceMonth } = payload;

    const { data } = yield call(api.get, '/v3/ivr/plans/consumption', {
      params: { referenceMonth },
    });

    yield put(actions.fetchIvrPlanConsumptionByMonthSuccess(data));
  } catch (e) {
    yield put(actions.fetchIvrPlanConsumptionByMonthFailure());
  }
}

export function* fetchIvrPlanConsumptionDay({ payload }) {
  try {
    const { referenceDay } = payload;

    const { data } = yield call(api.get, '/v3/ivr/plans/consumption/day', {
      params: { referenceDay },
    });

    yield put(actions.fetchIvrPlanConsumptionByDaySuccess(data));
  } catch (e) {
    yield put(actions.fetchIvrPlanConsumptionByDayFailure());
  }
}

export default all([
  takeLatest(types.FETCH_IVR_PLANS, fetchIvrPlans),
  takeLatest(types.SEARCH_IVR_PLAN_HISTORY, searchIvrPlanHistory),
  takeLatest(types.SELECT_IVR_PLAN, selectIvrPlan),
  takeLatest(
    types.FETCH_IVR_PLAN_CONSUMPTION_BY_MONTH,
    fetchIvrPlanConsumptionMonth
  ),
  takeLatest(
    types.FETCH_IVR_PLAN_CONSUMPTION_BY_DAY,
    fetchIvrPlanConsumptionDay
  ),
]);
