import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  organizations: [],
};

const updateOrganizations = (organizations, organizationId, name) => {
  const updated = organizations.map((org) => {
    if (org._id === organizationId) {
      return { ...org, name };
    }
    return org;
  });
  return updated;
};

export default function organization(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_ORGANIZATIONS_FROM_COMPANY_SUCCESS:
        draft.organizations = action.payload.organizations;
        break;
      case types.FETCH_ORGANIZATION_PLUS_USERS_SUCCESS:
        draft.organizationsPlusUsers = action.payload.organizations;
        break;
      case types.CREATE_ORGANIZATION_SUCCESS:
        draft.organizations = [
          ...state.organizations,
          action.payload.organization,
        ];
        break;
      case types.DELETE_ORGANIZATION_SUCCESS:
        draft.organizations = state.organizations.filter(
          (e) => e._id !== action.payload.organizationId
        );
        break;
      case types.EDIT_ORGANIZATION_SUCCESS:
        draft.organizations = updateOrganizations(
          state.organizations,
          action.payload.organizationId,
          action.payload.name
        );
        break;

      default:
    }
  });
}
