import types from './types';

export const listIAProvider = (token) => ({
  type: types.IA_LIST_PROVIDER,
  payload: { token },
});

export const resetValidateToken = (token) => ({
  type: types.RESET_VALIDATE_TOKEN,
  payload: { token },
});

export const listIAProviderSuccess = (data) => ({
  type: types.IA_LIST_PROVIDER_SUCCESS,
  payload: { data },
});

export const testValidateToken = ({ token }) => ({
  type: types.IA_TEST_VALIDATE_TOKEN,
  payload: { token },
});

export const testValidateTokenSuccess = (data) => {
  return {
    type: types.IA_TEST_VALIDATE_TOKEN_SUCCESS,
    payload: { data },
  };
};

export const updateApplication = (data) => {
  return {
    type: types.IA_UPDATE_APPLICATION,
    payload: { data },
  };
};
export const updateApplicationSuccess = (data) => {
  return {
    type: types.IA_UPDATE_APPLICATION_SUCCESS,
    payload: { data },
  };
};

export const createIAProvider = (platform, credentials, bot, language) => ({
  type: types.IA_CREATE_PROVIDER,
  payload: { platform, credentials, bot, language },
});
export const createIAProviderSuccess = (data) => ({
  type: types.IA_CREATE_PROVIDER_SUCCESS,
  payload: { data },
});

export const iaPlatformSuccess = (data) => ({
  type: types.IA_PLATFORM_SUCCESS,
  payload: { data },
});

export const getIaInfoSuccess = (token) => ({
  type: types.IA_PLATFORM_INFO_SUCCESS,
  payload: { token },
});

/* Intents  and samples */
export const listIntents = (token) => ({
  type: types.IA_LIST_INTENTS,
  payload: { token },
});
export const listIntentsSuccess = (data) => ({
  type: types.IA_LIST_INTENTS_SUCCESS,
  payload: { data },
});

export const saveIASample = (ia, sample) => ({
  type: types.IA_SAVE_SAMPLE,
  payload: { ia, sample },
});
export const saveIASampleSuccess = (data) => ({
  type: types.IA_SAVE_SAMPLE_SUCCESS,
  payload: { data },
});

export const deleteSamples = (name, token, type, sentence) => ({
  type: types.IA_DELETE_SAMPLES,
  payload: { intent: name, token, type, sentence },
});

export const listSamples = (token, type) => ({
  type: types.IA_LIST_SAMPLES,
  payload: { token, type },
});
export const listSamplesSuccess = (data) => ({
  type: types.IA_LIST_SAMPLES_SUCCESS,
  payload: { data },
});

export const listAllSamples = (token) => ({
  type: types.IA_LIST_ALL_SAMPLES,
  payload: { token },
});
export const listAllSamplesSuccess = (data) => ({
  type: types.IA_LIST_ALL_SAMPLES_SUCCESS,
  payload: { data },
});

export const listSamplesIntent = (payload) => ({
  type: types.IA_LIST_SAMPLES_INTENT,
  payload,
});
export const listSamplesIntentSuccess = (data) => ({
  type: types.IA_LIST_SAMPLES_INTENT_SUCCESS,
  payload: { data },
});

export const createIntents = (data) => ({
  type: types.IA_CREATE_INTENTS,
  payload: { ...data },
});

export const deleteIntents = (token, name) => ({
  type: types.IA_DELETE_INTENTS,
  payload: { token, name },
});

export const getIaInfo = (token) => ({
  type: types.IA_PLATFORM_INFO,
  payload: { token },
});

/* Condition */
export const listConditions = (botId) => ({
  type: types.IA_LIST_CONDITIONS,
  payload: botId,
});
export const listConditionsSuccess = (data) => ({
  type: types.IA_LIST_CONDITIONS_SUCCESS,
  payload: data,
});

export const createCondition = (payload, bot) => ({
  type: types.IA_CREATE_CONDITIONS,
  payload: { data: payload, bot },
});
export const createConditionSuccess = (data) => ({
  type: types.IA_CREATE_CONDITIONS_SUCCESS,
  payload: data,
});

export const updateCondition = (payload, bot) => ({
  type: types.IA_UPDATE_CONDITIONS,
  payload: { data: payload, bot },
});
export const updateConditionSuccess = (data) => ({
  type: types.IA_UPDATE_CONDITIONS_SUCCESS,
  payload: data,
});

export const deleteCondition = (_id) => ({
  type: types.IA_DELETE_CONDITIONS,
  payload: _id,
});
export const deleteConditionSuccess = (data) => ({
  type: types.IA_DELETE_CONDITIONS_SUCCESS,
  payload: data,
});

/* Entities */
export const createEntities = (payload) => ({
  type: types.IA_CREATE_ENTITIES,
  payload,
});
export const createEntitiesSuccess = (payload) => ({
  type: types.IA_CREATE_ENTITIES_SUCCESS,
  payload,
});

export const listEntities = (payload) => ({
  type: types.IA_LIST_ENTITIES,
  payload,
});
export const listEntitiesSuccess = (payload) => ({
  type: types.IA_LIST_ENTITIES_SUCCESS,
  payload,
});

export const listEntity = (token, name) => ({
  type: types.IA_LIST_ENTITY,
  payload: { token, name },
});
export const listEntitySuccess = (payload) => ({
  type: types.IA_LIST_ENTITY_SUCCESS,
  payload,
});

export const deleteEntities = (payload) => ({
  type: types.IA_DELETE_ENTITIES,
  payload,
});
export const deleteEntitiesSuccess = (payload) => ({
  type: types.IA_DELETE_ENTITIES_SUCCESS,
  payload,
});

export const deleteEntitiesObj = (payload) => ({
  type: types.IA_DELETE_ENTITIES_OBJ,
  payload,
});

export const deleteEntitiesObjSuccess = (payload) => ({
  type: types.IA_DELETE_ENTITIES_OBJ_SUCCESS,
  payload,
});

export const deleteEntitiesSyn = (payload) => ({
  type: types.IA_DELETE_ENTITIES_SYN,
  payload,
});

export const deleteEntitiesSynSuccess = (payload) => ({
  type: types.IA_DELETE_ENTITIES_SYN_SUCCESS,
  payload,
});

// aprimoramento de ia
export const listCurationMessages = (payload) => ({
  type: types.IA_CURATION_BOT_LIST_MESSAGES,
  payload,
});
export const listCurationMessagesSuccess = (payload) => ({
  type: types.IA_CURATION_BOT_LIST_MESSAGES_SUCCESS,
  payload,
});

export const ignoreCurationMessage = (bot, messageId, token) => ({
  type: types.IA_CURATION_BOT_IGNORE_MESSAGE,
  payload: { bot, messageId, token },
});
export const ignoreCurationMessageSuccess = (payload) => ({
  type: types.IA_CURATION_BOT_IGNORE_MESSAGE_SUCCESS,
  payload,
});

export const setAprimoramentos = (aprimoramento) => ({
  type: types.IA_CURATION_BOT_SET_APRIMORAMENTOS,
  payload: aprimoramento,
});

export const deleteAprimoramento = (aprimoramento) => ({
  type: types.IA_CURATION_BOT_APRIMORAMENTOS_DELETE,
  payload: aprimoramento,
});

export const clearAprimoramentos = () => ({
  type: types.IA_CURATION_BOT_APRIMORAMENTOS_CLEAR,
});

export const saveAprimoramentos = (payload) => ({
  type: types.IA_CURATION_BOT_SAVE_APRIMORAMENTOS,
  payload,
});

export const saveAprimoramentosSuccess = (payload) => ({
  type: types.IA_CURATION_BOT_SAVE_APRIMORAMENTOS_SUCCESS,
  payload,
});

export const listWit = (token) => ({
  type: types.IA_LIST_WIT,
  payload: { token },
});

export const listWitSuccess = (data) => ({
  type: types.IA_LIST_WIT_SUCCESS,
  payload: { data },
});

export const createWit = (platform, credentials, botid, language) => ({
  type: types.IA_CREATE_WIT,
  payload: { platform, credentials, botid, language },
});

export const createWitSuccess = (data) => ({
  type: types.IA_CREATE_WIT_SUCCESS,
  payload: { data },
});

export const buildLex = (payload) => ({
  type: types.IA_BUILD_LEX,
  payload,
});

export const publishLex = (payload) => ({
  type: types.IA_PUBLISH_LEX,
  payload,
});
