import types from './types';

export const getRegisters = () => ({
  type: types.GET_REGISTERS_REQUEST,
});

export const getRegistersSuccess = (registers) => ({
  type: types.GET_REGISTERS_SUCCESS,
  payload: registers,
});
