import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const ItemThatOpenContext = createContext();

export default function ItemThatOpenProvider({ children }) {
  const [itemIsOpen, setItemIsOpen] = useState(null);

  const handleToggleItem = (value) => {
    setItemIsOpen(value === itemIsOpen ? null : value);
  };

  return (
    <ItemThatOpenContext.Provider
      value={{ itemIsOpen, setItemIsOpen: handleToggleItem }}
    >
      {children}
    </ItemThatOpenContext.Provider>
  );
}

ItemThatOpenProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useItemIsOpen() {
  const { itemIsOpen, setItemIsOpen } = useContext(ItemThatOpenContext);
  return { itemIsOpen, setItemIsOpen };
}
