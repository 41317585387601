import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  bot: [],
  global: [],
};

export default function tags(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.CREATE_TAG_SUCCESS: {
        draft.bot = action.payload.bot;
        draft.global = action.payload.global;
        break;
      }

      case types.LIST_TAGS_SUCCESS: {
        draft.bot = action.payload.bot;
        draft.global = action.payload.global;
        break;
      }

      case types.EDIT_TAG_SUCCESS: {
        const updatedTag = action.payload.tag;
        draft.bot = state.bot.map((tag) =>
          tag._id === updatedTag._id ? updatedTag : tag
        );
        break;
      }

      case types.REMOVE_TAG_SUCCESS: {
        draft.bot = action.payload.bot;
        draft.global = action.payload.global;
        break;
      }

      default:
        return state;
    }
  });
}
