import styled from 'styled-components';

export const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    text-align: center;
    color: #5a5d68;
    margin-bottom: 2px;
  }

  .loading-bar {
    width: 162px;
    height: 4px;
    background: #dadce3;
    border-radius: 4px;
    margin-bottom: 2px;
    position: relative;

    &:after {
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: ${(props) => props.color};
      width: ${({ consumption }) => `${consumption}%`};
    }
  }

  & .emphasis {
    background: #fff;
    color: var(--primary-800-light) !important;

    svg {
      fill: var(--primary-800-light) !important;
    }
  }

  .loading-sub {
    font-family: Archivo;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 120%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #979aa5;
  }
`;
