import { takeLatest, call, put, all } from 'redux-saga/effects';

import i18n from '../../../translations/i18n';
import api from '~/services/api';
import { toast } from '~/components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';
import types from './types';

export function* fetchPermissions() {
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.get, `/v3/profiles/list`);

    yield put(actions.fetchPermissionsSuccess({ permissions: data }));
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_find_permissions'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([takeLatest(types.FETCH_PERMISSIONS, fetchPermissions)]);
