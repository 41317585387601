import types from './types';

export const createOrganization = ({ companyId, name, setModalIsOpen }) => ({
  type: types.CREATE_ORGANIZATION,
  payload: { companyId, name, setModalIsOpen },
});
export const createOrganizationSuccess = ({ organization }) => ({
  type: types.CREATE_ORGANIZATION_SUCCESS,
  payload: { organization },
});
export const deleteOrganization = ({ organizationId, setModalIsOpen }) => ({
  type: types.DELETE_ORGANIZATION,
  payload: { organizationId, setModalIsOpen },
});
export const deleteOrganizationSuccess = ({ organizationId }) => ({
  type: types.DELETE_ORGANIZATION_SUCCESS,
  payload: { organizationId },
});
export const editOrganization = ({ organizationId, name, setModalIsOpen }) => ({
  type: types.EDIT_ORGANIZATION,
  payload: { organizationId, name, setModalIsOpen },
});
export const editOrganizationSuccess = ({ organizationId, name }) => ({
  type: types.EDIT_ORGANIZATION_SUCCESS,
  payload: { organizationId, name },
});
export const fetchOrganizationsFromCompany = ({ companyId }) => ({
  type: types.FETCH_ORGANIZATIONS_FROM_COMPANY,
  payload: { companyId },
});
export const fetchOrganizationsFromCompanySuccess = ({ organizations }) => ({
  type: types.FETCH_ORGANIZATIONS_FROM_COMPANY_SUCCESS,
  payload: { organizations },
});
export const fetchOrganizationsPlusUsers = ({ companyId }) => ({
  type: types.FETCH_ORGANIZATION_PLUS_USERS,
  payload: { companyId },
});
export const fetchOrganizationsPlusUsersSuccess = ({ organizations }) => ({
  type: types.FETCH_ORGANIZATION_PLUS_USERS_SUCCESS,
  payload: { organizations },
});
