import types from './types';

export const getPlansRequest = () => ({
  type: types.GET_PLANS_REQUEST,
});
export const getPlansRequestSuccess = (payload) => ({
  type: types.GET_PLANS_REQUEST_SUCCESS,
  payload,
});

export const getHistoryCompanyPlanRequest = (payload) => ({
  type: types.GET_HISTORY_COMPANY_PLAN_REQUEST,
  payload,
});
export const getHistoryCompanyPlanRequestSuccess = (payload) => ({
  type: types.GET_HISTORY_COMPANY_PLAN_REQUEST_SUCCESS,
  payload,
});

export const putAlerts = (payload) => ({
  type: types.PUT_ALERTS,
  payload,
});
export const putAlertsSuccess = (payload) => ({
  type: types.PUT_ALERTS_SUCCESS,
  payload,
});

export const getCompanyPlanConsumption = (payload) => ({
  type: types.GET_COMPANY_PLAN_CONSUMPTION,
  payload,
});

export const getCompanyPlanConsumptionSuccess = (payload) => ({
  type: types.GET_COMPANY_PLAN_CONSUMPTION_SUCCESS,
  payload,
});
