import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  projects: [],
  grammars: [],
  statusList: [],
  voices: [],
  voicebotInstances: [],
  voicebotVariables: [],
};

export default function voiceBotState(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_PROJECTS_SUCCESS:
        draft.projects = action.payload.projects;
        break;

      case types.FETCH_GRAMMARS_SUCCESS:
        draft.grammars = action.payload.grammars;
        break;

      case types.FETCH_STATUS_SUCCESS:
        draft.statusList = action.payload.status;
        break;

      case types.FETCH_VOICES_SUCCESS:
        draft.voices = action.payload.voices;
        break;
      case types.FETCH_VOICES_FAILIURE:
        draft.voice = [];
        break;

      case types.FETCH_VOICEBOT_INSTANCES_SUCCESS:
        draft.voicebotInstances = action.payload.instances;
        break;
      case types.FETCH_VOICEBOT_INSTANCES_FAILIURE:
        draft.voicebotInstances = [];
        break;

      case types.CREATE_VOICEBOT_INSTANCE_SUCCESS: {
        const updatedInstanceArray = [
          ...state.voicebotInstances,
          action.payload.instance,
        ];
        draft.voicebotInstances = updatedInstanceArray;
        break;
      }
      case types.UPDATE_VOICEBOT_INSTANCE_SUCCESS: {
        const updatedInstance = action.payload.instance;

        const updatedInstances = state.voicebotInstances.map((instance) =>
          instance._id === updatedInstance._id ? updatedInstance : instance
        );

        draft.voicebotInstances = updatedInstances;
        break;
      }

      case types.FETCH_VARIABLES_SUCCESS:
        draft.voicebotVariables = action.payload.variables;
        break;

      default:
        return {
          ...state,
        };
    }
  });
}
