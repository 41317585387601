const Types = {
  LIST_TAGS: '@ivrTags/LIST_TAGS',
  LIST_TAGS_SUCCESS: '@ivrTags/LIST_TAGS_SUCCESS',

  CREATE_TAG: '@ivrTags/CREATE_TAG',
  CREATE_TAG_SUCCESS: '@ivrTags/CREATE_TAG_SUCCESS',

  EDIT_TAG: '@ivrTags/EDIT_TAG',
  EDIT_TAG_SUCCESS: '@ivrTags/EDIT_TAG_SUCCESS',

  REMOVE_TAG: '@ivrTags/REMOVE_TAG',
  REMOVE_TAG_SUCCESS: '@ivrTags/REMOVE_TAG_SUCCESS',
};

export default Types;
