const Types = {
  LIST_FILTER_INFO: '@filter/LIST_FILTER_INFO',
  LIST_FILTER_INFO_SUCCESS: '@filter/LIST_FILTER_INFO_SUCCESS',

  FILTER_ERRORS_LOG_INFO: '@filter/FILTER_ERRORS_LOG_INFO',
  FILTER_ERRORS_LOG_INFO_SUCCESS: '@filter/FILTER_ERRORS_LOG_INFO_SUCCESS',

  FILTER_REPORT_INFO: '@filter/FILTER_REPORT_INFO',
  FILTER_REPORT_INFO_SUCCESS: '@filter/FILTER_REPORT_INFO_SUCCESS',

  FILTER_ORGS_INFO: '@filter/FILTER_ORGS_INFO',
  FILTER_ORGS_INFO_SUCCESS: '@filter/FILTER_ORGS_INFO_SUCCESS',
};

export default Types;
