import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import * as S from './styles';

import Modal, { ModalFooter } from '~/components/Modal';
import Button from '~/components/Button';
import Switch from '~/components/Switch';
import Typography from '~/components/Typography';
import { isRedatorOrViewer } from '~/utils/utils';

import { notice } from '~/store/modules/auth/actions';
import { setOpenedModalBot } from '~/store/modules/bots/actions';

import iconBot from '~/assets/icons/icon_bot_white.svg';
import logoLigoBotsFull from '~/assets/ligo/ligo-bots.svg';

const FirstStepsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((state) => state.auth);
  const { selectedCompany } = useSelector((state) => state.auth.loggedUser);

  const [isOpen, onIsOpen] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const refVideoWelcome = useRef(null);

  useEffect(() => {
    if (loggedUser?.lastNotice) {
      refVideoWelcome?.current?.pause();
    } else if (isOpen) {
      refVideoWelcome?.current?.play();
    }
    setIsShowModal(loggedUser?.lastNotice);
    onIsOpen(!loggedUser?.lastNotice);
    return () => {
      refVideoWelcome?.current?.pause();
    };
  }, [loggedUser?.lastNotice]);

  const pularVideo = () => {
    if (isShowModal) {
      dispatch(notice(loggedUser, isShowModal));
    }
  };

  const handleClose = () => {
    onIsOpen(false);
    pularVideo();

    if (refVideoWelcome.current) {
      refVideoWelcome?.current?.pause();
    }
  };

  const handleCreateBot = () => {
    dispatch(setOpenedModalBot('create'));
    handleClose();
  };

  const handleDoNotShow = () => {
    setIsShowModal(!isShowModal);
  };

  return (
    <S.Container>
      <Modal
        isOpen={isOpen}
        maxWidth="661px"
        showHeader={false}
        onClose={handleClose}
      >
        <S.ModalHeaderCustom>
          <div className="title">
            <img src={logoLigoBotsFull} alt="Logo boteria" />
            <Typography variant="h6-heavy">
              {t('welcome_video.title')}
            </Typography>
          </div>
          <Typography variant="body-small">
            {t('welcome_video.message')}
          </Typography>
        </S.ModalHeaderCustom>
        <S.ModalContentCustom className="modal-custom-content">
          <video
            ref={refVideoWelcome}
            style={{ width: '100%' }}
            controls="controls"
            autoPlay={isOpen}
            preload="auto"
          >
            <source
              src="https://storage.googleapis.com/boteria-videos/v.mp4"
              type="video/mp4"
            />
            <track kind="captions" />
          </video>
        </S.ModalContentCustom>
        <ModalFooter className="custom-modal-footer">
          {/* <Button
            onClick={() =>
              window.open('https://code7.com/faq/boteria/', '_blank')
            }
            variant="neutral"
            className="btn-custom"
          >
            {t('welcome_video.help_center')}
          </Button> */}
          <div className="hight-buttons">
            <Switch
              checked={isShowModal}
              onChange={handleDoNotShow}
              label={t('welcome_video.dont_show_again')}
            />
            <Button
              startIcon={<img src={iconBot} alt="" />}
              type="button"
              onClick={handleCreateBot}
              disabled={isRedatorOrViewer(selectedCompany)}
            >
              {t('buttons.create_bot')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </S.Container>
  );
};

export default FirstStepsModal;
