import types from './types';

export const listTags = ({ botId }) => ({
  type: types.LIST_TAGS,
  payload: { botId },
});
export const listTagsSuccess = (payload) => ({
  type: types.LIST_TAGS_SUCCESS,
  payload,
});

export const createTag = ({ values }, callback) => ({
  type: types.CREATE_TAG,
  payload: { values, callback },
});
export const createTagSuccess = (payload) => ({
  type: types.CREATE_TAG_SUCCESS,
  payload,
});

export const editTag = (payload) => ({
  type: types.EDIT_TAG,
  payload,
});
export const editTagSuccess = (tag) => ({
  type: types.EDIT_TAG_SUCCESS,
  payload: { tag },
});

export const removeTag = ({ id, botId }) => ({
  type: types.REMOVE_TAG,
  payload: { id, botId },
});
export const removeTagSuccess = (payload) => ({
  type: types.REMOVE_TAG_SUCCESS,
  payload,
});
