import styled from 'styled-components';

export const ErrorHandlerFallbackWrapper = styled.div`
  text-align: center;
  width: 100%;
  max-width: 470px;
  height: 90vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > a img {
    width: 10rem;
    height: auto;
  }
`;

export const ErrorHandlerFallbackHeader = styled.header`
  margin-bottom: 16px;
`;

export const ErrorHandlerFallbackTitle = styled.h1`
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 24px;
  color: #35373f;
  margin-bottom: 6px;
`;

export const ErrorHandlerFallbackMessage = styled.p`
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  color: #777f88;
  margin: 6px 0px;
  max-width: 400px;
`;

export const ErrorHandlerFallbackRedirectMessage = styled.span`
  font-family: 'Archivo', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  color: #254edb;
  font-weight: 500;
  margin-top: 32px;
`;
