import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ModalConfirmation from '~/components/ModalConfirmation';
import { commonChangingFinish } from '~/store/modules/common/actions';

function ChangeRouteConfirmation({ userConfirmation }) {
  const { t } = useTranslation();
  const { isChanging } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isChanging && !!userConfirmation) {
      userConfirmation(false);
    }

    const createEventBeforeUnLoad = () => {
      window.onbeforeunload = !isChanging
        ? null
        : (event) => {
            const e = event || window.event;
            if (e) {
              e.preventDefault();
              e.returnValue = ''; // Legacy method for cross browser support
            }
            return ''; // Legacy method for cross browser support
          };
    };

    createEventBeforeUnLoad();

    window.onload = () => createEventBeforeUnLoad();
  }, [isChanging]);

  const handleConfirmModal = (value) => {
    if (value) {
      dispatch(commonChangingFinish());
    }
    userConfirmation(value);
  };

  const handleSetModalIsOpen = () => {
    !!userConfirmation && userConfirmation(false);
  };

  return (
    <>
      <ModalConfirmation
        title={t('bots.ia.intentions.modal_confirm_leave.title')}
        messageWarning={t('bots.ia.intentions.modal_confirm_leave.description')}
        confirmOptionMessage={t('buttons.leave_no_save')}
        isOpen={isChanging && !!userConfirmation}
        setModaIsOpen={handleSetModalIsOpen}
        onClick={() => handleConfirmModal(true)}
        idCancelButton="routes-delete-cancel"
        idDeleteButton="routes-delete-confirm"
      />

      <Prompt
        when={isChanging}
        message={t('bots.ia.intentions.modal_confirm_leave.description')}
      />
    </>
  );
}

export default ChangeRouteConfirmation;
