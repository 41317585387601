import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  local: [],
  global: [],
  foundVariables: {},
  isLoadingSearchVariables: false,
  isLoadingListVariables: false,
};

export default function ivrVariables(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.LIST_IVR_VARIABLES: {
        draft.isLoadingListVariables = true;
        break;
      }
      case types.LIST_IVR_VARIABLES_SUCCESS: {
        draft.local = action.payload.local;
        draft.global = action.payload.global;
        draft.isLoadingListVariables = false;
        break;
      }
      case types.LIST_IVR_VARIABLES_FAILURE: {
        draft.isLoadingListVariables = false;
        break;
      }

      case types.SEARCH_IVR_VARIABLES: {
        draft.isLoadingSearchVariables = true;
        break;
      }
      case types.SEARCH_IVR_VARIABLES_SUCCESS: {
        draft.foundVariables = action.payload;
        draft.isLoadingSearchVariables = false;
        break;
      }
      case types.SEARCH_IVR_VARIABLES_FAILURE: {
        draft.foundVariables = { data: [], metadata: null };
        draft.isLoadingSearchVariables = false;
        break;
      }
      case types.RESET_SEARCH_IVR_VARIABLES: {
        draft.isLoadingSearchVariables = false;
        draft.foundVariables = {};
        break;
      }

      case types.CREATE_IVR_IVR_VARIABLE_SUCCESS: {
        draft.local = [...(state.local || []), action.payload.newVariableLocal];
        break;
      }

      case types.UPDATE_IVR_VARIABLE_SUCCESS: {
        const updatedVariableLocal = action.payload.updatedVariableLocal;
        draft.local = state.local.map((variable) =>
          variable._id === updatedVariableLocal._id
            ? updatedVariableLocal
            : variable
        );
        break;
      }

      case types.DELETE_IVR_VARIABLE_SUCCESS: {
        const updatedLocalVariables = state.local.filter(
          (variable) => variable._id !== action.payload.id
        );
        draft.local = updatedLocalVariables;
        break;
      }

      default:
        return state;
    }
  });
}
