const Types = {
  FETCH_IVR_NUMBERS: '@ivr/FETCH_IVR_NUMBERS',
  FETCH_IVR_NUMBERS_SUCCESS: '@ivr/FETCH_IVR_NUMBERS_SUCCESS',
  FETCH_IVR_NUMBERS_FAILURE: '@ivr/FETCH_IVR_NUMBERS_FAILURE',

  SEARCH_IVR_NUMBERS_FOR_MANAGEMENT: '@ivr/SEARCH_IVR_NUMBERS_FOR_MANAGEMENT',
  SEARCH_IVR_NUMBERS_FOR_MANAGEMENT_SUCCESS:
    '@ivr/SEARCH_IVR_NUMBERS_FOR_MANAGEMENT_SUCCESS',
  SEARCH_IVR_NUMBERS_FOR_MANAGEMENT_FAILURE:
    '@ivr/SEARCH_IVR_NUMBERS_FOR_MANAGEMENT_FAILURE',

  SEARCH_IVR_NUMBERS_FOR_BUY: '@ivr/SEARCH_IVR_NUMBERS_FOR_BUY',
  SEARCH_IVR_NUMBERS_FOR_BUY_SUCCESS: '@ivr/SEARCH_IVR_NUMBERS_FOR_BUY_SUCCESS',
  SEARCH_IVR_NUMBERS_FOR_BUY_FAILURE: '@ivr/SEARCH_IVR_NUMBERS_FOR_BUY_FAILURE',

  BUY_IVR_NUMBER: '@ivr/BUY_IVR_NUMBER',
  BUY_IVR_NUMBER_SUCCESS: '@ivr/BUY_IVR_NUMBER_SUCCESS',
  BUY_IVR_NUMBER_FAILURE: '@ivr/BUY_IVR_NUMBER_FAILURE',

  CREATE_IVR_NUMBER: '@ivr/CREATE_IVR_NUMBER',
  CREATE_IVR_NUMBER_SUCCESS: '@ivr/CREATE_IVR_NUMBER_SUCCESS',
  CREATE_IVR_NUMBER_FAILURE: '@ivr/CREATE_IVR_NUMBER_FAILURE',

  UPDATE_IVR_NUMBER: '@ivr/UPDATE_IVR_NUMBER',
  UPDATE_IVR_NUMBER_SUCCESS: '@ivr/UPDATE_IVR_NUMBER_SUCCESS',
  UPDATE_IVR_NUMBER_FAILURE: '@ivr/UPDATE_IVR_NUMBER_FAILURE',

  ADD_ASSOCIATE_NUMBERS_TO_IVR: '@ivr/ADD_ASSOCIATE_NUMBERS_TO_IVR',
  ADD_ASSOCIATE_NUMBERS_TO_IVR_SUCCESS:
    '@ivr/ADD_ASSOCIATE_NUMBERS_TO_IVR_SUCCESS',
  ADD_ASSOCIATE_NUMBERS_TO_IVR_FAILURE:
    '@ivr/ADD_ASSOCIATE_NUMBERS_TO_IVR_FAILURE',

  REMOVE_ASSOCIATE_NUMBER_FROM_IVR: '@ivr/REMOVE_ASSOCIATE_NUMBER_FROM_IVR',
  REMOVE_ASSOCIATE_NUMBER_FROM_IVR_SUCCESS:
    '@ivr/REMOVE_ASSOCIATE_NUMBER_FROM_IVR_SUCCESS',
  REMOVE_ASSOCIATE_NUMBER_FROM_IVR_FAILURE:
    '@ivr/REMOVE_ASSOCIATE_NUMBER_FROM_IVR_FAILURE',

  REMOVE_IVR_NUMBER_FROM_ORGANIZATION:
    '@ivr/REMOVE_IVR_NUMBER_FROM_ORGANIZATION',
  REMOVE_IVR_NUMBER_FROM_ORGANIZATION_SUCCESS:
    '@ivr/REMOVE_IVR_NUMBER_FROM_ORGANIZATION_SUCCESS',
  REMOVE_IVR_NUMBER_FROM_ORGANIZATION_FAILURE:
    '@ivr/REMOVE_IVR_NUMBER_FROM_ORGANIZATION_FAILURE',
};

export default Types;
