export const URL_CACHED_$ = [
  /\/resumed-all-items$/,
  /\/items$/,
  /\/connectors$/,
  /\/variable\/[a-zA-Z0-9_-]+$/,
  /\/tag\/[a-zA-Z0-9_-]+$/,
  /\/items-subflow\/[a-zA-Z0-9_-]+$/,
  /\/connectors-subflow\/[a-zA-Z0-9_-]+$/,
];

export default function getURLCached(req) {
  const { url } = req;
  const urlMapped = URL_CACHED_$.map((pattern) => {
    const patternRegexp = new RegExp(pattern);
    return patternRegexp.test(url);
  });
  const shouldCached = urlMapped.includes(true);
  return !shouldCached;
}
