import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  global: [],
};

export default function ivrFunctions(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.LIST_IVR_FUNCTIONS_SUCCESS: {
        draft.global = action.payload.global;
        break;
      }

      default:
        return state;
    }
  });
}
