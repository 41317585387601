import styled from 'styled-components';

export const ContainerProfile = styled.div`
  width: 100%;
  padding: ${({ isOpenSidebar }) =>
    isOpenSidebar ? '8px 0px 8px 8px' : ' 8px 0 8px 0'};
  transition: all 0.5s ease;
  background-color: var(
    ${({ isOpenMenu }) => isOpenMenu && '--absolute-white'}
  );

  position: relative;

  :hover {
    background: var(--absolute-white);
    color: var(--primary-800-light) !important;
    border-radius: 4px;
    cursor: ${({ cursor }) => cursor || 'pointer'};
  }

  & .profile-details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  & .profile-details > i {
    height: 35px;
    width: 35px;
    line-height: 43px;
    color: var(--neutral-2);
  }

  &:hover .profile-details > i {
    color: var(--primary-800-light) !important;

    svg {
      fill: var(--primary-800-light) !important;
    }
  }

  & .emphasis {
    background: #fff;
    color: var(--primary-800-light) !important;

    svg {
      fill: var(--primary-800-light) !important;
    }
  }

  & .profile-details .profile-img {
    margin: 0px 11px 0px 1px !important;
  }
  & .profile-details > img {
    height: 33px;
    width: 33px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 10px;
    border: 1px solid var(--neutral-2);
    background-color: #e5e5e5;
  }
  & .profile-details .name_job {
    width: 170px;
  }

  & .profile-details .name,
  & .profile-details .job {
    opacity: ${({ isOpenSidebar }) => (isOpenSidebar ? 1 : 0)};
    transition: all 0.5s ease;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover .profile-details .job {
    color: var(--primary-800-light) !important;
  }

  & .profile-details .name {
    font-size: 0.625rem;
    color: var(--neutral-2);
    text-transform: uppercase;
    font-weight: 600;
  }

  & .profile-details .job {
    font-size: 0.875rem;
    color: var(--neutral-3);
  }
  & .btn-down {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 46px;
    line-height: 46px;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: all 0.5s ease, outline-color 0s;
    color: var(--neutral-3);

    opacity: ${({ isOpenSidebar }) => (isOpenSidebar ? 1 : 0)};
    width: ${({ isOpenSidebar }) => (isOpenSidebar ? '50px' : '100%')};
    background-color: transparent;

    &:focus-visible {
      outline-color: var(--primary-400-light) !important;
    }

    > img {
      transform: ${({ isOpenMenu }) =>
        isOpenMenu ? `rotate(180deg)` : `rotate(0)`};
      transition: all 0.2s ease;
    }
  }

  .profile-details .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 8px);
    z-index: 3;
    background: var(--neutral-n1);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.938rem;
    font-weight: 400;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
    color: var(--neutral-0);
  }

  li:hover .profile-details .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
    display: ${({ isOpenSidebar }) => (isOpenSidebar ? 'none' : 'block')};
  }
`;

export const MenuBg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const MenuDrop = styled.ul`
  padding: 0;
  position: absolute;
  background-color: #fff;
  z-index: 100;
  width: ${({ isOpenSidebar }) => (isOpenSidebar ? '100%' : '170px')};
  top: 2.8rem;
  border-radius: 4px;
  border: 1px solid var(--neutral-2);
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;

  & li {
    margin: 0;
  }

  & li > button {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    text-align: left;
    color: var(--neutral-3);
    font-weight: 500;
    padding: 1em;
  }

  & li > button:hover {
    background-color: #f2f2f2;
  }
`;

export const SubMenuProfile = styled.ul`
  margin-left: 2rem;
  margin-top: 3px;
  margin-bottom: 3px;

  & li {
    margin: 0;
  }

  & li > button {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    text-align: left;
    color: var(--neutral-3);
    font-weight: 500;
    padding: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.4s ease, outline-color 0s;

    &:focus-visible {
      outline-color: var(--primary-400-light) !important;
    }
  }

  & li > button:hover {
    background-color: #fff;
  }
`;
