import types from './types';

export const commonLoadingStart = () => ({
  type: types.LOADING_START,
});

export const commonLoadingFinish = () => ({
  type: types.LOADING_FINISH,
});

export const commonChangingStart = () => ({
  type: types.CHANGING_START,
});

export const commonChangingFinish = () => ({
  type: types.CHANGING_FINISH,
});

export const commonNetworkOnline = () => ({
  type: types.NETWORK_ONLINE,
});

export const commonNetworkOffline = () => ({
  type: types.NETWORK_OFFLINE,
});
