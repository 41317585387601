/* eslint-disable import/no-cycle */
import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import company from './company/sagas';
import organization from './organization/sagas';
import bots from './bots/sagas';
import users from './users/sagas';
import permissions from './permissions/sagas';
import plans from './plans/sagas';
import reports from './reports/sagas';
import triggers from './triggers/sagas';
import items from './items/sagas';
import registerFrom from './registerFrom/sagas';
import voiceBot from './voice-bots/sagas';
import connectors from './connectors/sagas';
import tags from './tags/sagas';
import variables from './variables/sagas';
import filter from './filter/sagas';
import builder from './builder/sagas';
import intelligence from './intelligence/sagas';
import artificialIntelligence from './artificial-intelligence/sagas';
import rdStation from './rd-station/sagas';
import templates from './templates/sagas';
import ivr from './ivr/sagas';
import ivrVariables from './ivrVariables/sagas';
import ivrFunctions from './ivrFunctions/sagas';
import ivrReports from './ivrReports/sagas';
import ivrSchedules from './ivrSchedules/sagas';
import prompt from './prompt/sagas';
import nps from './nps/sagas';
import topdesk from './topdesk/sagas';
import ivrTags from './ivrTags/sagas';
import ivrChatGPT from './ivrChatGPT/sagas';
import ivrPlans from './ivrPlans/sagas';
import leia from './leia/sagas';
import ivrNumbers from './ivrNumbers/sagas';
import ivrTelephoneOperators from './ivrTelephoneOperators/sagas';
import ivrBedrock from './ivrBedrock/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    bots,
    organization,
    users,
    permissions,
    company,
    plans,
    reports,
    triggers,
    items,
    registerFrom,
    voiceBot,
    connectors,
    tags,
    variables,
    filter,
    builder,
    intelligence,
    artificialIntelligence,
    rdStation,
    templates,
    ivr,
    ivrVariables,
    ivrFunctions,
    ivrReports,
    ivrSchedules,
    prompt,
    nps,
    topdesk,
    ivrTags,
    ivrChatGPT,
    ivrPlans,
    leia,
    ivrNumbers,
    ivrTelephoneOperators,
    ivrBedrock,
  ]);
}
