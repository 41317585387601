import { takeLatest, put, all, call } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';

import * as actions from './actions';
import types from './types';

export function* listIvrVariables({ payload }) {
  const { botId, callback } = payload;
  callback?.(true);
  try {
    const { data } = yield call(api.get, `/v3/ivr/variables/${botId}`);

    yield put(actions.listIvrVariablesSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_variables'));
    yield put(actions.listIvrVariablesFailure());
  } finally {
    callback?.(true);
  }
}

export function* searchIvrVariables({ payload }) {
  try {
    const { botId, queryParams } = payload;
    const { data } = yield call(api.get, `/v3/ivr/variables/${botId}/search`, {
      params: queryParams,
    });

    yield put(actions.searchIvrVariablesSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_variables'));
    yield put(actions.searchIvrVariablesFailure());
  }
}

export function* createIvrVariable({ payload }) {
  const { callback } = payload;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.post, '/v3/ivr/variables', payload.data);

    callback?.();
    yield put(actions.createIvrVariableSuccess({ newVariableLocal: data }));
    toast.success(i18n.t('success.success_create_variable'));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_create_variable'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateIvrVariable({ payload }) {
  const { callback } = payload;

  try {
    yield put(commonLoadingStart());

    const variableId = payload?.data._id;
    const { data } = yield call(
      api.put,
      `/v3/ivr/variables/${variableId}`,
      payload?.data
    );

    callback?.();
    yield put(actions.updateIvrVariableSuccess({ updatedVariableLocal: data }));
    toast.success(i18n.t('success.success_update_variable'));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_update_variable'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteIvrVariable({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { id, callback } = payload;

    yield call(api.delete, `/v3/ivr/variables/${id}`);

    yield put(actions.deleteIvrVariableSuccess({ id: payload.id }));
    toast.success(i18n.t('success.success_delete_variable'));

    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_delete_variable'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* decryptIvrVariable({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, value, callback } = payload;

    const { data } = yield call(
      api.get,
      `/v3/ivr/variables/${botId}/decryption/${value}`
    );

    callback?.(data);
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_decrypt_variable'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.LIST_IVR_VARIABLES, listIvrVariables),
  takeLatest(types.SEARCH_IVR_VARIABLES, searchIvrVariables),
  takeLatest(types.CREATE_IVR_VARIABLE, createIvrVariable),
  takeLatest(types.UPDATE_IVR_VARIABLE, updateIvrVariable),
  takeLatest(types.DELETE_IVR_VARIABLE, deleteIvrVariable),
  takeLatest(types.DECRYPT_IVR_VARIABLE, decryptIvrVariable),
]);
