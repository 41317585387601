import { takeLatest, put, all } from 'redux-saga/effects';

import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import * as actions from './actions';
import types from './types';

export function* listFilterInfo(action) {
  const { payload } = action;
  try {
    yield put(actions.listFilterInfoSuccess(payload));
  } catch (e) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('reports.report_filters.error.filter_error')
    );
  }
}

export function* filterReportInfo(action) {
  const { payload } = action;
  try {
    yield put(actions.filterReportInfoSuccess(payload));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('reports.report_filters.error.filter_error')
    );
  }
}

export default all([
  takeLatest(types.LIST_FILTER_INFO, listFilterInfo),
  takeLatest(types.FILTER_REPORT_INFO, filterReportInfo),
]);
