import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  companyMessagesPerBot: [],
  companyBotsCount: 0,
  companyUsersCount: 0,
  companySectorsCount: 0,
  myDashboard: {},
  listErrorLog: {
    docs: [],
    totalPages: 0,
    page: 0,
    limit: 0,
    total: 0,
  },
  companyMessages: [],
  companySessions: [],

  botClients: [],
  botSessions: [],
  botSessionTags: [],
  botMessages: [],
  botResume: {},
  iaReports: {},
  reportDownload: {},
  npsReport: {},
  orgIndicators: [],
  loadingDownloadListErrorLog: false,
};

export default function reports(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_COMPANY_BOTS_COUNT_SUCCESS: {
        draft.companyBotsCount = action.payload;
        break;
      }
      case types.GET_COMPANY_USERS_COUNT_SUCCESS: {
        draft.companyUsersCount = action.payload;
        break;
      }
      case types.GET_COMPANY_SECTORS_COUNT_SUCCESS: {
        draft.companySectorsCount = action.payload;
        break;
      }
      case types.GET_MY_DASHBOARD_SUCCESS: {
        draft.myDashboard = action.payload;
        break;
      }
      case types.CREATE_MY_DASHBOARD_SUCCESS: {
        draft.myDashboard = action.payload;
        break;
      }
      case types.UPDATE_MY_DASHBOARD_SUCCESS: {
        draft.myDashboard = action.payload;
        break;
      }
      case types.SUCCESS_LIST_ERROR_LOG: {
        draft.listErrorLog = action.payload;
        break;
      }
      case types.GET_COMPANY_MESSAGES_SUCCESS: {
        draft.companyMessages = action.payload;
        break;
      }
      case types.GET_COMPANY_SESSIONS_SUCCESS: {
        draft.companySessions = action.payload;
        break;
      }

      case types.GET_CLIENTS_REPORTS_SUCCESS: {
        draft.botClients = action.payload;
        break;
      }
      case types.GET_BOT_SESSION_TAGS_SUCCESS: {
        draft.botSessionTags = action.payload;
        break;
      }
      case types.GET_BOT_MESSAGES_SUCCESS: {
        draft.botMessages = action.payload;
        break;
      }
      case types.GET_BOT_RESUME_SUCCESS: {
        draft.botResume = action.payload;
        break;
      }
      case types.GET_NOT_UNDERSTOOD_INTENTIONS_SUCCESS: {
        draft.iaReports.notUnderstood = action.payload;
        break;
      }
      case types.GET_INTENTIONS_PERFORMANCE_SUCCESS: {
        draft.iaReports.intentionsPerformance = action.payload;
        break;
      }
      case types.GET_UNDERSTOOD_INTENTIONS_SUCCESS: {
        draft.iaReports.understood = action.payload;
        break;
      }
      case types.GET_REPORT_SESSION_GENERATE_SUCCESS: {
        draft.reportDownload._id = action.payload._id;
        draft.reportDownload.message = action.payload.message;
        break;
      }

      case types.GET_BOT_SESSION_REPORT_SUCCESS: {
        draft.botSessions = action.payload;
        break;
      }
      case types.LOAGING_LIST_ERROR_LOG: {
        draft.loadingDownloadListErrorLog = action.payload;
        break;
      }
      case types.GET_NPS_REPORT_SUCCESS: {
        draft.npsReport = action.payload;
        break;
      }

      default:
    }
  });
}
