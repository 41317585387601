import styled from 'styled-components';

export const Container = styled.div.attrs((props) => ({
  as: props.as,
}))`
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};

  &.body-default {
    font-family: var(--body-default-font-family);
    font-size: var(--body-default-font-size);
    letter-spacing: var(--body-default-letter-spacing);
    line-height: var(--body-default-line-height);
    font-weight: var(--body-default-font-weight);
    color: var(--body-default-color);
  }

  &.body-default-strong {
    font-family: var(--body-default-strong-font-family);
    font-size: var(--body-default-strong-font-size);
    letter-spacing: var(--body-default-strong-letter-spacing);
    line-height: var(--body-default-strong-line-height);
    font-weight: var(--body-default-strong-font-weight);
    color: var(--body-default-strong-color);
  }

  &.body-large {
    font-family: var(--body-large-font-family);
    font-size: var(--body-large-font-size);
    letter-spacing: var(--body-large-letter-spacing);
    line-height: var(--body-large-line-height);
    font-weight: var(--body-large-font-weight);
    color: var(--body-large-color);
  }

  &.body-large-strong {
    font-family: var(--body-large-strong-font-family);
    font-size: var(--body-large-strong-font-size);
    letter-spacing: var(--body-large-strong-letter-spacing);
    line-height: var(--body-large-strong-line-height);
    font-weight: var(--body-large-strong-font-weight);
    color: var(--body-large-strong-color);
  }

  &.body-small {
    font-family: var(--body-small-font-family);
    font-size: var(--body-small-font-size);
    letter-spacing: var(--body-small-letter-spacing);
    line-height: var(--body-small-line-height);
    font-weight: var(--body-small-font-weight);
    color: var(--body-small-color);
  }

  &.body-small-strong {
    font-family: var(--body-small-strong-font-family);
    font-size: var(--body-small-strong-font-size);
    letter-spacing: var(--body-small-strong-letter-spacing);
    line-height: var(--body-small-strong-line-height);
    font-weight: var(--body-small-strong-font-weight);
    color: var(--body-small-strong-color);
  }

  &.button-caption {
    font-family: var(--button-caption-font-family);
    font-size: var(--button-caption-font-size);
    letter-spacing: var(--button-caption-letter-spacing);
    line-height: var(--button-caption-line-height);
    font-weight: var(--button-caption-font-weight);
    text-transform: var(--button-caption-text-transform);
    color: var(--button-caption-color);
  }

  &.button-caption-tight {
    font-family: var(--button-caption-tight-font-family);
    font-size: var(--button-caption-tight-font-size);
    letter-spacing: var(--button-caption-tight-letter-spacing);
    line-height: var(--button-caption-tight-line-height);
    font-weight: var(--button-caption-tight-font-weight);
    color: var(--button-caption-tight-color);
  }

  &.caption-default {
    font-family: var(--caption-default-font-family);
    font-size: var(--caption-default-font-size);
    letter-spacing: var(--caption-default-letter-spacing);
    line-height: var(--caption-default-line-height);
    font-weight: var(--caption-default-font-weight);
    color: var(--caption-default-color);
  }

  &.caption-small {
    font-family: var(--caption-small-font-family);
    font-size: var(--caption-small-font-size);
    letter-spacing: var(--caption-small-letter-spacing);
    line-height: var(--caption-small-line-height);
    font-weight: var(--caption-small-font-weight);
    color: var(--caption-small-color);
  }

  &.caption-small-tight {
    font-family: var(--caption-small-tight-font-family);
    font-size: var(--caption-small-tight-font-size);
    letter-spacing: var(--caption-small-tight-letter-spacing);
    line-height: var(--caption-small-tight-line-height);
    font-weight: var(--caption-small-tight-font-weight);
    color: var(--caption-small-tight-color);
  }

  &.h1-heavy {
    font-family: var(--h1-heavy-font-family);
    font-size: var(--h1-heavy-font-size);
    letter-spacing: var(--h1-heavy-letter-spacing);
    line-height: var(--h1-heavy-line-height);
    font-weight: var(--h1-heavy-font-weight);
    color: var(--h1-heavy-color);
  }

  &.h1-normal {
    font-family: var(--h1-normal-font-family);
    font-size: var(--h1-normal-font-size);
    letter-spacing: var(--h1-normal-letter-spacing);
    line-height: var(--h1-normal-line-height);
    font-weight: var(--h1-normal-font-weight);
    color: var(--h1-normal-color);
  }

  &.h2-heavy {
    font-family: var(--h2-heavy-font-family);
    font-size: var(--h2-heavy-font-size);
    letter-spacing: var(--h2-heavy-letter-spacing);
    line-height: var(--h2-heavy-line-height);
    font-weight: var(--h2-heavy-font-weight);
    color: var(--h2-heavy-color);
  }

  &.h2-normal {
    font-family: var(--h2-normal-font-family);
    font-size: var(--h2-normal-font-size);
    letter-spacing: var(--h2-normal-letter-spacing);
    line-height: var(--h2-normal-line-height);
    font-weight: var(--h2-normal-font-weight);
    color: var(--h2-normal-color);
  }

  &.h3-heavy {
    font-family: var(--h3-heavy-font-family);
    font-size: var(--h3-heavy-font-size);
    letter-spacing: var(--h3-heavy-letter-spacing);
    line-height: var(--h3-heavy-line-height);
    font-weight: var(--h3-heavy-font-weight);
    color: var(--h3-heavy-color);
  }

  &.h3-normal {
    font-family: var(--h3-normal-font-family);
    font-size: var(--h3-normal-font-size);
    letter-spacing: var(--h3-normal-letter-spacing);
    line-height: var(--h3-normal-line-height);
    font-weight: var(--h3-normal-font-weight);
    color: var(--h3-normal-color);
  }

  &.h4-heavy {
    font-family: var(--h4-heavy-font-family);
    font-size: var(--h4-heavy-font-size);
    letter-spacing: var(--h4-heavy-letter-spacing);
    line-height: var(--h4-heavy-line-height);
    font-weight: var(--h4-heavy-font-weight);
    color: var(--h4-heavy-color);
  }

  &.h4-normal {
    font-family: var(--h4-normal-font-family);
    font-size: var(--h4-normal-font-size);
    letter-spacing: var(--h4-normal-letter-spacing);
    line-height: var(--h4-normal-line-height);
    font-weight: var(--h4-normal-font-weight);
    color: var(--h4-normal-color);
  }

  &.h5-heavy {
    font-family: var(--h5-heavy-font-family);
    font-size: var(--h5-heavy-font-size);
    letter-spacing: var(--h5-heavy-letter-spacing);
    line-height: var(--h5-heavy-line-height);
    font-weight: var(--h5-heavy-font-weight);
    color: var(--h5-heavy-color);
  }

  &.h5-normal {
    font-family: var(--h5-normal-font-family);
    font-size: var(--h5-normal-font-size);
    letter-spacing: var(--h5-normal-letter-spacing);
    line-height: var(--h5-normal-line-height);
    font-weight: var(--h5-normal-font-weight);
    color: var(--h5-normal-color);
  }

  &.h6-heavy {
    font-family: var(--h6-heavy-font-family);
    font-size: var(--h6-heavy-font-size);
    letter-spacing: var(--h6-heavy-letter-spacing);
    line-height: var(--h6-heavy-line-height);
    font-weight: var(--h6-heavy-font-weight);
    color: var(--h6-heavy-color);
  }

  &.h6-normal {
    font-family: var(--h6-normal-font-family);
    font-size: var(--h6-normal-font-size);
    letter-spacing: var(--h6-normal-letter-spacing);
    line-height: var(--h6-normal-line-height);
    font-weight: var(--h6-normal-font-weight);
    color: var(--h6-normal-color);
  }

  &.overline-large {
    font-family: var(--overline-large-font-family);
    font-size: var(--overline-large-font-size);
    letter-spacing: var(--overline-large-letter-spacing);
    line-height: var(--overline-large-line-height);
    font-weight: var(--overline-large-font-weight);
    text-transform: var(--overline-large-text-transform);
    color: var(--overline-large-color);
  }

  &.overline-small {
    font-family: var(--overline-small-font-family);
    font-size: var(--overline-small-font-size);
    letter-spacing: var(--overline-small-letter-spacing);
    line-height: var(--overline-small-line-height);
    font-weight: var(--overline-small-font-weight);
    text-transform: var(--overline-small-text-transform);
    color: var(--overline-small-color);
  }
`;
