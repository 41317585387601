import types from './types';

export const fetchIvrChatGPTModels = () => ({
  type: types.FETCH_IVR_CHAT_GPT_MODELS,
});
export const fetchIvrChatGPTModelsSuccess = (payload) => ({
  type: types.FETCH_IVR_CHAT_GPT_MODELS_SUCCESS,
  payload,
});
export const fetchIvrChatGPTModelsFailure = () => ({
  type: types.FETCH_IVR_CHAT_GPT_MODELS_FAILURE,
});

export const fetchIvrChatGPTPromptTemplates = () => ({
  type: types.FETCH_IVR_CHATGPT_PROMPT_TEMPLATES,
});
export const fetchIvrChatGPTPromptTemplatesSuccess = (templates) => ({
  type: types.FETCH_IVR_CHATGPT_PROMPT_TEMPLATES_SUCCESS,
  payload: templates,
});
export const fetchIvrChatGPTPromptTemplatesFailure = (templates) => ({
  type: types.FETCH_IVR_CHATGPT_PROMPT_TEMPLATES_SUCCESS,
  payload: templates,
});

export const searchIvrChatGPTPromptTemplates = ({ page, limit, search }) => ({
  type: types.SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES,
  payload: { page, limit, search },
});
export const searchIvrChatGPTPromptTemplatesSuccess = (data) => ({
  type: types.SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES_SUCCESS,
  payload: data,
});
export const searchIvrChatGPTPromptTemplatesFailure = () => ({
  type: types.SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES_FAILURE,
});

export const createIvrChatGPTPromptTemplate = (data, callback) => ({
  type: types.CREATE_IVR_CHATGPT_PROMPT_TEMPLATE,
  payload: { data, callback },
});
export const createIvrChatGPTPromptTemplateSuccess = (template) => ({
  type: types.CREATE_IVR_CHATGPT_PROMPT_TEMPLATE_SUCCESS,
  payload: template,
});
export const createIvrChatGPTPromptTemplateFailure = () => ({
  type: types.CREATE_IVR_CHATGPT_PROMPT_TEMPLATE_FAILURE,
});

export const updateIvrChatGPTPromptTemplate = (data, callback) => ({
  type: types.UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE,
  payload: { data, callback },
});
export const updateIvrChatGPTPromptTemplateSuccess = (template) => ({
  type: types.UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE_SUCCESS,
  payload: template,
});
export const updateIvrChatGPTPromptTemplateFailure = () => ({
  type: types.UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE_FAILURE,
});

export const deleteIvrChatGPTPromptTemplate = (id, callback) => ({
  type: types.DELETE_IVR_CHATGPT_PROMPT_TEMPLATE,
  payload: { id, callback },
});
export const deleteIvrChatGPTPromptTemplateSuccess = () => ({
  type: types.DELETE_IVR_CHATGPT_PROMPT_TEMPLATE_SUCCESS,
});
export const deleteIvrChatGPTPromptTemplateFailure = () => ({
  type: types.DELETE_IVR_CHATGPT_PROMPT_TEMPLATE_FAILURE,
});

export const createIvrChatGPTPromptCompletions = (data, callback) => ({
  type: types.CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS,
  payload: { data, callback },
});
export const createIvrChatGPTPromptCompletionsSuccess = () => ({
  type: types.CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS_SUCCESS,
});
export const createIvrChatGPTPromptCompletionsFailure = () => ({
  type: types.CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS_FAILURE,
});
