import styled, { css } from 'styled-components';
import Input from '../Input';

export const Container = styled.div`
  position: relative;
  width: max-content;
`;

export const WrapperButton = styled.span`
  display: flex;
  flex: 1;
  cursor: pointer;

  &.disabled {
    cursor: initial;
  }
`;

export const Dropdown = styled.div`
  border-radius: var(--radius-small);
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  position: absolute;
  display: none;
  z-index: 10;
  background-color: #fff;

  ${({ contentWidth }) =>
    !!contentWidth &&
    css`
      width: ${contentWidth};
    `}

  &.is-open {
    display: block;
  }

  &.left {
    top: 0;
    right: ${({ buttonWidth, contentOffset }) =>
      `${buttonWidth + contentOffset}px`};
  }

  &.right {
    top: 0;
    left: ${({ buttonWidth, contentOffset }) =>
      `${buttonWidth + contentOffset}px`};
  }

  &.bottom {
    right: 0;
    top: ${({ buttonHeight, contentOffset }) =>
      `${buttonHeight + contentOffset}px`};
  }

  &.top {
    right: 0;
    bottom: ${({ buttonHeight, contentOffset }) =>
      `${buttonHeight + contentOffset}px`};
  }

  &.top-left {
    left: 0;
    bottom: ${({ buttonHeight, contentOffset }) =>
      `${buttonHeight + contentOffset}px`};
  }
`;

export const MenuContentScrollable = styled.div`
  overflow-y: auto;
  max-height: 220px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  box-sizing: border-box;
  cursor: pointer;
  padding: var(--spacement-small) var(--spacement-medium);
  outline: none;
  width: 100%;
  font-size: 14px;
  color: var(--neutral-4);
  user-select: none;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.active {
    background: var(--primary-200-light);
    color: var(--primary-600-light);
  }

  &.disabled {
    cursor: not-allowed !important;
  }

  & > .icon {
    width: 18px;

    & > svg {
      width: 100%;
      fill: var(--neutral-4);
    }
  }

  &:hover {
    background: var(--primary-100-light);
    color: var(--primary-600-light);
  }

  &:first-of-type {
    border-radius: 4px 4px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }

  &.isRedatorOrViewer {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const MenuItemInput = styled(Input)`
  input {
    border: 1px solid transparent !important;

    &:focus {
      border-color: var(--neutral-2) !important;
      box-shadow: unset;
    }
    &:hover {
      border: 0px;
    }

    &:active {
      border: 0px;
    }
  }
`;
