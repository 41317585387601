import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  width: max-content;

  &.label-placement {
    &--start {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      .label {
        margin-right: 8px;
      }
    }

    &--top {
      flex-direction: column-reverse;
      align-items: center;
      .label {
        margin-bottom: 8px;
      }
    }

    &--end {
      align-items: center;
      justify-content: space-between;
      .label {
        margin-left: 8px;
      }
    }

    &--bottom {
      flex-direction: column;
      align-items: center;
      .label {
        margin-top: 8px;
      }
    }
  }

  .label {
    color: var(--neutral-3);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 114%;
    letter-spacing: -0.02em;
    max-width: calc(100% - 48px);
    cursor: pointer;
  }

  .labelDescription {
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral-2);
  }
`;

export const CustomSwitch = styled.label`
  &.switch {
    position: relative;
    display: flex;
    min-width: 48px;
    height: 24px;
  }

  &.switch input {
    display: none;
  }

  .slider {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 3px;
    background-color: var(--neutral-1);
    transition: 0.4s;
    border-radius: 24px;
  }

  .disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }

  .slider:before {
    border-radius: 31px;
    position: absolute;
    content: '';
    height: calc(100% - 6px);
    width: 20px;
    background-color: var(--absolute-white);
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out;
  }

  input:checked {
    & + .slider {
      &.primary {
        background-color: var(--status-blue);
      }
      &.success {
        background-color: var(--status-green);
      }
      &.danger {
        background-color: var(--status-red);
      }

      &:before {
        transform: translateX(calc(100% + 2px));
      }
    }
  }
`;

export const ErrorMessage = styled.div`
  color: var(--semantic-red-600-light);
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 120%;
  display: block;
  margin-top: 4px;
`;
