import types from './types';

export const iaPreview = ({ token, message }) => ({
  type: types.IA_PREVIEW,
  payload: { token, message },
});

export const iaPreviewSuccess = ({ data }) => ({
  type: types.IA_PREVIEW_SUCCESS,
  payload: { data },
});

export const iaPreviewClear = () => ({
  type: types.IA_PREVIEW_CLEAR,
});
