import { takeLatest, put, all, call } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';
import types from './types';

export function* fetchAllScheduleGroups({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.get,
      `/v3/ivr/schedules/group/org/${payload}`
    );

    yield put(actions.fetchAllScheduleGroupsSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_listing_groups')
    );
  } finally {
    yield put(actions.fetchAllScheduleGroupsFailure());
    yield put(commonLoadingFinish());
  }
}

export function* fetchScheduleGroup({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.get, `/v3/ivr/schedules/group/${payload}`);

    yield put(actions.fetchScheduleGroupSuccess({ payload: data }));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_loading_group')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createScheduleGroup({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { group, callback } = payload;

    const { data } = yield call(api.post, `/v3/ivr/schedules/group`, group);

    yield put(actions.createScheduleGroupSuccess({ payload: data }));
    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_creating_the_group')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateScheduleGroup({ payload }) {
  const { group, callback } = payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.put,
      `/v3/ivr/schedules/group/${group._id}`,
      group
    );

    yield put(actions.updateScheduleGroupSuccess(data));

    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_updating_the_group')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchScheduleRule(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.get, `/v3/ivr/schedules/rule/${payload}`);

    yield put(actions.fetchScheduleRuleSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_listing_rules')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchAllSchedulesRules(action) {
  const { payload } = action;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.get,
      `/v3/ivr/schedules/rule/org/${payload}`
    );

    yield put(actions.fetchAllScheduleRulesSuccess({ payload: data }));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_listing_rules')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* searchScheduleRules({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { queryParams } = payload;

    const { data } = yield call(api.get, `/v3/ivr/schedules/rule/search`, {
      params: queryParams,
    });

    yield put(actions.searchScheduleRulesSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_listing_rules')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateScheduleRule({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { ruleId, formData, callback } = payload;

    const response = yield call(
      api.put,
      `/v3/ivr/schedules/rule/${ruleId}`,
      formData
    );

    yield put(actions.updateScheduleRuleSuccess({ payload: response.data }));
    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_updating_the_rule'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createScheduleRule({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { formData, callback } = payload;

    const response = yield call(api.post, `/v3/ivr/schedules/rule`, formData);

    yield put(actions.createScheduleRuleSuccess({ payload: response.data }));
    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_creating_the_rule')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteScheduleRule({ payload }) {
  const { ruleId, callback } = payload;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.delete, `/v3/ivr/schedules/rule/${ruleId}`);

    yield put(actions.deleteScheduleRuleSuccess(data._id));

    callback?.();

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_deleted_rule')
    );
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_deleting_the_rule'));
  } finally {
    yield put(actions.deleteScheduleRuleFailure());
    yield put(commonLoadingFinish());
  }
}

export function* associateRulesToGroup({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { groupId, ruleIds, callback } = payload;

    const { data } = yield call(
      api.post,
      `/v3/ivr/schedules/group/${groupId}/associate`,
      { ruleIds }
    );

    yield put(actions.associateRulesToGroupSuccess(data));

    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_updating_rule_association_with_the_group')
    );
  } finally {
    yield put(commonLoadingFinish());
    yield put(actions.associateRulesToGroupFailure());
  }
}

export function* searchIvrHolidays({ payload }) {
  try {
    const { filters } = payload;
    const { data } = yield call(api.get, `/v3/ivr/schedules/holidays/search`, {
      params: filters,
    });

    yield put(actions.searchIvrHolidaysSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_fetching_the_holidays')
    );
    yield put(actions.searchIvrHolidaysFailure());
  }
}

export function* fetchIvrHolidaysAvailableCountries() {
  try {
    const { data } = yield call(
      api.get,
      `/v3/ivr/schedules/holidays/available-countries`
    );

    yield put(actions.fetchIvrHolidaysAvailableCountriesSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_listing_the_available_countries')
    );
    yield put(actions.fetchIvrHolidaysAvailableCountriesFailure());
  }
}

export function* fetchIvrHolidaysAvailableYears() {
  try {
    const { data } = yield call(
      api.get,
      `/v3/ivr/schedules/holidays/available-years`
    );

    yield put(actions.fetchIvrHolidaysAvailableYearsSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_listing_the_available_years')
    );
    yield put(actions.fetchIvrHolidaysAvailableYearsFailure());
  }
}

export function* importScheduleRuleFromHolidays({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { holidayIds, callback } = payload;
    const { data } = yield call(
      api.post,
      `/v3/ivr/schedules/rule/holidays/import`,
      { holidayIds }
    );

    yield put(actions.importScheduleRuleFromHolidaysSuccess(data));
    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_importing_holidays')
    );
    yield put(actions.importScheduleRuleFromHolidaysFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.FETCH_ALL_SCHEDULE_GROUPS, fetchAllScheduleGroups),
  takeLatest(types.FETCH_SCHEDULE_GROUP, fetchScheduleGroup),
  takeLatest(types.CREATE_SCHEDULE_GROUP, createScheduleGroup),
  takeLatest(types.UPDATE_SCHEDULE_GROUP, updateScheduleGroup),
  takeLatest(types.FETCH_SCHEDULE_RULE, fetchScheduleRule),
  takeLatest(types.FETCH_ALL_SCHEDULES_RULES, fetchAllSchedulesRules),
  takeLatest(types.SEARCH_SCHEDULE_RULES, searchScheduleRules),
  takeLatest(types.CREATE_SCHEDULE_RULE, createScheduleRule),
  takeLatest(types.UPDATE_SCHEDULE_RULE, updateScheduleRule),
  takeLatest(types.DELETE_SCHEDULE_RULE, deleteScheduleRule),
  takeLatest(types.ASSOCIATE_RULES_TO_GROUP, associateRulesToGroup),
  takeLatest(types.SEARCH_IVR_HOLIDAYS, searchIvrHolidays),
  takeLatest(
    types.FETCH_IVR_HOLIDAYS_AVAILABLE_COUNTRIES,
    fetchIvrHolidaysAvailableCountries
  ),
  takeLatest(
    types.FETCH_IVR_HOLIDAYS_AVAILABLE_YEARS,
    fetchIvrHolidaysAvailableYears
  ),
  takeLatest(
    types.IMPORT_SCHEDULE_RULE_FROM_HOLIDAYS,
    importScheduleRuleFromHolidays
  ),
]);
