const Types = {
  FETCH_IVR_TELEPHONE_OPERATORS: '@ivrOperator/FETCH_IVR_TELEPHONE_OPERATORS',
  FETCH_IVR_TELEPHONE_OPERATORS_SUCCESS:
    '@ivrOperator/FETCH_IVR_TELEPHONE_OPERATORS_SUCCESS',
  FETCH_IVR_TELEPHONE_OPERATORS_FAILURE:
    '@ivrOperator/FETCH_IVR_TELEPHONE_OPERATORS_FAILURE',

  CREATE_IVR_TELEPHONE_OPERATOR: '@ivrOperator/CREATE_IVR_TELEPHONE_OPERATOR',
  CREATE_IVR_TELEPHONE_OPERATOR_SUCCESS:
    '@ivrOperator/CREATE_IVR_TELEPHONE_OPERATOR_SUCCESS',
  CREATE_IVR_TELEPHONE_OPERATOR_FAILURE:
    '@ivrOperator/CREATE_IVR_TELEPHONE_OPERATOR_FAILURE',

  UPDATE_IVR_TELEPHONE_OPERATOR: '@ivrOperator/UPDATE_IVR_TELEPHONE_OPERATOR',
  UPDATE_IVR_TELEPHONE_OPERATOR_SUCCESS:
    '@ivrOperator/UPDATE_IVR_TELEPHONE_OPERATOR_SUCCESS',
  UPDATE_IVR_TELEPHONE_OPERATOR_FAILURE:
    '@ivrOperator/UPDATE_IVR_TELEPHONE_OPERATOR_FAILURE',

  DELETE_IVR_TELEPHONE_OPERATOR: '@ivrOperator/DELETE_IVR_TELEPHONE_OPERATOR',
  DELETE_IVR_TELEPHONE_OPERATOR_SUCCESS:
    '@ivrOperator/DELETE_IVR_TELEPHONE_OPERATOR_SUCCESS',
  DELETE_IVR_TELEPHONE_OPERATOR_FAILURE:
    '@ivrOperator/DELETE_IVR_TELEPHONE_OPERATOR_FAILURE',
};

export default Types;
