const types = {
  FETCH_ITEMS: '@items/FETCH_ITEMS',
  FETCH_ITEMS_SUCCESS: '@items/FETCH_ITEMS_SUCCESS',
  FETCH_ITEM_LAST_VERSION: '@bots/FETCH_ITEM_LAST_VERSION',
  FETCH_ALL_ITEMS: '@items/FETCH_ALL_ITEMS',
  FETCH_ITEM_LAST_VERSION_SUCCESS: '@bots/FETCH_ITEM_LAST_VERSION_SUCCESS',
  FETCH_ALL_ITEMS_SUCCESS: '@items/FETCH_ALL_ITEMS_SUCCESS',
  FETCH_SUBFLOW_ITEMS: '@items/FETCH_SUBFLOW_ITEMS',
  FETCH_SUBFLOW_ITEMS_SUCCESS: '@items/FETCH_SUBFLOW_ITEMS_SUCCESS',
  CREATE_ITEM: '@items/CREATE_ITEM',
  CREATE_ITEM_SUCCESS: '@items/CREATE_ITEM_SUCCESS',
  IVR_CLONE_ITEM_GROUP: '@items/IVR_CLONE_ITEM_GROUP',
  IVR_CLONE_ITEM_GROUP_SUCCESS: '@items/IVR_CLONE_ITEM_GROUP_SUCCESS',
  IVR_CLONE_ITEM_GROUP_FAILURE: '@items/IVR_CLONE_ITEM_GROUP_FAILURE',
  IVR_CLONE_ITEMS: '@items/IVR_CLONE_ITEMS',
  IVR_CLONE_ITEMS_SUCCESS: '@items/IVR_CLONE_ITEMS_SUCCESS',
  IVR_CLONE_ITEMS_FAILURE: '@items/IVR_CLONE_ITEMS_FAILURE',
  CREATE_ITEM_EXAMPLE_SUCCESS: '@items/CREATE_ITEM_EXAMPLE_SUCCESS',
  UPDATE_ITEM: '@items/UPDATE_ITEM',
  UPDATE_ITEM_SUCCESS: '@items/UPDATE_ITEM_SUCCESS',
  UPDATE_ITEMS: '@items/UPDATE_ITEMS',
  UPDATE_ITEMS_SUCCESS: '@items/UPDATE_ITEMS_SUCCESS',
  DELETE_ITEM: '@items/DELETE_ITEM',
  DELETE_ITEM_SUCCESS: '@items/DELETE_ITEM_SUCCESS',
  UPLOAD_CARD_IMAGE: '@items/UPLOAD_CARD_IMAGE',
  UPLOAD_ITEM_FILE: '@items/UPLOAD_ITEM_FILE',
  REMOVE_ITEM_FILE: '@items/REMOVE_ITEM_FILE',
  DOWNLOAD_ITEM_FILE: '@items/DOWNLOAD_ITEM_FILE',
  RETURN_ITEM: '@items/RETURN_ITEM',
  RETURN_ITEM_SUCCESS: '@items/RETURN_ITEM_SUCCESS',
  CREATE_CHATGPT_RESPONSES: '@bots/CREATE_CHATGPT_RESPONSES',
  FETCH_ITEMS_SUMMARY: '@items/FETCH_ITEMS_SUMMARY',
  FETCH_ITEMS_SUMMARY_SUCCESS: '@items/FETCH_ITEMS_SUMMARY_SUCCESS',
  FETCH_ITEMS_SUMMARY_FAILURE: '@items/FETCH_ITEMS_SUMMARY_FAILURE',
  FETCH_RESUMED_ALL_ITEMS: '@items/FETCH_RESUMED_ALL_ITEMS',
  FETCH_RESUMED_ALL_ITEMS_SUCCESS: '@items/FETCH_RESUMED_ALL_ITEMS_SUCCESS',
};

export default types;
