import React from 'react';
import PropTypes from 'prop-types';

import { ModalTitle } from './styles';

const ModalTitleComponent = ({ children, ...rest }) => {
  return <ModalTitle {...rest}>{children}</ModalTitle>;
};

ModalTitleComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalTitleComponent;
