import types from './types';

export const checkEmailRequest = ({ email, setErrorMessage }) => ({
  type: types.CHECK_EMAIL_REQUEST,
  payload: { email, setErrorMessage },
});
export const checkEmailSuccess = ({ isValid }) => ({
  type: types.CHECK_EMAIL_SUCCESS,
  payload: { isValid },
});
export const checkEmailFailure = ({ isValid }) => ({
  type: types.CHECK_EMAIL_FAILURE,
  payload: { isValid },
});
export const resetCheckEmail = () => ({
  type: types.RESET_CHECK_EMAIL,
});

export const resendEmailConfirmation = ({ email }) => ({
  type: types.RESEND_EMAIL_CONFIRMATION_REQUEST,
  payload: { email },
});

export const signInRequest = (
  { email, password, tokenReCaptcha },
  callback
) => ({
  type: types.SIGNIN_REQUEST,
  payload: { email, password, tokenReCaptcha, callback },
});
export const signInSuccess = ({ token, loggedUser }) => ({
  type: types.SIGNIN_SUCCESS,
  payload: { token, loggedUser },
});
export const signFailure = () => ({
  type: types.SIGNIN_FAILURE,
});

export const updateLoggedUserSelectedCompany = (selectedCompany) => ({
  type: types.UPDATE_LOGGED_USER_SELECTED_COMPANY,
  payload: selectedCompany,
});

export const updateSelectedCompanyOrganizationRequest = ({
  companyId,
  organizationId,
  isRootUser,
}) => ({
  type: types.UPDATE_SELECTED_COMPANY_ORGANIZATION_REQUEST,
  payload: { companyId, organizationId, isRootUser },
});

export const updateSelectedCompanyOrganizationSuccess = ({
  token,
  loggedUser,
}) => ({
  type: types.UPDATE_SELECTED_COMPANY_ORGANIZATION_SUCCESS,
  payload: { token, loggedUser },
});

export const updateSelectOrganization = (organizationId) => ({
  type: types.UPDATE_SELECT_ORGANIZATION_REQUEST,
  payload: {
    selectedOrganization: organizationId,
  },
});

export const updateSelectOrganizationSuccess = ({ token, loggedUser }) => ({
  type: types.UPDATE_SELECT_ORGANIZATION_SUCCESS,
  payload: { token, loggedUser },
});

export const signUpRequest = ({ user, tokenReCaptcha }, callback) => ({
  type: types.SIGNUP_REQUEST,
  payload: { user, tokenReCaptcha, callback },
});

export const signUpSuccess = () => ({
  type: types.SIGNUP_SUCCESS,
});

export const signUpFailure = () => ({
  type: types.SIGNUP_FAILURE,
});

export const confirmRequest = ({ email, token }) => ({
  type: types.CONFIRM_REQUEST,
  payload: { email, token },
});
export const confirmSuccess = () => ({
  type: types.CONFIRM_SUCCESS,
});
export const confirmFailure = () => ({
  type: types.CONFIRM_FAILURE,
});

export const recoverEmailRequest = ({ phone, tokenReCaptcha }) => ({
  type: types.RECOVER_EMAIL_REQUEST,
  payload: { phone, tokenReCaptcha },
});
export const recoverEmailSuccess = () => ({
  type: types.RECOVER_EMAIL_SUCCESS,
});
export const recoverEmailFailure = () => ({
  type: types.RECOVER_EMAIL_FAILURE,
});

export const recoverPasswordRequest = ({ email, tokenReCaptcha }) => ({
  type: types.RECOVER_PASSWORD_REQUEST,
  payload: { email, tokenReCaptcha },
});
export const recoverPasswordSuccess = () => ({
  type: types.RECOVER_PASSWORD_SUCCESS,
});
export const recoverPasswordFailure = () => ({
  type: types.RECOVER_PASSWORD_FAILURE,
});

export const resetPasswordRequest = (payload) => ({
  type: types.RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = () => ({
  type: types.RESET_PASSWORD_FAILURE,
});

export const createPassword = (payload) => ({
  type: types.CREATE_PASSWORD,
  payload,
});

export const signOut = () => ({
  type: types.SIGNOUT,
});

export const tfaSetup = (payload) => ({
  type: types.TFA_SETUP,
  payload,
});

export const tfaSetupSuccess = (payload) => ({
  type: types.TFA_SETUP_SUCCESS,
  payload,
});

export const tfaActive = ({ userId, token }, callback) => ({
  type: types.TFA_ACTIVE,
  payload: {
    userId,
    token,
    callback,
  },
});

export const tfaActiveSuccess = ({ user }) => ({
  type: types.TFA_ACTIVE_SUCCESS,
  payload: {
    user,
  },
});

export const tfaVerify = ({ userId, tfaToken }, callback) => ({
  type: types.TFA_VERIFY,
  payload: {
    userId,
    tfaToken,
    callback,
  },
});

export const tfaVerifySuccess = ({ token, loggedUser }) => ({
  type: types.TFA_VERIFY_SUCCESS,
  payload: { token, loggedUser },
});

export const tfaDisable = () => ({ type: types.TFA_DISABLE });

export const tfaDisableSuccess = () => ({ type: types.TFA_DISABLE_SUCCESS });

export const notice = (user, notices) => ({
  type: types.AUTH_NOTICE_REQUEST,
  payload: {
    user,
    notice: notices,
  },
});

export const noticeSuccess = (lastNotice) => ({
  type: types.LAST_NOTICE,
  payload: {
    lastNotice,
  },
});

export const updateUserProfile = ({ loggedUser }, callback) => ({
  type: types.UPDATE_USER_PROFILE_REQUEST,
  payload: { loggedUser, callback },
});

export const updatePasswordUserProfile = ({ data }) => ({
  type: types.UPDATE_USER_PASSWORD_REQUEST,
  payload: { data },
});

export const updateUserProfileSuccess = ({ loggedUser }) => ({
  type: types.UPDATE_USER_PROFILE_SUCCESS,
  payload: { loggedUser },
});

export const fetchLoggeduser = ({ id }) => ({
  type: types.FETCH_LOGGEDUSER,
  payload: { id },
});

export const fetchLoggeduserSuccess = ({ loggedUser }) => ({
  type: types.FETCH_LOGGEDUSER_SUCCESS,
  payload: { loggedUser },
});
