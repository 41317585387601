import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createBotRequest,
  setOpenedModalBot,
} from '~/store/modules/bots/actions';
import ModalCreateBot from './ModalCreateBot';
import history from '~/services/history';

const ModalCreateBotContainer = ({
  isOpen,
  setIsOpen,
  extraContent,
  templateId,
  fromBotId,
}) => {
  const dispatch = useDispatch();
  const { openedModal } = useSelector((state) => state.bots);
  const { isLoading } = useSelector((state) => state.common);
  const { selectedCompany, selectedOrganization } = useSelector(
    (state) => state.auth.loggedUser
  );

  const createBot = (payload) => {
    const pushBot = (botId) => history.push(`/bots/${botId}/abstract`);
    dispatch(createBotRequest(payload, pushBot));
  };

  const onClose = () => {
    dispatch(setOpenedModalBot(''));
    setIsOpen?.(false);
  };

  const props = {
    isOpen: openedModal === 'create' || isOpen,
    setIsOpen,
    extraContent,
    templateId,
    fromBotId,
    createBot,
    isLoading,
    selectedCompany,
    selectedOrganization,
    setOpenedModalBot: onClose,
  };

  return <ModalCreateBot {...props} />;
};

export default ModalCreateBotContainer;
