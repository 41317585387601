import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  commonNetworkOffline,
  commonNetworkOnline,
} from '~/store/modules/common/actions';

function useNetworkVerification() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('online', () => {
      dispatch(commonNetworkOnline());
    });
    window.addEventListener('offline', () => {
      dispatch(commonNetworkOffline());
    });
  }, []);

  return null;
}

export default useNetworkVerification;
