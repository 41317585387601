import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from '../../../components/CustomToast';
import i18n from '../../../translations/i18n';

import api from '~/services/api';
import types from './types';

import { fetchOrganizations } from '../company/actions';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';

export function* onCodeExpires(error) {
  const { companyId } = error?.response?.data?.organization;

  yield put(fetchOrganizations({ companyId }));

  toast.warn(
    i18n.t('bots.integrations.rd_station.warning'),
    i18n.t('bots.integrations.rd_station.session_expire')
  );
}

export function* generateAccessToken({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, code } = payload;
    const url = `v3/bots/${botId}/rd-station/auth`;

    const { data } = yield call(api.post, url, { code });

    yield put(actions.generateAccessTokenSuccess(data));
    toast.success(
      i18n.t('success.success'),
      i18n.t('bots.integrations.rd_station.new_token')
    );
  } catch (error) {
    if (error?.response?.data?.expiredCode) {
      yield onCodeExpires(error);
    } else {
      toast.error(
        i18n.t('error.error'),
        i18n.t('bots.integrations.rd_station.error_new_token')
      );
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteAccessToken({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;
    const url = `v3/bots/${botId}/rd-station/auth`;

    yield call(api.delete, url);

    yield put(actions.generateAccessTokenSuccess(null));
    toast.success(
      i18n.t('success.success'),
      i18n.t('bots.integrations.rd_station.remove_token_success')
    );
  } catch (error) {
    console.error(error);
    toast.error(
      i18n.t('error.error'),
      i18n.t('bots.integrations.rd_station.error_remove_token')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getFields({ payload }) {
  try {
    const { botId } = payload;
    const url = `v3/bots/${botId}/rd-station/fields`;
    const { data } = yield call(api.get, url);

    yield put(actions.getFieldsSuccess(data));
  } catch (error) {
    console.error(error);

    yield put(actions.getFieldsSuccess([]));

    if (error?.response?.data?.expiredCode) {
      yield onCodeExpires(error);
    } else {
      toast.error(
        i18n.t('error.error'),
        i18n.t('bots.integrations.rd_station.error_get_fields')
      );
    }
  }
}

export default all([
  takeLatest(types.GET_FIELDS_REQUEST, getFields),
  takeLatest(types.GENERATE_ACCESS_TOKEN_REQUEST, generateAccessToken),
  takeLatest(types.REMOVE_ACCESS_TOKEN_REQUEST, deleteAccessToken),
  takeLatest(types.RESET_AUTH_DATA, onCodeExpires),
]);
