import axios from 'axios';

const getDynamicIdentifier = (data) => {
  if (data?._id) return data?._id;
  if (data?.itemIds && data?.itemIds.length > 0) return data?.itemIds[0];
  if (data?.lastUpdate) return new Date(data?.lastUpdate);
};

const apiInterceptorDuplicated = {
  request: function interceptorRequest(config, pendingRequests) {
    // generate an identifier for each request
    const { data } = config;
    const requestIdentifier = `${config.url}_${
      config.method
    }_${getDynamicIdentifier(data)}`;
    // check if there is already a pending request with the same identifier
    if (pendingRequests.has(requestIdentifier)) {
      const cancelTokenSource = pendingRequests.get(requestIdentifier);
      // cancel the previous request
      cancelTokenSource.cancel('Cancelled due to new request');
    }

    // create a new CancelToken
    const newCancelTokenSource = axios.CancelToken.source();
    config.cancelToken = newCancelTokenSource.token;

    // store the new cancel token source in the map
    pendingRequests.set(requestIdentifier, newCancelTokenSource);
    return config;
  },
  response: function interceptorResponse(response, pendingRequests) {
    const { data } = response.config;
    const dataFormatted =
      typeof data === 'object' ? data : JSON.parse(data || null);
    const requestIdentifier = `${response.config.url}_${
      response.config.method
    }_${getDynamicIdentifier(dataFormatted)}`;
    pendingRequests.delete(requestIdentifier);
  },
};

export { apiInterceptorDuplicated };
