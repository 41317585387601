import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

function CustomRoute({
  component: Component,
  layout: Layout,
  isPrivate,
  auth,
  ...rest
}) {
  const { token, loggedUser } = useSelector((state) => state.auth);
  const location = useLocation();
  const history = useHistory();

  if (!token && isPrivate) {
    history.replace('/');
    return null;
  }

  if (token && isPrivate) {
    if (
      !loggedUser?.selectedCompany &&
      location.pathname !== '/select-company-organization'
    ) {
      history.replace('/');
      return null;
    }
  }

  if (token && !isPrivate && !loggedUser?.selectedCompany) {
    if (loggedUser?.isRootUser || loggedUser?.companies?.length > 1) {
      return <Redirect to="/select-company-organization" />;
    }
  }

  if (
    token &&
    isPrivate &&
    auth.length &&
    loggedUser.selectedCompany &&
    loggedUser.selectedCompany.claim &&
    !auth.includes(loggedUser?.selectedCompany?.claim?.name?.toLowerCase())
  ) {
    history.replace('/');
    return null;
  }

  const renderLayout = (props) =>
    Layout ? (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    ) : (
      <Component {...props} />
    );

  return <Route {...rest} render={renderLayout} />;
}

CustomRoute.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.any]).isRequired,
  layout: PropTypes.oneOfType([PropTypes.any]),
  auth: PropTypes.oneOfType([PropTypes.array]),
};

CustomRoute.defaultProps = {
  isPrivate: false,
  auth: [],
  layout: null,
};

export default CustomRoute;
