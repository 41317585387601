import React from 'react';
import PropTypes from 'prop-types';

import { ModalFooterWrapper } from './styles';

const ModalFooter = ({ children, ...rest }) => {
  return <ModalFooterWrapper {...rest}>{children}</ModalFooterWrapper>;
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalFooter;
