import { takeLatest, put, all, call } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';
import { downloadURL } from '~/utils/downloadFile';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';

import * as actions from './actions';
import types from './types';

export function* fetchIvrReportTemplates({ payload }) {
  try {
    const { botId } = payload;
    const { data } = yield call(api.get, `v3/ivr/reports/templates`, {
      params: { botId },
    });

    yield put(actions.fetchIvrReportTemplatesSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_listing_templates')
    );
    yield put(actions.fetchIvrReportTemplatesFailure());
  }
}

export function* searchIvrReportTemplates({ payload }) {
  try {
    const { botId, page, limit, search } = payload;
    const { data } = yield call(api.get, `v3/ivr/reports/templates/search`, {
      params: { botId, page, limit, search },
    });

    yield put(actions.searchIvrReportTemplatesSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_listing_templates')
    );
    yield put(actions.searchIvrReportTemplatesFailure());
  }
}

export function* createIvrReportTemplate({ payload }) {
  try {
    const { data, callback } = payload;

    const response = yield call(api.post, `v3/ivr/reports/templates`, data);

    yield put(actions.createIvrReportTemplateSuccess(response.data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_creating_template')
    );
    yield put(actions.createIvrReportTemplateFailure());
  }
}

export function* updateIvrReportTemplate({ payload }) {
  try {
    const { data, callback } = payload;

    const response = yield call(api.put, `v3/ivr/reports/templates`, data);

    yield put(actions.updateIvrReportTemplateSuccess(response.data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_updating_the_template')
    );
    yield put(actions.updateIvrReportTemplateFailure());
  }
}

export function* cloneIvrReportTemplate({ payload }) {
  try {
    const { _id, targetBotId, callback } = payload;

    const response = yield call(api.post, `v3/ivr/reports/templates/clone`, {
      _id,
      targetBotId,
    });

    yield put(actions.cloneIvrReportTemplateSuccess(response.data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_cloning_the_template')
    );
    yield put(actions.cloneIvrReportTemplateFailure());
  }
}

export function* deleteIvrReportTemplate({ payload }) {
  try {
    const { _id, callback } = payload;

    const response = yield call(api.delete, `v3/ivr/reports/templates/${_id}`);

    yield put(actions.deleteIvrReportTemplateSuccess(response.data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_deleting_the_template')
    );
    yield put(actions.deleteIvrReportTemplateFailure());
  }
}

export function* searchIvrReportCalls({ payload }) {
  try {
    const { page, limit, searchData } = payload;
    const { data } = yield call(
      api.post,
      `v3/ivr/reports/calls/search?page=${page}&limit=${limit}`,
      searchData,
      { timeout: 30000 }
    );

    yield put(actions.searchIvrReportCallsSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_searching_call_report')
    );
    yield put(actions.searchIvrReportCallsFailure());
  }
}

export function* generateIvrReportCalls({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { data, callback } = payload;

    const response = yield call(
      api.post,
      `v3/ivr/reports/calls/generate`,
      data
    );

    yield put(actions.generateIvrReportCallsSuccess(response.data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_there_was_an_error_while_trying_to_produce_report')
    );
    yield put(actions.generateIvrReportCallsFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* searchIvrReports({ payload }) {
  try {
    const { data } = yield call(api.get, `v3/ivr/reports/search`, {
      params: payload,
    });

    yield put(actions.searchIvrReportsSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_searching_reports')
    );
    yield put(actions.searchIvrReportsFailure());
  }
}

export function* deleteIvrReport({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { id, callback } = payload;
    const { data } = yield call(api.delete, `v3/ivr/reports/${id}`);

    yield put(actions.deleteIvrReportSuccess(data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_deleting_report')
    );
    yield put(actions.deleteIvrReportFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* downloadIvrReport({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { id, callback } = payload;
    const { data } = yield call(api.post, `v3/ivr/reports/${id}/download`);

    const { url } = data;

    downloadURL(url, `${id}.csv`);

    yield put(actions.downloadIvrReportSuccess(data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_download_report')
    );
    yield put(actions.downloadIvrReportFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* searchIvrReportDashbaord({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { filters } = payload;

    const { data } = yield call(api.post, `v3/ivr/reports/dashboard`, {
      filters,
    });

    yield put(actions.searchIvrReportDashboardSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_fetching_the_ivr_report')
    );
    yield put(actions.searchIvrReportDashboardFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* searchIvrReportErrors({ payload }) {
  try {
    const params = payload;
    const { data } = yield call(api.get, `v3/ivr/reports/errors/search`, {
      params,
    });

    yield put(actions.searchIvrReportErrorsSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_fetching_the_ivr_report')
    );
    yield put(actions.searchIvrReportErrorsFailure());
  }
}

export default all([
  takeLatest(types.FETCH_IVR_REPORT_TEMPLATES, fetchIvrReportTemplates),
  takeLatest(types.SEARCH_IVR_REPORT_TEMPLATES, searchIvrReportTemplates),
  takeLatest(types.CREATE_IVR_REPORT_TEMPLATE, createIvrReportTemplate),
  takeLatest(types.UPDATE_IVR_REPORT_TEMPLATE, updateIvrReportTemplate),
  takeLatest(types.CLONE_IVR_REPORT_TEMPLATE, cloneIvrReportTemplate),
  takeLatest(types.DELETE_IVR_REPORT_TEMPLATE, deleteIvrReportTemplate),
  takeLatest(types.SEARCH_IVR_REPORT_CALLS, searchIvrReportCalls),
  takeLatest(types.GENERATE_IVR_REPORT_CALLS, generateIvrReportCalls),
  takeLatest(types.SEARCH_IVR_REPORTS, searchIvrReports),
  takeLatest(types.DELETE_IVR_REPORT, deleteIvrReport),
  takeLatest(types.DOWNLOAD_IVR_REPORT, downloadIvrReport),
  takeLatest(types.SEARCH_IVR_REPORT_DASHBOARD, searchIvrReportDashbaord),
  takeLatest(types.SEARCH_IVR_REPORT_ERRORS, searchIvrReportErrors),
]);
