import { takeLatest, put, all, call } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import * as actions from './actions';
import types from './types';

export function* fetchIvrTelephoneOperators() {
  try {
    const { data } = yield call(api.get, '/v3/ivr/telephone-operator');

    yield put(actions.fetchIvrTelephoneOperatorsSuccess(data));
  } catch (e) {
    yield put(actions.fetchIvrTelephoneOperatorsFailure());
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_fetch_ivr_telephone_operators')
    );
  }
}

export function* createIvrTelephoneOperator({ payload }) {
  try {
    const { data, callback } = payload;
    const response = yield call(api.post, '/v3/ivr/telephone-operator', data);

    yield put(actions.createIvrTelephoneOperatorSuccess(response.data));

    callback?.();
  } catch (e) {
    yield put(actions.createIvrTelephoneOperatorFailure());
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_create_telephone_operator')
    );
  }
}

export function* updateIvrTelephoneOperator({ payload }) {
  try {
    const { data, callback } = payload;
    const response = yield call(
      api.put,
      `/v3/ivr/telephone-operator/${data._id}`,
      data
    );

    yield put(actions.updateIvrTelephoneOperatorSuccess(response.data));

    callback?.();
  } catch (e) {
    yield put(actions.updateIvrTelephoneOperatorFailure());
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_update_telephone_operator')
    );
  }
}

export function* deleteIvrTelephoneOperator({ payload }) {
  try {
    const { _id } = payload;
    yield call(api.delete, `/v3/ivr/telephone-operator/${_id}`);

    yield put(actions.deleteIvrTelephoneOperatorSuccess({ _id }));
  } catch (e) {
    yield put(actions.deleteIvrTelephoneOperatorFailure());
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_remove_telephone_operator')
    );
  }
}

export default all([
  takeLatest(types.FETCH_IVR_TELEPHONE_OPERATORS, fetchIvrTelephoneOperators),
  takeLatest(types.CREATE_IVR_TELEPHONE_OPERATOR, createIvrTelephoneOperator),
  takeLatest(types.UPDATE_IVR_TELEPHONE_OPERATOR, updateIvrTelephoneOperator),
  takeLatest(types.DELETE_IVR_TELEPHONE_OPERATOR, deleteIvrTelephoneOperator),
]);
