import types from './types';

export const getTemplatesRequest = () => ({
  type: types.GET_TEMPLATES_REQUEST,
});

export const getTemplatesSuccess = (payload) => ({
  type: types.GET_TEMPLATES_SUCCESS,
  payload,
});

export const createTemplateBot = (payload) => ({
  type: types.CREATE_TEMPLATE_BOT,
  payload,
});
export const createTemplateBotsuccess = (payload) => ({
  type: types.CREATE_TEMPLATE_BOT_SUCCESS,
  payload,
});

export const updateTemplateBot = (payload) => ({
  type: types.UPDATE_TEMPLATE_BOT,
  payload,
});
export const updateTemplateBotSuccess = (payload) => ({
  type: types.UPDATE_TEMPLATE_BOT_SUCCESS,
  payload,
});

export const updateTemplateBotFlow = (payload) => ({
  type: types.UPDATE_TEMPLATE_BOT_FLOW,
  payload,
});
export const updateTemplateBotFlowSuccess = (payload) => ({
  type: types.UPDATE_TEMPLATE_BOT_FLOW_SUCCESS,
  payload,
});

export const getTemplateBot = (botId) => ({
  type: types.GET_TEMPLATE_BOT,
  payload: { botId },
});

export const getTemplateBotSuccess = (payload) => ({
  type: types.GET_TEMPLATE_BOT_SUCCESS,
  payload,
});

export const getTemplateIdRequest = (templateId) => ({
  type: types.GET_TEMPLATE_ID_REQUEST,
  payload: { templateId },
});

export const getTemplateIdSuccess = (payload) => ({
  type: types.GET_TEMPLATE_ID_SUCCESS,
  payload,
});
