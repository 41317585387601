import { takeLatest, put, all, call, select } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';

import * as actions from './actions';
import types from './types';

export function* listVariables({ payload }) {
  const { botId, callback } = payload;
  callback?.(true);
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.get, `/v3/bots/variable/${botId}`);

    yield put(actions.listVariablesSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_variables'));
  } finally {
    callback?.(false);
    yield put(commonLoadingFinish());
  }
}

export function* createVariable({ payload }) {
  const { callback } = payload;
  try {
    yield put(commonLoadingStart());

    const { bot, global } = yield select((state) => state.variables);
    const { _id: botId } = yield select((state) => state.bots.bot);

    const url = '/v3/bots/variable';
    const { data } = yield call(api.post, url, payload.values, {
      clearCacheEntry: [`${url}/${botId}`],
    });

    const newVariables = {
      bot: [...bot, data],
      global,
    };

    callback?.();
    yield put(actions.createVariableSuccess(newVariables));
    toast.success(i18n.t('success.success_create_variable'));
  } catch (error) {
    if (
      error.response?.data?.statusCode === 409 &&
      error.response?.data?.message === 'variable already exists'
    ) {
      toast.error(i18n.t('error.oops'), i18n.t('error.error_variable_exists'));
    } else {
      const message =
        error.response?.data?.message || i18n.t('error.error_create_variable');
      toast.error(i18n.t('error.oops'), message);
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* editVariable({ payload }) {
  const { callback, _id, botId } = payload;

  try {
    yield put(commonLoadingStart());

    const url = '/v3/bots/variable';
    const { data } = yield call(api.put, `${url}/${_id}`, payload, {
      clearCacheEntry: [`/variable/${botId}`],
    });

    callback?.();
    yield put(actions.editVariableSuccess(data));
    toast.success(i18n.t('success.success_update_variable'));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_update_variable'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* removeVariable({ payload }) {
  const { id, botId } = payload;
  try {
    yield put(commonLoadingStart());

    const url = '/v3/bots/variable';
    yield call(api.delete, `${url}/${id}`, {
      clearCacheEntry: [`/variable/${botId}`],
    });

    yield put(actions.removeVariableSuccess({ id }));
    toast.success(i18n.t('success.success_delete_variable'));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_delete_variable'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.LIST_VARIABLES, listVariables),
  takeLatest(types.CREATE_VARIABLE, createVariable),
  takeLatest(types.EDIT_VARIABLE, editVariable),
  takeLatest(types.REMOVE_VARIABLE, removeVariable),
]);
