import { takeLatest, all, call, put } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import * as actions from './actions';
import types from './types';

export function* listIvrFunctions() {
  try {
    const { data } = yield call(api.get, `/v3/ivr/functions`);

    yield put(actions.listIvrFunctionsSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_functions'));
  }
}

export default all([takeLatest(types.LIST_IVR_FUNCTIONS, listIvrFunctions)]);
