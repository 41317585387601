const Types = {
  GET_TEMPLATES_REQUEST: '@templates/GET_TEMPLATES_REQUEST',
  GET_TEMPLATES_SUCCESS: '@templates/GET_TEMPLATES_SUCCESS',

  CREATE_TEMPLATE_BOT: '@templates/CREATE_TEMPLATE_BOT',
  CREATE_TEMPLATE_BOT_SUCCESS: '@templates/CREATE_TEMPLATE_BOT_SUCCESS',

  UPDATE_TEMPLATE_BOT: 'template-bot/UPDATE_TEMPLATE_BOT',
  UPDATE_TEMPLATE_BOT_SUCCESS: '@templates/UPDATE_TEMPLATE_BOT_SUCCESS',

  UPDATE_TEMPLATE_BOT_FLOW: '@templates/UPDATE_TEMPLATE_BOT_FLOW',
  UPDATE_TEMPLATE_BOT_FLOW_SUCCESS:
    '@templates/UPDATE_TEMPLATE_BOT_FLOW_SUCCESS',

  GET_TEMPLATE_BOT: '@templates/GET_TEMPLATE_BOT',
  GET_TEMPLATE_BOT_SUCCESS: '@templates/GET_TEMPLATE_BOT_SUCCESS',

  GET_TEMPLATE_ID_REQUEST: '@templates/GET_TEMPLATES_ID_REQUEST',
  GET_TEMPLATE_ID_SUCCESS: '@templates/GET_TEMPLATES_ID_SUCCESS',
};

export default Types;
