import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
  endDate: new Date(),
  vlSelect: '1',
  dateFilter: new Date(),
  tags: [],
  nameTags: [],
  channels: [],
  variables: [],
  users: [],
  actions: [],
  inputValue: '',
  ErrorsLogIndicators: [],
  sessionPhoneNumber: '',
  orgIndicators: [],
  favoriteOption: null,
};

export default function filter(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.LIST_FILTER_INFO_SUCCESS: {
        draft.startDate = action.payload.startDate;
        draft.endDate = action.payload.endDate;
        draft.vlSelect = action.payload.vlSelect;
        draft.tags = action.payload.tags;
        draft.nameTags = action.payload.nameTags;
        draft.channels = action.payload.channels;
        draft.variables = action.payload.variables;
        draft.users = action.payload.users;
        draft.actions = action.payload.actions;
        draft.inputValue = action.payload.inputValue;
        draft.sessionPhoneNumber = action.payload.sessionPhoneNumber;
        draft.ErrorsLogIndicators = action.payload.ErrorsLogIndicators;
        draft.orgIndicators = action.payload.orgIndicators;
        draft.favoriteOption = action.payload.favoriteOption;
        break;
      }

      case types.FILTER_REPORT_INFO_SUCCESS: {
        draft.dateFilter = action.payload.dateFilter;
        break;
      }

      case types.FILTER_ORGS_INFO_SUCCESS: {
        draft.orgIndicators = action.selectedOptions;
        break;
      }

      default:
        return state;
    }
  });
}
