import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  users: [],
  user: {},
};

export default function users(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_USERS_SUCCESS:
        draft.users = action.payload.users;
        break;

      case types.FETCH_USER_SUCCESS:
        draft.user = action.payload.user;
        break;

      case types.CREATE_USER_SUCCESS:
        draft.users = [...state.users, action.payload.user];
        break;

      case types.UPDATE_USER_SUCCESS: {
        const updatedUser = action.payload.user;
        const updatedUsers = state.users?.map((user) =>
          user._id === updatedUser?._id ? updatedUser : user
        );
        draft.users = updatedUsers;
        break;
      }

      default:
    }
  });
}
