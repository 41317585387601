import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import { toast } from '~/components/CustomToast';
import i18n from '~/translations/i18n';

import {
  commonLoadingStart,
  commonLoadingFinish,
  commonChangingFinish,
} from '../common/actions';
import { updateBotLastUpdateSuccess } from '../bots/actions';
import * as actions from './actions';
import types from './types';

export function* loadTriggers(action) {
  const { botId } = action.payload;

  try {
    yield put(commonLoadingStart());

    const response = yield call(api.get, `/v3/bots/escape/list/${botId}`);
    yield put(actions.loadTriggersSucces(response.data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_load_triggers'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* saveBotTriggers(action) {
  const { botId, triggers, maxTimeWithoutResponse } = action.payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.put,
      `/v3/bots/escape/update/${botId}`,
      {
        triggers,
        maxTimeWithoutResponse,
      },
      {
        clearCacheEntry: [`/items`],
      }
    );

    yield put(updateBotLastUpdateSuccess(data));

    const toastMessage = triggers
      ? i18n.t('success.success_save_bot_triggers')
      : i18n.t('success.success_save_bot_maxtime');

    toast.success(i18n.t('success.success'), toastMessage);
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_save_bot_triggers'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.LOAD, loadTriggers),
  takeLatest(types.SAVE_BOT_TRIGGERS, saveBotTriggers),
]);
