import types from './types';

export const fetchProjects = ({ params }) => ({
  type: types.FETCH_PROJECTS_REQUEST,
  payload: { params },
});

export const fetchProjectsSuccess = ({ projects }) => ({
  type: types.FETCH_PROJECTS_SUCCESS,
  payload: { projects },
});

export const fetchGrammars = ({ params }) => ({
  type: types.FETCH_GRAMMARS_REQUEST,
  payload: { params },
});
export const fetchGrammarsSuccess = ({ grammars }) => ({
  type: types.FETCH_GRAMMARS_SUCCESS,
  payload: { grammars },
});

export const fetchStatus = ({ params }) => ({
  type: types.FETCH_STATUS_REQUEST,
  payload: { params },
});
export const fetchStatusSuccess = ({ status }) => ({
  type: types.FETCH_STATUS_SUCCESS,
  payload: { status },
});

export const fetchVoices = ({ params }) => ({
  type: types.FETCH_VOICES_REQUEST,
  payload: { params },
});
export const fetchVoicesSuccess = ({ voices }) => ({
  type: types.FETCH_VOICES_SUCCESS,
  payload: { voices },
});
export const fetchVoicesFailiure = () => ({
  type: types.FETCH_VOICES_FAILIURE,
});

export const fetchVoicebotInstances = ({ organizationId }) => ({
  type: types.FETCH_VOICEBOT_INSTANCES_REQUEST,
  payload: { organizationId },
});
export const fetchVoicebotInstancesSuccess = ({ instances }) => ({
  type: types.FETCH_VOICEBOT_INSTANCES_SUCCESS,
  payload: { instances },
});
export const fetchVoicebotInstancesFailiure = () => ({
  type: types.FETCH_VOICEBOT_INSTANCES_FAILIURE,
});

export const createVoicebotInstance = (instance, callback) => ({
  type: types.CREATE_VOICEBOT_INSTANCE_REQUEST,
  payload: { instance, callback },
});
export const createVoicebotInstanceSuccess = (instance) => ({
  type: types.CREATE_VOICEBOT_INSTANCE_SUCCESS,
  payload: { instance },
});

export const updateVoicebotInstance = (instance, callback) => ({
  type: types.UPDATE_VOICEBOT_INSTANCE_REQUEST,
  payload: { instance, callback },
});
export const updateVoicebotInstanceSuccess = (instance) => ({
  type: types.UPDATE_VOICEBOT_INSTANCE_SUCCESS,
  payload: { instance },
});

export const fetchVoicebotVariables = ({ params }) => ({
  type: types.FETCH_VARIABLES_REQUEST,
  payload: { params },
});
export const fetchVoicebotVariablesSuccess = ({ variables }) => ({
  type: types.FETCH_VARIABLES_SUCCESS,
  payload: { variables },
});
