import { takeLatest, call, put, all } from 'redux-saga/effects';

import i18n from '../../../translations/i18n';
import api from '~/services/api';

import { toast } from '../../../components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';
import types from './types';
import { formatWhatsappConsumptionDataToGraphic } from './utils';

export function* getPlansRequest() {
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.get, `v3/plans`);
    yield put(actions.getPlansRequestSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_plans'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getHistoryCompanyPlanRequest({ payload }) {
  const { companyId } = payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.get, `v3/plans/history/${companyId}`);
    yield put(actions.getHistoryCompanyPlanRequestSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_history_plan'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* putAlerts({ payload }) {
  const { companyId, alertsA } = payload;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.put,
      `/v3/companies/plans/alerts/${companyId}`,
      { alerts: alertsA }
    );
    yield put(actions.putAlertsSuccess(data));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_update_alert')
    );
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_update_alert'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getCompanyPlanConsumption({ payload }) {
  try {
    yield put(commonLoadingStart());

    const params = {
      date: payload.date,
      company: payload.companyId,
      timezone: payload.timezone,
      isFreemium: payload.isFreemium || false,
      isReport: payload.isReport || false,
      orgIndicators: payload.orgIndicators,
    };

    const response = yield call(
      api.get,
      `v3/companies/plans/consumption/bots`,
      { params }
    );

    const consumptionByBot = response?.data?.whatsappConsumptionByBot || [];
    const { data, keys } =
      formatWhatsappConsumptionDataToGraphic(consumptionByBot);

    yield put(
      actions.getCompanyPlanConsumptionSuccess({
        graphicData: { data, keys },
        ...response.data,
      })
    );
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_getting_plan_consumption')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.GET_PLANS_REQUEST, getPlansRequest),
  takeLatest(
    types.GET_HISTORY_COMPANY_PLAN_REQUEST,
    getHistoryCompanyPlanRequest
  ),
  takeLatest(types.PUT_ALERTS, putAlerts),
  takeLatest(types.GET_COMPANY_PLAN_CONSUMPTION, getCompanyPlanConsumption),
]);
