import { takeLatest, put, all, call, select } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';

import * as actions from './actions';
import types from './types';

export function* listTags({ payload }) {
  const { botId } = payload;
  try {
    const { data } = yield call(api.get, `/v3/ivr/${botId}/tag`);

    yield put(actions.listIvrTagsSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_listing_tags'));
  }
}

export function* createTag({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, values, callback } = payload;

    const { data } = yield call(api.post, `/v3/ivr/${botId}/tag`, values);
    const tags = yield select((state) => state.ivrTags);

    const newTags = {
      bot: [...tags.bot, ...data],
      global: tags.global,
    };

    callback?.();
    yield put(actions.createIvrTagSuccess(newTags));
  } catch (error) {
    const message =
      error.response?.data?.message || i18n.t('error.error_create_tag');
    toast.error(i18n.t('error.oops'), message);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* editTag({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.put, `/v3/ivr/tag/${payload._id}`, payload);

    yield put(actions.editIvrTagSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_update_tag'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* removeTag(action) {
  const { payload } = action;
  try {
    yield put(commonLoadingStart());

    yield call(api.delete, `/v3/ivr/tag/${payload.id}`);

    const tags = yield select((state) => state.ivrTags);
    const botTags = tags.bot.filter((tag) => tag._id !== payload.id);
    const updatedTags = {
      bot: botTags,
      global: tags.global,
    };

    yield put(actions.removeIvrTagSuccess(updatedTags));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_delete_tag'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.LIST_TAGS, listTags),
  takeLatest(types.CREATE_TAG, createTag),
  takeLatest(types.EDIT_TAG, editTag),
  takeLatest(types.REMOVE_TAG, removeTag),
]);
