import { takeLatest, put, all, call } from 'redux-saga/effects';

import api from '~/services/api';
import i18n from '~/translations/i18n';

import { toast } from '~/components/CustomToast';

import * as actions from './actions';
import types from './types';

export function* fetchIvrBedrockPromptTemplates() {
  try {
    const { data } = yield call(api.get, `/v3/ivr/bedrock/prompts/templates`);

    yield put(actions.fetchIvrBedrockPromptTemplatesSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_templates'));
    yield put(actions.fetchIvrBedrockPromptTemplatesFailure());
  }
}

export function* searchIvrBedrockPromptTemplates({ payload }) {
  try {
    const { page, limit, search } = payload;
    const { data } = yield call(
      api.get,
      `/v3/ivr/bedrock/prompts/templates/search`,
      {
        params: { page, limit, search },
      }
    );

    yield put(actions.searchIvrBedrockPromptTemplatesSuccess(data));
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_listing_templates')
    );
    yield put(actions.searchIvrBedrockPromptTemplatesFailure());
  }
}

export function* createIvrBedrockPromptTemplates({ payload }) {
  try {
    const { data, callback } = payload;

    const response = yield call(
      api.post,
      `/v3/ivr/bedrock/prompts/templates`,
      data
    );

    yield put(actions.createIvrBedrockPromptTemplateSuccess(response.data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_creating_template')
    );
    yield put(actions.createIvrBedrockPromptTemplateFailure());
  }
}

export function* updateIvrBedrockPromptTemplates({ payload }) {
  try {
    const { data, callback } = payload;

    const response = yield call(
      api.put,
      `/v3/ivr/bedrock/prompts/templates`,
      data
    );

    yield put(actions.updateIvrBedrockPromptTemplateSuccess(response.data));

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_updating_the_template')
    );
    yield put(actions.updateIvrBedrockPromptTemplateFailure());
  }
}

export function* deleteIvrBedrockPromptTemplates({ payload }) {
  try {
    const { id, callback } = payload;

    yield call(api.delete, `/v3/ivr/bedrock/prompts/templates/${id}`);

    yield put(actions.deleteIvrBedrockPromptTemplateSuccess());

    callback?.();
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_deleting_the_template')
    );
    yield put(actions.deleteIvrBedrockPromptTemplateFailure());
  }
}

export function* createIvrBedrockPromptCompletions({ payload }) {
  try {
    const { data, callback } = payload;

    const response = yield call(
      api.post,
      `/v3/ivr/bedrock/prompts/completions`,
      data
    );

    yield put(actions.createIvrBedrockPromptCompletionsSuccess(response.data));

    callback?.(response.data);
  } catch (e) {
    toast.error(
      i18n.t('error.oops'),
      i18n.t('error.error_when_creating_bedrock_responses')
    );
    yield put(actions.createIvrBedrockPromptCompletionsFailure());
  }
}

export function* fetchIvrBedrockModels() {
  try {
    const { data } = yield call(api.get, '/v3/ivr/bedrock/models');

    yield put(actions.fetchIvrBedrockModelsSuccess(data));
  } catch (e) {
    yield put(actions.fetchIvrBedrockModelsFailure());
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_models'));
  }
}

export default all([
  takeLatest(
    types.FETCH_IVR_BEDROCK_PROMPT_TEMPLATES,
    fetchIvrBedrockPromptTemplates
  ),
  takeLatest(
    types.SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES,
    searchIvrBedrockPromptTemplates
  ),
  takeLatest(
    types.CREATE_IVR_BEDROCK_PROMPT_TEMPLATE,
    createIvrBedrockPromptTemplates
  ),
  takeLatest(
    types.UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE,
    updateIvrBedrockPromptTemplates
  ),
  takeLatest(
    types.DELETE_IVR_BEDROCK_PROMPT_TEMPLATE,
    deleteIvrBedrockPromptTemplates
  ),
  takeLatest(
    types.CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS,
    createIvrBedrockPromptCompletions
  ),
  takeLatest(types.FETCH_IVR_BEDROCK_MODELS, fetchIvrBedrockModels),
]);
