import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  allConnectors: [],
  connectors: [],
};

export default function connectors(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_CONNECTORS_SUCCESS:
      case types.FETCH_SUBFLOW_CONNECTORS_SUCCESS:
        draft.connectors = action.payload.connectors;
        break;

      case types.FETCH_ALL_CONNECTORS_SUCCESS:
        draft.allConnectors = action.payload.allConnectors;
        break;

      case types.CREATE_CONNECTOR_SUCCESS:
        draft.connectors.push(action.payload.connector);
        break;

      case types.DELETE_CONNECTOR_SUCCESS:
        draft.connectors = action.payload;
        draft.allConnectors = state.allConnectors.filter(
          (all) => all._id !== action._id
        );
        break;

      default:
    }
  });
}
