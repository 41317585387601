const Types = {
  LIST_IVR_VARIABLES: '@ivrVariables/LIST_IVR_VARIABLES',
  LIST_IVR_VARIABLES_SUCCESS: '@ivrVariables/LIST_IVR_VARIABLES_SUCCESS',
  LIST_IVR_VARIABLES_FAILURE: '@ivrVariables/LIST_IVR_VARIABLES_FAILURE',

  SEARCH_IVR_VARIABLES: '@ivrVariables/SEARCH_IVR_VARIABLES',
  SEARCH_IVR_VARIABLES_SUCCESS: '@ivrVariables/SEARCH_IVR_VARIABLES_SUCCESS',
  SEARCH_IVR_VARIABLES_FAILURE: '@ivrVariables/SEARCH_IVR_VARIABLES_FAILURE',
  RESET_SEARCH_IVR_VARIABLES: '@ivrVariables/RESET_SEARCH_IVR_VARIABLES',

  CREATE_IVR_VARIABLE: '@ivrVariables/CREATE_IVR_VARIABLE',
  CREATE_IVR_IVR_VARIABLE_SUCCESS: '@ivrVariables/CREATE_IVR_VARIABLE_SUCCESS',

  UPDATE_IVR_VARIABLE: '@ivrVariables/UPDATE_IVR_VARIABLE',
  UPDATE_IVR_VARIABLE_SUCCESS: '@ivrVariables/UPDATE_IVR_VARIABLE_SUCCESS',

  DELETE_IVR_VARIABLE: '@ivrVariables/DELETE_IVR_VARIABLE',
  DELETE_IVR_VARIABLE_SUCCESS: '@ivrVariables/DELETE_IVR_VARIABLE_SUCCESS',

  DECRYPT_IVR_VARIABLE: '@ivrVariables/DECRYPT_IVR_VARIABLE',
};

export default Types;
