import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  isViewer: false,
  isViewerRedator: false,
  isUserViewerRedator: false,
};

export default function session(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.SET_IS_USER_VIEWER_REDATOR: {
        draft.isUserViewerRedator = action.payload;
        break;
      }

      case types.SET_IS_VIEWER_REDATOR: {
        draft.isViewerRedator = action.payload;
        break;
      }

      case types.SET_IS_VIEWER: {
        draft.isViewer = action.payload;
        break;
      }

      default:
    }
  });
}
