import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  prompts: [],
};

export default function intelligence(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_PROMPTS_SUCCESS: {
        draft.prompts = action.payload;
        break;
      }

      default:
        return state;
    }
  });
}
