import { takeLatest, put, all, call } from 'redux-saga/effects';

import i18n from '~/translations/i18n';

import api from '~/services/api';
import { toast } from '~/components/CustomToast';
import * as actions from './actions';
import types from './types';

export function* getPrompts() {
  try {
    const { data } = yield call(api.get, `/v3/prompt`);

    yield put(actions.getPromptsSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.filter_error'));
  }
}
export default all([takeLatest(types.GET_PROMPTS, getPrompts)]);
