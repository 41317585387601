import styled from 'styled-components';

export const Container = styled.div`
  .menu_style {
    width: 185px;
    color: var(--primary-800-light) !important;

    :focus {
      box-shadow: none;
    }

    :hover {
      background: var(--bg) !important;
    }
  }

  .menu_item_style {
    width: 174px;
    margin: 3px;

    :focus {
      box-shadow: none;
    }

    :hover {
      background: var(--primary-800-light) !important;
      color: var(--absolute-white) !important;
      border-radius: 4px;
    }
  }
`;
