import React from 'react';
import PropTypes from 'prop-types';

import { ModalHeader } from './styles';

const ModalHeaderComponent = ({ children, ...rest }) => {
  return <ModalHeader {...rest}>{children}</ModalHeader>;
};

ModalHeaderComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalHeaderComponent;
