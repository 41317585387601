import types from './types';

export const fetchProfiles = ({ language }) => ({
  type: types.FETCH_PROFILES,
  payload: { language },
});

export const fetchProfilesSuccess = ({ profiles }) => ({
  type: types.FETCH_PROFILES_SUCCESS,
  payload: { profiles },
});

export const fetchPressets = ({ botId }) => ({
  type: types.FETCH_PRESSETS,
  payload: { botId },
});

export const fetchPressetsSuccess = ({ pressets }) => ({
  type: types.FETCH_PRESSETS_SUCCESS,
  payload: { pressets },
});
