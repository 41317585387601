const Types = {
  FETCH_DATA_START: '@builder/FETCH_DATA_START',
  FETCH_DATA_SUCCESS: '@builder/FETCH_DATA_SUCCESS',
  FETCH_DATA_FAILURE: '@builder/FETCH_DATA_FAILURE',
  SET_IS_LOADING: '@builder/SET_IS_LOADING',
  SET_RENDER_FLOW: '@builder/SET_RENDER_FLOW',
  SET_CLIENT_POSITION: '@builder/SET_CLIENT_POSITION',
  SET_COPY_PASTE_ITEMS: '@builder/SET_COPY_PASTE_ITEMS',
};

export default Types;
