import types from './types';

export const fetchNpsRequest = (botId) => ({
  type: types.FETCH_NPS_REQUEST,
  payload: { botId },
});

export const fetchNpsSuccess = (payload) => ({
  type: types.FETCH_NPS_SUCCESS,
  payload,
});

export const updateNpsRequest = (payload) => ({
  type: types.UPDATE_NPS_REQUEST,
  payload,
});

export const updateNpsSuccess = (payload) => ({
  type: types.UPDATE_NPS_SUCCESS,
  payload,
});

export const deleteNpsRequest = (payload) => ({
  type: types.DELETE_NPS_REQUEST,
  payload,
});

export const deleteNpsSuccess = ({ npsId }) => ({
  type: types.DELETE_NPS_SUCCESS,
  payload: { npsId },
});

export const createNpsRequest = (payload) => ({
  type: types.CREATE_NPS_REQUEST,
  payload,
});

export const createNpsSuccess = (payload) => ({
  type: types.CREATE_NPS_SUCCESS,
  payload,
});
