const Types = {
  FETCH_ORGANIZATIONS_FROM_COMPANY:
    '@organization/FETCH_ORGANIZATIONS_FROM_COMPANY',
  FETCH_ORGANIZATIONS_FROM_COMPANY_SUCCESS:
    '@organization/FETCH_ORGANIZATIONS_FROM_COMPANY_SUCCESS',
  FETCH_ORGANIZATION_PLUS_USERS: '@organization/FETCH_ORGANIZATION_PLUS_USERS',
  FETCH_ORGANIZATION_PLUS_USERS_SUCCESS:
    '@organization/FETCH_ORGANIZATION_PLUS_USERS_SUCCESS',
  CREATE_ORGANIZATION: '@organization/CREATE_ORGANIZATION',
  CREATE_ORGANIZATION_SUCCESS: '@organization/CREATE_ORGANIZATION_SUCCESS',
  DELETE_ORGANIZATION: '@organization/DELETE_ORGANIZATION',
  DELETE_ORGANIZATION_SUCCESS: '@organization/DELETE_ORGANIZATION_SUCCESS',
  EDIT_ORGANIZATION: '@organization/EDIT_ORGANIZATION',
  EDIT_ORGANIZATION_SUCCESS: '@organization/EDIT_ORGANIZATION_SUCCESS',
};

export default Types;
