import produce from 'immer';
import types from './types';

const INITIAL_STATE = {};

export default function artificialIntelligence(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.IA_PREVIEW_SUCCESS: {
        draft.iaPreviewResult = action.payload.data.result;
        break;
      }
      case types.IA_PREVIEW_CLEAR: {
        draft.iaPreviewResult = [];
        break;
      }
      default:
        break;
    }
  });
}
