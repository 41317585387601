import React from 'react';

import * as S from './styles';

const MenuItemInput = ({
  value,
  onChange,
  placeholder,
  className,
  startIcon,
}) => {
  return (
    <S.MenuItemInput
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      startIcon={startIcon}
      className={className}
    />
  );
};

export default MenuItemInput;
