import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '../Typography';

import * as S from './styles';

const PoweredByFooter = () => {
  const { t } = useTranslation();

  return (
    <S.Footer>
      <S.Textfooter>
        Powered by
        <a href="https://ligo.cloud/" target="_blank" rel="noreferrer">
          <span> Ligo </span>
        </a>
      </S.Textfooter>
      <Typography marginTop="11px" variant="body-small">
        <a href="/privacy-policy" target="_blank" rel="noreferrer">
          {t('labels.policy_privacy')}
        </a>
      </Typography>
    </S.Footer>
  );
};

export default PoweredByFooter;
