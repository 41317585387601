const Types = {
  CHECK_EMAIL_REQUEST: '@auth/CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS: '@auth/CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE: '@auth/CHECK_EMAIL_FAILURE',
  RESET_CHECK_EMAIL: '@auth/RESET_CHECK_EMAIL',

  SIGNIN_REQUEST: '@auth/SIGNIN_REQUEST',
  SIGNIN_SUCCESS: '@auth/SIGNIN_SUCCESS',
  SIGNIN_FAILURE: '@auth/SIGNIN_FAILURE',

  LAST_NOTICE: '@auth/LAST_NOTICE',

  SIGNUP_REQUEST: '@auth/SIGNUP_REQUEST',
  SIGNUP_SUCCESS: '@auth/SIGNUP_SUCCESS',
  SIGNUP_FAILURE: '@auth/SIGNUP_FAILURE',

  UPDATE_LOGGED_USER_SELECTED_COMPANY:
    '@auth/UPDATE_LOGGED_USER_SELECTED_COMPANY',

  UPDATE_SELECTED_COMPANY_ORGANIZATION_REQUEST:
    '@auth/UPDATE_SELECTED_COMPANY_ORGANIZATION_REQUEST',
  UPDATE_SELECTED_COMPANY_ORGANIZATION_SUCCESS:
    '@auth/UPDATE_SELECTED_COMPANY_ORGANIZATION_SUCCESS',

  UPDATE_SELECT_ORGANIZATION_REQUEST:
    '@auth/UPDATE_SELECT_ORGANIZATION_REQUEST',
  UPDATE_SELECT_ORGANIZATION_SUCCESS:
    '@auth/UPDATE_SELECT_ORGANIZATION_SUCCESS',

  CONFIRM_REQUEST: '@auth/CONFIRM_REQUEST',
  CONFIRM_SUCCESS: '@auth/CONFIRM_SUCCESS',
  CONFIRM_FAILURE: '@auth/CONFIRM_FAILURE',

  RECOVER_EMAIL_REQUEST: '@auth/RECOVER_EMAIL_REQUEST',
  RECOVER_EMAIL_SUCCESS: '@auth/RECOVER_EMAIL_SUCCESS',
  RECOVER_EMAIL_FAILURE: '@auth/RECOVER_EMAIL_FAILURE',

  RECOVER_PASSWORD_REQUEST: '@auth/RECOVER_PASSWORD_REQUEST',
  RECOVER_PASSWORD_SUCCESS: '@auth/RECOVER_PASSWORD_SUCCESS',
  RECOVER_PASSWORD_FAILURE: '@auth/RECOVER_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: '@auth/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: '@auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: '@auth/RESET_PASSWORD_FAILURE',

  RESEND_EMAIL_CONFIRMATION_REQUEST: '@auth/RESEND_EMAIL_CONFIRMATION_REQUEST',

  AUTH_NOTICE_REQUEST: '@auth/AUTH_NOTICE_REQUEST',

  SIGNOUT: '@auth/SIGNOUT',

  UPDATE_USER_PASSWORD_REQUEST: '@auth/UPDATE_USER_PASSWORD_REQUEST',
  UPDATE_USER_PROFILE_REQUEST: '@auth/UPDATE_USER_PROFILE_REQUEST',
  UPDATE_USER_PROFILE_SUCCESS: '@auth/UPDATE_USER_PROFILE_SUCCESS',

  TFA_SETUP: '@auth/TFA_SETUP',
  TFA_SETUP_SUCCESS: '@auth/TFA_SETUP_SUCCESS',
  TFA_ACTIVE: '@auth/TFA_ACTIVE',
  TFA_ACTIVE_SUCCESS: '@auth/TFA_ACTIVE_SUCCESS',
  TFA_DISABLE: '@auth/TFA_DISABLE',
  TFA_DISABLE_SUCCESS: '@auth/TFA_DISABLE_SUCCESS',
  TFA_VERIFY: '@auth/TFA_VERIFY',
  TFA_VERIFY_SUCCESS: '@auth/TFA_VERIFY_SUCCESS',

  FETCH_LOGGEDUSER: '@auth/FETCH_LOGGEDUSER',
  FETCH_LOGGEDUSER_SUCCESS: '@auth/FETCH_LOGGEDUSER_SUCCESS',

  CREATE_PASSWORD: '@auth/CREATE_PASSWORD',
};

export default Types;
