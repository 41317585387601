import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-top: ${({ marginTop }) => marginTop};
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  & .icon {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--neutral-3);

    &.start-icon {
      left: 12px;
    }
    &.end-icon {
      right: 12px;
    }
  }

  & .pass-view {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 10px;
    padding-top: 12px;
    font-size: 1rem;
    color: var(--neutral-3);
    margin-top: 0;
  }
`;

export const LabelWrapper = styled.label`
  margin-bottom: 8px;

  .label {
    display: block;
    color: var(--neutral-3);
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 114%;
    letter-spacing: -0.02em;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    color: var(--neutral-2);
    display: block;
  }
`;

export const CustomInput = styled.input`
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: -0.02em;

  border-radius: var(--radius-small);
  color: var(--neutral-3);
  width: ${(props) => (props.width ? props.width : '100%')};
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    border 0.2s ease-in-out;

  &.outline {
    background: var(--absolute-white);
    border: 1px solid var(--neutral-2);

    &:hover {
      border: 1px solid var(--primary-800-light);
    }

    &:active {
      border: 1px solid var(--primary-800-light);
    }
  }

  &.filled {
    background: var(--neutral-0);
    border: none;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #6690ff;
  }

  &:disabled {
    border: 1px solid var(--neutral-1);
    background: var(--neutral-0);
    color: var(--neutral-2);
  }

  &.small {
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &.medium {
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &.large {
    height: 48px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 1rem;
  }

  &.success {
    background: var(--semantic-green-100-light);
    border: 1px solid var(--semantic-green-600-light);
    color: var(--semantic-green-600-light);
  }

  &.danger {
    background: var(--semantic-red-100-light);
    border: 1px solid var(--semantic-red-600-light);
    color: var(--semantic-red-600-light);
  }

  &.error {
    background: var(--semantic-red-100-light);
    border: 1px solid var(--semantic-red-600-light);
    color: var(--semantic-red-600-light);
  }

  &.builder::placeholder {
    color: var(--primary-800-light);
  }
  &.builder {
    background: var(--absolute-white);
  }

  &.has-start-icon {
    padding-left: 40px;
  }
  &.has-end-icon {
    padding-right: 40px;
  }

  &[type='range'] {
    height: 25px;
    -webkit-appearance: none;
    width: 100%;
    border: none;
    padding-left: 0px;
    padding-right: 0px;

    &:focus {
      outline: none;
      border: none !important;
      box-shadow: none;
    }
    &:active {
      outline: none;
      border: none !important;
    }
  }

  &[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: var(--neutral-1);
    border-radius: 3px;
    border: 0px solid #000000;
  }
  &[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid #2497e3;
    height: 18px;
    width: 18px;
    border-radius: 5px;
    background: var(--primary-800-light);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  &[type='range']:focus::-webkit-slider-runnable-track {
    background: var(--neutral-1);
  }
  &[type='range']::-moz-range-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: var(--neutral-1);
    border-radius: 1px;
    border: 0px solid #000000;
  }
  &[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000;
    border: 1px solid var(--neutral-1);
    height: 18px;
    width: 18px;
    border-radius: 25px;
    background: #a1d0ff;
    cursor: pointer;
  }

  &[type='date'],
  &[type='datetime-local'] {
    color: var(--neutral-3);
    font-family: 'Archivo', -apple-system, BlinkMacSystemFont, sans-serif;
  }
`;

export const Helper = styled.div`
  &.helper {
    font-style: normal;
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 120%;
    color: var(--neutral-3);
    display: block;
    margin-top: 4px;

    &.success {
      color: var(--semantic-green-600-light);
    }

    &.danger {
      color: var(--semantic-red-600-light);
    }
  }
`;

export const Error = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 120%;
  display: block;
  margin-top: 4px;
  color: var(--semantic-red-600-light);
`;

export const Loading = styled.div`
  position: absolute;
  right: 12px;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RequiredIcon = styled.div`
  color: var(--status-red);
`;
