const Types = {
  GET_COMPANY_USERS_COUNT_REQUEST: '@reports/GET_COMPANY_USERS_COUNT_REQUEST',
  GET_COMPANY_USERS_COUNT_SUCCESS: '@reports/GET_COMPANY_USERS_COUNT_SUCCESS',
  GET_COMPANY_SECTORS_COUNT_REQUEST:
    '@reports/GET_COMPANY_SECTORS_COUNT_REQUEST',
  GET_COMPANY_SECTORS_COUNT_SUCCESS:
    '@reports/GET_COMPANY_SECTORS_COUNT_SUCCESS',
  GET_MY_DASHBOARD_REQUEST: '@reports/GET_MY_DASHBOARD_REQUEST',
  GET_MY_DASHBOARD_SUCCESS: '@reports/GET_MY_DASHBOARD_SUCCESS',
  CREATE_MY_DASHBOARD_REQUEST: '@reports/CREATE_MY_DASHBOARD_REQUEST',
  CREATE_MY_DASHBOARD_SUCCESS: '@reports/CREATE_MY_DASHBOARD_SUCCESS',
  UPDATE_MY_DASHBOARD_REQUEST: '@reports/UPDATE_MY_DASHBOARD_REQUEST',
  UPDATE_MY_DASHBOARD_SUCCESS: '@reports/UPDATE_MY_DASHBOARD_SUCCESS',
  GET_LIST_ERROR_LOG: '@reports/GET_LIST_ERROR_LOG',
  SUCCESS_LIST_ERROR_LOG: '@reports/SUCCESS_LIST_ERROR_LOG',
  GET_COMPANY_MESSAGES_REQUEST: '@reports/GET_COMPANY_MESSAGES_REQUEST',
  GET_COMPANY_MESSAGES_SUCCESS: '@reports/GET_COMPANY_MESSAGES_SUCCESS',
  GET_COMPANY_SESSIONS_REQUEST: '@reports/GET_COMPANY_SESSIONS_REQUEST',
  GET_COMPANY_SESSIONS_SUCCESS: '@reports/GET_COMPANY_SESSIONS_SUCCESS',

  GET_CLIENTS_REPORTS: '@reports/GET_CLIENTS_REPORTS',
  GET_CLIENTS_REPORTS_SUCCESS: '@reports/GET_CLIENTS_REPORTS_SUCCESS',
  GET_BOT_MESSAGES_REQUEST: '@reports/GET_BOT_MESSAGES_REQUEST',
  GET_BOT_MESSAGES_SUCCESS: '@reports/GET_BOT_MESSAGES_SUCCESS',
  GET_COMPANY_BOTS_COUNT_REQUEST: '@reports/GET_COMPANY_BOTS_COUNT_REQUEST',
  GET_COMPANY_BOTS_COUNT_SUCCESS: '@reports/GET_COMPANY_BOTS_COUNT_SUCCESS',
  GET_BOT_SESSION_TAGS: '@reports/GET_BOT_SESSION_TAGS',
  GET_BOT_SESSION_TAGS_SUCCESS: '@reports/GET_BOT_SESSION_TAGS_SUCCESS',
  GENERATE_BOT_CUSTOM_REPORT: '@reports/GENERATE_BOT_CUSTOM_REPORT',

  GET_BOT_RESUME: '@reports/GET_BOT_RESUME',
  GET_BOT_RESUME_SUCCESS: '@reports/GET_BOT_RESUME_SUCCESS',
  GET_NOT_UNDERSTOOD_INTENTIONS: '@reports/GET_NOT_UNDERSTOOD_INTENTIONS',
  GET_NOT_UNDERSTOOD_INTENTIONS_SUCCESS:
    '@reports/GET_NOT_UNDERSTOOD_INTENTIONS_SUCCESS',
  GET_INTENTIONS_PERFORMANCE: '@reports/GET_INTENTIONS_PERFORMANCE',
  GET_INTENTIONS_PERFORMANCE_SUCCESS:
    '@reports/GET_INTENTIONS_PERFORMANCE_SUCCESS',
  GET_UNDERSTOOD_INTENTIONS: '@reports/GET_UNDERSTOOD_INTENTIONS',
  GET_UNDERSTOOD_INTENTIONS_SUCCESS:
    '@reports/GET_UNDERSTOOD_INTENTIONS_SUCCESS',
  GET_REPORT_SESSION_GENERATE: '@reports/GET_REPORT_SESSION_GENERATE',
  GET_REPORT_SESSION_GENERATE_SUCCESS:
    '@reports/GET_REPORT_SESSION_GENERATE_SUCCESS',

  GET_BOT_SESSION_REPORT: '@reports/GET_BOT_SESSION_REPORT',
  GET_BOT_SESSION_REPORT_SUCCESS: '@reports/GET_BOT_SESSION_REPORT_SUCCESS',

  EXPORT_GET_LIST_ERROR_LOG: '@reposts/EXPORT_GET_LIST_ERROR_LOG',
  LOAGING_LIST_ERROR_LOG: '@reposts/LOAGING_LIST_ERROR_LOG',
  GET_NPS_REPORT_REQUEST: '@nps/GET_NPS_REPORT_REQUEST',
  GET_NPS_REPORT_SUCCESS: '@nps/GET_NPS_REPORT_SUCCESS',
};

export default Types;
