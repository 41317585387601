import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  background-color: #f3f5f9;

  .icon {
    width: 104px;
    height: 104px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;

  .img-logo {
    width: 136px;
  }

  .descripton {
    max-width: 320px;
    color: #5a5d68;
    text-align: center;
  }
`;
