const types = {
  FETCH_CONNECTORS: '@connectors/FETCH_CONNECTORS',
  FETCH_CONNECTORS_SUCCESS: '@connectors/FETCH_CONNECTORS_SUCCESS',
  FETCH_SUBFLOW_CONNECTORS: '@connectors/FETCH_SUBFLOW_CONNECTORS',
  FETCH_SUBFLOW_CONNECTORS_SUCCESS:
    '@connectors/FETCH_SUBFLOW_CONNECTORS_SUCCESS',
  FETCH_ALL_CONNECTORS: '@connectors/FETCH_ALL_CONNECTORS',
  FETCH_ALL_CONNECTORS_SUCCESS: '@connectors/FETCH_ALL_CONNECTORS_SUCCESS',
  DELETE_CONNECTORS: '@connectors/DELETE_CONNECTORS',
  CREATE_CONNECTOR: '@connectors/CREATE_CONNECTOR',
  CREATE_CONNECTOR_SUCCESS: '@connectors/CREATE_CONNECTOR_SUCCESS',
  DELETE_CONNECTOR_SUCCESS: '@connectors/DELETE_CONNECTOR_SUCCESS',
  UPDATE_CONNECTORS: '@connectors/UPDATE_CONNECTORS',
};

export default types;
