import styled from 'styled-components';

export const Container = styled.div`
  .custom-modal-footer {
    background: var(--absolute-white);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn-custom {
      color: var(--primary-600-light);
    }

    .hight-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
`;

export const ModalHeaderCustom = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--neutral-light-2);
  > .title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const ModalContentCustom = styled.div`
  padding: 1rem;
`;
