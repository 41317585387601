import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '~/services/api';
import { toast } from '~/components/CustomToast';
import i18n from '../../../translations/i18n';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';

import types from './types';

export function* fetchIvrNumbers({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { queryParams } = payload;

    const { data } = yield yield call(api.get, 'v3/ivr/numbers', {
      params: {
        ivrId: queryParams?.ivrId,
      },
    });

    yield put(actions.fetchIvrNumbersSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_ivr_numbers'));
    yield put(actions.fetchIvrNumbersFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* searchIvrNumbersForManagement({ payload }) {
  try {
    const { params } = payload;

    const { data } = yield yield call(api.get, 'v3/ivr/numbers/management', {
      params,
    });

    yield put(actions.searchIvrNumbersForManagementSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_ivr_numbers'));
    yield put(actions.searchIvrNumbersForManagementFailure());
  }
}

export function* searchIvrNumbersForBuy({ payload }) {
  try {
    const { params } = payload;

    const { data } = yield yield call(api.get, 'v3/ivr/numbers/buy', {
      params,
    });

    yield put(actions.searchIvrNumbersForBuySuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_ivr_numbers'));
    yield put(actions.searchIvrNumbersForBuyFailure());
  }
}

export function* buyIvrNumber({ payload }) {
  try {
    const { id, accountId, callback } = payload;

    yield yield call(api.post, `v3/ivr/numbers/${id}/buy`, { accountId });

    yield put(actions.buyIvrNumberSuccess());
    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_when_buy_number'));
    yield put(actions.buyIvrNumberFailure());
  }
}

export function* createIvrNumber({ payload }) {
  try {
    const { data, callback } = payload;

    yield yield call(api.post, 'v3/ivr/numbers', data);

    yield put(actions.createIvrNumberSuccess());
    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_create_number')
    );
    yield put(actions.createIvrNumberFailure());
  }
}

export function* updateIvrNumber({ payload }) {
  try {
    const { data, callback } = payload;

    yield yield call(api.put, 'v3/ivr/numbers', data);

    yield put(actions.updateIvrNumberSuccess());
    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_update_number')
    );
    yield put(actions.updateIvrNumberFailure());
  }
}

export function* addAssociateNumbersToIvr({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { ivrId, dids, callback } = payload;

    yield yield call(api.put, 'v3/ivr/numbers/associate', {
      ivrId,
      dids,
    });

    yield put(actions.addAssociateNumbersToIvrSuccess());
    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_associating_did'));
    yield put(actions.addAssociateNumbersToIvrFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* removeAssociateNumberFromIvr({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { ivrId, did, callback } = payload;

    yield yield call(api.delete, 'v3/ivr/numbers/associate', {
      data: {
        ivrId,
        did,
      },
    });

    yield put(actions.removeAssociateNumberFromIvrSuccess());
    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_removing_association_did')
    );
    yield put(actions.removeAssociateNumberFromIvrFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* removeIvrNumberFromOrganization({ payload }) {
  try {
    const { id, callback } = payload;

    yield yield call(
      api.delete,
      `v3/ivr/numbers/${id}/remove-from-organization`
    );

    yield put(actions.removeIvrNumberFromOrganizationSuccess());
    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_remove_number')
    );
    yield put(actions.removeIvrNumberFromOrganizationFailure());
  }
}

export default all([
  takeLatest(types.FETCH_IVR_NUMBERS, fetchIvrNumbers),
  takeLatest(types.BUY_IVR_NUMBER, buyIvrNumber),
  takeLatest(types.CREATE_IVR_NUMBER, createIvrNumber),
  takeLatest(types.UPDATE_IVR_NUMBER, updateIvrNumber),
  takeLatest(
    types.SEARCH_IVR_NUMBERS_FOR_MANAGEMENT,
    searchIvrNumbersForManagement
  ),
  takeLatest(types.SEARCH_IVR_NUMBERS_FOR_BUY, searchIvrNumbersForBuy),
  takeLatest(types.ADD_ASSOCIATE_NUMBERS_TO_IVR, addAssociateNumbersToIvr),
  takeLatest(
    types.REMOVE_ASSOCIATE_NUMBER_FROM_IVR,
    removeAssociateNumberFromIvr
  ),
  takeLatest(
    types.REMOVE_IVR_NUMBER_FROM_ORGANIZATION,
    removeIvrNumberFromOrganization
  ),
]);
