import React, { useState, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Menu, { MenuItem } from '../Menu';
import Button from '../Button';

import * as S from './styles';

const arrayLanguages = [
  { name: 'Português (Brasil)', value: 'pt-br' },
  { name: 'English', value: 'en' },
  { name: 'Español', value: 'es' },
];

const SelectLanguage = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(arrayLanguages[0]);
  const { loggedUser } = useSelector(({ auth }) => auth);

  useEffect(() => {
    if (loggedUser && loggedUser.preferredLanguage) {
      const [storedLanguage] = arrayLanguages.filter(
        (lng) => lng.value === loggedUser.preferredLanguage
      );

      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage.value);
    }
  }, [loggedUser]);

  const handleChangeLanguage = (value) => {
    const [lang] = arrayLanguages.filter((element) => element.name === value);

    setLanguage(lang);
    i18n.changeLanguage(lang.value);
  };

  return (
    <S.Container>
      <Menu
        onChange={(_value) => handleChangeLanguage(_value)}
        button={
          <Button
            className="menu_style"
            variant="ghost"
            endIcon={<FaChevronDown size={18} />}
          >
            {language.name}
          </Button>
        }
      >
        {arrayLanguages?.map((languageList, index) => (
          <MenuItem
            className="menu_item_style"
            key={String(index)}
            value={languageList.name}
          >
            {languageList.name}
          </MenuItem>
        ))}
      </Menu>
    </S.Container>
  );
};

export default SelectLanguage;
