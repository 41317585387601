const Types = {
  LIST_VARIABLES: '@variables/LIST_VARIABLES',
  LIST_VARIABLES_SUCCESS: '@variables/LIST_VARIABLES_SUCCESS',

  CREATE_VARIABLE: '@variables/CREATE_VARIABLE',
  CREATE_VARIABLE_SUCCESS: '@variables/CREATE_VARIABLE_SUCCESS',

  EDIT_VARIABLE: '@variables/EDIT_VARIABLE',
  EDIT_VARIABLE_SUCCESS: '@variables/EDIT_VARIABLE_SUCCESS',

  REMOVE_VARIABLE: '@variables/REMOVE_VARIABLE',
  REMOVE_VARIABLE_SUCCESS: '@variables/REMOVE_VARIABLE_SUCCESS',
};

export default Types;
