const UraTypes = {
  CREATE_IVR: '@ivr/CREATE_IVR',

  CLONE_IVR_REQUEST: '@ivr/CLONE_IVR_REQUEST',
  CLONE_IVR_SUCCESS: '@ivr/CLONE_IVR_SUCCESS',

  DELETE_IVR_REQUEST: '@ivr/DELETE_IVR_REQUEST',
  DELETE_IVR_SUCCESS: '@ivr/DELETE_IVR_SUCCESS',

  CREATE_CONFIG_IVR_OMNI: '@ivr/CREATE_CONFIG_IVR_OMNI',
  CREATE_CONFIG_IVR_OMNI_SUCCESS: '@ivr/CREATE_CONFIG_IVR_OMNI_SUCCESS',

  CREATE_CONFIG_IVR_OMNI_IN_API_DS: '@ivr/CREATE_CONFIG_IVR_OMNI_IN_API_DS',

  FETCH_CONFIG_IVR_OMNI_BY_ID: '@ivr/FETCH_CONFIG_IVR_OMNI_BY_ID',
  FETCH_CONFIG_IVR_OMNI_BY_ID_SUCCESS:
    '@ivr/FETCH_CONFIG_IVR_OMNI_BY_ID_SUCCESS',
  FETCH_CONFIG_IVR_OMNI_BY_ID_FAILURE:
    '@ivr/FETCH_CONFIG_IVR_OMNI_BY_ID_FAILURE',

  FETCH_ALL_CONFIG_IVR_OMNI: '@ivr/FETCH_ALL_CONFIG_IVR_OMNI',
  FETCH_ALL_CONFIG_IVR_OMNI_SUCCESS: '@ivr/FETCH_ALL_CONFIG_IVR_OMNI_SUCCESS',

  UPDATE_CONFIG_IVR_OMNI: '@ivr/UPDATE_CONFIG_IVR_OMNI',
  UPDATE_CONFIG_IVR_OMNI_SUCCESS: '@ivr/UPDATE_CONFIG_IVR_OMNI_SUCCESS',

  PUBLISH_IVR_REQUEST: '@ivr/PUBLISH_IVR_REQUEST',
  PUBLISH_IVR_SUCCESS: '@ivr/PUBLISH_IVR_SUCCESS',
  PUBLISH_IVR_FAILURE: '@ivr/PUBLISH_IVR_FAILURE',

  FETCH_IVRS_REQUEST: '@ivr/FETCH_IVRS_REQUEST',
  FETCH_IVRS_SUCCESS: '@ivr/FETCH_IVRS_SUCCESS',
  FETCH_IVRS_FAILURE: '@ivr/FETCH_IVRS_FAILURE',

  IVR_UPLOAD_AUDIOS_REQUEST: '@ivr/IVR_UPLOAD_AUDIOS_REQUEST',
  IVR_UPLOAD_AUDIOS_SUCCESS: '@ivr/IVR_UPLOAD_AUDIOS_SUCCESS',
  IVR_UPLOAD_AUDIOS_FAILURE: '@ivr/IVR_UPLOAD_AUDIOS_FAILURE',

  FETCH_IVR_AUDIOS: '@ivr/FETCH_IVR_AUDIOS',
  FETCH_IVR_AUDIOS_SUCCESS: '@ivr/FETCH_IVR_AUDIOS_SUCCESS',

  UPDATE_CONFIG_OMNI_IVR: '@ivr/UPDATE_CONFIG_IVR_OMNI',
  UPDATE_CONFIG_OMNI_IVR_SUCCESS: '@ivr/UPDATE_CONFIG_IVR_OMNI_SUCCESS',

  REMOVE_CONFIG_IVR_OMNI: '@ivr/REMOVE_CONFIG_IVR_OMNI',
  REMOVE_CONFIG_IVR_OMNI_SUCCESS: '@ivr/REMOVE_CONFIG_IVR_OMNI_SUCCESS',

  DELETE_IVR_AUDIOS_REQUEST: '@ivr/DELETE_IVR_AUDIOS_REQUEST',
  DELETE_IVR_AUDIOS_SUCCESS: '@ivr/DELETE_IVR_AUDIOS_SUCCESS',

  UPDATE_IVR_AUDIOS: '@ivr/UPDATE_IVR_AUDIOS',
  UPDATE_IVR_AUDIOS_SUCCESS: '@ivr/UPDATE_IVR_AUDIOS_SUCCESS',

  FETCH_IVR_SERVICES_BY_ORGANIZATION: '@ivr/FETCH_IVR_SERVICES_BY_ORGANIZATION',
  FETCH_IVR_SERVICES_BY_ORGANIZATION_SUCCESS:
    '@ivr/FETCH_IVR_SERVICES_BY_ORGANIZATION_SUCCESS',
  FETCH_IVR_SERVICES_BY_ORGANIZATION_FAILURE:
    '@ivr/FETCH_IVR_SERVICES_BY_ORGANIZATION_FAILURE',

  FETCH_IVR_DAC_DS_QUEUES: '@ivr/FETCH_IVR_DAC_DS_QUEUES',
  FETCH_IVR_DAC_DS_QUEUES_SUCCESS: '@ivr/FETCH_IVR_DAC_DS_QUEUES_SUCCESS',
  FETCH_IVR_DAC_DS_QUEUES_FAILURE: '@ivr/FETCH_IVR_DAC_DS_QUEUES_FAILURE',

  SEARCH_IVR_ATTEND_GROUPS: '@ivr/SEARCH_IVR_ATTEND_GROUPS',
  SEARCH_IVR_ATTEND_GROUPS_SUCCESS: '@ivr/SEARCH_IVR_ATTEND_GROUPS_SUCCESS',
  SEARCH_IVR_ATTEND_GROUPS_FAILURE: '@ivr/SEARCH_IVR_ATTEND_GROUPS_FAILURE',

  FETCH_ALL_WHATSAPP_NUMBERCHIPS: '@ivr/FETCH_ALL_WHATSAPP_NUMBERCHIPS',
  FETCH_ALL_WHATSAPP_NUMBERCHIPS_SUCCESS:
    '@ivr/FETCH_ALL_WHATSAPP_NUMBERCHIPS_SUCCESS',
  FETCH_ALL_WHATSAPP_NUMBERCHIPS_FAILURE:
    '@ivr/FETCH_ALL_WHATSAPP_NUMBERCHIPS_FAILURE',

  FETCH_ALL_WHATSAPP_TEMPLATES: '@ivr/FETCH_ALL_WHATSAPP_TEMPLATES',
  FETCH_ALL_WHATSAPP_TEMPLATES_SUCCESS:
    '@ivr/FETCH_ALL_WHATSAPP_TEMPLATES_SUCCESS',
  FETCH_ALL_WHATSAPP_TEMPLATES_FAILURE:
    '@ivr/FETCH_ALL_WHATSAPP_TEMPLATES_FAILURE',

  FETCH_NAMA_DATASETS: '@ivr/FETCH_NAMA_DATASETS',
  FETCH_NAMA_DATASETS_SUCCESS: '@ivr/FETCH_NAMA_DATASETS_SUCCESS',
  FETCH_NAMA_DATASETS_FAILURE: '@ivr/FETCH_NAMA_DATASETS_FAILURE',

  FETCH_TOPDESK_OPERATOR_GROUP: '@ivr/FETCH_TOPDESK_OPERATOR_GROUP',
  FETCH_TOPDESK_OPERATOR_GROUP_SUCCESS:
    '@ivr/FETCH_TOPDESK_OPERATOR_GROUP_SUCCESS',
  FETCH_TOPDESK_OPERATOR_GROUP_FAILURE:
    '@ivr/FETCH_TOPDESK_OPERATOR_GROUP_FAILURE',

  GENERATE_TTS_REQUEST: '@ivr/GENERAYE_TTS_REQUEST',
  GENERATE_TTS_SUCCESS: '@ivr/GENERAYE_TTS_SUCCESS',
  GENERATE_TTS_FAILURE: '@ivr/GENERAYE_TTS_FAILURE',
  GENERATE_TTS_SAVE_SUCCESS: '@ivr/GENERAYE_TTS_SAVE_SUCCESS',

  SET_AUDIO_TMP_TTS: '@ivr/SET_AUDIO_URL_TTS',

  FETCH_TTS_VOICES: '@ivr/FETCH_TTS_VOICES',
  FETCH_TTS_VOICES_SUCCESS: '@ivr/FETCH_TTS_VOICES_SUCCESS',
  FETCH_TTS_VOICES_FAILURE: '@ivr/FETCH_TTS_VOICES_FAILURE',

  SEARCH_IVR_AUDIOS: '@ivr/SEARCH_IVR_AUDIOS',
  SEARCH_IVR_AUDIOS_SUCCESS: '@ivr/SEARCH_IVR_AUDIOS_SUCCESS',
  SEARCH_IVR_AUDIOS_FAILURE: '@ivr/SEARCH_IVR_AUDIOS_FAILURE',
  RESET_SEARCH_IVR_AUDIOS: '@ivr/RESET_SEARCH_IVR_AUDIOS',

  FETCH_IVR_ITEMS_LOG_KEYS: '@ivr/FETCH_IVR_ITEMS_LOG_KEYS',
  FETCH_IVR_ITEMS_LOG_KEYS_SUCCESS: '@ivr/FETCH_IVR_ITEMS_LOG_KEYS_SUCCESS',
  FETCH_IVR_ITEMS_LOG_KEYS_FAILURE: '@ivr/FETCH_IVR_ITEMS_LOG_KEYS_FAILURE',

  SEARCH_IVR_ACCOUNTS_BY_ORG: '@ivr/SEARCH_IVR_ACCOUNTS_BY_ORG',
  SEARCH_IVR_ACCOUNTS_BY_ORG_SUCCESS: '@ivr/SEARCH_IVR_ACCOUNTS_BY_ORG_SUCCESS',
  SEARCH_IVR_ACCOUNTS_BY_ORG_FAILURE: '@ivr/SEARCH_IVR_ACCOUNTS_BY_ORG_FAILURE',
};

export default UraTypes;
