import styled from 'styled-components';

export const TextElement = styled.div`
  margin: 16px 16px 0px 16px;
`;

export const ContainerBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  margin: var(--spacement-medium);
  border: 1px solid var(--neutral-1);
  box-sizing: border-box;
  border-radius: 4px;

  background: var(--semantic-red-100-light);
  border: 1px solid var(--semantic-red-600-light);

  .divIcon {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 10%;
    color: var(--semantic-red-600-light);
    margin-right: 5px;

    font-size: 15px;
  }

  .divText {
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    p {
      font-weight: bold;
      font-family: Archivo;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 143%;
      color: var(--semantic-red-600-light);
    }

    span {
      font-family: Archivo;
      font-style: normal;
      font-weight: lighter;
      font-size: 14px;
      line-height: 143%;
      color: var(--semantic-red-600-light);
    }
  }
`;
