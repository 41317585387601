import { sortDate } from '~/pages/CompanyReports/Message/utils';

export const formatWhatsappConsumptionDataToGraphic = (consumptionByBot) => {
  const data = [];
  const keys = [];

  consumptionByBot.forEach(({ _id, bots }) => {
    const date = { dateHour: _id };

    bots.forEach((bot) => {
      const { botName, count, _id } = bot;
      const keyName = `${botName} |id: ${_id}`;
      date[keyName] = count;

      if (!keys.includes(keyName)) {
        keys.push(keyName);
      }
    });

    data.push(date);
  });

  data.sort(sortDate);

  return { data, keys };
};
