import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
 .using-sidebar {
    min-height: 100vh;
    width: 100%;

    transition: all 0.5s ease;

    @media (max-width: 960px) {
      & {
        padding-left: 65px;
      }
    }

    @media (min-width: 961px){
      & {
        padding-left: ${(props) => (props.isOpen ? '296px' : '65px')};
      }
    }
  }
`;

export const ContainerBgSideBar = styled.div`
  z-index: 98;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--neutral-jumbo);
  opacity: 0.1;
  visibility: hidden;

  @media (max-width: 960px) {
    & {
      visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    }
  }
`;

export const ContainerSideBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  top: 0;
  bottom: 0;
  background: var(--neutral-0);
  z-index: 99;
  transition: all 0.2s ease;
  position: fixed;
  width: ${({ isOpen }) => (isOpen ? '296px' : '65px')};
  box-shadow: inset -4px 0px 10px -2px rgba(0, 0, 0, 0.1);

  @media (max-width: 960px) {
    & {
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    }
  }

  .main-sidebar {
    overflow-x: ${(props) => (props.isOpen ? 'hidden' : 'visible')};
    overflow-y: ${(props) => (props.isOpen ? 'scroll' : 'visible')};
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .main-sidebar::-webkit-scrollbar {
    display: none;
  }

  & .item:last-child {
    padding: ${({ isOpen }) => (isOpen ? '10px 26px' : '0px')};
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: relative;

    & > a {
      display: block;
      margin: 0;
    }
  }

  & .logo-details {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 0.3s ease;
    padding: ${({ isOpen }) => (isOpen ? '24px 18px 24px' : '10px 14px')};

    & .rounded {
      border-radius: 100%;
      width: 2.25rem;
      height: 2.255rem;
    }
  }

  & .details-title {
    display: flex;
    align-items: center;
    text-decoration: none;

    & .icon {
      opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
      transition: all 0.3s ease;
    }

    & img {
      margin-left: 2px;
      transition: all 0.3s ease;
      height: ${({ isOpen }) => (isOpen ? '2.3rem' : '2rem')};
    }
  }

  & .btn.btn-side-open {
    position: absolute;
    top: 32px;
    left: 90px;
    opacity: ${({ isOpen }) => isOpen && 0};
  }

  & .btn.close {
    transition: all 0.3s ease;
    right: -10px;
    top: 30px;
    padding: 3px;
    height: 21px;
    width: 21px;
    font-size: 0.8rem;
  }

  &:hover .btn {
    opacity: 1;
    transition: all 0.5s ease;
  }

  & .btn {
    opacity: 0;
    text-align: ${({ isOpen }) => (isOpen ? 'right' : 'center')};
    position: absolute;
    top: 28px;
    right: 0;
    transform: translateY(-50%);
    transition: all 0.4s ease;
    font-size: 1.25rem;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    background-color: var(--absolute-white);
    border-radius: 4px;
    padding: 3px;
    padding-left: 0;
    height: 23px;
    width: 23px;
    color: var(--neutral-3);
    border: 1px solid var(--neutral-1);
    font-size: 1rem;
    right: -12px;
    z-index: 10;
  }

  & .btn i {
    font-size: 1.25rem;
    min-width: 0;
    line-height: 0px;
  }
  & i {
    color: var(--neutral-3);
    min-width: 50px;
    font-size: 1.75rem;
    text-align: left;
    line-height: 60px;
    padding-left: 8px;
    margin-right: -4px;
  }

  & .nav-list {
    height: auto;
    padding: 0;
    padding: ${({ isOpen }) => (isOpen ? '20px 16px 0px' : ' 10px 15px 14px')};
    transition: all 0.3s ease;
  }

  & li {
    margin: ${({ isOpen }) => (isOpen ? '0px' : '0px')};
    position: relative;
    list-style: none;
    transition: all 0.5s ease;

    & .title {
      font-size: 0.875rem;
      font-weight: 500;
    }
  }

  & li .tooltip {
    position: absolute;
    top: -20px;
    left: calc(100% + 8px);
    z-index: 10;
    background: var(--neutral-n1);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 0.938rem;
    font-weight: 400;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
    color: var(--neutral-0);
  }
  & li:hover .tooltip {
    display: ${({ isOpen }) => (isOpen ? 'none' : 'block')};
    opacity: 1;
    pointer-events: auto;
    transition: all 0.3s ease;
    top: 50%;
    transform: translateY(-50%);
  }

  & li .btn-link {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease, outline-color 0s;
    padding-left: ${({ isOpen }) => (isOpen ? '8px;' : 0)};
    overflow: hidden;

    &:disabled {
      cursor: not-allowed;
    }

    &:focus-visible {
      outline-color: var(--primary-400-light) !important;
    }
  }
  & li .btn-link:hover {
    background: var(--absolute-white);
    color: var(--primary-800-light) !important;

    svg {
      fill: var(--primary-800-light) !important;
    }
  }

  & .emphasis {
    background: var(--absolute-white);
    color: var(--primary-800-light) !important;

    svg {
      fill: var(--primary-800-light) !important;
    }
  }

  & li .btn-link .links_name {
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
    pointer-events: none ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
    color: var(--neutral-3);
    white-space: nowrap;
    transition: all 0.3s ease;
    width: ${({ isOpen }) => isOpen && 0};
  }

  & li .btn-link:hover .links_name,
  & li .btn-link:hover i {
    transition: all 0.5s ease;
    color: var(--primary-800-light);
  }
  & li i {
    height: 50px;
    line-height: 55px;
    border-radius: 12px;
    font-size: 1.125rem;
    color: var(--neutral-2);
  }

  & .separator {
    border-bottom: 1px solid #dadce3;
  }

  & .badge {
    top: ${({ isOpen }) => (isOpen ? '15px' : '5px')};
    right: ${({ isOpen }) => (isOpen ? '16px' : '5px')};
    font-size: ${({ isOpen }) => (isOpen ? '12px' : '9px')};
    background-color: ${({ isOpen }) => (isOpen ? '#dadce3' : '#ff7961')};
    color: ${({ isOpen }) => (isOpen ? 'var(--neutral-3)' : '#fff')};
    padding: ${({ isOpen }) => (isOpen ? '1px 3px' : '0 2px')};
    position: absolute;
    transition: all 0.6s ease;
    border-radius: 3px;
  }

  @media (max-width: 420px) {
    & li .tooltip {
      display: none;
    }
  }
`;
