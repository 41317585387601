import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  list: [],
  historyCompanyPlan: [],
  alerts: {},
  planConsumption: {},
};

export default function plans(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_PLANS_REQUEST_SUCCESS: {
        draft.list = [...action.payload];
        break;
      }

      case types.GET_HISTORY_COMPANY_PLAN_REQUEST_SUCCESS: {
        draft.historyCompanyPlan = [...action.payload];
        break;
      }

      case types.PUT_ALERTS_SUCCESS: {
        draft.alerts = action.payload;
        break;
      }

      case types.GET_COMPANY_PLAN_CONSUMPTION_SUCCESS: {
        draft.planConsumption = action.payload;
        break;
      }

      default:
    }
  });
}
