import types from './types';

export const fetchIvrTelephoneOperators = () => ({
  type: types.FETCH_IVR_TELEPHONE_OPERATORS,
});
export const fetchIvrTelephoneOperatorsSuccess = (payload) => ({
  type: types.FETCH_IVR_TELEPHONE_OPERATORS_SUCCESS,
  payload,
});
export const fetchIvrTelephoneOperatorsFailure = () => ({
  type: types.FETCH_IVR_TELEPHONE_OPERATORS_FAILURE,
});

export const createIvrTelephoneOperator = (data, callback) => ({
  type: types.CREATE_IVR_TELEPHONE_OPERATOR,
  payload: { data, callback },
});
export const createIvrTelephoneOperatorSuccess = (payload) => ({
  type: types.CREATE_IVR_TELEPHONE_OPERATOR_SUCCESS,
  payload,
});
export const createIvrTelephoneOperatorFailure = () => ({
  type: types.CREATE_IVR_TELEPHONE_OPERATOR_FAILURE,
});

export const updateIvrTelephoneOperator = (data, callback) => ({
  type: types.UPDATE_IVR_TELEPHONE_OPERATOR,
  payload: { data, callback },
});
export const updateIvrTelephoneOperatorSuccess = (payload) => ({
  type: types.UPDATE_IVR_TELEPHONE_OPERATOR_SUCCESS,
  payload,
});
export const updateIvrTelephoneOperatorFailure = () => ({
  type: types.UPDATE_IVR_TELEPHONE_OPERATOR_FAILURE,
});

export const deleteIvrTelephoneOperator = ({ _id }) => ({
  type: types.DELETE_IVR_TELEPHONE_OPERATOR,
  payload: { _id },
});
export const deleteIvrTelephoneOperatorSuccess = ({ _id }) => ({
  type: types.DELETE_IVR_TELEPHONE_OPERATOR_SUCCESS,
  payload: { _id },
});
export const deleteIvrTelephoneOperatorFailure = () => ({
  type: types.DELETE_IVR_TELEPHONE_OPERATOR_FAILURE,
});
