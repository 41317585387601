import axios from 'axios';
import { toast } from '../../components/CustomToast';
import i18n from '../../translations/i18n';

export const API_TOKEN = 'API_TOKEN';

const url = process.env.REACT_APP_API_IA_URL || 'http://localhost:3002';

const api = axios.create({
  baseURL: `${url}`,
  headers: {
    common: { authorization: `Bearer ${localStorage.getItem(API_TOKEN)}` },
  },
});

const UNAUTHORIZED_STATUS = 401;
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === UNAUTHORIZED_STATUS) {
      localStorage.removeItem('persist:@boteria-frontend');
      toast.error(
        i18n.t('preferences.error'),
        i18n.t('error.error_token_expired')
      );
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
