import types from './types';

export const listIvrFunctions = () => ({
  type: types.LIST_IVR_FUNCTIONS,
});

export const listIvrFunctionsSuccess = (payload) => ({
  type: types.LIST_IVR_FUNCTIONS_SUCCESS,
  payload,
});
