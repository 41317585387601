import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '~/services/api';
import types from './types';

import {
  commonLoadingStart,
  commonLoadingFinish,
  commonChangingFinish,
} from '../common/actions';
import * as actions from './actions';

export function* validateTopdeskSettingsRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const { data } = yield call(api.get, `v3/bots/${botId}/topdesk/validate`);
    yield put(commonChangingFinish());

    if (data) {
      yield put(actions.validateTopdeskSettingsSuccess(data));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getTopdeskStatusesRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const { data } = yield call(
      api.get,
      `v3/bots/${botId}/topdesk/getStatusList`
    );
    yield put(commonChangingFinish());

    if (data) {
      yield put(actions.getTopdeskStatusesSuccess(data));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getTopdeskOperatorsRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const { data } = yield call(
      api.get,
      `v3/bots/${botId}/topdesk/operators-groups`
    );

    yield put(commonChangingFinish());

    if (data) {
      yield put(actions.getTopdeskOperatorsSuccess(data));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getTopdeskCategoriesRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const { data } = yield call(api.get, `v3/bots/${botId}/topdesk/categories`);

    yield put(commonChangingFinish());

    if (data) {
      yield put(actions.getTopdeskCategoriesSuccess(data));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getTopdeskCallTypesRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const { data } = yield call(api.get, `v3/bots/${botId}/topdesk/call-types`);

    yield put(commonChangingFinish());

    if (data) {
      yield put(actions.getTopdeskCallTypesSuccess(data));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getTopdeskEntryTypesRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId } = payload;

    const { data } = yield call(
      api.get,
      `v3/bots/${botId}/topdesk/entry-types`
    );

    yield put(commonChangingFinish());

    if (data) {
      yield put(actions.getTopdeskEntryTypesSuccess(data));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getTopdeskSubCategoriesRequest({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, categoryId } = payload;
    const { data } = yield call(
      api.get,
      `v3/bots/${botId}/topdesk/categories/${categoryId}/sub-categories`
    );

    yield put(commonChangingFinish());

    if (data) {
      yield put(actions.getTopdeskSubCategoriesSuccess(data));
    }
  } catch (error) {
    return false;
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(
    types.VALIDATE_TOPDESK_SETTINGS_REQUEST,
    validateTopdeskSettingsRequest
  ),
  takeLatest(types.GET_TOPDESK_STATUSES_REQUEST, getTopdeskStatusesRequest),
  takeLatest(types.GET_TOPDESK_CATEGORIES_REQUEST, getTopdeskCategoriesRequest),
  takeLatest(
    types.GET_TOPDESK_SUB_CATEGORIES_REQUEST,
    getTopdeskSubCategoriesRequest
  ),
  takeLatest(types.GET_TOPDESK_OPERATORS_REQUEST, getTopdeskOperatorsRequest),
  takeLatest(types.GET_TOPDESK_CALL_TYPES_REQUEST, getTopdeskCallTypesRequest),
  takeLatest(
    types.GET_TOPDESK_ENTRY_TYPES_REQUEST,
    getTopdeskEntryTypesRequest
  ),
]);
