import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  permissions: [],
};

export default function permissions(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_PERMISSIONS_SUCCESS: {
        draft.permissions = action.payload.permissions;
        break;
      }

      default:
    }
  });
}
