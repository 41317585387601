import styled from 'styled-components';

export const Container = styled.div`
  background-color: var(--neutral-0) !important;
  width: 100vw;
  min-height: 100vh;
  background: var(--bg);
  padding-top: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logo {
    margin-bottom: 24px;

    > img {
      width: 10rem;
      height: auto;
    }
  }
`;

export const ContainerMenu = styled.div`
  margin-top: 24px;
  margin-bottom: 50px;
`;
