import api from '~/services/api';
import { store } from '../store';

export const useSubmitErrorHandler = () => {
  const handleSubmitError = async (error) => {
    try {
      const localData = store.getState();
      const payload = {
        pathname: window.location?.pathname,
        error: JSON.stringify({ message: error?.message, stack: error?.stack }),
        localData: JSON.stringify(localData),
      };

      await api.post('/v3/frontend-error', payload);
    } catch (err) {
      console.log(err);
    }
  };

  return handleSubmitError;
};
