import React from 'react';
import PropTypes from 'prop-types';

import { ModalContentWrapper } from './styles';

const ModalContent = ({
  children,
  maxHeight,
  overflowY,
  overflowX,
  ...rest
}) => {
  return (
    <ModalContentWrapper
      maxHeight={maxHeight}
      overflowY={overflowY}
      overflowX={overflowX}
      {...rest}
    >
      {children}
    </ModalContentWrapper>
  );
};

ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.string,
  overflowY: PropTypes.string,
  overflowX: PropTypes.string,
};

ModalContent.defaultProps = {
  maxHeight: 'max-content',
  overflowY: 'initial',
  overflowX: 'auto',
};

export default ModalContent;
