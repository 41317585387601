import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import { setConfig } from 'react-hot-loader';
import { ErrorBoundary } from 'react-error-boundary';

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './store';

import GlobalStyle from './styles/global';
import Routes from './routes';
import { ContainerToast } from './components/CustomToast';
import ErrorHandlerFallback from './components/ErrorHandlerFallback';
import { useSubmitErrorHandler } from './hooks/useSubmitErrorHandler';
import SetHistory from './utils/SetHistory';

setConfig({
  showReactDomPatchNotification: false,
});

function App() {
  const [userConfirmation, setUserConfirmation] = useState(null);
  const handleSubmitError = useSubmitErrorHandler();

  return (
    <ErrorBoundary
      FallbackComponent={ErrorHandlerFallback}
      onError={handleSubmitError}
    >
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          setUserConfirmation(() => (value) => {
            setUserConfirmation(null);
            callback(value);
          });
        }}
      >
        <SetHistory />
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Routes userConfirmation={userConfirmation} />
            <GlobalStyle />
            <ContainerToast />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
