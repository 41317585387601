import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '~/services/api';
import { toast } from '~/components/CustomToast';
import i18n from '../../../translations/i18n';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';
import * as botActions from '../bots/actions';

import types from './types';

export function* createIvr(action) {
  const { payload: body, callback } = action;

  const payload = {
    description: body?.description,
    title: body?.title,
    type: body?.type,
    companyId: body?.companyId,
    organizationId: body?.organizationId,
    deleted: body?.deleted,
    isTemplate: body?.isTemplate,
  };

  yield put(commonLoadingStart());

  try {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) =>
      formData.append(key, value)
    );

    const { data } = yield call(api.post, '/v3/ivr', formData);
    yield put(botActions.createBotSuccess({ newBot: data }));

    callback?.(data?._id);
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_creating_ivr'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteIvr({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { id, callback } = payload;
    yield call(api.delete, `/v3/ivr/${id}`);

    yield put(actions.deleteIvrSuccess({ id }));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_remove_ura')
    );

    callback?.();
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_delete_ura'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createConfigIvrOmni(action) {
  const { payload: body, callback } = action.payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.post, '/v3/ivr/config-ivr-omni', body);

    yield put(actions.createConfigIvrOmniSuccess({ payload: data }));
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_created_settings_ivromni')
    );

    callback?.({ success: true });
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || i18n.t('error.error_creating_ivromni');
    toast.error(i18n.t('error.error'), errorMessage);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createConfigIvrOmniInApiDS(action) {
  const { payload: body, callback } = action.payload;

  try {
    yield put(commonLoadingStart());

    yield call(api.post, '/v3/ivr/config-ivr-omni/api-ds', body);

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_created_settings_ivromni')
    );

    callback?.({ success: true });
  } catch (error) {
    const errorMessage =
      error.response?.data?.message || i18n.t('error.error_creating_ivromni');
    toast.error(i18n.t('error.error'), errorMessage);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchAllConfigIvrOmni(action) {
  const { payload } = action;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.get,
      `/v3/ivr/config-ivr-omni?organizationId=${payload}`
    );

    yield put(actions.fetchAllConfigIvrOmniSuccess({ payload: data }));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_all_ivromni_settings')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchConfigIvrOmniById(action) {
  const { payload } = action;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.get,
      `/v3/ivr/config-ivr-omni/${payload.id}`
    );

    yield put(actions.fetchConfigIvrOmniByIdSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_ivromni_setting')
    );
    yield put(actions.fetchConfigIvrOmniByIdFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateConfigIvrOmni(action) {
  const { payload: body, callback } = action.payload;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.put,
      `/v3/ivr/config-ivr-omni/${body._id}`,
      body
    );

    yield put(actions.updateConfigIvrOmniSuccess({ payload: data }));
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_updated_settings_ivromni')
    );

    callback?.({ success: true });
  } catch (error) {
    const errorMessage =
      error.response?.data?.message ||
      i18n.t('error.error_update_settings_ivromni');
    toast.error(i18n.t('error.error'), errorMessage);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* publishIvrRequest(action) {
  const { botId, isActive } = action.payload;

  try {
    const { data } = yield call(api.post, `/v3/ivr/${botId}/publish`, {
      isActive,
    });

    toast.success(
      i18n.t('success.success_publish_ivr.title'),
      i18n.t('success.success_publish_ivr.subtitle')
    );

    yield put(botActions.publishBotSuccess(data));
    yield put(actions.publishIvrSuccess());
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_publish_ivr'));
    yield put(actions.publishIvrFailure());
  }
}

export function* fetchIvrsRequest() {
  yield put(commonLoadingStart());

  try {
    const { data } = yield call(api.get, '/v3/ivr');
    yield put(actions.fetchIvrsSuccess({ data }));
  } catch (error) {
    yield put(actions.fetchIvrsFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* ivrUploadAudios({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, files, callback } = payload;

    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append(index, file);
    });

    const { data } = yield call(
      api.post,
      `/v3/ivr/tts/file/${botId}`,
      formData,
      { timeout: 60000 }
    );

    const { error, success } = data;

    if (error?.length > 0) {
      toast.warn(
        i18n.t('error.error'),
        i18n.t('error.error_upload_audios_length')
      );
    }

    yield put(actions.ivrUploadAudiosSuccess({ audios: success }));

    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_upload_audios'));
    yield put(actions.ivrUploadAudiosFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchIvrAudios({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { params } = payload;

    const { data } = yield call(api.get, `/v3/ivr/tts/${params.botId}`, {
      timeout: 30000,
    });

    yield put(actions.fetchIvrAudiosSuccess({ audios: data.audios }));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_audios'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* searchIvrAudios({ payload }) {
  try {
    const { botId, queryParams } = payload;
    const { data } = yield call(api.get, `/v3/ivr/tts/${botId}/search`, {
      params: queryParams,
    });

    yield put(actions.searchIvrAudiosSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_fetch_audios'));
    yield put(actions.searchIvrAudiosFailure());
  }
}

export function* deleteIvrAudio({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { audioId, callback } = payload;
    yield call(api.delete, `/v3/ivr/tts/file/${audioId}`);

    yield put(actions.deleteIvrAudiosSuccess({ audioId }));
    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_deleting_audio'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateIvrAudios({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { _id: audioId, callback } = payload;

    const { data } = yield call(api.put, `/v3/ivr/tts/${audioId}`, payload);

    yield put(actions.updateIvrAudiosSuccess(data));

    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_updating_audio'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* removeConfigIvrOmni(action) {
  const { payload } = action;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.delete,
      `/v3/ivr/config-ivr-omni/${payload}`
    );

    yield put(actions.removeConfigIvrOmniSuccess({ payload: data }));
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_deleted_settings_ivromni')
    );
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_deleted_settings_ivromni')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchIvrServicesByOrganization() {
  try {
    yield put(commonLoadingStart());

    const { data } = yield yield call(api.get, 'v3/ivr/integration/services');

    yield put(actions.fetchIvrServicesByOrganizationSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_dac_ds_queues')
    );
    yield put(actions.fetchIvrServicesByOrganizationFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* cloneIvrRequest({ payload }) {
  try {
    yield put(commonLoadingStart());
    const { payload: body, callback } = payload;
    const { botId, title, description } = body;

    const { data } = yield call(api.post, `/v3/ivr/${botId}/clone`, {
      title,
      description,
    });

    toast.success(
      i18n.t('labels.clone_in_progress'),
      i18n.t('success.success_clone_in_progress_description')
    );

    yield put(actions.cloneIvrSuccess(data));

    if (callback) {
      callback();
    }
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_clone_ivr'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* generateTts({ payload }) {
  try {
    const { text, voice, expire, botId, audioName, audioSave, callback } =
      payload;
    const { data } = yield call(
      api.post,
      '/v3/ivr/tts/generate',
      {
        text,
        voice,
        expire,
        botId,
        audioName,
        audioSave,
      },
      {
        timeout: 50000,
      }
    );
    if (audioSave) {
      yield put(actions.generateTtsSaveSuccess(data));
    } else {
      yield put(actions.generateTtsSuccess(data));
    }

    callback?.(data);
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_generating_tts'));
    yield put(actions.generateTtsFailure());
  }
}

export function* fetchTtsVoices() {
  try {
    yield put(commonLoadingStart());

    const { data } = yield yield call(api.get, 'v3/ivr/tts/voices');

    yield put(actions.fetchTtsVoicesSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_when_searching_for_the_voice_options')
    );
    yield put(actions.fetchTtsVoicesFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchIvrDacDsQueues({ payload }) {
  try {
    yield put(commonLoadingStart());

    const botId = payload?.botId;

    const { data } = yield yield call(
      api.get,
      'v3/ivr/integration/dac-ds/queue',
      { params: { botId } }
    );

    yield put(actions.fetchIvrDacDsQueuesSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_dac_ds_queues')
    );
    yield put(actions.fetchIvrDacDsQueuesFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* searchIvrAttendGroups() {
  try {
    yield put(commonLoadingStart());

    const { data } = yield yield call(
      api.get,
      'v3/ivr/integration/attend-groups/search'
    );

    yield put(actions.searchIvrAttendGroupsSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_attend_groups')
    );
    yield put(actions.searchIvrAttendGroupsFailure());
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchAllWhatsappNumberchips({ payload }) {
  try {
    const botId = payload?.botId;

    const { data } = yield call(
      api.get,
      'v3/ivr/integration/whatsapp/numberchip/all',
      { params: { botId } }
    );

    yield put(actions.fetchAllWhatsappNumberchipsSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_whatsapp_numbers')
    );
    yield put(actions.fetchAllWhatsappNumberchipsFailure());
  }
}

export function* fetchAllWhatsappTemplates({ payload }) {
  try {
    const botId = payload?.botId;
    const numberchip = payload?.numberchip;

    const { data } = yield call(
      api.get,
      'v3/ivr/integration/whatsapp/templates/all',
      { params: { botId, numberchip } }
    );

    yield put(actions.fetchAllWhatsappTemplatesSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_whatsapp_templates')
    );
    yield put(actions.fetchAllWhatsappTemplatesFailure());
  }
}

export function* fetchIvrItemsLogKeys({ payload }) {
  try {
    const botId = payload?.botId;

    const { data } = yield call(api.get, `v3/ivr/${botId}/items/log-keys`);

    yield put(actions.fetchIvrItemsLogKeysSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_log_keys'));
    yield put(actions.fetchIvrItemsLogKeysFailure());
  }
}

export function* fetchNamaDatasets({ payload }) {
  try {
    const botId = payload?.botId;

    const { data } = yield call(api.get, 'v3/ivr/integration/nama/datasets', {
      params: { botId },
    });

    yield put(actions.fetchNamaDatasetsSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_nama_datasets')
    );
    yield put(actions.fetchNamaDatasetsFailure());
  }
}

export function* fetchTopdeskOperatorGroup({ payload }) {
  try {
    const botId = payload?.botId;

    const { data } = yield call(
      api.get,
      'v3/ivr/integration/topdesk/operator/groups',
      {
        params: { botId },
      }
    );

    yield put(actions.fetchTopdeskOperatorGroupSuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_topdesk_operator_group')
    );
    yield put(actions.fetchTopdeskOperatorGroupFailure());
  }
}

export function* searchIvrAccountsByOrg() {
  try {
    const { data } = yield call(api.get, 'v3/ivr/accounts/organization');

    yield put(actions.searchIvrAccountsByOrgSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetching_accounts'));
    yield put(actions.searchIvrAccountsByOrgFailure());
  }
}

export default all([
  takeLatest(types.CREATE_IVR, createIvr),
  takeLatest(types.DELETE_IVR_REQUEST, deleteIvr),
  takeLatest(types.CREATE_CONFIG_IVR_OMNI, createConfigIvrOmni),
  takeLatest(
    types.CREATE_CONFIG_IVR_OMNI_IN_API_DS,
    createConfigIvrOmniInApiDS
  ),
  takeLatest(types.FETCH_ALL_CONFIG_IVR_OMNI, fetchAllConfigIvrOmni),
  takeLatest(types.FETCH_CONFIG_IVR_OMNI_BY_ID, fetchConfigIvrOmniById),
  takeLatest(types.UPDATE_CONFIG_IVR_OMNI, updateConfigIvrOmni),
  takeLatest(types.REMOVE_CONFIG_IVR_OMNI, removeConfigIvrOmni),
  takeLatest(types.PUBLISH_IVR_REQUEST, publishIvrRequest),
  takeLatest(types.FETCH_IVRS_REQUEST, fetchIvrsRequest),
  takeLatest(types.IVR_UPLOAD_AUDIOS_REQUEST, ivrUploadAudios),
  takeLatest(types.FETCH_IVR_AUDIOS, fetchIvrAudios),
  takeLatest(types.SEARCH_IVR_AUDIOS, searchIvrAudios),
  takeLatest(types.DELETE_IVR_AUDIOS_REQUEST, deleteIvrAudio),
  takeLatest(types.UPDATE_IVR_AUDIOS, updateIvrAudios),
  takeLatest(types.CLONE_IVR_REQUEST, cloneIvrRequest),
  takeLatest(
    types.FETCH_IVR_SERVICES_BY_ORGANIZATION,
    fetchIvrServicesByOrganization
  ),
  takeLatest(types.GENERATE_TTS_REQUEST, generateTts),
  takeLatest(types.FETCH_TTS_VOICES, fetchTtsVoices),
  takeLatest(types.FETCH_IVR_DAC_DS_QUEUES, fetchIvrDacDsQueues),
  takeLatest(types.SEARCH_IVR_ATTEND_GROUPS, searchIvrAttendGroups),
  takeLatest(types.FETCH_ALL_WHATSAPP_NUMBERCHIPS, fetchAllWhatsappNumberchips),
  takeLatest(types.FETCH_ALL_WHATSAPP_TEMPLATES, fetchAllWhatsappTemplates),
  takeLatest(types.FETCH_NAMA_DATASETS, fetchNamaDatasets),
  takeLatest(types.FETCH_TOPDESK_OPERATOR_GROUP, fetchTopdeskOperatorGroup),
  takeLatest(types.FETCH_IVR_ITEMS_LOG_KEYS, fetchIvrItemsLogKeys),
  takeLatest(types.SEARCH_IVR_ACCOUNTS_BY_ORG, searchIvrAccountsByOrg),
]);
