import produce from 'immer';

import types from './types';

export const INITIAL_STATE = {
  promptTemplates: { data: [], error: false, loading: true },
  foundPromptTemplates: { data: [], error: false, loading: true },
  models: { data: null, error: false, loading: false },
  isLoadingPromptTemplateMutation: false,
  isLoadingPromptCompletion: false,
};

export default function ivrBedrock(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_IVR_BEDROCK_PROMPT_TEMPLATES: {
        draft.promptTemplates = { error: false, loading: true };
        break;
      }
      case types.FETCH_IVR_BEDROCK_PROMPT_TEMPLATES_SUCCESS: {
        const data = action.payload;
        draft.promptTemplates = { error: false, loading: false, data };
        break;
      }
      case types.FETCH_IVR_BEDROCK_PROMPT_TEMPLATES_FAILURE: {
        draft.promptTemplates = { error: true, loading: false };
        break;
      }

      case types.SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES: {
        draft.foundPromptTemplates.error = false;
        draft.foundPromptTemplates.loading = true;
        break;
      }
      case types.SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES_SUCCESS: {
        const data = action.payload;
        draft.foundPromptTemplates = { error: false, loading: false, data };
        break;
      }
      case types.SEARCH_IVR_BEDROCK_PROMPT_TEMPLATES_FAILURE: {
        draft.foundPromptTemplates = { error: true, loading: false };
        break;
      }

      case types.DELETE_IVR_BEDROCK_PROMPT_TEMPLATE:
      case types.UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE:
      case types.CREATE_IVR_BEDROCK_PROMPT_TEMPLATE: {
        draft.isLoadingPromptTemplateMutation = true;
        break;
      }
      case types.DELETE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS:
      case types.UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS:
      case types.CREATE_IVR_BEDROCK_PROMPT_TEMPLATE_SUCCESS: {
        draft.isLoadingPromptTemplateMutation = false;
        break;
      }
      case types.DELETE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE:
      case types.UPDATE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE:
      case types.CREATE_IVR_BEDROCK_PROMPT_TEMPLATE_FAILURE: {
        draft.isLoadingPromptTemplateMutation = false;
        break;
      }

      case types.CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS: {
        draft.isLoadingPromptCompletion = true;
        break;
      }
      case types.CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS_SUCCESS: {
        draft.isLoadingPromptCompletion = false;
        break;
      }
      case types.CREATE_IVR_BEDROCK_PROMPT_COMPLETIONS_FAILURE: {
        draft.isLoadingPromptCompletion = false;
        break;
      }

      case types.FETCH_IVR_BEDROCK_MODELS: {
        draft.models.error = false;
        draft.models.loading = true;
        break;
      }
      case types.FETCH_IVR_BEDROCK_MODELS_SUCCESS: {
        draft.models.error = false;
        draft.models.loading = false;
        draft.models.data = action.payload;
        break;
      }
      case types.FETCH_IVR_BEDROCK_MODELS_FAILURE: {
        draft.models.data = null;
        draft.models.error = true;
        draft.models.loading = false;
        break;
      }

      default:
        return state;
    }
  });
}
