const Types = {
  GET_FIELDS_REQUEST: 'rd-station/GET_FIELDS_REQUEST',
  GET_FIELDS_SUCCESS: 'rd-station/GET_FIELDS_SUCCESS',
  RD_STATION_LOGIN: 'rd-station/RD_STATION_LOGIN',
  GENERATE_ACCESS_TOKEN_REQUEST: 'rd-station/GENERATE_ACCESS_TOKEN_REQUEST',
  GENERATE_ACCESS_TOKEN_SUCCESS: 'rd-station/GENERATE_ACCESS_TOKEN_SUCCESS',
  REMOVE_ACCESS_TOKEN_REQUEST: 'rd-station/REMOVE_ACCESS_TOKEN_REQUEST',
  RESET_AUTH_DATA: 'rd-station/RESET_AUTH_DATA',
};

export default Types;
