const Types = {
  FETCH_USERS: '@users/FETCH_USERS',
  FETCH_USERS_SUCCESS: '@users/FETCH_USERS_SUCCESS',
  FETCH_USER: '@users/FETCH_USER',
  FETCH_USER_SUCCESS: '@users/FETCH_USER_SUCCESS',
  CREATE_USER: '@users/CREATE_USER',
  CREATE_USER_SUCCESS: '@users/CREATE_USER_SUCCESS',
  UPDATE_USER: '@users/UPDATE_USER',
  UPDATE_USER_SUCCESS: '@users/UPDATE_USER_SUCCESS',
};

export default Types;
