import axios from 'axios';

const url =
  process.env.REACT_APP_API_LEIA_URL || 'https://api.leia.digitalcontact.cloud';

const api = axios.create({
  baseURL: `${url}`,
});

export default api;
