import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  isLoadingData: false,
  isRenderFlow: true,
  clientPosition: { x: null, y: null },
  copyPasteItems: { botId: null, selectedNodes: null, selectedEdges: null },
};

export default function builder(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_DATA_START: {
        draft.isLoadingData = true;
        break;
      }
      case types.FETCH_DATA_SUCCESS: {
        draft.isLoadingData = false;
        break;
      }
      case types.FETCH_DATA_FAILURE: {
        draft.isLoadingData = false;
        break;
      }
      case types.SET_IS_LOADING: {
        draft.isLoadingData = action.payload;
        break;
      }
      case types.SET_RENDER_FLOW: {
        draft.isRenderFlow = action.payload;
        break;
      }

      case types.SET_CLIENT_POSITION: {
        draft.clientPosition = action.payload;
        break;
      }

      case types.SET_COPY_PASTE_ITEMS: {
        draft.copyPasteItems = action.payload;
        break;
      }
      default:
    }
  });
}
