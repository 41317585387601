const VoiceBotsTypes = {
  FETCH_PROJECTS_REQUEST: '@voice-bots/FETCH_PROJECTS',
  FETCH_PROJECTS_SUCCESS: '@voice-bots/FETCH_PROJECTS_SUCCESS',
  FETCH_GRAMMARS_REQUEST: '@voice-bots/FETCH_GRAMMARS',
  FETCH_GRAMMARS_SUCCESS: '@voice-bots/FETCH_GRAMMARS_SUCCESS',
  FETCH_STATUS_REQUEST: '@voice-bots/FETCH_STATUS',
  FETCH_STATUS_SUCCESS: '@voice-bots/FETCH_STATUS_SUCCESS',
  FETCH_VOICES_REQUEST: '@voice-bots/FETCH_VOICES',
  FETCH_VOICES_SUCCESS: '@voice-bots/FETCH_VOICES_SUCCESS',
  FETCH_VOICES_FAILIURE: '@voice-bots/FETCH_VOICES_FAILIURE',
  FETCH_VOICEBOT_INSTANCES_REQUEST: '@voice-bots/FETCH_VOICEBOT_INSTANCES',
  FETCH_VOICEBOT_INSTANCES_SUCCESS:
    '@voice-bots/FETCH_VOICEBOT_INSTANCES_SUCCESS',
  FETCH_VOICEBOT_INSTANCES_FAILIURE:
    '@voice-bots/FETCH_VOICEBOT_INSTANCES_FAILIURE',
  CREATE_VOICEBOT_INSTANCE_REQUEST: '@voice-bots/CREATE_VOICEBOT_INSTANCE',
  CREATE_VOICEBOT_INSTANCE_SUCCESS:
    '@voice-bots/CREATE_VOICEBOT_INSTANCE_SUCCESS',
  UPDATE_VOICEBOT_INSTANCE_REQUEST: '@voice-bots/UPDATE_VOICEBOT_INSTANCE',
  UPDATE_VOICEBOT_INSTANCE_SUCCESS:
    '@voice-bots/UPDATE_VOICEBOT_INSTANCE_SUCCESS',
  FETCH_VARIABLES_REQUEST: '@voice-bots/FETCH_VARIABLES_REQUEST',
  FETCH_VARIABLES_SUCCESS: '@voice-bots/FETCH_VARIABLES_SUCCESS',
};

export default VoiceBotsTypes;
