import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

import {
  Container,
  WrapperButton,
  Dropdown,
  MenuContentScrollable,
} from './styles';
import MenuItem from './MenuItem';
import MenuItemInput from './MenuItemInput';

const Menu = React.forwardRef(
  (
    {
      position,
      button,
      disabled,
      children: childrenProp,
      value,
      onChange,
      closeMenuOnSelect,
      className,
      contentWidth,
      contentOffset,
      ...rest
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [buttonHeight, setButtonHeight] = useState(0);
    const [buttonWidth, setButtonWidth] = useState(0);

    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const handleButtonClick = (event) => {
      setIsOpen(!isOpen);

      const { currentTarget } = event;

      const isEqualPrevDimensions =
        currentTarget?.offsetHeight === buttonHeight &&
        currentTarget?.offsetWidth === buttonWidth;

      if (!isEqualPrevDimensions) {
        setButtonHeight(event.currentTarget?.offsetHeight);
        setButtonWidth(event.currentTarget?.offsetWidth);
      }
    };

    const handleCloseMenu = useCallback(
      (event) => {
        if (
          menuRef.current &&
          !menuRef.current.contains(event.target) &&
          buttonRef.current &&
          !buttonRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      },
      [menuRef, buttonRef]
    );

    useEffect(() => {
      document.addEventListener('click', handleCloseMenu);
      return () => {
        document.removeEventListener('click', handleCloseMenu);
      };
    });

    const handleClickMenuItem = (_value) => {
      if (closeMenuOnSelect) {
        setIsOpen(false);
      }

      onChange?.(_value);
    };

    const children = React.Children.map(childrenProp, (child) => {
      if (!React.isValidElement(child)) {
        return null;
      }

      return React.cloneElement(child, {
        active: child.props?.value === value,
        onClick: (e) => {
          handleClickMenuItem(child.props?.value);
          child.props?.onClick?.(e);
        },
      });
    });

    return (
      <Container className={className} ref={ref} {...rest}>
        <WrapperButton
          aria-expanded={isOpen}
          onClick={handleButtonClick}
          ref={buttonRef}
          className={cc([{ disabled }])}
        >
          {button}
        </WrapperButton>
        {!disabled ? (
          <>
            <Dropdown
              className={cc(['menu-dropdown', position, { 'is-open': isOpen }])}
              role="menu"
              buttonHeight={buttonHeight}
              buttonWidth={buttonWidth}
              contentOffset={contentOffset}
              contentWidth={
                contentWidth === '100%' ? `${buttonWidth}px` : contentWidth
              }
              ref={menuRef}
            >
              {children}
            </Dropdown>
          </>
        ) : (
          ''
        )}
      </Container>
    );
  }
);

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  closeMenuOnSelect: PropTypes.bool,
  button: PropTypes.element.isRequired,
  position: PropTypes.oneOf(['left', 'right', 'top', 'top-left', 'bottom']),
  contentWidth: PropTypes.string,
  contentOffset: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Menu.defaultProps = {
  position: 'bottom',
  contentWidth: '',
  contentOffset: 8,
  className: '',
  value: '',
  onChange: null,
  closeMenuOnSelect: true,
  disabled: false,
};

export { MenuItem, MenuItemInput, MenuContentScrollable };
export default Menu;
