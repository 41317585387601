const PlanTypes = {
  GET_PLANS_REQUEST: '@plan/GET_PLANS_REQUEST',
  GET_PLANS_REQUEST_SUCCESS: '@plan/GET_PLANS_REQUEST_SUCCESS',

  GET_HISTORY_COMPANY_PLAN_REQUEST: '@plan/GET_HISTORY_COMPANY_PLAN_REQUEST',
  GET_HISTORY_COMPANY_PLAN_REQUEST_SUCCESS:
    '@plan/GET_HISTORY_COMPANY_PLAN_REQUEST_SUCCESS',

  PUT_ALERTS: '@plan/PUT_ALERTS',
  PUT_ALERTS_SUCCESS: '@plan/PUT_ALERTS_SUCCESS',

  POST_ADDITIONAL_MESSAGE: '@plan/POST_ADDITIONAL_MESSAGE',
  POST_ADDITIONAL_MESSAGE_SUCCESS: '@plan/POST_ADDITIONAL_MESSAGE_SUCCESS',

  POST_NEW_PLAN: '@plan/POST_NEW_PLAN',
  POST_NEW_PLAN_SUCCESS: '@plan/POST_NEW_PLAN_SUCCESS',

  GET_COMPANY_PLAN_CONSUMPTION: '@plan/GET_COMPANY_PLAN_CONSUMPTION',
  GET_COMPANY_PLAN_CONSUMPTION_SUCCESS:
    '@plan/GET_COMPANY_PLAN_CONSUMPTION_SUCCESS',
};

export default PlanTypes;
