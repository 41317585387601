const Types = {
  FETCH_COMPANIES: '@company/FETCH_COMPANIES',
  FETCH_COMPANIES_SUCCESS: '@company/FETCH_COMPANIES_SUCCESS',

  FETCH_COMPANY: '@company/FETCH_COMPANY',
  FETCH_COMPANY_SUCCESS: '@company/FETCH_COMPANY_SUCCESS',

  UPDATE_COMPANY: '@company/UPDATE_COMPANY',
  UPDATE_COMPANY_SUCCESS: '@company/UPDATE_COMPANY_SUCCESS',

  CHANGE_PLAN_COMPANY: '@company/CHANGE_PLAN_COMPANY',
  CHANGE_PLAN_COMPANY_SUCCESS: '@company/CHANGE_PLAN_COMPANY_SUCCESS',

  CREATE_ADDITIONAL_MESSAGE: '@company/CREATE_ADDITIONAL_MESSAGE',
  CREATE_ADDITIONAL_MESSAGE_SUCCESS:
    '@company/CREATE_ADDITIONAL_MESSAGE_SUCCESS',

  CREATE_NEW_PLAN: '@plan/CREATE_NEW_PLAN',
  CREATE_NEW_PLAN_SUCCESS: '@plan/CREATE_NEW_PLAN_SUCCESS',

  FETCH_ORGANIZATIONS: '@company/FETCH_ORGANIZATIONS',
  FETCH_ORGANIZATIONS_SUCCESS: '@company/FETCH_ORGANIZATIONS_SUCCESS',

  FETCH_COMPANY_HISTORY_ACTIONS: '@company/FETCH_COMPANY_HISTORY_ACTIONS',
  FETCH_COMPANY_HISTORY_ACTIONS_SUCCESS:
    '@company/FETCH_COMPANY_HISTORY_ACTIONS_SUCCESS',
};

export default Types;
