import types from './types';

export const validateTopdeskSettingsRequest = (payload) => ({
  type: types.VALIDATE_TOPDESK_SETTINGS_REQUEST,
  payload,
});

export const validateTopdeskSettingsSuccess = (payload) => ({
  type: types.VALIDATE_TOPDESK_SETTINGS_SUCCESS,
  payload,
});

export const getTopdeskStatusesRequest = (payload) => ({
  type: types.GET_TOPDESK_STATUSES_REQUEST,
  payload,
});

export const getTopdeskStatusesSuccess = (payload) => ({
  type: types.GET_TOPDESK_STATUSES_SUCCESS,
  payload,
});

export const getTopdeskCategoriesRequest = (payload) => ({
  type: types.GET_TOPDESK_CATEGORIES_REQUEST,
  payload,
});

export const getTopdeskCategoriesSuccess = (payload) => ({
  type: types.GET_TOPDESK_CATEGORIES_SUCCESS,
  payload,
});

export const getTopdeskSubCategoriesRequest = (payload) => ({
  type: types.GET_TOPDESK_SUB_CATEGORIES_REQUEST,
  payload,
});

export const getTopdeskSubCategoriesSuccess = (payload) => ({
  type: types.GET_TOPDESK_SUB_CATEGORIES_SUCCESS,
  payload,
});

export const getTopdeskOperatorsRequest = (payload) => ({
  type: types.GET_TOPDESK_OPERATORS_REQUEST,
  payload,
});

export const getTopdeskOperatorsSuccess = (payload) => ({
  type: types.GET_TOPDESK_OPERATORS_SUCCESS,
  payload,
});

export const getTopdeskCallTypesRequest = (payload) => ({
  type: types.GET_TOPDESK_CALL_TYPES_REQUEST,
  payload,
});

export const getTopdeskCallTypesSuccess = (payload) => ({
  type: types.GET_TOPDESK_CALL_TYPES_SUCCESS,
  payload,
});

export const getTopdeskEntryTypesRequest = (payload) => ({
  type: types.GET_TOPDESK_ENTRY_TYPES_REQUEST,
  payload,
});

export const getTopdeskEntryTypesSuccess = (payload) => ({
  type: types.GET_TOPDESK_ENTRY_TYPES_SUCCESS,
  payload,
});
