import produce from 'immer';
import types from './types';

const initialState = {
  categories: [],
  subCategories: [],
  operatorsGroups: [],
  callTypes: [],
  entryTypes: [],
  integrationStatus: '',
  statuses: [],
};

export default function topdesk(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.VALIDATE_TOPDESK_SETTINGS_SUCCESS:
        draft.categories = action.payload.categories;
        draft.operatorsGroups = action.payload.operatorsGroups;
        draft.integrationStatus = action.payload.status;
        break;

      case types.GET_TOPDESK_STATUSES_SUCCESS:
        draft.statuses = action.payload.statusList;
        break;

      case types.GET_TOPDESK_CATEGORIES_SUCCESS:
        draft.categories = action.payload.categories;
        break;

      case types.GET_TOPDESK_SUB_CATEGORIES_SUCCESS:
        draft.subCategories = action.payload.subCategories;
        break;

      case types.GET_TOPDESK_OPERATORS_SUCCESS:
        draft.operatorsGroups = action.payload.operatorsGroups;
        break;

      case types.GET_TOPDESK_CALL_TYPES_SUCCESS:
        draft.callTypes = action.payload.callTypes;
        break;

      case types.GET_TOPDESK_ENTRY_TYPES_SUCCESS:
        draft.entryTypes = action.payload.entryTypes;
        break;

      default:
    }
  });
}
