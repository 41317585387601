import { takeLatest, call, put, all } from 'redux-saga/effects';

import api from '../../../services/api.leia';
import i18n from '../../../translations/i18n';

import { toast } from '~/components/CustomToast';
import { formatSelectOptions } from './utils';

import { commonLoadingStart, commonLoadingFinish } from '../common/actions';
import * as actions from './actions';
import types from './types';

export function* fetchProfiles({ payload }) {
  try {
    const { language } = payload;
    yield put(commonLoadingStart());
    const { data } = yield call(api.get, `/personas?language=${language}`);
    yield put(
      actions.fetchProfilesSuccess({
        profiles: formatSelectOptions(data) || [],
      })
    );
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_profiles_list'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchPressets({ payload }) {
  try {
    const { botId } = payload;

    yield put(commonLoadingStart());
    const { data } = yield call(api.get, `/tags/${botId}`);
    yield put(
      actions.fetchPressetsSuccess({
        pressets: formatSelectOptions(data?.tags, true) || [],
      })
    );
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_pressets_list'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.FETCH_PROFILES, fetchProfiles),
  takeLatest(types.FETCH_PRESSETS, fetchPressets),
]);
