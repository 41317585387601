import {
  takeLatest,
  takeEvery,
  call,
  put,
  all,
  select,
} from 'redux-saga/effects';
import moment from 'moment';
import i18n from '../../../translations/i18n';

import api from '~/services/api';
import { toast } from '~/components/CustomToast';

import {
  commonLoadingStart,
  commonLoadingFinish,
  commonChangingFinish,
} from '../common/actions';
import * as actions from './actions';
import * as variableActions from '../variables/actions';
import types from './types';
import { fetchItemsSuccess, fetchResumedAllItems } from '../items/actions';
import { fetchConnectorsSuccess } from '../connectors/actions';
import { setIsRenderFlow } from '../builder/actions';

import downloadFile from '~/utils/downloadFile';

import history from '../../../services/history';

export function* fetchBots() {
  try {
    yield put(commonLoadingStart());

    const response = yield call(api.get, '/v3/bots');

    yield put(actions.fetchBotsSuccess({ bots: response.data }));
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_bots'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchBotsByCompany() {
  try {
    yield put(commonLoadingStart());

    const response = yield call(api.get, `/v3/bots/company`);

    yield put(actions.fetchBotsByCompanySuccess({ bots: response.data }));
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_list_bots'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchBot({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { id } = payload;

    const url = `/v3/bots/${id}`;

    const response = yield call(api.get, url);

    yield put(actions.fetchBotSuccess({ bot: response.data }));
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_find_bot'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* checkPublishStatus() {
  try {
    const { bot } = yield select((status) => status.bots);
    const botId = bot._id;
    const response = yield call(api.get, `/v3/bots/${botId}`);
    const responseData = response.data;
    const publishStatus = responseData.publishStatus;
    if (publishStatus !== 'publishing') {
      yield put(actions.setPublishStatus({ botId, publishStatus }));
    }
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_check_publish_status')
    );
  }
}

export function* fetchBotCollectDataCount({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, dashboard_token, startDate, endDate } = payload;
    const url = `/v3/dashboard/collect-data-count?bot_id=${botId}&token=${dashboard_token}&start_date=${startDate}&end_date=${endDate}`;
    const { data } = yield call(api.get, url);

    yield put(actions.fetchBotCollectDataCountSuccess({ data }));
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_unexpected'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchBotCollectDataCSV({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { botId, dashboard_token, startDate, endDate } = payload;
    const url = `/v3/dashboard/collect-data-csv?bot_id=${botId}&token=${dashboard_token}&start_date=${startDate}&end_date=${endDate}`;
    const { data } = yield call(api.get, url, { responseType: 'arraybuffer' });

    downloadFile({ type: 'text/csv', fileName: botId, file: data });
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_unexpected'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createBot({ payload, callback }) {
  try {
    yield put(commonLoadingStart());

    const newBotData = payload;

    const formData = new FormData();
    Object.keys(newBotData).forEach((key) => {
      formData.append(key, newBotData[key]);
    });

    const { data } = yield call(api.post, '/v3/bots', formData);

    if (!data?._id) {
      throw Error(i18n.t('error.error_create_bot'));
    }

    yield put(actions.createBotSuccess({ newBot: data }));

    callback?.(data?._id);

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_create_bot')
    );
  } catch (err) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_create_bot'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteBot({ payload }) {
  try {
    yield put(commonLoadingStart());

    const { id, callback } = payload;
    yield call(api.delete, `/v3/bots/${id}`);

    yield put(actions.deleteBotSuccess({ id }));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_remove_bot')
    );

    callback?.();
  } catch ({ response }) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_delete_bot'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* cloneBot(action) {
  try {
    const { uuid, title, description, companyId, organizationId, copyAI } =
      action.payload;

    yield put(commonLoadingStart());

    const { data } = yield call(api.post, `/v3/bots/copy`, {
      uuid,
      title,
      description,
      companyId,
      organizationId,
      copyAI,
    });

    yield put(actions.cloneBotRequestSuccess({ pendingBot: data }));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_clone_bot')
    );
  } catch ({ response }) {
    const responseMessagesByStatusCode = {
      410: i18n.t('error.clone_expired'),
      404: i18n.t('error.clone_not_found'),
    };
    const message =
      responseMessagesByStatusCode[response.status] ||
      i18n.t('error.error_create_bot');

    toast.error(i18n.t('error.error'), message);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateBotStatusRequest(action) {
  const { botId, isActive } = action.payload;
  try {
    yield put(actions.activeBotLoadingStart());

    yield call(api.put, `/v3/bots/update/${botId}`, {
      isActive,
    });

    yield put(
      actions.updateBotStatusRequestSuccess({
        isActive,
        botId,
      })
    );
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_update_bot'));
  } finally {
    yield put(actions.activeBotLoadingFinish());
  }
}

export function* updateBotLastUpdate({ payload }) {
  try {
    const { botId, qtdUpdates } = payload;
    const lastUpdate = new Date();

    const { data } = yield call(
      api.put,
      `/v3/bots/update/lastUpdate/${botId}`,
      { lastUpdate, qtdUpdates }
    );
    const { updatedBotData } = data;
    yield put(actions.updateBotLastUpdateSuccess(updatedBotData));
  } catch ({ response }) {
    toast.error(i18n.t('error.user_update_bot'));
  }
}

export function* updateBot(action) {
  try {
    yield put(commonLoadingStart());

    const apiUrl = `/v3/bots/update/${action.payload.botid}`;

    const { data } = yield call(api.put, apiUrl, action.payload.data);

    if (action.payload.showToast) {
      toast.success(
        i18n.t('success.success'),
        i18n.t('success.success_upadte_bot')
      );
    }
    yield put(actions.updateBotSuccess({ data: data.bot }));
    yield put(commonChangingFinish());

    action.payload.callback?.();
  } catch (error) {
    if (error.response.status === 406)
      toast.error(i18n.t('error.error'), i18n.t('error.not_have_permission'));
    else toast.error(i18n.t('error.error'), i18n.t('error.error_update_bot'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateBotGeneralInformation(action) {
  const { callback } = action.payload;

  try {
    yield put(commonLoadingStart());
    const { botId, ...newPayload } = action.payload;
    const formData = new FormData();
    Object.keys(newPayload).forEach((key) => {
      formData.append(key, newPayload[key]);
    });

    const url = `/v3/bots/update-general/${botId}`;
    const { data } = yield call(api.put, url, formData);

    yield put(actions.updateBotSuccess({ data }));

    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_upadte_bot')
    );
    yield put(commonChangingFinish());
    callback?.(true);
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_update_bot'));
    callback?.(false);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateJiraSettings(action) {
  const { botId, settings } = action;
  try {
    yield put(commonLoadingStart());

    const response = yield call(api.post, `/v3/bots/jira-settings/${botId}`, {
      settings,
    });

    if (response?.data) {
      const { bot } = yield select((state) => state.bots);

      const newSettings = {
        ...bot.settings,
        jiraSettings: settings,
      };

      yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));
      toast.success(
        i18n.t('success.success'),
        i18n.t('success.success_saving_config')
      );
      yield put(commonChangingFinish());
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      toast.error(i18n.t('error.error'), error.response.data);
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateZendeskSettings(action) {
  const { botId, data: settings, callback } = action.payload;
  try {
    yield put(commonLoadingStart());

    const response = yield call(
      api.post,
      `/v3/bots/zendes-ticket-settings/${botId}`,
      {
        settings,
      }
    );

    if (response && response?.data) {
      const { bot } = yield select((state) => state.bots);

      const newSettings = {
        ...bot.settings,
        zendeskTicket: settings,
      };

      toast.success(
        i18n.t('success.success'),
        i18n.t('success.success_saving_config')
      );

      yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));
      yield put(commonChangingFinish());

      callback?.();
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      toast.error(i18n.t('error.error'), error.response.data);
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getPhraseology(action) {
  const { botId, page = 1, limit = 20, searchTerm = '' } = action.payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.get,
      `/v3/bots/${botId}/phraseology?page=${page}&limit=${limit}&searchTerm=${searchTerm}`
    );

    yield put(actions.getBotPhraseologySuccess(data));
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_getting_phraseology')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updatePhraseology(action) {
  const { botId, records } = action.payload;

  try {
    const { data } = yield call(
      api.put,
      `/v3/bots/${botId}/phraseology`,
      { records },
      {
        clearCacheEntry: [
          '/items',
          '/resumed-all-items',
          '/all-items',
          '/items-subflow',
        ],
      }
    );
    const { updatedBotData } = data;
    yield put(actions.fetchBotSuccess({ bot: updatedBotData }));
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_updating_phraseology')
    );
  }
}

export function* getBotVariables(action) {
  const { botId } = action.payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(api.get, `/v3/bots/variable/${botId}`);

    yield put(actions.getBotVariablesSuccess(data));
    yield put(variableActions.listVariablesSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_getting_variables'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* publishRequest(action) {
  const { botId, isActive } = action.payload;
  yield put(actions.setPublishStatus({ botId, publishStatus: 'publishing' }));

  try {
    const { data } = yield call(
      api.post,
      `/v3/bots/${botId}/publish`,
      {
        isActive,
      },
      {
        clearCacheEntry: [`/v3/bots/`],
      }
    );

    toast.success(
      i18n.t('success.success_publish_bot.title'),
      i18n.t('success.success_publish_bot.subtitle')
    );

    yield put(actions.publishBotSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_publish_bot'));
    yield put(actions.publishBotFailed());
  }
}

export function* changeStageArea(action) {
  try {
    const { botId, version } = action.payload;

    const { data } = yield call(api.put, `/v3/bots/${botId}/stagingArea`, {
      version: version?.id,
    });

    yield put(fetchResumedAllItems({ botId }));
    yield put(fetchConnectorsSuccess({ connectors: data.connectors }));
    yield put(fetchItemsSuccess({ items: data.items }));
    yield put(actions.fetchBotSuccess({ bot: data.bot }));

    yield put(actions.changeStageAreaSuccess());

    yield put(setIsRenderFlow(true));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_update_staged_area'));
    yield put(actions.changeStageAreaFailed());
  }
}

export function* updateAttendanceHoursSettings(action) {
  const { botId, settings, newSettings, message } = action;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      api.post,
      `/v3/bots/timezone-settings/${botId}`,
      {
        settings,
      },
      {
        clearCacheEntry: [`/bots`],
      }
    );
    yield put(actions.retrieveAttendenceHoursSettings(data));
    yield put(actions.updateBotLastUpdateSuccess(data));
    yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));
    message === 'save'
      ? toast.success(
          i18n.t('success.success'),
          i18n.t('success.success_office_hours')
        )
      : toast.success(
          i18n.t('success.success'),
          i18n.t('success.delete_office_hours')
        );
    yield put(commonChangingFinish());
  } catch (error) {
    if (error?.response?.status === 404) {
      toast.error(i18n.t('error.error'), error.response.data);
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_office_hours'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* verifyAttendanceHours(action) {
  const { botId, businessHourId, callback } = action.payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      api.post,
      `/v3/bots/verify-business-hour/${botId}`,
      {
        businessHourId,
      }
    );
    if (data && data.success) {
      callback?.(data);
    } else {
      callback?.();
    }
    yield put(commonChangingFinish());
  } catch (error) {
    callback?.();
  }
}

export function* updateDaysOffSettings(action) {
  const { botId, settings } = action;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(
      api.post,
      `/v3/bots/daysoff-settings/${botId}`,
      {
        settings,
      }
    );
    yield put(actions.updateBotLastUpdateSuccess(data));

    if (data) {
      const { bot } = yield select((state) => state.bots);

      const newSettings = {
        ...bot.settings,
        daysOff: settings,
      };
      yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));
    }

    if (action.settings.showToast) {
      toast.success(
        i18n.t('success.success'),
        i18n.t('success.success_days_off')
      );
    }
    yield put(commonChangingFinish());
  } catch (error) {
    if (error?.response?.status === 404) {
      toast.error(i18n.t('error.error'), error.response.data);
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_days_off'));
    }
  }
}
export function* getBotHumanAttendanceQueues(action) {
  const { botId } = action.payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.get, `/v3/bots/${botId}/humanAttendence`);
    yield put(actions.getBotHumanAttendanceQueuesSuccess({ queues: data }));
  } catch (error) {
    toast.error(i18n.t('error.oops'), i18n.t('error.error_list_queued'));
    yield put(actions.getBotHumanAttendanceQueuesSuccess({ queues: [] }));
  } finally {
    yield put(commonLoadingFinish());
  }
}

const onPeriodInvalid = (startDate, endDate) => {
  const isInvalid = moment(startDate).isAfter(endDate);
  if (isInvalid) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_start_date_before_end')
    );
  }
  return isInvalid;
};

const addEndpointBotParameters = ({
  startDate,
  endDate,
  tags = [],
  channels = [],
  variables = [],
  timezone,
  session,
  sessionPhoneNumber,
  favoriteOption,
}) => {
  let endpoint = ``;

  if (startDate) {
    endpoint += `&start=${startDate}`;
  }

  if (endDate) {
    endpoint += `&end=${endDate}`;
  }

  if (timezone) {
    endpoint += `&timezone=${timezone}`;
  }

  if (tags.length !== 0) {
    endpoint += `&tags=${encodeURIComponent(JSON.stringify(tags))}`;
  }

  if (channels.length !== 0) {
    endpoint += `&channels=${encodeURIComponent(JSON.stringify(channels))}`;
  }

  if (variables.length !== 0) {
    endpoint += `&variables=${encodeURIComponent(JSON.stringify(variables))}`;
  }

  if (session) {
    endpoint += `&session=${session}`;
  }

  if (sessionPhoneNumber) {
    endpoint += `&session_phone_number=${encodeURIComponent(
      sessionPhoneNumber.trim()
    )}`;
  }

  if (favoriteOption) {
    endpoint += `&favorite_option=${encodeURIComponent(favoriteOption)}`;
  }

  return endpoint;
};

export function* getSessions(action) {
  const {
    botId,
    token,
    startDate,
    endDate,
    tags,
    channels,
    variables,
    timezone,
    sessionPhoneNumber,
    index,
    favoriteOption,
  } = action.payload;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) return;

  try {
    yield put(commonLoadingStart());

    const url = `/v3/bots/sessions?bot=${botId}&token=${token}&index=${index}&count=10${addEndpointBotParameters(
      {
        startDate,
        endDate,
        tags,
        channels,
        variables,
        timezone,
        sessionPhoneNumber,
        favoriteOption,
      }
    )}`;

    const { data } = yield call(api, url);

    yield put(actions.getSessionsSuccess(data));
  } catch (error) {
    const response = JSON.parse(error.request?.response);
    if (response.statusCode === 400) {
      toast.error(
        i18n.t('error.error'),
        i18n.t('reports.report_filters.error.period_less_than_31')
      );
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_unexpected'));
    }
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getSessionById(action) {
  const { sessionId, favoriteOption } = action.payload;

  try {
    yield put(commonLoadingStart());

    const url = `/v3/bots/sessionById/?sessionId=${sessionId}&favoritOption=${favoriteOption}`;

    const { data } = yield call(api, url);

    yield put(actions.getSessionByIdSuccess(data));
  } catch (error) {
    history.push('/static-error', {});
    yield put(actions.getSessionByIdSuccess({ session: {} }));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getMessagesSession(action) {
  const { botId, startDate, endDate, session, favoriteOption } = action.payload;
  const isInvalid = onPeriodInvalid(startDate, endDate);

  if (isInvalid) return;

  try {
    yield put(commonLoadingStart());

    const url = `/v3/bots/messages?bot=${botId}&session=${session}${addEndpointBotParameters(
      { favoriteOption }
    )}`;

    const { data } = yield call(api, url);

    yield put(actions.getMessagesSessionSuccess(data));
  } catch (e) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_unexpected'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateGlpiSettings(action) {
  const { botId, settings } = action;

  try {
    yield put(commonLoadingStart());

    const response = yield call(
      api.post,
      `/v1/bots/glpi-settings/${botId}`,
      settings
    );

    if (response?.data) {
      const { bot } = yield select((state) => state.bots);

      const newSettings = {
        ...bot.settings,
        glpiSettings: settings,
      };

      yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));

      toast.success(
        i18n.t('success.success'),
        i18n.t('success.success_saving_config')
      );

      yield put(actions.updateBotSuccess({ data: '' }));
      yield put(commonChangingFinish());
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
    }
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateVtexSettings(action) {
  const { botId, settings } = action;

  try {
    yield put(commonLoadingStart());

    const response = yield call(
      api.post,
      `/v3/bots/vtex-settings/${botId}`,
      settings
    );

    if (response?.data) {
      const { bot } = yield select((state) => state.bots);

      const newSettings = {
        ...bot.settings,
        vtexSettings: settings,
      };

      toast.success(
        i18n.t('success.success'),
        i18n.t('success.success_saving_config')
      );

      yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
    }
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateChatGptSettings(action) {
  const { botId, settings, showToast, callback } = action;

  try {
    yield put(commonLoadingStart());

    const response = yield call(
      api.post,
      `/v3/bots/chatgpt-settings/${botId}`,
      settings
    );

    if (response?.data) {
      const { bot } = yield select((state) => state.bots);

      const newSettings = {
        ...bot.settings,
        chatgpt: settings,
      };

      if (showToast) {
        toast.success(
          i18n.t('success.success'),
          i18n.t('success.success_saving_config')
        );
      }

      yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));
      yield put(commonChangingFinish());
      callback?.();
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
    }
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateLeiaSettings(action) {
  const { botId, settings, showToast, callback } = action;

  try {
    yield put(commonLoadingStart());

    const response = yield call(
      api.post,
      `/v3/bots/leia-settings/${botId}`,
      settings
    );

    if (response?.data) {
      const { bot } = yield select((state) => state.bots);

      const newSettings = {
        ...bot.settings,
        leia: settings,
      };

      if (showToast) {
        toast.success(
          i18n.t('success.success'),
          i18n.t('success.success_saving_config')
        );
      }

      yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));
      yield put(commonChangingFinish());
      callback?.();
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
    }
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_saving_config'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateQuickAccess(action) {
  const { payload } = action;
  const { botId, settings } = payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.post,
      `/v3/bots/quick-access-settings/${botId}`,
      { settings }
    );
    const { countUpdatesNoPublish } = data;
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_update_quick_access')
    );

    yield put(actions.updateQuickAccessSuccess(data));
    yield put(actions.updateBotLastUpdateSuccess({ countUpdatesNoPublish }));
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_update_quick_access')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* getHolidays(action) {
  const { botId } = action.payload;
  const COUNTRY = 'BR';
  try {
    yield put(actions.activeBotLoadingStart());

    const { data } = yield call(
      api.get,
      `/v3/bots/${botId}/days-off/${COUNTRY}`
    );
    yield put(actions.getHolidaysSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_update_bot'));
  } finally {
    yield put(actions.activeBotLoadingFinish());
  }
}

export function* fetchBuilderTracking(action) {
  yield put(commonLoadingStart());

  try {
    const {
      botId,
      limit,
      page,
      startDate,
      endDate,
      users,
      actions: userActions,
      itemId,
    } = action.payload;

    const myParams = {
      limit,
      startDate,
      endDate,
      users,
      actions: userActions,
      itemId,
    };

    const url = `v3/bots/${botId}/builder-tracking`;
    let params = `page=${page}`;

    Object.entries(myParams).forEach(([key, value]) => {
      if (value) params += `&${key}=${value}`;
    });

    const { data } = yield call(api.get, `${url}?${params}`);

    yield put(actions.fetchBuilderTrackingSuccess(data));
  } catch (error) {
    const response = JSON.parse(error.request?.response);
    if (response.statusCode === 400) {
      toast.error(
        i18n.t('error.error'),
        i18n.t('reports.report_filters.error.period_less_than_31')
      );
    } else {
      toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_audit'));
    }
    yield put(actions.fetchBuilderTrackingSuccess({ data: [] }));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchBuilderTrackingDetails(action) {
  yield put(commonLoadingStart());

  try {
    const { botId, itemId } = action.payload;

    const url = `v3/bots/${botId}/builder-tracking/details/${itemId}`;

    const { data } = yield call(api.get, url);

    yield put(commonLoadingFinish());

    yield put(actions.fetchBuilderTrackingDetailsSuccess(data));
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_audit'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchBotsWithShareableIA(action) {
  try {
    const { companyId } = action.payload;
    const response = yield call(api.get, `/v3/bots/shareable-ia/${companyId}`);
    yield put(actions.fetchBotsWithShareableIASuccess(response.data));
  } catch ({ response }) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_fetch_bots_shareable_ia')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* fetchBuilderPendings(action) {
  yield put(commonLoadingStart());

  try {
    const { botId, limit, page } = action.payload;
    if (botId) {
      const url = `v3/bots/${botId}/builder-pending`;
      const params = `page=${page}&limit=${limit}`;

      const { data } = yield call(api.get, `${url}?${params}`);

      yield put(actions.fetchBuilderPendingsSuccess(data));
    }
  } catch (error) {
    const response = error.request?.response;
    if (!response.includes('ObjectId')) {
      toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_audit'));
    }
    yield put(actions.fetchBuilderPendingsSuccess({ data: [] }));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* returnBot({ payload }) {
  yield put(
    actions.returnBotSuccess({
      returnedBot: {
        id: payload.botId,
        url: payload.url,
        itemId: payload.itemId,
        positionOnScreen: payload.positionOnScreen,
      },
    })
  );
}

export function* fetchBehaviorPendings(action) {
  yield put(commonLoadingStart());

  try {
    const { botId, limit, page } = action.payload;

    const url = `v3/bots/${botId}/behavior-pending`;
    const params = `page=${page}&limit=${limit}`;

    const { data } = yield call(api.get, `${url}?${params}`);

    yield put(actions.fetchBehaviorPendingsSuccess(data));
  } catch (error) {
    const response = error.request?.response;
    if (!response.includes('ObjectId')) {
      toast.error(i18n.t('error.error'), i18n.t('error.error_fetch_audit'));
    }
    yield put(actions.fetchBehaviorPendingsSuccess({ data: [] }));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* setMessageComment({ payload }) {
  const { comment, messageId, userId, type, callback } = payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.put, `v3/bots/message/action`, {
      id: messageId,
      userId,
      comment,
      action: 'comment',
      type,
    });

    yield put(
      actions.setMessageCommentSuccess({
        comment,
        userId,
        messageId,
        commentId: data?.commentId,
      })
    );
    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_commenting_message')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* removeMessageCommentRequest({ payload }) {
  const { commentId, messageId, type, callback } = payload;
  try {
    yield put(commonLoadingStart());

    yield call(api.put, `v3/bots/message/comment/${messageId}`, {
      commentId,
      type,
    });

    yield put(actions.removeMessageCommentSuccess({ commentId, messageId }));
    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'), i18n.t('error.error_removing_comment'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* setMessageFavoriteRequest({ payload }) {
  const { messageId, userId, type, favorite, callback } = payload;
  try {
    yield put(commonLoadingStart());
    yield call(api.put, `v3/bots/message/action`, {
      id: messageId,
      userId,
      action: 'favorite',
      favorite,
      type,
    });

    yield put(
      actions.setMessageFavoriteSuccess({ messageId, userId, favorite })
    );
    callback?.();
  } catch (error) {
    toast.error(
      i18n.t('error.error'),
      i18n.t('error.error_favoriting_comment')
    );
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* setCustomChannelApiKeyRequest({ payload }) {
  const botId = payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.post, `v3/bots/${botId}/custom/key`);
    yield put(actions.setCustomChannelApiKeySuccess(data.message));
    toast.success(
      i18n.t('success.success'),
      i18n.t('bots.channels.custom_channel.generated_success')
    );
  } catch (error) {
    toast.error(i18n.t('error.error'), error.response.message);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* viewCustomChannelApiKeyRequest({ payload }) {
  const botId = payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.get, `v3/bots/${botId}/custom/key`);
    yield put(actions.viewCustomChannelApiKeySuccess(data.message));
  } catch (error) {
    if (error.response.status === 406)
      toast.error(i18n.t('error.error'), i18n.t('error.not_have_permission'));
    else toast.error(i18n.t('error.error'), error.response.message);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteCustomChannelApiKeyRequest({ payload }) {
  const botId = payload;
  try {
    yield put(commonLoadingStart());
    yield call(api.delete, `v3/bots/${botId}/custom/key`);
    yield put(actions.deleteCustomChannelApiKeySuccess());
    toast.success(
      i18n.t('success.success'),
      i18n.t('bots.channels.custom_channel.delete_key_success')
    );
  } catch (error) {
    if (error.response.status === 406)
      toast.error(i18n.t('error.error'), i18n.t('error.not_have_permission'));
    else toast.error(i18n.t('error.error'), error.response.message);
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateChannel({ payload }) {
  const { botId, channelId, data } = payload;
  try {
    yield put(commonLoadingStart());
    yield call(api.patch, `v3/bots/${botId}/channels/${channelId}`, data);
    toast.success(
      i18n.t('success.success'),
      i18n.t('success.success_upadte_bot')
    );
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteChannel({ payload }) {
  const { botId, channelId } = payload;
  try {
    yield put(commonLoadingStart());
    yield call(api.delete, `v3/bots/${botId}/channels/${channelId}`);
    toast.success(i18n.t('success.success'));
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateHumanAttendance({ payload }) {
  const { botId, data } = payload;
  try {
    yield put(commonLoadingStart());
    yield call(api.patch, `v3/bots/${botId}/human`, data);
    const { bot } = yield select((state) => state.bots);

    const newSettings = {
      ...bot.settings,
      human: data,
    };

    yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));
    toast.success(i18n.t('success.success'));
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateIdentity({ payload }) {
  const { botId, data } = payload;
  try {
    yield put(commonLoadingStart());
    yield call(api.patch, `v3/bots/${botId}/identity`, data);
    yield put(actions.updateBotSuccess({ data }));
    yield put(actions.updateBotLastUpdate(payload));
    toast.success(i18n.t('success.success'));
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* updateIntegration({ payload }) {
  const { botId, integrationId, data } = payload;
  try {
    yield put(commonLoadingStart());
    yield call(
      api.patch,
      `v3/bots/${botId}/integration/${integrationId}`,
      data
    );

    const { bot } = yield select((state) => state.bots);

    const newSettings = {
      ...bot.settings,
    };

    if (data?.campaign) {
      newSettings.sms = data;
    }

    if (data?.host) {
      newSettings.email = data;
    }

    if (data?.storage) {
      newSettings.storage = data;
    }

    toast.success(i18n.t('success.success'));
    yield put(actions.updateBotSuccess({ data: { settings: newSettings } }));
    yield put(commonChangingFinish());
  } catch (error) {
    toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* createFork({ payload }) {
  const { botId, description, date, callback } = payload;
  try {
    yield put(commonLoadingStart());
    const { data } = yield call(api.post, `v3/bots/${botId}/fork`, {
      description,
      date,
    });
    yield put(actions.createForkRequestSuccess(data));
    toast.success(i18n.t('success.success'));
    callback?.();
  } catch (error) {
    if (error.response.status === 403)
      toast.error(i18n.t('error.error'), 'Limite de 10 forks excedido');
    else toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* deleteFork({ payload }) {
  const { botId, forkId, callback } = payload;
  try {
    yield put(commonLoadingStart());
    yield call(api.delete, `v3/bots/${botId}/fork/${forkId}`);
    toast.success(i18n.t('success.success'));
    yield put(actions.deleteForkRequestSuccess({ forkId }));
    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* compareFork({ payload }) {
  const { botId, base, target, callback } = payload;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.post,
      `v3/bots/${botId}/fork/${base}/compare`,
      { targetForkId: target }
    );

    if (data?.checkUsersPresence) {
      yield put(
        actions.fetchUsersPresenceBuilderSuccess(data?.usersPresence || [])
      );
      yield put(commonLoadingFinish());
      return;
    }

    if (
      data &&
      !data.newItems.length &&
      !data.newConnectors.length &&
      !data.modifiedItems.length &&
      !data.deletedConnectors.length &&
      !data.deletedItems.length
    ) {
      toast.default(
        i18n.t('labels.warning'),
        i18n.t('bots.forks.modal.without_changes')
      );
      return;
    }
    callback?.({
      newItems: data.newItems,
      newConnectors: data.newConnectors,
      modifiedItems: data.modifiedItems,
      warningDuplicateConnector: data?.warningDuplicateConnector,
      deletedItems: data?.deletedItems,
      deletedConnectors: data?.deletedConnectors,
    });
  } catch (error) {
    toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* mergeFork({ payload }) {
  const {
    modifiedItems,
    botId,
    targetForkId,
    baseForkId,
    userId,
    userName,
    callback,
  } = payload;
  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.post,
      `v3/bots/${botId}/fork/${baseForkId}/merge`,
      {
        modifiedItems,
        targetForkId,
        userId,
        userName,
      }
    );

    if (data?.checkUsersPresence) {
      yield put(
        actions.fetchUsersPresenceBuilderSuccess(data?.usersPresence || [])
      );
      yield put(commonLoadingFinish());
      return;
    }

    if (data?.status) {
      yield put(
        actions.updateStatusForkSuccess({
          forkId: baseForkId,
          status: data?.status,
        })
      );
    }
    toast.success(i18n.t('success.success'));
    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export function* pullForkRequest({ payload }) {
  const { botId, forkId, callback } = payload;

  try {
    yield put(commonLoadingStart());

    const { data } = yield call(
      api.post,
      `v3/bots/${botId}/fork/${forkId}/pull`
    );

    if (data?.checkUsersPresence) {
      yield put(
        actions.fetchUsersPresenceBuilderSuccess(data?.usersPresence || [])
      );
      yield put(commonLoadingFinish());
      return;
    }

    if (data?.status) {
      yield put(
        actions.updateStatusForkSuccess({
          forkId,
          status: data?.status,
          updatedAt: data?.updatedAt,
        })
      );
    }
    toast.success(i18n.t('success.success'));
    callback?.();
  } catch (error) {
    toast.error(i18n.t('error.error'));
  } finally {
    yield put(commonLoadingFinish());
  }
}

export default all([
  takeLatest(types.FETCH_BOTS_REQUEST, fetchBots),
  takeLatest(types.FETCH_BOTS_BY_COMPANY_REQUEST, fetchBotsByCompany),
  takeLatest(types.FETCH_BOT_REQUEST, fetchBot),
  takeLatest(types.FETCH_COLLECT_DATA_COUNT, fetchBotCollectDataCount),
  takeLatest(types.FETCH_BOT_COLLECT_DATA_CSV, fetchBotCollectDataCSV),
  takeLatest(types.CREATE_REQUEST, createBot),
  takeLatest(types.DELETE_REQUEST, deleteBot),
  takeLatest(types.UPDATE_BOT_REQUEST, updateBot),
  takeLatest(types.CLONE_REQUEST, cloneBot),
  takeLatest(types.UPDATE_LAST_UPDATE_REQUEST, updateBotLastUpdate),
  takeLatest(types.UPDATE_BOT_GENERAL_REQUEST, updateBotGeneralInformation),
  takeLatest(types.UPDATE_JIRA_SETTINGS_REQUEST, updateJiraSettings),
  takeLatest(types.UPDATE_ZENDESK_SETTINGS_REQUEST, updateZendeskSettings),
  takeLatest(types.UPDATE_DAYS_OFF_SETTINGS, updateDaysOffSettings),
  takeLatest(types.GET_PHRASEOLOGY_REQUEST, getPhraseology),
  takeLatest(types.UPDATE_PHRASEOLOGY_REQUEST, updatePhraseology),
  takeLatest(types.GET_VARIABLES_REQUEST, getBotVariables),
  takeLatest(types.CHANGE_STAGING_AREA_REQUEST, changeStageArea),
  takeLatest(types.PUBLISH_BOT_REQUEST, publishRequest),
  takeLatest(
    types.GET_BOT_HUMAN_ATTENDANCE_QUEUES,
    getBotHumanAttendanceQueues
  ),
  takeLatest(types.GET_SESSIONS, getSessions),
  takeLatest(types.GET_SESSION_BY_ID, getSessionById),
  takeLatest(types.GET_MESSAGES_SESSIONS, getMessagesSession),
  takeLatest(types.UPDATE_GLPI_SETTINGS, updateGlpiSettings),
  takeLatest(types.UPDATE_VTEX_SETTINGS, updateVtexSettings),
  takeLatest(types.UPDATE_CHATGPT_SETTINGS, updateChatGptSettings),
  takeLatest(types.UPDATE_LEIA_SETTINGS, updateLeiaSettings),
  takeLatest(types.UPDATE_STATUS_REQUEST, updateBotStatusRequest),
  takeLatest(types.UPDATE_QUICK_ACCESS, updateQuickAccess),
  takeLatest(types.GET_HOLIDAYS, getHolidays),
  takeLatest(types.FETCH_BUILDER_TRACKING, fetchBuilderTracking),
  takeLatest(types.FETCH_BUILDER_TRACKING_DETAILS, fetchBuilderTrackingDetails),
  takeLatest(types.FETCH_BOTS_WITH_SHAREABLE_IA, fetchBotsWithShareableIA),
  takeLatest(types.FETCH_BUILDER_PENDINGS, fetchBuilderPendings),
  takeLatest(types.RETURN_BOT, returnBot),
  takeLatest(types.FETCH_BEHAVIOR_PENDINGS, fetchBehaviorPendings),
  takeLatest(types.SET_MESSAGE_FAVORITE_REQUEST, setMessageFavoriteRequest),
  takeLatest(types.SET_MESSAGE_COMMENT_REQUEST, setMessageComment),
  takeLatest(types.REMOVE_MESSAGE_COMMENT_REQUEST, removeMessageCommentRequest),
  takeLatest(types.SET_CUSTOM_CHANNEL_API_KEY, setCustomChannelApiKeyRequest),
  takeLatest(types.VIEW_CUSTOM_CHANNEL_API_KEY, viewCustomChannelApiKeyRequest),
  takeLatest(
    types.DELETE_CUSTOM_CHANNEL_API_KEY,
    deleteCustomChannelApiKeyRequest
  ),
  takeLatest(types.UPDATE_CHANNEL_REQUEST, updateChannel),
  takeLatest(types.DELETE_CHANNEL_REQUEST, deleteChannel),
  takeLatest(types.UPDATE_HUMAN_ATTENDANCE_REQUEST, updateHumanAttendance),
  takeLatest(types.UPDATE_IDENTITY_REQUEST, updateIdentity),
  takeLatest(
    types.UPDATE_ATTENDANCE_HOUR_SETTINGS,
    updateAttendanceHoursSettings
  ),
  takeLatest(types.VERIFY_ATTENDANCE_HOUR, verifyAttendanceHours),
  takeLatest(types.UPDATE_INTEGRATION_REQUEST, updateIntegration),
  takeLatest(types.CREATE_FORK_REQUEST, createFork),
  takeEvery(types.DELETE_FORK_REQUEST, deleteFork),
  takeLatest(types.COMPARE_FORK_REQUEST, compareFork),
  takeLatest(types.MERGE_FORK_REQUEST, mergeFork),
  takeLatest(types.PULL_FORK_REQUEST, pullForkRequest),
  takeLatest(types.CHECK_PUBLISH_STATUS, checkPublishStatus),
]);
