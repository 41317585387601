const Types = {
  FETCH_IVR_CHAT_GPT_MODELS: '@ivrChatGPT/FETCH_IVR_CHAT_GPT_MODELS',
  FETCH_IVR_CHAT_GPT_MODELS_SUCCESS:
    '@ivrChatGPT/FETCH_IVR_CHAT_GPT_MODELS_SUCCESS',
  FETCH_IVR_CHAT_GPT_MODELS_FAILURE:
    '@ivrChatGPT/FETCH_IVR_CHAT_GPT_MODELS_FAILURE',

  FETCH_IVR_CHATGPT_PROMPT_TEMPLATES:
    '@ivrChatGPT/FETCH_IVR_CHATGPT_PROMPT_TEMPLATES',
  FETCH_IVR_CHATGPT_PROMPT_TEMPLATES_SUCCESS:
    '@ivrChatGPT/FETCH_IVR_CHATGPT_PROMPT_TEMPLATES_SUCCESS',
  FETCH_IVR_CHATGPT_PROMPT_TEMPLATES_FAILURE:
    '@ivrChatGPT/FETCH_IVR_CHATGPT_PROMPT_TEMPLATES_FAILURE',

  SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES:
    '@ivrChatGPT/SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES',
  SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES_SUCCESS:
    '@ivrChatGPT/SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES_SUCCESS',
  SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES_FAILURE:
    '@ivrChatGPT/SEARCH_IVR_CHATGPT_PROMPT_TEMPLATES_FAILURE',

  CREATE_IVR_CHATGPT_PROMPT_TEMPLATE:
    '@ivrChatGPT/CREATE_IVR_CHATGPT_PROMPT_TEMPLATE',
  CREATE_IVR_CHATGPT_PROMPT_TEMPLATE_SUCCESS:
    '@ivrChatGPT/CREATE_IVR_CHATGPT_PROMPT_TEMPLATE_SUCCESS',
  CREATE_IVR_CHATGPT_PROMPT_TEMPLATE_FAILURE:
    '@ivrChatGPT/CREATE_IVR_CHATGPT_PROMPT_TEMPLATE_FAILURE',

  UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE:
    '@ivrChatGPT/UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE',
  UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE_SUCCESS:
    '@ivrChatGPT/UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE_SUCCESS',
  UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE_FAILURE:
    '@ivrChatGPT/UPDATE_IVR_CHATGPT_PROMPT_TEMPLATE_FAILURE',

  DELETE_IVR_CHATGPT_PROMPT_TEMPLATE:
    '@ivrChatGPT/DELETE_IVR_CHATGPT_PROMPT_TEMPLATE',
  DELETE_IVR_CHATGPT_PROMPT_TEMPLATE_SUCCESS:
    '@ivrChatGPT/DELETE_IVR_CHATGPT_PROMPT_TEMPLATE_SUCCESS',
  DELETE_IVR_CHATGPT_PROMPT_TEMPLATE_FAILURE:
    '@ivrChatGPT/DELETE_IVR_CHATGPT_PROMPT_TEMPLATE_FAILURE',

  CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS:
    '@ivrChatGPT/CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS',
  CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS_SUCCESS:
    '@ivrChatGPT/CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS_SUCCESS',
  CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS_FAILURE:
    '@ivrChatGPT/CREATE_IVR_CHATGPT_PROMPT_COMPLETIONS_FAILURE',
};

export default Types;
