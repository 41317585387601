export const sortDate = (a, b) => {
  const datesA = a.dateHour.split('/');
  const datesB = b.dateHour.split('/');
  const monthA = datesA[1];
  const monthB = datesB[1];
  const dayA = datesA[0];
  const dayB = datesB[0];
  const crossYear = Math.abs(monthA - monthB) > 1;

  if (crossYear) {
    if (monthA < monthB) return +1;
    if (monthA > monthB) return -1;
  }

  if (!crossYear) {
    if (monthA < monthB) return -1;
    if (monthA > monthB) return +1;
    if (monthA === monthB) {
      if (dayA < dayB) return -1;
      if (dayA > dayB) return +1;
    }
  }
  return 0;
};
