import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import logo from '~/assets/ligo/ligo-bots.svg';

const ENV = process.env.REACT_APP_ENV;

const calculateSecondsLeft = (currentSecond) => {
  if (currentSecond > 0) return currentSecond - 1;
  return currentSecond;
};

const ErrorHandlerFallback = () => {
  const [timeLeft, setTimeLeft] = useState(calculateSecondsLeft(5));
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateSecondsLeft(timeLeft));
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (timeLeft === 0) {
      window.location.href = '/';
    }
  }, [timeLeft]);

  const isProduction = ENV === 'PROD';
  if (isProduction) return null;

  return (
    <S.ErrorHandlerFallbackWrapper role="alert">
      <S.ErrorHandlerFallbackHeader>
        <a href="/">
          <img src={logo} alt="Logo" />
        </a>
      </S.ErrorHandlerFallbackHeader>
      <S.ErrorHandlerFallbackTitle>
        {t('ErrorHandlerFallback.title')}
      </S.ErrorHandlerFallbackTitle>
      <S.ErrorHandlerFallbackMessage>
        {t('ErrorHandlerFallback.description')}
      </S.ErrorHandlerFallbackMessage>
      <S.ErrorHandlerFallbackRedirectMessage>
        {t('ErrorHandlerFallback.redirect')} {timeLeft}{' '}
        {t('ErrorHandlerFallback.seconds')}
      </S.ErrorHandlerFallbackRedirectMessage>
    </S.ErrorHandlerFallbackWrapper>
  );
};

export default ErrorHandlerFallback;
